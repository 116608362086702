import {Button} from 'antd';
import {ButtonType} from 'antd/lib/button';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {MetaActionInfo} from 'modules/metadata';
import {useAppDispatch} from 'store/config/hooks';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';

interface ButtonSaveOwnProps {
    actionTitle: string;
    actionType?: MetaActionInfo['actionType'];
    actionIcon?: TIconType;
    buttonType: ButtonType;
}

interface ButtonSaveProps extends ButtonSaveOwnProps {}

export const ButtonSave: React.FunctionComponent<ButtonSaveProps> = ({
    actionTitle,
    actionType,
    actionIcon,
    buttonType,
    ...props
}: ButtonSaveProps) => {
    const dispatch = useAppDispatch();
    const {setSubmitButtonActionType} = requestSliceActions;

    const handleClick = () => {
        if (actionType) dispatch(setSubmitButtonActionType(actionType));
    };

    return (
        <Button
            {...props}
            type={buttonType}
            className="save-action-btn"
            htmlType="submit"
            onClick={handleClick}
        >
            {actionIcon && <DynamicIcon type={actionIcon} />}
            {actionTitle}
        </Button>
    );
};
