// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2Nf-pM2W_AyhaB-W-DkelV {\n  border: 1px solid #E0E4F0;\n  border-radius: 5px;\n  padding: 16px;\n}\n._3o8N6H5HCwdUqEpebHrfHM {\n  margin-left: 8px;\n  color: #ED1C24;\n}\n._1vOMBXDN3uA_1i1rZ9pR21 {\n  color: #43A047;\n}\n", "",{"version":3,"sources":["webpack://src/features/sign-with-crypto-pro/ui/certificate-info/certificate-info.module.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;AADF;AAGE;EACE,gBAAA;EACA,cAAA;AADJ;AAGI;EACE,cAAA;AADN","sourcesContent":["@import 'src/themes/default/default';\n\n.crypto-pro-signature {\n  border: 1px solid #E0E4F0;\n  border-radius: 5px;\n  padding: 16px;\n\n  &__status {\n    margin-left: 8px;\n    color: @red-600;\n\n    &_is-valid {\n      color: @green-600;\n    }\n  }\n}\n@hack: true; @import \"/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crypto-pro-signature": "_2Nf-pM2W_AyhaB-W-DkelV",
	"crypto-pro-signature__status": "_3o8N6H5HCwdUqEpebHrfHM",
	"crypto-pro-signature__status_is-valid": "_1vOMBXDN3uA_1i1rZ9pR21"
};
export default ___CSS_LOADER_EXPORT___;
