import {Tooltip} from 'antd';
import cn from 'classnames';
import React from 'react';

import {useCustomLink} from 'components/@common/widgets/custom-link/use-custom-link/use-custom-link';
import {IconsMap} from 'components/dynamic-icon';
import {UIBlocker} from 'components/ui-blocker';
import {LinkDto} from 'shared/types/links';

interface ViewPudButtonProps {
    link: LinkDto;
}

export const ViewPudButton = ({link}: ViewPudButtonProps) => {
    const {action} = useCustomLink({link});

    const hasLinkAction = !!action;

    return (
        <UIBlocker
            childrenClassName="d-flex"
        >
            <Tooltip
                mouseEnterDelay={0.7}
                placement="right"
                title="Просмотреть ПУД"
            >
                <IconsMap.Document
                    onClick={() => {
                        if (hasLinkAction) {
                            action();
                        }
                    }}
                    className={cn(
                        'ais-documents-register-table__pud-button',
                        {'ais-documents-register-table__pud-button_disabled': !hasLinkAction},
                    )}
                />
            </Tooltip>
        </UIBlocker>
    );
};
