import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {
    pickDataFromQueryByAdditionalFields,
    shouldPickDataFromQueryByAdditionalInfoKeys,
} from 'components/table/action/table-action.utils';
import {selectContextRawData} from 'modules/context/context-selector';
import {selectEntityData} from 'modules/data';
import {
    actionCreateListCP,
    actionsRequestButton,
    createActionConfirm,
    createActionGetFile,
    createActionLastPeriod,
} from 'modules/data/data-actions';
import {TableEntityData} from 'modules/data/data-types';
import {MetaActionType, RequestType, selectMetadata} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {isEmptyArray} from 'shared/utils';
import {areRequiredQueryParamsPresent} from 'shared/utils/metadata';
import {useQueryParams} from 'shared/utils/query-params';
import {useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';
import {selectUserInfo} from 'store/slices/auth-slice';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

const ButtonsActionComponent: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const dispatch = useDispatch();
    const contextData = useAppSelector(selectContextRawData);
    const {selectedRowKeys = []} = useSelector((state: AppState) => (
        selectEntityData(entityName,
            EntityType.TABLE)(state) || {}) as TableEntityData);
    const {userName} = useAppSelector(selectUserInfo) || {};
    const filterMeta = useAppSelector(selectMetadata(entityName, EntityType.FILTER));
    const {urlConfig} = useAppSelector(selectMetadata(entityName, EntityType.TABLE)) || {};

    const [isDisabled, setDisabled] = useState<boolean>(false);

    const location = useLocation();
    const {getQueryParam} = useQueryParams();

    const {
        actionType,
        requestType,
        referenceUrl,
        additionalInfoKeys,
        actionIcon,
        actionTitle,
        requiredQueryParams,
    } = meta;

    useEffect(() => {
        if (!isEmptyArray(requiredQueryParams)) {
            const arePresent = areRequiredQueryParamsPresent({
                requiredQueryParams,
                getQueryParam,
            });
            setDisabled(!arePresent);
        }
    }, [location.search]);

    const handleClick = () => {
        if (actionType === MetaActionType.CREATE_LIST_CP) {
            dispatch(
                actionCreateListCP(entityName, EntityType.TABLE, referenceUrl, requestType, contextData),
            );
        } else if (
            actionType === MetaActionType.UNLOAD_CP
            || actionType === MetaActionType.DOWNLOAD_TEMPLATE
            || actionType === MetaActionType.DOWNLOAD_ALL_EXCEL
            || actionType === MetaActionType.DOWNLOAD_REPORT
            || actionType === MetaActionType.DOWNLOAD_EXCEL
            || actionType === MetaActionType.DOWNLOAD_XML
        ) {
            let queryParamsData = contextData;

            if (additionalInfoKeys
                    && shouldPickDataFromQueryByAdditionalInfoKeys({filterMeta})
                    && requestType === RequestType.GET) {
                const additionalData = pickDataFromQueryByAdditionalFields({additionalInfoKeys, getQueryParam});
                queryParamsData = {
                    ...queryParamsData,
                    ...(additionalData ?? {}),
                };
            }

            dispatch(
                createActionGetFile({
                    referenceUrl,
                    requestType,
                    data: queryParamsData,
                }),
            );
        } else if (actionType === MetaActionType.SEND_REMINDER) {
            dispatch(
                actionsRequestButton(referenceUrl, requestType, contextData),
            );
        } else if (actionType === MetaActionType.BUTTON_CONFIRM) {
            dispatch(
                createActionConfirm(entityName, referenceUrl, requestType, contextData),
            );
        } else if (actionType === MetaActionType.BUTTON_COPY_RULES_FROM_LAST_PERIOD) {
            dispatch(
                createActionLastPeriod(referenceUrl, requestType, contextData),
            );
        } else if (actionType === MetaActionType.SEND_REQUEST_WITH_USER) {
            dispatch(actionsRequestButton(referenceUrl, requestType, contextData, {userName, ids: selectedRowKeys}));
        } else if (actionType === MetaActionType.SEND_REQUEST) {
            const additionalData = pickDataFromQueryByAdditionalFields({additionalInfoKeys, getQueryParam});
            dispatch(actionsRequestButton(
                referenceUrl,
                requestType,
                additionalData,
                undefined,
                meta?.shouldIgnoreUrlConfig ? undefined : urlConfig,
            ));
        }
    };
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={actionIcon}
            disabled={isDisabled}
        >
            {actionTitle}
        </Component>
    );
};

export const ButtonsAction = ButtonsActionComponent;
