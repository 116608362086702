import {Button, Spin} from 'antd';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {ListArrayFactory} from 'components/@common/specific/list-array-factory';
import {SignatureActionButton} from 'components/ais/reclaiming-information-card/fields/signature-action-button';
import {SignatureItem} from 'components/ais/reclaiming-information-card/fields/signature-item/signature-item';
import {useUploadSignature} from 'components/ais/reclaiming-information-card/hooks';
import {DateListItem} from 'components/form/fields/date-list-item';
import {DownloadDocumentListItem} from 'components/form/fields/download-document-list-item';
import {URL_AIS3_DOCUMENT_INVENTORY} from 'shared/constants/urls';
import {Attachment} from 'shared/types/files';
import {
    ReclaimingInformationEntity,
    useLazyGetReclaimingInformationQuery,
    useRunSubmitProtocolServiceMutation,
    useRunSubmitInabilityInfoServiceMutation,
    useRunSubmitResponseToDocRequestServiceMutation,
} from 'store/api/reclaiming-information/reclaiming-information.api';

interface ReclaimingMainInfoProps {
    id: string;
    initialLoadedData: ReclaimingInformationEntity;
    onClose: () => void;
}

export const ReclaimingMainInfo = ({id, initialLoadedData, onClose}: ReclaimingMainInfoProps) => {
    const history = useHistory();

    const [runSubmitProtocolService, {isLoading: isSubmitProtocolLoading}] = useRunSubmitProtocolServiceMutation();
    const [
        runSubmitInabilityInfoService, {isLoading: isSubmitInabilityInfoLoading},
    ] = useRunSubmitInabilityInfoServiceMutation();
    const [
        runSubmitResponseToDocRequestService, {isLoading: isSubmitResponseToDocRequestLoading},
    ] = useRunSubmitResponseToDocRequestServiceMutation();

    const [
        getReclaimingInformationQuery,
        {
            currentData,
            isFetching: isDataRefetching,
        },
    ] = useLazyGetReclaimingInformationQuery();

    const reloadData = () => {
        getReclaimingInformationQuery({id});
    };

    const reclaimingInfo = currentData ?? initialLoadedData;

    const [isProtocolSignatureUploading, handleUploadProtocolSignature] = useUploadSignature({
        attachmentId: reclaimingInfo?.protocolMainDocument?.attachmentId,
        reloadData,
    });
    const [isInabilityInfoSignatureUploading, handleUploadInabilityInfoSignature] = useUploadSignature({
        attachmentId: reclaimingInfo?.notificationDocument?.objectId?.toString(),
        reloadData,
    });
    const [isResponseToDocSignatureUploading, handleUploadResponseToDocSignature] = useUploadSignature({
        attachmentId: reclaimingInfo?.responseRequirementDocument?.attachmentId,
        reloadData,
    });

    const handleCreateInventoryClick = () => {
        const docIdParam = `?docId=${reclaimingInfo?.fileListMainDocument?.[0]?.objectId}`;
        history.push(`${URL_AIS3_DOCUMENT_INVENTORY}${docIdParam}`);
    };

    const renderFormationButton = () => (
        <Button
            type="primary"
            onClick={handleCreateInventoryClick}
        >
            Сформировать
        </Button>
    );

    return (
        <Spin
            spinning={isDataRefetching}
            tip="Подпись загружена, обновляем данные..."
        >
            <ListArrayFactory
                listArrayDescription={[
                    {
                        listTitle: 'Основная информация о требовании',
                        list: [
                            {
                                title: 'Номер требования:',
                                description: reclaimingInfo?.mainDocNumber || '-',
                            },
                            {
                                title: 'Дата получения:',
                                description: <DateListItem
                                    date={reclaimingInfo?.downloadDate}
                                />,
                            },
                            {
                                title: 'Файл требования:',
                                description: <DownloadDocumentListItem
                                    fileList={
                                        reclaimingInfo?.fileListMainDocument?.[0]
                                            ? [reclaimingInfo?.fileListMainDocument?.[0]]
                                            : undefined
                                    }
                                />,
                            },
                            {
                                title: 'Подпись:',
                                description: <DownloadDocumentListItem
                                    fileList={
                                        reclaimingInfo?.fileListMainDocument?.[0]?.signatureFileName
                                            ? [{
                                                fileName: reclaimingInfo?.fileListMainDocument?.[0]
                                                    ?.signatureFileName,
                                                attachmentId: reclaimingInfo?.fileListMainDocument?.[0]
                                                    ?.attachmentId,
                                            } as Attachment] : undefined
                                    }
                                />,
                            },
                            {
                                title: 'Файл поручения:',
                                description: <DownloadDocumentListItem
                                    fileList={
                                        reclaimingInfo?.fileListMainDocument?.[1]
                                            ? [reclaimingInfo?.fileListMainDocument?.[1]]
                                            : undefined
                                    }
                                />,
                            },
                            {
                                title: 'Подпись поручения:',
                                description: <DownloadDocumentListItem
                                    fileList={
                                        reclaimingInfo?.fileListMainDocument?.[0]?.signatureFileName
                                            ? [{
                                                fileName: reclaimingInfo?.fileListMainDocument?.[1]
                                                    ?.signatureFileName,
                                                attachmentId: reclaimingInfo?.fileListMainDocument?.[1]
                                                    ?.attachmentId,
                                            } as Attachment] : undefined
                                    }
                                />,
                            },
                        ],
                    },
                    {
                        listTitle: 'Протокол обработки требования',
                        list: [{
                            title: 'Файл протокола:',
                            description: <DownloadDocumentListItem
                                fileList={
                                    reclaimingInfo?.protocolMainDocument
                                        ? [reclaimingInfo?.protocolMainDocument] : []
                                }
                            />,
                        },
                        {
                            title: 'Подпись:',
                            description: <SignatureItem
                                signatureName={reclaimingInfo?.protocolMainDocument?.signatureFileName}
                                attachmentId={reclaimingInfo?.protocolMainDocument?.attachmentId}
                            />,
                        },
                        {
                            title: 'Дата отправки:',
                            description: <DateListItem
                                date={reclaimingInfo?.processingAudit?.createdDate}
                            />,
                        },
                        ],
                        useButtonsBottomMargin: true,
                        buttons: [
                            <SignatureActionButton
                                signatureName={reclaimingInfo?.protocolMainDocument?.signatureFileName}
                                attachmentId={reclaimingInfo?.protocolMainDocument?.attachmentId}
                                isSignatureUploading={isProtocolSignatureUploading}
                                handleUploadFile={handleUploadProtocolSignature}
                            />,
                            <Button
                                type="primary"
                                disabled={
                                    !reclaimingInfo?.protocolMainDocument?.attachmentId
                                        || !reclaimingInfo?.protocolMainDocument?.signatureFileName
                                }
                                onClick={() => {
                                    runSubmitProtocolService()
                                        .unwrap()
                                        .then(onClose);
                                }}
                                loading={isSubmitProtocolLoading}
                            >
                                Отправить
                            </Button>,
                        ],
                    },
                    {
                        listTitle: 'Ответ на требование',
                        list: [{
                            title: 'Файл ответа на требование:',
                            description: <DownloadDocumentListItem
                                fileList={
                                    reclaimingInfo?.responseRequirementDocument
                                        ? [reclaimingInfo?.responseRequirementDocument] : []
                                }
                            />,
                        },
                        {
                            title: 'Подпись:',
                            description: <SignatureItem
                                signatureName={reclaimingInfo?.responseRequirementDocument?.signatureFileName}
                                attachmentId={reclaimingInfo?.responseRequirementDocument?.attachmentId}
                            />,
                        },
                        {
                            title: 'Дата отправки:',
                            description: <DateListItem
                                date={reclaimingInfo?.responseRequirementAudit?.createdDate}
                            />,
                        },
                        ],
                        useButtonsBottomMargin: true,
                        buttons: [
                            renderFormationButton(),
                            <SignatureActionButton
                                signatureName={reclaimingInfo?.responseRequirementDocument?.signatureFileName}
                                attachmentId={reclaimingInfo?.responseRequirementDocument?.attachmentId}
                                isSignatureUploading={isResponseToDocSignatureUploading}
                                handleUploadFile={handleUploadResponseToDocSignature}
                            />,
                            <Button
                                type="primary"
                                disabled={
                                    !reclaimingInfo?.responseRequirementDocument?.attachmentId
                                    || !reclaimingInfo?.responseRequirementDocument?.signatureFileName
                                }
                                onClick={() => {
                                    runSubmitResponseToDocRequestService()
                                        .unwrap()
                                        .then(onClose);
                                }}
                                loading={isSubmitResponseToDocRequestLoading}
                            >
                                Отправить
                            </Button>,
                        ],
                    },
                    {
                        listTitle: 'Протокол обработки ответа на требование',
                        list: [{
                            title: 'Файл протокола:',
                            description: <DownloadDocumentListItem
                                fileList={
                                    reclaimingInfo?.protocolResponseRequirementDocument
                                        ? [reclaimingInfo?.protocolResponseRequirementDocument] : []
                                }
                            />,
                        },
                        {
                            title: 'Подпись:',
                            description: <SignatureItem
                                signatureName={
                                    reclaimingInfo?.protocolResponseRequirementDocument?.signatureFileName
                                }
                                attachmentId={reclaimingInfo?.protocolResponseRequirementDocument?.attachmentId}
                            />,
                        },
                        {
                            title: 'Дата получения:',
                            description: <DateListItem
                                date={reclaimingInfo?.incomingProcessingAudit?.createdDate}
                            />,
                        },
                        ],
                        useButtonsBottomMargin: true,
                    },
                    {
                        listTitle: 'Уведомление о невозможности предоставить документ',
                        list: [{
                            title: 'Файл уведомления:',
                            description: <DownloadDocumentListItem
                                fileList={
                                    reclaimingInfo?.notificationDocument
                                        ? [reclaimingInfo?.notificationDocument] : []
                                }
                            />,
                        },
                        {
                            title: 'Подпись:',
                            description: <SignatureItem
                                signatureName={reclaimingInfo?.notificationDocument?.signatureFileName}
                                attachmentId={reclaimingInfo?.notificationDocument?.attachmentId}
                            />,
                        },
                        {
                            title: 'Дата отправки:',
                            description: <DateListItem
                                date={reclaimingInfo?.notificationAudit?.createdDate}
                            />,
                        },
                        ],
                        useButtonsBottomMargin: true,
                        buttons: [
                            renderFormationButton(),
                            <SignatureActionButton
                                signatureName={
                                    reclaimingInfo?.notificationDocument?.signatureFileName
                                }
                                attachmentId={reclaimingInfo?.notificationDocument?.attachmentId}
                                isSignatureUploading={isInabilityInfoSignatureUploading}
                                handleUploadFile={handleUploadInabilityInfoSignature}
                            />,
                            <Button
                                type="primary"
                                disabled={
                                    !reclaimingInfo?.notificationDocument?.attachmentId
                                    || !reclaimingInfo?.notificationDocument?.signatureFileName
                                }
                                onClick={() => {
                                    const objectId = reclaimingInfo?.notificationDocument?.objectId;
                                    if (!objectId) {
                                        return;
                                    }
                                    runSubmitInabilityInfoService({id: objectId?.toString()})
                                        .unwrap()
                                        .then(onClose);
                                }}
                                loading={isSubmitInabilityInfoLoading}
                            >
                                Отправить
                            </Button>,
                        ],
                    },
                    {
                        listTitle: 'Решение о продлении сроков предоставления документов',
                        list: [{
                            title: 'Файл решения в xml:',
                            description: <DownloadDocumentListItem
                                fileList={
                                    reclaimingInfo?.solutionDocument?.[0]
                                        ? [reclaimingInfo?.solutionDocument?.[0]]
                                        : undefined
                                }
                            />,
                        },
                        {
                            title: 'Подпись xml:',
                            description: <DownloadDocumentListItem
                                fileList={
                                    reclaimingInfo?.solutionDocument?.[0]?.signatureFileName
                                        ? [{
                                            fileName: reclaimingInfo.solutionDocument?.[0]
                                                ?.signatureFileName,
                                            attachmentId: reclaimingInfo.solutionDocument?.[0]
                                                ?.attachmentId,
                                        } as Attachment] : undefined
                                }
                            />,
                        },
                        {
                            title: 'Файл решения в pdf:',
                            description: <DownloadDocumentListItem
                                fileList={
                                    reclaimingInfo?.solutionDocument?.[1]
                                        ? [reclaimingInfo.solutionDocument?.[1]]
                                        : undefined
                                }
                            />,
                        },
                        {
                            title: 'Подпись pdf:',
                            description: <DownloadDocumentListItem
                                fileList={
                                    reclaimingInfo?.solutionDocument?.[0]?.signatureFileName
                                        ? [{
                                            fileName: reclaimingInfo.solutionDocument?.[1]
                                                ?.signatureFileName,
                                            attachmentId: reclaimingInfo.solutionDocument?.[1]
                                                ?.attachmentId,
                                        } as Attachment] : undefined
                                }
                            />,
                        },
                        {
                            title: 'Дата получения:',
                            description: <DateListItem
                                date={reclaimingInfo?.solutionAudit?.createdDate}
                            />,
                        },
                        {
                            title: 'Дата предоставления документов:',
                            description: <DateListItem
                                date={reclaimingInfo?.solutionAudit?.lastModifiedDateMainDocument}
                            />,
                        },
                        ],
                    },
                ]}
            />
        </Spin>
    );
};
