import {SafetyCertificateOutlined} from '@ant-design/icons';
import cn from 'classnames';
import React from 'react';

import {useCertificateValidityDate} from '../../hooks/use-certificate-validity';
import {CertificateExtended} from '../../model/types';
import s from './certificate-info.module.less';

interface CryptoProCertificateInfoProps {
    certificate?: CertificateExtended;
}

export const CryptoProCertificateInfo: React.FC<CryptoProCertificateInfoProps> = (
    {certificate}: CryptoProCertificateInfoProps,
) => {
    const {
        validFromFormatted,
        validToFormatted,
        isValid,
    } = useCertificateValidityDate(certificate?.certificate);

    return (
        <div className={cn(s['crypto-pro-signature'])}>
            <div className={cn('fw-500')}>
                <SafetyCertificateOutlined className="mr-1" />
                Информация о сертификате
            </div>

            <div>
                Статус:
                <span className={cn(s['crypto-pro-signature__status'], {
                    [s['crypto-pro-signature__status_is-valid']]: isValid,
                })}
                >
                    {isValid ? 'Действителен' : 'Недействителен'}
                </span>
            </div>

            <div className="mt-1">
                <div>Криптопровайдер: {certificate?.providerName}</div>
                <div>Алгоритм ключа: {certificate?.algorithmName}</div>
                <div>Издатель: {certificate?.certificate.issuerName}</div>
                <div>Выдан: {validFromFormatted}</div>
                <div>Действителен до: {validToFormatted}</div>
                {certificate?.owner.map(tag => (
                    <div
                        key={tag.title}
                    >
                        {tag.title}: {tag.description}
                    </div>
                ))}
            </div>
        </div>
    );
};
