import {
    Space, Spin, Typography, Upload,
} from 'antd';
import Button from 'antd/es/button';
import {RcFile} from 'antd/lib/upload';
import {isNil} from 'lodash';
import React, {useEffect} from 'react';

import {ConfirmModal} from 'components/confirm-modal/confirm-modal';
import {SimpleActionButton} from 'components/form/components';
import {SignWithCryptoProInput} from 'features/sign-with-crypto-pro';
import {createActionGetFile} from 'modules/data/data-actions';
import {loadDocumentsSigningMethod} from 'modules/documents/documents-actions';
import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {DocumentsSigningMethod} from 'modules/documents/documents-types';
import {RequestType} from 'modules/metadata';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {FileDto, UploadFile} from 'shared/types/files';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {
    generateUploadFileList,
    getHandleBeforeUpload,
    getSignsUploadHandler,
    useButtonsUploadImitation,
} from '../uploader-list.utils';
import {SIGN_UPLOAD_EXTENSIONS} from './constants/upload-list-signs-contants';
import {FileListUploader} from './file-list-uploader';
import {SignFilesErrors} from './sign-and-file-list-uploader.types';
import {SignListUploader} from './sign-list-uploader';

import './sign-and-file-list-uploader.less';

interface SignAndFileListUploaderProps {
    disabled?: boolean;
    buttonText?: string;
    buttonSignText?: string;
    buttonDownloadDocumentText?: string;
    url?: string;
    signUrl?: string;
    fileList?: FileDto[];
    onChange?: (fileList: UploadFile<Blob>[]) => void;
    dataKeyForDownload?: string;
    requestCommentId?: string;
    isDownloadDocumentList?: boolean;
}

/**
 * Поле для загрузки файлов с подписями
 * @param fileList конечный массив обьектов в которых главными полями будут file: сам файл, sign: его подпись
 * @param buttonText
 * @param buttonSignText
 * @param buttonDownloadAllDocuments
 * @param disabled
 * @param onChange
 * @param url
 * @param dataKeyForDownload
 * @param signUrl
 * @param requestCommentId
 * @constructor
 */

export const SignAndFileListUploader: React.FC<SignAndFileListUploaderProps> = ({
    fileList = [],
    buttonText = 'Выбрать файл',
    buttonSignText = 'Массовое подписание',
    buttonDownloadDocumentText = 'Скачать все',
    isDownloadDocumentList,
    disabled,
    onChange,
    url,
    dataKeyForDownload,
    requestCommentId,
    signUrl,
}: SignAndFileListUploaderProps) => {
    const dispatch = useAppDispatch();
    const [signsErrors, setSignsErrors] = React.useState<SignFilesErrors>({
        isErrorNamesConfirmVisible: false,
        isErrorEmptyFilesConfirmVisible: false,
        errorSignFilesNames: [],
        errorEmptySignFilesNames: [],
    });
    const {handleClick: handleFileClick} = useButtonsUploadImitation();
    const {handleClick: handleSignClick} = useButtonsUploadImitation();

    const documentsSigningMethod = useAppSelector(selectDocumentsSigningMethod);

    const {hasUserRoleFunction, isLoadingUserFunctions} = useUserRoleFunctions({
        sections: ['app.requests.title'], // todo: requests не должно быть хардкодом, заменить на entityName
    });

    useEffect(() => {
        dispatch(loadDocumentsSigningMethod());
    }, []);

    const normalizedFileList = generateUploadFileList(
        fileList as FileDto[],
        {url, dataKeyForDownload, signUrl},
    ) ?? [];

    const handleFileBeforeUpload = getHandleBeforeUpload(normalizedFileList, onChange);
    const handleSignsUpload = getSignsUploadHandler(normalizedFileList, setSignsErrors, onChange);

    const downloadDocumentList = () => {
        dispatch(
            createActionGetFile({
                referenceUrl: `${url}/all-files-by-comment/${requestCommentId}`,
                requestType: RequestType.GET,
            }),
        );
    };

    const confirmErrorNameMessage = (
        <ConfirmModal
            title="Отсутствует подходящий файл для следующих файлов подписи"
            visible={signsErrors?.isErrorNamesConfirmVisible || false}
            content={signsErrors?.errorSignFilesNames}
            onCancel={() => setSignsErrors(p => ({
                ...p,
                isErrorNamesConfirmVisible: false,
                errorSignFilesNames: [],
            }))}
            cancelText="Отмена"
            onConfirm={() => setSignsErrors(p => ({
                ...p,
                isErrorNamesConfirmVisible: false,
                errorSignFilesNames: [],
            }))}
            useWrapperDivElementAsContainer
            modalClassName="confirm-error-message"
        />
    );

    const confirmErrorEmptyFilesMessage = (
        <ConfirmModal
            title="Следующие файлы ЭП пустые"
            visible={signsErrors?.isErrorEmptyFilesConfirmVisible || false}
            content={signsErrors?.errorEmptySignFilesNames}
            onCancel={() => setSignsErrors(p => ({
                ...p,
                isErrorEmptyFilesConfirmVisible: false,
                errorEmptySignFilesNames: [],
            }))}
            cancelText="Отмена"
            onConfirm={() => setSignsErrors(p => ({
                ...p,
                isErrorEmptyFilesConfirmVisible: false,
                errorEmptySignFilesNames: [],
            }))}
            useWrapperDivElementAsContainer
        />
    );

    const buttonDownloadDocumentList = (
        <Space
            direction="vertical"
            className="button-download-document-list"
        >
            <Button
                type="primary"
                onClick={downloadDocumentList}
            >{buttonDownloadDocumentText}
            </Button>
        </Space>
    );

    return (
        <>
            {isDownloadDocumentList && buttonDownloadDocumentList}
            {signsErrors?.isErrorNamesConfirmVisible && confirmErrorNameMessage}
            {signsErrors?.isErrorEmptyFilesConfirmVisible && confirmErrorEmptyFilesMessage}
            <div
                className="sing-and-file-list-uploader"
            >
                <div className="files">
                    <Upload
                        disabled={disabled}
                        showUploadList={false}
                        fileList={normalizedFileList}
                        beforeUpload={handleFileBeforeUpload}
                        multiple
                    >
                        <Space direction="vertical">
                            {disabled && <Typography.Text style={{lineHeight: '36px'}}>Файлы</Typography.Text>}
                            {!disabled && (
                                <SimpleActionButton
                                    icon="File"
                                    type="primary"
                                    title={buttonText}
                                    onClick={handleFileClick}
                                />
                            )}
                        </Space>
                    </Upload>
                    <FileListUploader
                        fileList={normalizedFileList}
                        onChange={onChange}
                        disabled={disabled}
                    />
                </div>

                <div className="signs">
                    {documentsSigningMethod === DocumentsSigningMethod.CRYPTO_PRO_PLUGIN
                        && hasUserRoleFunction(UserRoleFunction.SIGN_FILE_DOC) && !disabled
                        ? (
                            <SignWithCryptoProInput
                                fileList={normalizedFileList
                                    .filter(nFile => !nFile.attachmentId && !nFile.signName && !!nFile.file)
                                    .map(nFile => nFile.file as RcFile)}
                                documentsToSignInDB={normalizedFileList
                                    .filter(nFile => (!isNil(nFile.attachmentId) || !isNil(nFile.id))
                                    && !nFile.signName).map(nFile => ({
                                        attachId: nFile.attachmentId ? nFile.attachmentId as string : `${nFile?.id}`,
                                        documentName: nFile.name,
                                    }))}
                                onChange={signedFiles => {
                                    handleSignsUpload(signedFiles, signedFiles);
                                }}
                                isSignaturesListHidden
                                buttonLabel={buttonSignText}
                                entityName="requests" // (!) временное решение, не должно быть хардкодом
                            />
                        ) : (
                            <Upload
                                accept={SIGN_UPLOAD_EXTENSIONS}
                                disabled={disabled}
                                showUploadList={false}
                                multiple
                                beforeUpload={handleSignsUpload}
                            >

                                <Space direction="vertical">
                                    {!disabled && documentsSigningMethod === DocumentsSigningMethod.UPLOAD_EDS_FILE ? (
                                        <Spin
                                            spinning={isLoadingUserFunctions}
                                            size="small"
                                            style={{width: '100%'}}
                                        >
                                            <SimpleActionButton
                                                type="primary"
                                                title={buttonSignText}
                                                onClick={handleSignClick}
                                            />
                                        </Spin>
                                    ) : (
                                        <Typography.Text style={{lineHeight: '36px'}}>
                                            Файлы подписи
                                        </Typography.Text>
                                    )}
                                </Space>
                            </Upload>
                        )}
                    <SignListUploader
                        fileList={normalizedFileList}
                        setSignsErrors={setSignsErrors}
                        onChange={onChange}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};
