import {Checkbox, Input} from 'antd';
import React, {FC} from 'react';

import {DateInput} from 'components/@common/inputs/date-input';
import {CustomSelect, CustomSelectMode} from 'components/form/inputs/custom-select';
import {ReportConfigurationQueryParameterVariableType} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {TableReportFilterFieldType} from 'store/slices/table-report-slice/table-report-slice-types';

import {formatReferenceDataForSqlValueSet} from '../utils/table-report-filter.utils';

export interface TableReportFieldResolverProps {
    label: string;
    type: ReportConfigurationQueryParameterVariableType;
    format?: string;

    // Этот блок должен быть передан от Form. Item
    value?: any;
    onChange?: (value: any) => void;

    // Поля для reference select
    referenceUrl?: string;
    referenceUrlQueryParams?: {
        [index: string]: string;
    };
    showSearch?: boolean;
    isClearable?: boolean;

    disabled?: boolean;

    paramName: string;
    areParametersApplied: boolean;
}

export const TableReportFieldResolver: FC<TableReportFieldResolverProps> = ({
    referenceUrl,
    referenceUrlQueryParams,
    showSearch,
    type,
    paramName,
    areParametersApplied,
    disabled,
    ...props
}: TableReportFieldResolverProps) => {
    const inputPlaceholder = `Введите ${props?.label?.toLowerCase()}`;
    const selectPlaceholder = 'Выберите значение';

    const isDisabled = areParametersApplied || disabled;

    const handleNumberInputChange = (value: string) => {
        props?.onChange?.(value.split(',').join('.'));
    };

    switch (type) {
    case TableReportFilterFieldType.INTEGER:
    case TableReportFilterFieldType.NUMBER:
        return (
            // InputNumber ограничивает максимальное значение до Number.MAX_SAFE_INTEGER, чего нам не не достаточно
            <Input
                {...props}
                onChange={e => {
                    e.persist();
                    handleNumberInputChange(e.target.value);
                }}
                value={props.value}
                onBlur={e => {
                    e.persist();
                    let {value} = e.target;
                    if (value.startsWith('.')) value = `0${value}`;
                    handleNumberInputChange(value);
                }}
                disabled={isDisabled}
                autoComplete="off"
                placeholder="Введите значение"
            />
        );
    case TableReportFilterFieldType.BOOLEAN:
        return (
            <Checkbox
                disabled={isDisabled}
                {...props}
            >
                {props.label}
            </Checkbox>
        );
    case TableReportFilterFieldType.DATE:
        return (
            <DateInput
                value={props.value}
                onChange={props.onChange}
                disabled={isDisabled}
                resetTimeOnSet
            />
        );
    case TableReportFilterFieldType.DATETIME:
        return (
            <DateInput
                showTime
                value={props.value}
                onChange={props.onChange}
                disabled={isDisabled}
            />
        );
    case TableReportFilterFieldType.VALUE_SET:
        return (
            <CustomSelect
                {...props}
                settings={{
                    showSearch,
                    isClearable: props.isClearable,
                    valuePath: 'lookupCode',
                    labelPath: 'meaning',
                    url: referenceUrl,
                    placeholder: selectPlaceholder,
                    referenceUrlQueryParams: referenceUrlQueryParams ?? {},
                    isDisabled,
                }}
                fixedDropdown={false}
            />
        );
    case TableReportFilterFieldType.SQL_VALUE_SET:
    case TableReportFilterFieldType.SQL_MULTI_VALUE_SET:
        return (
            <>
                <CustomSelect
                    {...props}
                    settings={{
                        showSearch,
                        mode: type === TableReportFilterFieldType.SQL_MULTI_VALUE_SET
                            ? CustomSelectMode.multiple : undefined,
                        isClearable: props.isClearable,
                        valuePath: 'lookupCode',
                        labelPath: 'meaning',
                        url: referenceUrl,
                        placeholder: selectPlaceholder,
                        referenceUrlQueryParams: referenceUrlQueryParams ?? {},
                        isDisabled,
                    }}
                    rawDataInStoreFormatter={data => formatReferenceDataForSqlValueSet(data, paramName)}
                    fixedDropdown={false}
                />
            </>
        );
    case TableReportFilterFieldType.MULTI_VALUE_SET:
        return (
            <CustomSelect
                {...props}
                settings={{
                    showSearch,
                    mode: CustomSelectMode.multiple,
                    isClearable: props.isClearable,
                    valuePath: 'lookupCode',
                    labelPath: 'meaning',
                    url: referenceUrl,
                    placeholder: selectPlaceholder,
                    isDisabled,
                }}
                fixedDropdown={false}
            />
        );
    default:
        return (
            <Input
                {...props}
                disabled={isDisabled}
                autoComplete="off"
                placeholder={inputPlaceholder}
            />
        );
    }
};
