import {LoadingOutlined} from '@ant-design/icons';
import {
    Button, Modal, Spin, Tooltip,
} from 'antd';
import {ModalProps} from 'antd/es/modal';
import {TooltipProps} from 'antd/es/tooltip';
import cn from 'classnames';
import React, {
    CSSProperties,
    ReactElement, useImperativeHandle, useState,
} from 'react';

import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';

import {ModalCloseConfirmation} from '../modal-close-confirmation';

import './modal-opener-component.less';

export interface ModalOpenerComponentProps {
    children?: (ReactElement | string | boolean) | (ReactElement | string | boolean)[];
    component?: ReactElement;
    tooltip?: TooltipProps;
    modalProps?: ModalProps;
    extraControls?: ReactElement;
    controlsStyle?: CSSProperties;
    shouldConfirm?: () => boolean;
    isOpen?: boolean;
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    handleSave?: (setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>) => void;
    handleCancel?: (setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>) => void;
    handleDelete?: (setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>) => void;
    hideControls?: {
        all?: boolean;
        save?: boolean;
        cancel?: boolean;
    };
    loadingControls?: {
        save?: boolean;
    };
    disabledControls?: {
        save?: boolean;
        delete?: boolean;
    };
    controlLabels?: {
        save?: string | ReactElement;
        cancel?: string;
    };
    componentWrapperClassNames?: cn.Argument;
    onShow?: () => void;
    isLoading?: boolean;
    preventComponentClick?: () => boolean;
    afterModalClose?: () => void;
    onComponentClick?: () => void;
    footer?: React.ReactNode;
}

export interface ModalOpenerComponentRef {
    closeModal: (shouldConfirm?: boolean) => void;
    showModal: () => void;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalOpenerComponent = React.forwardRef<ModalOpenerComponentRef, ModalOpenerComponentProps>(({
    children,
    component,
    modalProps = {},
    isOpen,
    shouldConfirm,
    setIsOpen,
    handleSave,
    handleDelete,
    handleCancel,
    hideControls,
    extraControls,
    loadingControls,
    disabledControls,
    controlLabels = {},
    componentWrapperClassNames,
    onShow,
    isLoading,
    tooltip,
    preventComponentClick,
    controlsStyle,
    afterModalClose, // срабатывает после закрытия модалки и завершения анимации
    onComponentClick,
    footer,
}: ModalOpenerComponentProps, ref) => {
    const [isModalOpenState, setIsModalOpenState] = useState(false);
    const [isModalOpen, setIsModalOpen] = [isOpen ?? isModalOpenState, setIsOpen ?? setIsModalOpenState];

    const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState(false);

    const {
        save: saveLabel = 'Сохранить',
        cancel: cancelLabel = 'Отмена',
    } = controlLabels;

    const {
        width = '980px',
        title = <>&nbsp;</>,
        forceRender = true,
    } = modalProps;

    const showModal = () => {
        setIsModalOpen(true);
        onShow?.();
    };

    const handleClose = () => {
        if (handleCancel) {
            handleCancel(setIsModalOpen);
        } else {
            setIsModalOpen(false);
        }
    };

    const onCancel = () => {
        if (shouldConfirm?.()) {
            setIsCloseConfirmationOpen(true);
            // setIsModalOpen(false);
        } else {
            handleClose();
        }
    };

    useImperativeHandle(ref, () => ({
        closeModal: onCancel,
        setIsModalOpen,
        showModal,
    }));

    const componentToRender = (
        <div
            className={cn(componentWrapperClassNames)}
            onClick={() => {
                onComponentClick?.();
                if (!preventComponentClick?.()) showModal();
            }}
        >
            {component}
        </div>
    );

    return (
        <>
            {(() => {
                if (!component) return null;
                if (tooltip) {
                    return (
                        <Tooltip
                            mouseEnterDelay={0.7}
                            {...tooltip}
                        >
                            {componentToRender}
                        </Tooltip>
                    );
                }
                return componentToRender;
            })()}

            <Modal
                width={width}
                className="modal-opener-component"
                footer={footer || null}
                maskClosable={false}
                forceRender={forceRender}
                title={title}
                visible={isModalOpen}
                onCancel={() => {
                    onCancel();
                }}
                afterClose={() => {
                    afterModalClose?.();
                }}
                closeIcon={<CloseIcon className={cn('modal-opener-component__close-icon')} />}
                {...modalProps}
            >
                <Spin
                    spinning={isLoading ?? false}
                    tip="Загрузка..."
                >
                    {children}
                </Spin>

                {!hideControls?.all && (
                    <div
                        className={cn('modal-opener-component__controls')}
                        style={controlsStyle}
                    >
                        {handleDelete && (
                            <Button
                                disabled={disabledControls?.delete}
                                type="primary"
                                className="button-critic"
                                onClick={() => {
                                    handleDelete(setIsModalOpen);
                                }}
                            >Удалить
                            </Button>
                        )}
                        {!hideControls?.save && handleSave && (
                            <Button
                                disabled={disabledControls?.save}
                                type="primary"
                                onClick={() => {
                                    handleSave?.(setIsModalOpen);
                                }}
                            >
                                {loadingControls?.save && (
                                    <div
                                        className="d-inline"
                                        style={{marginRight: 6}}
                                    ><LoadingOutlined />
                                    </div>
                                )}

                                {saveLabel}
                            </Button>
                        )}
                        {extraControls}
                        {!hideControls?.cancel && (
                            <Button
                                type="default"
                                onClick={() => {
                                    onCancel();
                                }}
                            >{cancelLabel}
                            </Button>
                        )}
                    </div>
                )}
            </Modal>

            {shouldConfirm && (
                <ModalCloseConfirmation
                    isOpen={isCloseConfirmationOpen}
                    onClose={() => {
                        setIsCloseConfirmationOpen(false);
                        handleClose();
                    }}
                    onBack={() => {
                        setIsCloseConfirmationOpen(false);
                        showModal();
                    }}
                />
            )}
        </>
    );
});
