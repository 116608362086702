import {Button} from 'antd';
import React from 'react';

import {MetaActionInfo} from 'modules/metadata';
import {setFormDraftMetadata} from 'modules/metadata/metadata-actions';
import {useAppDispatch} from 'store/config/hooks';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';

interface ButtonSaveDraftProps {
    actionType: MetaActionInfo['actionType'];
    actionTitle: string;
    entityName: string;
}

export const ButtonSaveDraft: React.FC<ButtonSaveDraftProps> = ({
    actionType,
    actionTitle = 'Сохранить',
    entityName,
}: ButtonSaveDraftProps) => {
    const dispatch = useAppDispatch();
    const {setSubmitButtonActionType} = requestSliceActions;

    const handleClick = () => {
        dispatch(setFormDraftMetadata({
            entityName,
            isDraft: true,
        }));
        dispatch(setSubmitButtonActionType(actionType));
    };

    return (
        <Button
            type="primary"
            className="save-action-btn"
            onClick={handleClick}
            htmlType="submit"
        >{actionTitle}
        </Button>
    );
};
