import {Card} from 'antd';
import React, {FunctionComponent, useEffect} from 'react';

import {Form} from 'components/form/form';
import {FormMode} from 'components/form/form-types';
import {Spinner} from 'components/spinner';
import {selectContextRawData} from 'modules/context/context-selector';
import {
    selectEntityData, resetLoadedData, Entity,
} from 'modules/data';
import {
    loadEditFormData,
    performActionForForm,
    resetFormData,
    resetFormUsedAdditionalOptions,
} from 'modules/data/data-actions';
import {FormEntityData} from 'modules/data/data-types';
import {selectMetadata, loadMetadata as loadMetadataAction, RequestType} from 'modules/metadata';
import {resetMetadata} from 'modules/metadata/metadata-actions';
import {DynamicTitle, UrlConfig} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {StateSetter} from 'shared/types/generics';
import {combineString} from 'shared/utils/combine-string';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {controlRatioSelectors} from 'store/slices/control-ratio-slice';

interface IOwnProps {
    entityName: string;
    entityType?: EntityType;
    url: string;
    resetEntityName?: string;
    resetEntityType?: EntityType;
    onClose?: () => void;
    setShouldResetTable?: StateSetter<boolean>;
}

export const FormEditContent: FunctionComponent<IOwnProps> = ({
    entityName,
    entityType = EntityType.FORM,
    url,
    resetEntityName,
    onClose,
    setShouldResetTable,
    resetEntityType,
}: IOwnProps) => {
    const dispatch = useAppDispatch();
    const metadataSelector = selectMetadata(entityName, entityType);
    const dataSelector = selectEntityData(entityName, EntityType.FORM);
    const metadataTableSelector = selectMetadata(entityName, EntityType.TABLE);

    const metadata = useAppSelector(state => metadataSelector(state));
    const metadataTable = useAppSelector(state => metadataTableSelector(state));
    const formData = useAppSelector(state => dataSelector(state)) as FormEntityData;
    const contextData = useAppSelector(selectContextRawData);
    const modalParameters = useAppSelector(controlRatioSelectors.selectFormAdditionalParameters);

    const actionForForm = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        entityName: string,
        referenceUrl: string,
        requestType: RequestType,
        newData: Record<string, any>,
        isJsonRequest?: boolean,
        urlParamKey?: string,
        additionalData?: Entity,
        urlConfig?: UrlConfig,
    ) => dispatch(performActionForForm(entityType)(
        entityName, referenceUrl, requestType, newData, isJsonRequest, urlParamKey, additionalData, urlConfig,
    ));
    const onHandleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    React.useEffect(() => {
        if (!metadata) {
            dispatch(loadMetadataAction(entityName, entityType));
        }
        if (!formData?.initialData && metadata) {
            dispatch(loadEditFormData(entityName, url, metadata?.prefix, metadata?.urlConfig));
        }
        if (metadataTable?.useDifferentForms) {
            onHandleClose();
        }
    }, [metadata]);

    // data cleanup on unmount
    useEffect(() => () => {
        dispatch(resetFormData({entityName}));
        dispatch(resetFormUsedAdditionalOptions({entityName}));
        dispatch(resetMetadata({entityName, entityType, loading: false}));
        if (resetEntityName && resetEntityType) {
            dispatch(resetLoadedData(resetEntityName, resetEntityType));
        }
    }, []);

    const dynamicTitleOptions: DynamicTitle = metadata?.dynamicFormTitle as DynamicTitle;

    const cardTitle = (dynamicTitleOptions?.edit
        && combineString(dynamicTitleOptions.edit, formData?.data)) || dynamicTitleOptions?.edit;

    const isLoaded = !!metadata && !!formData && formData.initialData;
    return isLoaded ? (
        <Form
            mode={FormMode.EDIT}
            cardTitle={cardTitle}
            formData={formData}
            meta={metadata}
            entityName={entityName}
            onClose={onHandleClose}
            actionForForm={actionForForm}
            additionalValuesForRequest={modalParameters || undefined}
            contextData={contextData}
            setShouldResetTable={setShouldResetTable}
        />
    ) : <Card style={{paddingTop: 20}}> <Spinner /></Card>;
};
