import {Spin} from 'antd';
import cn from 'classnames';
import React, {CSSProperties} from 'react';

import s from './spinner.module.less';

interface SpinnerClassNames {
    containerClassName: string;
}

interface SpinnerProps {
    tip?: string;
    style?: CSSProperties;
    classNames?: SpinnerClassNames;
}

const DEFAULT_TIP = 'Загрузка...';

export const Spinner: React.FC<SpinnerProps> = ({
    tip = DEFAULT_TIP,
    style,
    classNames,
}: SpinnerProps) => (
    <div className={cn(
        s['spinner-container'],
        classNames?.containerClassName && s[classNames.containerClassName],
    )}
    >
        <Spin
            style={style}
            tip={tip}
        />
    </div>
);
