import {createHash} from 'crypto-pro';

import {FileExtensions} from 'shared/constants/file-extensions';

import {fetchFileHash, fetchFileInfo} from '../api';
import {CADESCOM_HASH_ALGORITHM_CP_SHA1, DEFAULT_SIGNED_FILE_NAME} from '../model/constants';
import {CertificateExtended, CryptoProSignatureType} from '../model/types';
import {getSignatureByType} from './get-sign-by-type';

interface SignContentArgs {
    file?: File;
    docId?: string;
    entityName?: string;
    certificateThumbprint: string;
    certificateCadescomAlgorithm: number;
    selectedCertificate: CertificateExtended;
    selectedSignatureType: CryptoProSignatureType;
    selectedFileExtension?: string;
    signedFileName?: string;
}

interface SignContentResult {
    signatureFile: File;
    fileName?: string;
    publishAllowed?: boolean;
}

/**
 * Подписывает файл или документа с использованием CryptoPro.
 * Поддерживает создание как присоединённую (attached), так и отсоединённую (detached) подписей.
 *
 * @param file Файл для подписания.
 * Если не указан, будет использоваться документ, идентифицируемый `docId` и `entityName`.
 * @param docId Идентификатор документа для подписания (используется, если файл не указан).
 * @param entityName Название сущности, связанной с документом для подписания (используется, если файл не указан).
 * @param certificateThumbprint Отпечаток сертификата для подписи.
 * @param certificateCadescomAlgorithm Алгоритм подписи Cadescom.
 * @param selectedCertificate Выбранный сертификат, расширенный дополнительной информацией.
 * @param selectedSignatureType Тип подписи (вложенная или отделённая).
 * @param selectedFileExtension Расширение для создаваемого файла подписи. По умолчанию — `.sig`.
 * @param signedFileName Название файла подписи по умолчанию, если имя не указано. По умолчанию — `sgn`.
 *
 * @returns Объект `SignContentResult`, содержащий файл с подписью, имя файла и флаг разрешения публикации.
 *
 * @throws Если отсутствуют обязательные параметры `docId` и `entityName` при подписании документа.
 */
export const signContent = async ({
    file,
    docId,
    entityName,
    certificateThumbprint,
    certificateCadescomAlgorithm,
    selectedCertificate,
    selectedSignatureType,
    selectedFileExtension = FileExtensions.SIG,
    signedFileName = DEFAULT_SIGNED_FILE_NAME,
}: SignContentArgs): Promise<SignContentResult> => {
    const getFileMetaToSign = async () => {
        const fileBuffer = await file?.arrayBuffer() || '';
        const fileHash = await createHash(fileBuffer, {hashedAlgorithm: certificateCadescomAlgorithm});
        const fileName = file?.name || signedFileName;

        return {fileHash, fileName, publishAllowed: false};
    };

    const getFileMetaToSignFromDoc = async () => {
        if (!docId || !entityName) {
            throw new Error('Для подписания документа требуется docId и entityName');
        }

        const fileHash = (await fetchFileHash({
            entityName,
            docId,
            algorithmCode: certificateCadescomAlgorithm,
        }));

        const {fileName = signedFileName, publishAllowed = false} = await fetchFileInfo(entityName, docId);

        return {fileHash, fileName, publishAllowed};
    };

    const {fileHash, fileName, publishAllowed} = file
        ? await getFileMetaToSign()
        : await getFileMetaToSignFromDoc();

    const privateKey = await selectedCertificate.certificate.getCadesProp('PrivateKey');
    if (certificateCadescomAlgorithm !== CADESCOM_HASH_ALGORITHM_CP_SHA1) {
        await privateKey.propset_CachePin(true);
    }

    const signature = await getSignatureByType({
        selectedSignatureType,
        certificateThumbprint,
        certificateCadescomAlgorithm,
        fileHash,
        entityName,
        docId: file ? undefined : docId,
        fileArrayBuffer: file ? await file.arrayBuffer() : undefined,
    });

    return {
        signatureFile: new File([signature], `${fileName}${selectedFileExtension}`, {type: 'text/plain'}),
        fileName,
        publishAllowed,
    };
};
