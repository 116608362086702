import {Button, Form} from 'antd';
import {FormListFieldData} from 'antd/es/form/FormList';
import {FormInstance} from 'antd/lib/form';
import React from 'react';
import {useDispatch} from 'react-redux';

import {DynamicIcon} from 'components/dynamic-icon';
import {setData} from 'modules/data/data-actions';
import {EntityData, FormEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';

import {FieldTreeChild} from './field-tree-child';
import {FieldsTreeGenerator} from './fields-tree-generator';

import './fields-tree.less';

interface FieldsTreeProps {
    fieldMeta: FieldMeta;
    entityName: string;
    formData?: FormEntityData;
    form?: FormInstance;
}

export const FieldsTree: React.FunctionComponent<FieldsTreeProps> = ({
    fieldMeta,
    formData,
    form,
    entityName,
}: FieldsTreeProps) => {
    const dispatch = useDispatch();
    const fieldChildrenMeta = fieldMeta.children?.find(field => !!field.children && !!field.children.length);

    return (
        <div>
            <Form.List
                name={fieldMeta.key}
                key="programSetPrograms"
            >
                {(fields: FormListFieldData[], {add, remove}) => (
                    <div>
                        {/* todo: parameters should be rendered after setting program, */}
                        {/* backend will send field to render */}
                        <Button
                            className="button"
                            onClick={() => {
                                add({
                                    program: undefined,
                                    programSetArgs: [
                                        {
                                            code: '',
                                            defaultType: undefined,
                                        },
                                    ],
                                });
                            }}
                        >
                            <DynamicIcon
                                className="button__icon"
                                type="PlusCircleOutlined"
                            />
                            Добавить программу
                        </Button>

                        {fields.map((field: FormListFieldData) => (
                            <div
                                key={field.key}
                                className="fields-tree"
                            >
                                <div className="fields-tree__header">
                                    <div>Программы этапа</div>
                                    {fields.length > 1 && (
                                        <Button
                                            className="button"
                                            onClick={() => {
                                                if (formData?.data && formData?.data?.programSetPrograms
                                                    && Array.isArray(formData?.data?.programSetPrograms)) {
                                                    formData?.data?.programSetPrograms.splice(field.name, 1);
                                                    dispatch(setData({
                                                        entityName,
                                                        entityType: EntityType.FORM,
                                                        data: formData as EntityData,
                                                    }));
                                                }
                                                remove(field.name);
                                            }}
                                        >
                                            <DynamicIcon
                                                className="button__icon"
                                                type="DeleteOutlined"
                                            />
                                            Удалить
                                        </Button>
                                    )}
                                </div>
                                <div className="fields-tree__wrapper">
                                    <div >
                                        <FieldsTreeGenerator
                                            fieldData={field}
                                            meta={fieldMeta}
                                            form={form}
                                            formData={formData}
                                            entityName={entityName}
                                        />
                                    </div>

                                    {fieldChildrenMeta && (
                                        <FieldTreeChild
                                            fieldMeta={fieldChildrenMeta}
                                            parentField={field}
                                            form={form}
                                            formData={formData}
                                            entityName={entityName}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </Form.List>
        </div>
    );
};
