import {Button, Form} from 'antd';
import Empty from 'antd/es/empty';
import cn from 'classnames';
import isMatch from 'lodash/isMatch';
import React, {useEffect} from 'react';

import {DirectoriesCompanyFormTabProps} from 'components/directories-company-form/directories-company-form.types';
import {AisSignatoriesItem} from 'components/directories-company-form/tabs/ais-signatories/ais-signatories-item/ais-signatories-item';
import {DynamicIcon} from 'components/dynamic-icon';
import {isEmptyArray} from 'shared/utils';
import {areArraysLengthEqual} from 'shared/utils/arrays';
import {showMessage} from 'shared/utils/notifications';
import {showMessageFromResponse} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {directoriesCompanySelectors} from 'store/slices/directories-company-slice/directories-company-slice';
import {
    loadAisSignatoriesByOrgId,
    uploadAisSignatoriesByOrgId,
} from 'store/slices/directories-company-slice/directories-company-slice-thunks';
import {AisSignatoryDto} from 'store/slices/directories-company-slice/directories-company-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import './ais-signatories.less';

interface AisSignatoriesProps extends DirectoriesCompanyFormTabProps {
    entityId: number | null;
}

export const AisSignatories = ({
    form,
    entityId,
    resetFormStatus,
    setDirty,
    isDisabled,
}: AisSignatoriesProps) => {
    const dispatch = useAppDispatch();

    const isAisSignatoriesLoading = useAppSelector(s => selectIsThunkPending(s, loadAisSignatoriesByOrgId.typePrefix));
    const initialValues = useAppSelector(directoriesCompanySelectors.selectAisSignatories);

    useEffect(() => {
        if (!initialValues && entityId) {
            dispatch(loadAisSignatoriesByOrgId({orgId: entityId}));
        }
        if (initialValues) form.resetFields();
    }, [initialValues]);

    useEffect(() => {
        if (initialValues) form.resetFields();
    }, [initialValues]);

    const handleSubmit = (values: {aisSignatories: AisSignatoryDto[]}) => {
        if (!entityId) {
            console.error('Не найден id организации');
            return;
        }

        dispatch(uploadAisSignatoriesByOrgId({
            orgId: entityId,
            signatories: values.aisSignatories,
        })).unwrap()
            .then(
                resp => {
                    showMessage({message: resp ?? 'Список обновлен'});
                    dispatch(loadAisSignatoriesByOrgId({orgId: entityId}));
                    resetFormStatus();
                },
                error => showMessageFromResponse({response: error, isError: true}),
            );
    };

    const handleValuesChange = () => {
        if (!setDirty) {
            console.error('handleValuesChange -> функция setDirty null или undefined');
            return;
        }

        const currentValues = form.getFieldValue('aisSignatories');

        if (!areArraysLengthEqual(initialValues, currentValues)) {
            setDirty(true);
            return;
        }

        if (initialValues === undefined && isEmptyArray(currentValues)) {
            setDirty(false);
            return;
        }

        const areDifferent = !isMatch({a: initialValues}, {a: currentValues});

        setDirty(areDifferent);
    };

    return (
        <div className="ais-signatories">
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onValuesChange={handleValuesChange}
                initialValues={{aisSignatories: initialValues}}
            >
                <Form.List
                    name="aisSignatories"
                >
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            <Form.ErrorList errors={errors} />
                            <div className="ais-signatories-header">
                                <Button
                                    type="default"
                                    onClick={() => add()}
                                    disabled={isDisabled}
                                >
                                    <DynamicIcon type="PlusCircleOutlined" />
                                    Добавить
                                </Button>
                            </div>
                            {(() => {
                                if (isAisSignatoriesLoading) return null;

                                if (fields?.length === 0) {
                                    return <Empty description="Список подписантов пуст" />;
                                }

                                return (
                                    <div className={cn('ais-signatories-form-list')}>
                                        {fields?.map(field => (
                                            <AisSignatoriesItem
                                                key={field.fieldKey}
                                                field={field}
                                                onRemove={remove}
                                                isDisabled={isDisabled}
                                            />
                                        ))}
                                    </div>
                                );
                            })()}
                        </>
                    )}
                </Form.List>
            </Form>
        </div>
    );
};
