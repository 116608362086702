import {SafetyCertificateOutlined} from '@ant-design/icons';
import React, {useRef} from 'react';

import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {ActionButton} from 'components/table/action/action-button';
import {TableActionProps} from 'components/table/action/table-action-types';
import {SignWithCryptoProIModal} from 'features/sign-with-crypto-pro';
import {resetLoadedData, selectEntityData} from 'modules/data';
import {setData} from 'modules/data/data-actions';
import {TableEntityData} from 'modules/data/data-types';
import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {DocumentsSigningMethod} from 'modules/documents/documents-types';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

interface OpenCryptoProSigningModalProps extends TableActionProps {}

export const OpenCryptoProSigningModal: React.FunctionComponent<OpenCryptoProSigningModalProps> = ({
    meta,
    wrapper,
    entityName,
    ...props
}: OpenCryptoProSigningModalProps) => {
    const dispatch = useAppDispatch();

    const {selectedRowKeys = []} = useAppSelector(selectEntityData(entityName, EntityType.TABLE)) as TableEntityData
        ?? {};
    const documentsSigningMethod = useAppSelector(selectDocumentsSigningMethod);

    const signingModalRef = useRef<ModalOpenerComponentRef>(null);

    const onClick = async () => {
        signingModalRef.current?.showModal();
    };

    if (documentsSigningMethod !== DocumentsSigningMethod.CRYPTO_PRO_PLUGIN) return null;
    const ComponentWrapper = wrapper || ActionButton;
    return (
        <>
            <ComponentWrapper
                {...props}
                onClick={onClick}
                actionIcon={meta.actionIcon}
                disabled={!selectedRowKeys?.length}
            >
                <SafetyCertificateOutlined />
                {meta.actionTitle}
            </ComponentWrapper>

            <SignWithCryptoProIModal
                ref={signingModalRef}
                className="d-none"
                entityName={entityName}
                documentIds={selectedRowKeys as string[]}
                onStart={() => {
                    dispatch(setData({
                        entityName,
                        entityType: EntityType.TABLE,
                        data: {
                            selectedRowKeys: [],
                            selectedRows: [],
                        },
                    }));
                }}
                onFinish={() => {
                    dispatch(resetLoadedData(entityName, EntityType.TABLE));
                }}
            />
        </>

    );
};
