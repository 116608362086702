import React, {MutableRefObject} from 'react';
import {v4 as uid} from 'uuid';

import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {showMessage} from 'shared/utils';

import {CertificateExtended, CryptoProSignatureType, DocumentToSignInDatabase} from '../model/types';
import {signContent} from './sign-content';

interface ProcessSignaturesArgs {
    filesToSign: File[];
    entityName: string;
    documentsToSignInDB?: DocumentToSignInDatabase[];
    certificateThumbprint: string;
    certificateCadescomAlgorithm: number;
    isWithResign: boolean;
    overlappingFileNamesInDB: string[];
    value: any;
    setIsSigning: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCertificate: CertificateExtended;
    selectedSignatureType: CryptoProSignatureType;
    selectedFileExtension?: string;
    onChange?: (files: File[], newValue: any) => void;
    modalRef: MutableRefObject<ModalOpenerComponentRef | null>;
}

/**
 * Функция выполняет подписания для файлов,
 * а также для документов, хранящихся в базе данных, с возможностью повторной подписи для уже подписанных документов.
 * - подписывает файлы и если необходимо переподписывает документы, хранящиеся в базе данных;
 * - передаёт результаты в родительский компонент;
 * - уведомляет пользователя о статусе процедуры подписания.
 *
 * @param filesToSign Массив файлов, которые необходимо подписать.
 * @param entityName Название сущности, с которой связаны документы.
 * @param documentsToSignInDB Массив документов, хранящихся в базе данных, для которых нужно выполнить подпись.
 * @param certificateThumbprint Отпечаток сертификата, используемого для подписания.
 * @param certificateCadescomAlgorithm Алгоритм подписи (например, SHA-1).
 * @param isWithResign Флаг, указывающий, следует ли повторно подписывать документы, уже имеющие подпись.
 * @param overlappingFileNamesInDB Список имён файлов, которые уже существуют в базе данных,
 * чтобы избежать их повторной подписи.
 * @param value Значение, которое передаётся в родительский компонент.
 * Обычно используется для хранения подписанных файлов.
 * @param setIsSigning Функция для обновления состояния, указывающая на процесс подписания.
 * @param selectedCertificate Выбранный сертификат для подписи.
 * @param selectedSignatureType Тип подписи (отсоединённая или присоединённая).
 * param selectedFileExtension Расширение файла для подписи (опционально).
 * @param onChange Функция, которая вызывается для передачи новых подписанных файлов в родительский компонент.
 * @param modalRef Ссылка на компонент модального окна для его закрытия после завершения процесса подписания.
 */
export const processSignatures = async ({
    filesToSign,
    entityName,
    documentsToSignInDB,
    certificateThumbprint,
    certificateCadescomAlgorithm,
    isWithResign,
    overlappingFileNamesInDB,
    value,
    setIsSigning,
    selectedCertificate,
    selectedFileExtension,
    selectedSignatureType,
    onChange,
    modalRef,
}: ProcessSignaturesArgs) => {
    modalRef.current?.closeModal();

    try {
        setIsSigning(true);

        const signatures = await Promise.all([
            ...(filesToSign ?? []).map(file => signContent({
                file,
                certificateThumbprint,
                certificateCadescomAlgorithm,
                selectedCertificate,
                selectedSignatureType,
                selectedFileExtension,
            }).then(({signatureFile}) => signatureFile)),
            ...(documentsToSignInDB ?? [])
                .filter(({documentName, documentId}) => {
                    if (!isWithResign && overlappingFileNamesInDB.includes(documentName)) return false;
                    return !!documentId;
                })
                .map(({documentName, documentId}) => documentId && signContent({
                    docId: documentId,
                    entityName,
                    certificateCadescomAlgorithm,
                    certificateThumbprint,
                    selectedCertificate,
                    selectedSignatureType,
                    signedFileName: documentName,
                    selectedFileExtension,
                }).then(({signatureFile}) => signatureFile)),
        ]);

        const filesToSet = isWithResign ? signatures : [...(value ?? []), ...signatures];

        filesToSet.filter((file: any) => !!file)
            .forEach((file: any) => {
                file.uid = uid();
            });

        if (onChange) onChange(filesToSet, undefined);

        showMessage({message: 'Подписи успешно сформированы', isError: false});
    } catch (e) {
        console.warn(e);
        showMessage({message: 'Произошла ошибка формирования подписей', isError: true});
    } finally {
        setIsSigning(false);
    }
};
