import {
    Button, Upload,
} from 'antd';
import {RcFile} from 'antd/lib/upload';
import React from 'react';

import {SIGN_UPLOAD_EXTENSIONS} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/monitor-service-dynamic-fields.constants';
import {IconsMap} from 'components/dynamic-icon';
import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {DocumentsSigningMethod} from 'modules/documents/documents-types';
import {useAppSelector} from 'store/config/hooks';

interface SignatureItemActionsProps {
  signatureName: string | undefined;
  attachmentId: string | undefined;
  isSignatureUploading: boolean;
  handleUploadFile: (file: RcFile) => boolean;
}

export const SignatureActionButton = ({
    signatureName, attachmentId, isSignatureUploading, handleUploadFile,
}: SignatureItemActionsProps) => {
    const documentsSigningMethod = useAppSelector(selectDocumentsSigningMethod);

    return (
        <>
            {documentsSigningMethod === DocumentsSigningMethod.UPLOAD_EDS_FILE && (
                <Upload
                    beforeUpload={handleUploadFile}
                    accept={SIGN_UPLOAD_EXTENSIONS}
                    showUploadList={false}
                    disabled={!attachmentId}
                >
                    <Button
                        type="primary"
                        loading={isSignatureUploading}
                        disabled={!attachmentId}
                    >
                        {`${signatureName ? 'Загрузить новую' : 'Загрузить'} ЭП`}
                    </Button>
                </Upload>
            )}
            {documentsSigningMethod === DocumentsSigningMethod.CRYPTO_PRO_PLUGIN && (
                <Button
                    type="primary"
                    icon={<IconsMap.SafetyCertificateOutlined />}
                    // onClick={handleSign} todo
                >
                    Подписать
                </Button>
            )}
        </>
    );
};
