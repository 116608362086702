import {Row} from 'antd';
import Form, {FormInstance} from 'antd/lib/form';
import {FormListFieldData} from 'antd/lib/form/FormList';
import React from 'react';

import {Field} from 'components/form/field/field';
import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta, FieldType} from 'modules/metadata';

interface FieldsTreeGeneratorProps {
    meta: FieldMeta;
    entityName: string;
    fieldData: FormListFieldData;
    formData?: FormEntityData;
    form?: FormInstance;
}

export const FieldsTreeGenerator = ({
    fieldData,
    meta,
    form,
    formData,
    entityName,
}: FieldsTreeGeneratorProps) => {
    const getFieldKeys = (fieldMeta: FieldMeta) => fieldMeta.key?.split?.('.') ?? [fieldMeta.key];
    const programSetField = meta?.children?.filter(
        field => field.type === FieldType.REFERENCE,
    ).find(
        reference => reference?.additionalOptions?.optionsField?.isChangingFormStructure,
    );

    return (
        <Row
            className="row"
            key={fieldData.key}
        >
            {meta?.children?.map(field => (
                !field?.children && !field?.children?.length
                    && (
                        <>
                            <Form.Item
                                {...fieldData}
                                key={field.key}
                                className="form-fields"
                                name={[fieldData.name, ...getFieldKeys(field)]}
                                dependencies={field?.dependentsFieldKey
                                    ? [meta.key, fieldData.name, field?.dependentsFieldKey]
                                    : undefined}
                                rules={[
                                    () => ({
                                        validator() {
                                            if (programSetField && formData?.data?.programSetPrograms) {
                                                const programSetPrograms = form?.getFieldsValue()?.programSetPrograms;
                                                const copies = programSetPrograms.filter(
                                                    (copy: any) => copy.program
                                                        && copy.program.id === programSetPrograms[
                                                            fieldData.name].program.id,
                                                );
                                                if (copies.length > 1) {
                                                    return Promise.reject(new Error('Программа не уникальная!'));
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Field
                                    key={field.key}
                                    fieldMeta={field}
                                    entityName={entityName}
                                    form={form}
                                    placeholder={field.placeholder}
                                    formData={formData}
                                    name={[fieldData.name, ...getFieldKeys(field)]}
                                    fieldKey={[fieldData.fieldKey, ...getFieldKeys(field)]}
                                    formMode={formData?.formMode}
                                    areProgramSetProgramsUnique={field.key === programSetField?.key}
                                    isDisableFields={(() => {
                                        if (field?.key === 'program') {
                                            const programSetPrograms = form?.getFieldValue('programSetPrograms') || [];
                                            const programSetProgram = programSetPrograms[fieldData.fieldKey];

                                            const hasId = programSetProgram?.id
                                                && typeof programSetProgram?.id === 'number';

                                            if (hasId) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    })()}
                                />
                            </Form.Item>
                        </>
                    )
            ))}
        </Row>
    );
};
