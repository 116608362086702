import {FormInstance} from 'antd/lib/form';

import {DocumentToSignInDatabase, FileWithId} from '../model/types';
import {getOverlappingFiles} from './get-overlapping-files';

interface GetFilesToSignArgs {
    form?: FormInstance;
    fileFieldNames?: string[];
    fileList?: File[];
    isWithResign: boolean;
    documentsToSignInDB?: DocumentToSignInDatabase[];
    inputFileValue?: FileWithId[];
}

/**
 * Возвращает список файлов, которые необходимо подписать,
 * с учётом состояния формы, пересекающихся файлов и параметра переотправки (`isWithResign`).
 *
 * @param form Экземпляр формы (`FormInstance`),
 * предоставляемый библиотекой `antd`. Используется для получения значений из полей формы.
 *
 * @param fileFieldNames Массив строк, представляющий названия полей формы, содержащих файлы.
 *
 * @param fileList Массив объектов `File`, представляющий текущие файлы, добавленные для подписания.
 *
 * @param isWithResign Логическое значение, указывающее,
 * нужно ли включать в список для подписания все файлы, включая пересекающиеся:
 * - Если `true`, возвращаются все файлы.
 * - Если `false`, исключаются файлы, пересекающиеся с уже подписанными документами.
 *
 * @param documentsToSignInDB Массив объектов `DocumentToSignInDatabase`,
 представляющий документы, которые уже находятся в базе данных.
 *
 * @param inputFileValue Массив объектов `FileWithId`,
 * представляющий текущие значения файлов, добавленных для подписания.
 *
 * @returns Объект с двумя свойствами:
 * - `filesToSign` — массив файлов (`File`), которые необходимо подписать.
 * - `overlappingFileNamesInDB` — массив строковых названий файлов, пересекающихся с файлами в базе данных.
 *
 * Функция выполняет следующие шаги:
 * 1. Вызывает функцию `getOverlappingFiles` для определения файлов, пересекающихся с уже подписанными в базе данных.
 * 2. Формирует плоский массив всех файлов (`filesFlat`) на основе
 * значений из полей формы (`fileFieldNames`) и предоставленного массива `fileList`.
 * 3. Фильтрует файлы:
 *    - Если `isWithResign = false`, исключает пересекающиеся файлы.
 *    - Если `isWithResign = true`, возвращает все файлы.
 * 4. Возвращает объект с массивами `filesToSign` и `overlappingFileNamesInDB`.
 */
export const getFilesToSign = ({
    form,
    fileFieldNames,
    fileList,
    isWithResign,
    documentsToSignInDB,
    inputFileValue,
}: GetFilesToSignArgs) => {
    const {overlappingFiles, overlappingFileNamesInDB} = getOverlappingFiles({
        currentValue: inputFileValue,
        fileFieldNames,
        form,
        documentsToSignInDB,
    });

    const filesFlat = (() => {
        let files: File[] = [];
        fileFieldNames?.forEach(fieldName => {
            const fieldValue = form?.getFieldValue(fieldName);
            if (Array.isArray(fieldValue)) {
                files = [...files, ...fieldValue.map(f => f?.file || f)];
            } else {
                files = [...files, fieldValue];
            }
        });

        if (fileList) files = [...files, ...fileList];
        return files;
    })();

    const filesToSign = filesFlat.filter(v => {
        if (!isWithResign && overlappingFiles?.includes(v)) return false;
        return !!v;
    });

    return {
        filesToSign,
        overlappingFileNamesInDB,
    };
};
