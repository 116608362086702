import {
    Form, Spin,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import {isNil, omitBy} from 'lodash';
import React, {useEffect, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {
    loadContextData, loadContextLookups, resetContext, saveContextData, updateContextRawData,
} from 'modules/context/context-actions';
import {fetchContextData, fetchContextLookupValues} from 'modules/context/context-api';
import {
    selectContextDataWithLookups, selectContextLookups, selectContextRawData,
} from 'modules/context/context-selector';
import {LookupValue} from 'modules/data/data-types';
import {ReactComponent as DateIcon} from 'shared/assets/header/date-icon.svg';
import {useAppHistory} from 'shared/hooks/use-app-history';
import {showMessage} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params';
import {store} from 'store/config';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';
import {selectAuthToken, selectUserId} from 'store/slices/auth-slice';

import {CONTEXT_FILTER_LOOKUPS, ORGANIZATION_QUERY_PARAM_NAME, TAX_PERIOD_QUERY_PARAM_NAME} from '../context-filter.constants';

import './context-filter-preview.less';

interface ContextFilterPreviewProps {
    onChange?: (values: Record<string, any>) => any;
    isDisabled?:boolean;
}

export const ContextFilterPreview: React.FC<ContextFilterPreviewProps> = (
    {onChange, isDisabled}: ContextFilterPreviewProps,
) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const contextDataWithLookups = useAppSelector(selectContextDataWithLookups);
    const contextRawData = useAppSelector(selectContextRawData);
    const contextLookups = useAppSelector(selectContextLookups);
    const userId = useAppSelector(selectUserId);
    const isAuthorized = !!useAppSelector(selectAuthToken);

    const {getQueryParam, removeQueryParam} = useQueryParams();

    const {listen} = useAppHistory();

    const {
        organizationId: organization,
        taxPeriodId: taxPeriod,
    } = contextDataWithLookups;

    useEffect(() => {
        const unlisten = listen(v => {
            const queryTaxPeriodId = new URLSearchParams(v.search).get(TAX_PERIOD_QUERY_PARAM_NAME);
            const queryOrganizationId = new URLSearchParams(v.search).get(ORGANIZATION_QUERY_PARAM_NAME);

            const dataInStore = selectContextRawData(store.getState() as AppState);

            const availableTaxPeriodIds = (selectContextLookups(store.getState() as AppState)
                .taxPeriodId ?? []).map((period: LookupValue) => period.id);
            const availableOrganizationIds = (selectContextLookups(store.getState() as AppState)
                .organizationId ?? []).map((org: LookupValue) => org.id);

            const arePeriodsDifferent = dataInStore?.taxPeriodId !== Number(queryTaxPeriodId);
            const areOrganizationsDifferent = dataInStore?.organizationId !== Number(queryOrganizationId);

            const shouldUpdateTaxPeriod = arePeriodsDifferent
            && queryTaxPeriodId
            && availableTaxPeriodIds.includes(Number(queryTaxPeriodId));

            const shouldUpdateOrganization = areOrganizationsDifferent
            && queryOrganizationId
            && availableOrganizationIds.includes(Number(queryOrganizationId));

            if ((shouldUpdateTaxPeriod || shouldUpdateOrganization) && dataInStore
                && !isNil(userId)) {
                dispatch(resetContext());

                const updates: {taxPeriodId?: number; organizationId?: number} = {};

                if (shouldUpdateTaxPeriod) {
                    updates.taxPeriodId = Number(queryTaxPeriodId);
                }

                if (shouldUpdateOrganization) {
                    updates.organizationId = Number(queryOrganizationId);
                }

                if (Object.keys(updates).length > 0) {
                    (async () => {
                        await dispatch(
                            saveContextData({
                                ...omitBy(dataInStore, isNil),
                                ...updates,
                                userId,
                            }),
                        );
                        dispatch(loadContextData());
                    })();
                }
            }
        });
        return () => { unlisten(); };
    }, [userId]);

    useEffect(() => {
        dispatch(loadContextLookups());
    }, []);

    useEffect(() => {
        if (isAuthorized && !isNil(userId)) {
            const queryTaxPeriodId = getQueryParam(TAX_PERIOD_QUERY_PARAM_NAME);
            const queryOrganizationId = getQueryParam(ORGANIZATION_QUERY_PARAM_NAME);

            if (!isNil(queryTaxPeriodId) || !isNil(queryOrganizationId)) {
                (async () => {
                    // не экшн из стора, чтобы не сохранять значения и не триггерить useEffect

                    const fetchContextRequest = fetchContextData();
                    const fetchTaxPeriodsRequest = fetchContextLookupValues({
                        lookupType: CONTEXT_FILTER_LOOKUPS.taxPeriodId,
                    });
                    const fetchOrganizationsRequest = fetchContextLookupValues({
                        lookupType: CONTEXT_FILTER_LOOKUPS.organizationId,
                    });

                    try {
                        const [
                            contextDataResponse,
                            periodsDataResponse,
                            organizationsDataResponse,
                        ] = await Promise.all([fetchContextRequest, fetchTaxPeriodsRequest, fetchOrganizationsRequest]);

                        const taxPeriodId = contextDataResponse?.data.taxPeriodId;
                        const availableTaxPeriodIds = periodsDataResponse.data.map((period: LookupValue) => period.id);

                        const organizationId = contextDataResponse?.data?.organizationId;
                        const availableOrganizationIds = organizationsDataResponse.data.map(
                            (org: LookupValue) => org.id,
                        );

                        const updates: {taxPeriodId?: number; organizationId?: number} = {};

                        if (taxPeriodId !== Number(queryTaxPeriodId)
                                && availableTaxPeriodIds.includes(Number(queryTaxPeriodId))) {
                            updates.taxPeriodId = Number(queryTaxPeriodId);
                        }

                        if (
                            !isNil(queryOrganizationId)
                            && organizationId !== Number(queryOrganizationId)
                            && availableOrganizationIds.includes(Number(queryOrganizationId))
                        ) {
                            updates.organizationId = Number(queryOrganizationId);
                        }

                        if (Object.keys(updates).length > 0) {
                            await dispatch(
                                saveContextData({
                                    ...omitBy(contextDataResponse.data, isNil),
                                    ...updates,
                                    userId,
                                }),
                            );
                        }
                    } catch {
                        showMessage({
                            message: 'Ошибка изменения контекста',
                            isError: true,
                        });
                    }

                    dispatch(loadContextData());
                })();
            } else dispatch(loadContextData());
        }
    }, [isAuthorized, userId]);

    useEffect(() => {
        const {organizationId, taxPeriodId} = contextRawData ?? {};
        if (organizationId) {
            form.setFieldsValue({
                organizationId,
            });
        }
        if (taxPeriodId) {
            form.setFieldsValue({
                taxPeriodId,
            });
        }
    }, [contextRawData]);

    const handleValuesChange = (values: Record<string, any>) => {
        const queryTaxPeriodId = getQueryParam(TAX_PERIOD_QUERY_PARAM_NAME);
        if (queryTaxPeriodId) {
            removeQueryParam(TAX_PERIOD_QUERY_PARAM_NAME, {action: 'replace'});
        }
        const queryOrganizationId = getQueryParam(ORGANIZATION_QUERY_PARAM_NAME);
        if (queryOrganizationId) {
            removeQueryParam(ORGANIZATION_QUERY_PARAM_NAME, {action: 'replace'});
        }

        setIsSubmitting(true);
        const valuesWithUserId = {
            ...values,
            userId,
        };
        dispatch(saveContextData(valuesWithUserId)).then(() => {
            if (onChange) onChange(valuesWithUserId);
            else { dispatch(updateContextRawData({rawData: valuesWithUserId})); }
        }, () => {
            showMessage({message: 'Произошла ошибка при изменении контекста', isError: true});
        }).finally(() => {
            setIsSubmitting(false);
        });
    };

    if (!organization || !taxPeriod || !contextLookups) return <Spin />;
    return (
        <div className={cn('g-context-filter-preview__content')}>
            <Form
                form={form}
                onValuesChange={(_, values) => {
                    handleValuesChange(values);
                }}
            >
                <Form.Item
                    name="organizationId"
                >
                    <CustomSelect
                        dropdownClassName="custom-dropdown"
                        entries={(contextLookups?.organizationId ?? []).map(e => ({
                            label: e.meaning,
                            value: e.id,
                        }))}
                        settings={{
                            isDisabled: isSubmitting || isDisabled,
                            showSearch: true,
                            placeholder: 'Выберите организацию',
                        }}
                    />
                </Form.Item>
                <div className="g-context-filter-preview__content__form-item-tax">
                    <DateIcon className="g-context-filter-preview__content__form-item-tax__svg" />
                    <Form.Item
                        name="taxPeriodId"
                    >

                        <CustomSelect
                            dropdownClassName="custom-dropdown"
                            entries={(contextLookups?.taxPeriodId ?? []).map(e => ({
                                label: e.meaning,
                                value: e.id,
                            }))}
                            settings={{
                                isDisabled: isSubmitting || isDisabled,
                                showSearch: true,
                                placeholder: 'Выберите период',
                            }}
                        />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};
