import {MutableRefObject} from 'react';

import {ModalOpenerComponentRef} from 'components/modal-opener-component';

import {sendSignedFile} from '../api';
import {SIGNING_MESSAGE_KEY} from '../model/constants';
import {CertificateExtended, CryptoProSignatureType} from '../model/types';
import {notify} from './notify';
import {signContent} from './sign-content';

interface ProcessDocumentsArgs {
    documentIdsToSign: string[];
    entityName: string;
    certificateThumbprint: string;
    certificateCadescomAlgorithm: number;
    selectedCertificate: CertificateExtended;
    selectedSignatureType: CryptoProSignatureType;
    modalRef: MutableRefObject<ModalOpenerComponentRef | null>;
    selectedFileExtension?: string;
    onStart?: () => void;
    onFinish?: () => void;
}

/**
 * Функция выполняет последовательность шагов, необходимых для подписания документов:
 * - подписывает каждый документ;
 * - отправляет подписанные файлы на сервер;
 * - уведомляет пользователя о статусе процедуры подписания.
 *
 * @param documentIdsToSign Массив идентификаторов документов, которые нужно подписать.
 * @param entityName Название сущности, с которой связаны документы.
 * @param certificateThumbprint Отпечаток сертификата, который используется для подписания.
 * @param certificateCadescomAlgorithm Алгоритм, используемый для подписи (например, SHA-1).
 * @param selectedCertificate Выбранный сертификат, который будет использоваться для подписания.
 * @param selectedSignatureType Тип подписи (отсоединённая или присоединённая).
 * @param modalRef Ссылка на компонент модального окна для его закрытия по завершении процесса.
 * @param selectedFileExtension Расширение файла для подписи (опционально).
 * @param onStart Функция, которая вызывается перед началом процесса подписания.
 * @param onFinish Функция, которая вызывается после завершения процесса подписания.
 */
export const processDocuments = async ({
    documentIdsToSign,
    entityName,
    certificateThumbprint,
    certificateCadescomAlgorithm,
    selectedCertificate,
    selectedSignatureType,
    selectedFileExtension,
    modalRef,
    onStart,
    onFinish,
}: ProcessDocumentsArgs): Promise<void> => {
    const documentAmountToSign = documentIdsToSign.length;

    onStart?.();

    notify({
        key: SIGNING_MESSAGE_KEY,
        documentCount: documentAmountToSign,
        status: 'loading',
    });

    modalRef.current?.closeModal();

    try {
        const results = await Promise.all(
            documentIdsToSign.map(docId => signContent({
                docId,
                entityName,
                certificateCadescomAlgorithm,
                certificateThumbprint,
                selectedCertificate,
                selectedSignatureType,
                selectedFileExtension,
            }).then(({signatureFile, fileName, publishAllowed}) => sendSignedFile({
                signatureFile,
                fileName,
                docId,
                publishAllowed,
                entityName,
                selectedFileExtension,
            })).then(() => ({success: true}))
                .catch(error => {
                    console.error(`Ошибка подписи документа ${docId}:`, error);
                    return {success: false};
                })),
        );

        const successfulCount = results.filter(({success}) => success).length;

        notify({
            key: SIGNING_MESSAGE_KEY,
            successfulCount,
            totalCount: documentAmountToSign,
            status: successfulCount === 0 ? 'error' : 'success',
        });

        onFinish?.();
    } catch (error) {
        console.error('Общая ошибка подписания:', error);
        notify({
            key: SIGNING_MESSAGE_KEY,
            status: 'error',
        });
    }
};
