import {getUserCertificates} from 'crypto-pro';

import {OID_TO_HASH_ALG_MAPPING} from '../model/constants';

/**
 * Асинхронная функция `getUserCertificatesExtended` возвращает расширенную информацию
 * о пользовательских сертификатах `crypto-pro`.
 *
 * @returns Массив объектов с расширенной информацией о сертификатах. Каждый объект содержит:
 * - `certificate` — оригинальный объект сертификата, возвращённый функцией `getUserCertificates`.
 * - `providerName` — имя провайдера, связанного с закрытым ключом сертификата.
 * - `algorithmName` — имя алгоритма, связанного с сертификатом.
 * - `algorithmCadescomConstant` — константа CAdESCOM, связанная с алгоритмом, полученная из `OID_TO_HASH_ALG_MAPPING`.
 * - `issuer` — информация об издателе сертификата.
 * - `owner` — информация о владельце сертификата.
 * - `thumbprint` — отпечаток сертификата.
 *
 * Функция выполняет следующие шаги:
 * 1. Асинхронно вызывает `getUserCertificates` с параметром `true` для получения списка сертификатов.
 * 2. Для каждого сертификата асинхронно получает:
 *    - Информацию о владельце через `cert.getOwnerInfo`.
 *    - Информацию об издателе через `cert.getIssuerInfo`.
 *    - Алгоритм через `cert.getAlgorithm`.
 *    - Свойства закрытого ключа через `cert.getCadesProp('PrivateKey')`.
 * 3. Формирует расширенный объект с дополнительной информацией о сертификате.
 * 4. Возвращает массив таких объектов.
 */
export const getUserCertificatesExtended = async () => {
    const certificates = await getUserCertificates(true);

    const certificatesExtendedPromises = certificates.map(async cert => {
        const owner = await cert.getOwnerInfo();
        const issuer = await cert.getIssuerInfo();
        const algorithm = await cert.getAlgorithm();
        const privateKey = await cert.getCadesProp('PrivateKey');
        const providerName = await privateKey?.ProviderName;

        return {
            // spread для cert удалит прототип, поэтому его не используем!
            certificate: cert,
            // extra fields ↴
            providerName,
            algorithmName: algorithm.algorithm,
            algorithmCadescomConstant: OID_TO_HASH_ALG_MAPPING[algorithm.oid],
            issuer,
            owner,
            thumbprint: cert?.thumbprint,
        };
    });

    const certificatesExtended = await Promise.all(certificatesExtendedPromises);

    return certificatesExtended;
};
