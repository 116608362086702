import {createAsyncThunk} from '@reduxjs/toolkit';
import {notification} from 'antd';

import {showMessageFromResponse} from 'shared/utils';
import {authSliceActions} from 'store/slices/auth-slice/auth-slice';
import {
    authRequest,
    logoutRequest,
    changePasswordRequest,
    fetchTwoFACode,
    fetchTwoFAConfig,
    fetchUserInfo,
    AuthRequestArgs,
    ChangePasswordRequestArgs,
    FetchTwoFACodeArgs,
    fetchChangePasswordSettings,
    fetchAuthProfile,
    fetchAuthStatus,
    fetchMicroserviceToken,
} from 'store/slices/auth-slice/auth-slice-api';
import {AuthErrors, authSliceName, SESSION_EXPIRED_NOTIFICATION} from 'store/slices/auth-slice/auth-slice-constants';
import {
    AuthProfile,
    AuthResponseWithCredentials,
    ChangePasswordSettings,
    TwoFactorAuthStatus,
    UserInfo,
} from 'store/slices/auth-slice/auth-slice-types';

export const resetTokenInfo = () => {
    localStorage.removeItem('token');
};

// todo: temporary solution
export const resetHiddenColumnsTokens = () => {
    Object.keys(localStorage)?.forEach(key => {
        if (key?.includes('-hidden-columns')) {
            localStorage.removeItem(key);
        }
    });
};

export const authorize = createAsyncThunk<
    AuthResponseWithCredentials, AuthRequestArgs, {rejectValue: Omit<AuthResponseWithCredentials, 'token'>}
    >(`${authSliceName}/authorize`, async (args, {rejectWithValue}) => {
        const {username, password} = args;
        // Переводим в UTF-8, чтобы избежать исключения с кириллицей для btoa
        const token = `Basic ${window.btoa(unescape(encodeURIComponent(`${username}:${password}`)))}`;

        try {
            notification.close(SESSION_EXPIRED_NOTIFICATION.KEY);
            const authenticationResponse = await authRequest(args);

            if (authenticationResponse.code === 200) {
                localStorage.setItem('token', token);
            } else {
                resetTokenInfo();
                return rejectWithValue({
                    ...authenticationResponse,
                    userName: username,
                });
            }

            return {
                ...authenticationResponse,
                userName: username,
                token,
            };
        } catch (e) {
            resetTokenInfo();
            return rejectWithValue(e);
        }
    });

export const logout = createAsyncThunk(
    `${authSliceName}/logout`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await logoutRequest();
            resetTokenInfo();
            resetHiddenColumnsTokens();
            authSliceActions.resetMicroserviceToken();
            return response;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadUserInfo = createAsyncThunk<UserInfo, void>(
    `${authSliceName}/loadUserInfo`,
    async (args, {dispatch, rejectWithValue}) => {
        try {
            const userInfoResponse = await fetchUserInfo();

            // todo - implement logout on userInfo fetch error
            if (userInfoResponse.status !== 200) {
                dispatch(logout());
            }

            return userInfoResponse.data;
        } catch (e) {
            showMessageFromResponse({
                response: e?.data?.message,
                isError: true,
                customMessage: AuthErrors.USER_INFO_ERROR_MESSAGE,
            });
            dispatch(logout());
            return rejectWithValue(e);
        }
    },
);

export const changePassword = createAsyncThunk<any, ChangePasswordRequestArgs, {rejectValue: string}>(
    `${authSliceName}/changePassword`,
    async (args, {rejectWithValue}) => {
        try {
            const changePasswordResponse = await changePasswordRequest(args);

            if (changePasswordResponse.status !== 200) {
                rejectWithValue(changePasswordResponse?.data?.message ?? AuthErrors.DEFAULT_ERROR_MESSAGE);
            }

            return changePasswordResponse;
        } catch (e) {
            return rejectWithValue(e?.response?.data?.message ?? AuthErrors.DEFAULT_ERROR_MESSAGE);
        }
    },
);

export const getTwoFAConfig = createAsyncThunk<string, void, {rejectValue: string}>(
    `${authSliceName}/getTwoFAConfig`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchTwoFAConfig();
            if (response.status !== 200) {
                return rejectWithValue(response?.data?.message ?? AuthErrors.DEFAULT_ERROR_MESSAGE);
            }
            return response?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data?.message ?? AuthErrors.DEFAULT_ERROR_MESSAGE);
        }
    },
);

export const getTwoFACode = createAsyncThunk<TwoFactorAuthStatus, FetchTwoFACodeArgs, {rejectValue: void}>(
    `${authSliceName}/getTwoFACode`,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (args, _) => {
        try {
            const response = await fetchTwoFACode(args);
            if (response.status === 200) {
                return {message: response?.data?.message, isError: false};
            }
            return {message: AuthErrors.DEFAULT_ERROR_MESSAGE, isError: true};
        } catch (e) {
            console.error(e);
            return {message: e?.response?.data?.message, isError: true};
        }
    },
);

export const getChangePasswordSettings = createAsyncThunk<ChangePasswordSettings, void, {rejectValue: string}>(
    `${authSliceName}/getChangePasswordSettings`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchChangePasswordSettings();
            if (response.status !== 200) {
                return rejectWithValue(AuthErrors.CHANGE_PASSWORD_SETTINGS_REQUEST_FAILED);
            }
            return response?.data;
        } catch (e) {
            return rejectWithValue(AuthErrors.CHANGE_PASSWORD_SETTINGS_REQUEST_FAILED);
        }
    },
);

export const getAuthProfile = createAsyncThunk<AuthProfile, void, {rejectValue: string}>(
    `${authSliceName}/getAuthProfile`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchAuthProfile();
            if (response.status !== 200) {
                return rejectWithValue(AuthErrors.PROFILE_NOT_FOUND);
            }
            return response?.data;
        } catch (e) {
            return rejectWithValue(AuthErrors.PROFILE_NOT_FOUND);
        }
    },
);

export const checkAuthStatus = createAsyncThunk<string, void, {rejectValue: string | void}>(
    `${authSliceName}/checkAuthStatus`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchAuthStatus();

            if (response.status !== 200) {
                return rejectWithValue(response?.data); // data has redirect-url
            }

            localStorage.setItem('token', response?.data); // data has token
            return response?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    },
);

export const loadMicroserviceToken = createAsyncThunk<string, void>(
    `${authSliceName}/loadMicroserviceToken`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchMicroserviceToken();

            return response.data;
        } catch (e) {
            showMessageFromResponse({
                response: e?.data?.message,
                isError: true,
                customMessage: AuthErrors.MICROSERVICE_TOKEN_ERROR,
            });
            return rejectWithValue(AuthErrors.MICROSERVICE_TOKEN_ERROR);
        }
    },
);
