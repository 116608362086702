import {DatePicker} from 'antd';
import {WeekPickerProps} from 'antd/lib/date-picker';
import moment from 'moment';
import React, {useMemo} from 'react';

import {ReactComponent as CloseXOutlined} from 'shared/assets/close-x-outlined.svg';
import {ReactComponent as LockIcon} from 'shared/assets/forms/lock.svg';
import {ReactComponent as CalendarIcon} from 'shared/assets/forms/time/calendar.svg';
import {
    DATE_DMY,
    DATE_FORMATS_PLACEHOLDERS_MAP, DATE_FOR_REQUEST,
    DATE_WITH_TIME_DOTS_SECONDS_24,
    DEFAULT_FORMAT_PLACEHOLDER,
} from 'shared/constants/date-format';
import {FormItemProps} from 'shared/types';
import './date-input.less';
import {getPopupContainer} from 'shared/utils';

interface DateInputProps extends FormItemProps {
    showTime?: WeekPickerProps['showTime'];
    format?: string;
    formatToSet?: string;
    disabled?: boolean;
    resetTimeOnSet?: boolean;
    fixedDropdown?: boolean;
}

export const DateInput: React.FC<DateInputProps> = (
    {
        disabled,
        showTime,
        onChange,
        value,
        format,
        formatToSet = DATE_FOR_REQUEST,
        resetTimeOnSet,
        fixedDropdown = false,
    }: DateInputProps,
) => {
    const defaultFormat = showTime ? DATE_WITH_TIME_DOTS_SECONDS_24 : DATE_DMY;

    const datePickerValue = (() => {
        if (!value) return undefined;

        const momentDate = moment(value, formatToSet);

        if (!momentDate.isValid()) return undefined;

        return momentDate;
    })();

    const placeholder = useMemo(() => {
        if (!format) return DEFAULT_FORMAT_PLACEHOLDER;
        if (DATE_FORMATS_PLACEHOLDERS_MAP.has(format)) {
            return DATE_FORMATS_PLACEHOLDERS_MAP.get(format);
        }
        return DEFAULT_FORMAT_PLACEHOLDER;
    }, [format]);

    const handleChange = (val: moment.Moment | null) => {
        if (!val) {
            onChange?.(undefined, undefined);
            return;
        }

        const momentDate = moment(val);
        if (resetTimeOnSet) {
            momentDate.set({
                hour: 0, minute: 0, second: 0, millisecond: 0,
            });
        }

        const newValue = momentDate.format(formatToSet);
        onChange?.(newValue, undefined);
    };

    return (
        <DatePicker
            disabled={disabled}
            format={format ? [format] : [defaultFormat]}
            value={datePickerValue}
            onSelect={showTime ? handleChange : undefined}
            onChange={handleChange}
            className="date-input"
            showTime={showTime}
            suffixIcon={disabled ? <LockIcon /> : <CalendarIcon />}
            clearIcon={<CloseXOutlined className="clear-icon" />}
            dropdownClassName="time-picker-dropdown" // somehow it applies className to dropdown
            placeholder={placeholder}
            getPopupContainer={getPopupContainer(fixedDropdown)}
        />
    );
};
