import {LookupEntry} from 'shared/types/lookups';
import {performRequest} from 'shared/utils';

import {TableReportConfigRequestParams, TableReportDataRequestParams, TableReportExtendedConfiguration} from './table-report-slice-types';

export interface FetchTableReportTemplateConfigArgs {
    templateCode: string;
    isExtended: boolean;
    params: TableReportConfigRequestParams;
}
export const fetchTableReportTemplateConfig = (
    {
        templateCode,
        params,
        isExtended,
    }: FetchTableReportTemplateConfigArgs,
) => {
    if (isExtended) {
        return performRequest<TableReportExtendedConfiguration>({
            url: `table-reports/${templateCode}/config/ext`,
            method: 'POST',
            data: params,
        });
    }
    return performRequest<TableReportExtendedConfiguration>({
        url: `table-reports/${templateCode}`,
        method: 'GET',
        params,
    });
};

export const fetchTableReportData = ({
    templateCode,
    docId,
    page,
    size,
    filters,
    sort,
    includeCR,
    parameters,
    includeComparison,
    comparedDocId,
    drillParameters,
    userReportGroups,
    groupAfterUserFilter,
    addHiddenData,
}: TableReportDataRequestParams) => performRequest({
    url: `table-reports/${templateCode}/data`,
    data: {
        DOC_ID: docId,
        DOC_ID_2: comparedDocId,
        page,
        size,
        filters,
        sort,
        includeCR,
        parameters,
        includeComparison,
        drillParameters,
        userReportGroups,
        groupAfterUserFilter,
        addHiddenData,
    },
    method: 'POST',
});

export const fetchTableReportFiltersConditions = () => performRequest<LookupEntry[]>({
    url: 'lookupValue/TABLE_REPORT_FILTER_VIEW',
    method: 'GET',
});
