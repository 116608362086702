import {Form} from 'antd';
import cn from 'classnames';
import {isArray} from 'lodash';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';

import {AIS_ROUTES} from 'components/ais/ais.constants';
import {getColumnRedirectParams} from 'components/table-report/table-report-columns/table-report-columns-utils';
import {MonitorServicesContentDisplayer} from 'components/table/columns/monitor-services-content-displayer/monitor-services-content-displayer';
import {MonitorServiceContentDisplayerType} from 'components/table/columns/monitor-services-content-displayer/monitor-services-content-displayer.types';
import {Entity, EntityValue} from 'modules/data';
import {FieldMeta, FieldType} from 'modules/metadata';
import {EntityMeta} from 'modules/metadata/metadata-types';
import {TablePageQueryParams} from 'pages/table-page/query-params-aware-table/constants/table-query-params.constants';
import {EntityType} from 'shared/constants/entities';
import {FileType} from 'shared/types/files';
import {combineString} from 'shared/utils/combine-string';

import {Counter} from '../counter';
import {BoolField} from '../form/inputs/bool-field';
import {CheckboxField} from '../form/inputs/checkbox-field';
import {EditableField} from '../form/inputs/editable-field/editable-field';
import {ReportLinkField} from '../form/inputs/report-link-field/report-link-field';
import {RequestLink} from '../form/inputs/request-link/request-link';
import {RichTextRenderer} from '../rich-text';
import {QueryParamLink} from '../routing';
import {ActionDeleteCell} from './columns/action-delete-cell/action-delete-cell';
import {AdditionalInformationCell} from './columns/additional-information-cell/additional-information-cell';
import {BoolCellString} from './columns/bool-cell-string/bool-cell-string';
import {BoolCell} from './columns/bool-cell/bool-cell';
import {BreadcrumbItem} from './columns/breadcrumb-item/breadcrumb-item';
import {DownloadFileLink} from './columns/download-file-link-cell';
import {FileColumn} from './columns/file';
import {FileUploadCell} from './columns/file-upload-cell';
import {FunctionButtonField} from './columns/function-button-cell';
import {IconsDownloadCell} from './columns/icon-download-cell';
import {IconsCell} from './columns/icons-cell';
import {FIELD_TYPE_TO_SQUARE_VARIANT_MAP, NumberSquare} from './columns/number-square/number-square';
import {OpenDocumentCodesModalAction} from './columns/open-document-codes-modal-actions/open-document-codes-modal-actions';
import {OpenModalAction} from './columns/open-modal-action/open-modal-action';
import {OpenReportConfigurationHistoryModal} from './columns/open-report-configuration-history-modal/open-report-configuration-history-modal';
import {OpenSettingsPageButton} from './columns/open-settings-page-button/open-settings-page-button';
import {ReportWatcher} from './columns/report-watcher/report-watcher';
import {Status} from './columns/status-cell/status-cell';
import {UploadScanFilesCell} from './columns/upload-scan-files-cell';
import {
    createStringCell,
    createStringDate,
    createTableCellClassNameResolver,
} from './utils/table.utils';

import './table.less';

export const createTableColumnResolver = (
    key: string,
    field: FieldMeta,
    url: string,
    options: Pick<EntityMeta, 'linkField'>,
    entityName: string,
    entityType?: EntityType,
    checkKey?: any,
    openModalForm?: (modalName: string, record: any, parentEntityName?: string) => void,
    handleBreadcrumbsEntityName?: (newEntityName: string, metaTable: EntityMeta) => void,
    handleChangeIndexRow?: (value: Entity, key: string, dependenceFieldKey?: string) => void,
    prefix?: string,
    params?: Record<string, any>,
) => (
    value: EntityValue,
    record: Entity,
    rowIndex: number,
) => {
    let cell;
    const isRecordNotRead = get(record, 'readFlag') === false;
    const isFileExchangeCellBold = (field.key === 'name' || field.key === 'downloadDate')
    && entityName === AIS_ROUTES.FILE_EXCHANGE_ENTITY_NAME
    && record.openCard === false;

    const cellClassName = cn(
        'table-container__text',
        field.ellipsis && 'ellipsis',
        isRecordNotRead && 'accented',
        isFileExchangeCellBold && 'cell-text_bold',
        createTableCellClassNameResolver(entityName, field.key),
    );

    switch (field.type) {
    case FieldType.ICON:
        cell = (
            <IconsCell
                record={record}
                field={field}
            />
        );
        break;
    case FieldType.ICON_DOWNLOAD:
        cell = (
            <IconsDownloadCell
                record={record}
                field={field}
            />
        );
        break;
    case FieldType.COUNTER:
        cell = (
            <Counter
                count={value as number}
                color={field?.color}
            />
        );
        break;
    case FieldType.BREADCRUMB_ITEM:
        cell = (
            <BreadcrumbItem
                record={record}
                field={field}
                entityName={entityName}
                entityType={entityType}
                handleBreadcrumbsEntityName={handleBreadcrumbsEntityName}
            />
        );
        break;
    case FieldType.UPLOAD_SCAN_FILES:
        cell = (
            <UploadScanFilesCell
                fieldType={key}
                value={value}
                record={record}
                entityType={entityType ?? EntityType.TABLE}
                entityName={entityName}
            />
        );
        break;
    case FieldType.FILE:
    case FieldType.ATTORNEY:
    case FieldType.SIGNATURE:
        cell = (
            <FileColumn
                fieldType={key}
                type={field.type}
                value={value}
                record={record}
                entityType={entityType}
                entityName={entityName}
                actions={field.actions}
            />
        );
        break;
    case FieldType.SIGNATURE_DOWNLOAD_LINK:
    case FieldType.FILE_DOWNLOAD_LINK: {
        const topIcon = field?.fieldStructure?.[0]?.iconName;
        cell = (
            <DownloadFileLink
                value={value}
                record={record}
                stringStructure={field.stringStructure}
                icon={topIcon}
                fileType={
                    field.type === FieldType.SIGNATURE_DOWNLOAD_LINK
                        ? FileType.SIGNATURE
                        : FileType.FILE
                }
            />
        );
        break;
    }
    case FieldType.FILE_UPLOAD_CELL:
    case FieldType.SIGNATURE_UPLOAD_CELL:
        cell = (
            <FileUploadCell
                value={value}
                fieldMeta={field}
                record={record}
                entityName={entityName}
                isSignUpload={field.type === FieldType.SIGNATURE_UPLOAD_CELL}
            />
        );
        break;
    case FieldType.BOOLEAN_STRING:
        cell = (
            <BoolCellString
                field={field}
                value={get(record, field.key)}
            />
        );
        break;
    case FieldType.COMBINED_STRING: {
        const {stringStructure, type} = field;
        const dataForCombinedIntoString = typeof value === 'object' ? value : record;
        if (!stringStructure) {
            throw new Error(`Отсутствует stringStructure для поля с типом ${type}`);
        }

        let combinedString;
        if (Array.isArray(dataForCombinedIntoString)) {
            combinedString = dataForCombinedIntoString.map(item => combineString(stringStructure, item)).join(', ');
        } else {
            combinedString = combineString(stringStructure, dataForCombinedIntoString, field?.dateFormat);
        }
        if (field?.jasperReportName) {
            return <Link to={field.jasperReportName}>{combinedString}</Link>;
        }
        cell = <span className="field-doc">{combinedString}</span>;
        break;
    }

    case FieldType.BOOLEAN:
        cell = (
            <BoolCell
                field={field}
                value={value}
                record={record}
                handleChangeIndexRow={handleChangeIndexRow}
            />
        );
        break;
    case FieldType.ADDITIONAL_INFORMATION:
        cell = (
            <AdditionalInformationCell
                record={record}
                field={field}
                entityName={field?.parentEntityName || ''}
            />
        );
        break;
    case FieldType.ACTION_DELETE:
        cell = (
            <ActionDeleteCell
                entityName={entityName}
                record={record}

            />
        );
        break;
    case FieldType.DATE:
        cell = createStringDate(value, field?.dateFormat);
        break;
    case FieldType.DATE_PASSED_INDICATOR: {
        const now = new Date();
        cell = (
            <div
                style={{color: typeof value === 'string' && new Date(value) < now ? '#ED1C24' : 'inherit'}}
            >{createStringDate(value, field?.dateFormat)}
            </div>
        );
        break;
    }
    case FieldType.COMBINED_STRING_DATE: {
        const {stringStructure, dateFormat} = field;
        if (stringStructure) {
            const date = moment(combineString(stringStructure, record));
            if (date.isValid()) {
                cell = date.format(dateFormat);
            }
        }
        break;
    }
    case FieldType.CHECKBOX: {
        const linkField = options.linkField || 'id';
        const formItemName = `${field.key}_${record[linkField]}`;

        cell = (
            <Form.Item
                name={formItemName}
                key={formItemName}
                initialValue={value}
            >
                <BoolField
                    key={field.key}
                    value={value}
                />
            </Form.Item>
        );
        break;
    }

    case FieldType.STRING:
    case FieldType.NUMBER:
        if (field.index) {
            const cellArr = get(record, field.key);
            const index = field.index - 1;
            if (cellArr) {
                cell = cellArr[index as keyof typeof cellArr];
            }
            break;
        }
        cell = (get(record, field.key));
        if (isArray(cell)) {
            cell = createStringCell(cell, field?.stringStructure);
        }
        break;

    case FieldType.CHECKBOX_GROUP:
        cell = (
            <CheckboxField
                field={field}
                record={record}
                value={value}
                disabled={field.isDisabled}
                checkKey={checkKey}
                entityName={entityName}
            />

        );
        break;

    case FieldType.FUNCTION_BUTTON:
        cell = (
            <FunctionButtonField
                url={url}
                record={record}
                field={field}
            />
        );
        break;

    case FieldType.REQUEST_LINK: {
        cell = (
            <RequestLink
                field={field}
                record={record}
                url={url}
                entityName={entityName || ''}
            />
        );
        break;
    }

    case FieldType.REPORT_LINK:
        cell = (
            <ReportLinkField
                entityName={entityName}
                value={record}
                fieldMeta={field}
                entityType={entityType}
                prefix={prefix}
                url={url}
                record={record}
                params={params}
            />
        );
        break;

    case FieldType.EDITABLE_FIELD:
        cell = (
            <EditableField
                entityName={entityName}
                value={record}
                fieldMeta={field}
                entityType={entityType}
                prefix={prefix}
                url={url}
                params={params}
            />
        );
        break;
    case FieldType.OPEN_MODAL_ACTION: {
        cell = (
            <OpenModalAction
                url={url}
                entityName={entityName}
                record={record}
                field={field}
            />
        );
        break;
    }
    case FieldType.OPEN_DOCUMENT_CODES_MODAL: {
        cell = (
            <OpenDocumentCodesModalAction
                url={url}
                entityName={entityName}
                field={field}
                record={record}
            />
        );
        break;
    }
    case FieldType.OPEN_EDIT_MODAL: {
        if (field.isNeedToRedirect) {
            cell = (
                <QueryParamLink
                    params={[
                        {name: TablePageQueryParams.entity, value: record[field.isNeedToRedirect] as string},
                        {name: TablePageQueryParams.entityType, value: EntityType.EDIT_FORM},
                    ]}
                >{get(record, field.key)}
                </QueryParamLink>
            );
        }
        break;
    }
    case FieldType.NUMBER_SQUARE_DEFAULT:
    case FieldType.NUMBER_SQUARE_PRIMARY:
        cell = (
            <NumberSquare
                variant={FIELD_TYPE_TO_SQUARE_VARIANT_MAP[field.type]}
                value={value}
            />
        );
        break;
    case FieldType.RICH_TEXT_RENDERER:
        cell = (
            <RichTextRenderer value={typeof value === 'string' ? value : undefined} />
        );
        break;
    case FieldType.TABLE_ROW_INDEX:
        cell = 1 + rowIndex + ((params?.paginationCurrent ?? 1) - 1) * (params?.paginationPageSize ?? 0);
        break;

    case FieldType.STATUS:
        cell = (
            <Status
                value={value}
            />
        );
        break;

    case FieldType.REPORT_WATCHER:
        cell = (
            <ReportWatcher
                meta={field}
                record={record}
                value={value}
            />
        );
        break;

    case FieldType.OPEN_REPORT_CONFIGURATION_FORM:
        cell = (
            <OpenSettingsPageButton
                queryParams={{
                    name: TablePageQueryParams.entity,
                    value: record?.templateCode as string,
                }}
            />
        );
        break;

    case FieldType.OPEN_FLEX_FIELDS_SETTINGS_PAGE:
        cell = (
            <OpenSettingsPageButton
                queryParams={{
                    name: TablePageQueryParams.dffName,
                    value: record?.name as string,
                }}
                popoverText="Перейти к настройке ОГП"
            />
        );
        break;

    case FieldType.OPEN_REPORT_CONFIGURATION_HISTORY_MODAL:
        cell = (
            <OpenReportConfigurationHistoryModal
                templateName={record?.templateName as string}
            />
        );
        break;

    case FieldType.AIS3_MS_REQUEST_CONTENT:
    case FieldType.AIS3_MS_RESPONSE_CONTENT:
        cell = (
            <MonitorServicesContentDisplayer
                record={record}
                type={
                    field.type === FieldType.AIS3_MS_REQUEST_CONTENT
                        ? MonitorServiceContentDisplayerType.request
                        : MonitorServiceContentDisplayerType.response
                }
            />
        );
        break;

    default:
        cell = value;
    }

    const shouldUseDefaultRedirect = !field.jasperReportName
        && field.type !== FieldType.OPEN_MODAL_ACTION
        && field.type !== FieldType.OPEN_EDIT_MODAL
        && field.type !== FieldType.REQUEST_LINK;

    const redirectParams = getColumnRedirectParams(field, record);
    const cellWithRedirect = (
        redirectParams ? (
            <QueryParamLink
                params={redirectParams}
            >{cell}
            </QueryParamLink>
        ) : cell
    );

    return (
        <div
            style={{color: field?.customColor || ''}}
            className={cellClassName}
        >
            {(field.isNeedToRedirect || field?.redirectOnCondition) && shouldUseDefaultRedirect
                ? cellWithRedirect : cell}
        </div>
    );
};
