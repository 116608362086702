import {TablePaginationConfig} from 'antd/es/table';
import React from 'react';

import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';

export const COMMENTS_REQUEST_DEFAULT_PAGE_SIZE = 10;

export const TABLE_PAGINATION_CONFIG: TablePaginationConfig = {
    defaultPageSize: COMMENTS_REQUEST_DEFAULT_PAGE_SIZE,
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['bottomLeft'],
    showSizeChanger: true,
    locale: {items_per_page: 'на странице', jump_to: ''},
    showQuickJumper: {
        goButton: <ArrowRightIcon className="pagination_jumper" />,
    },
};
