import {DocumentTypeResponse} from './documents-types';

export const DOCUMENTS_MODULE = 'DOCUMENTS_MODULE';

export const FILE_UNIQUE_FIELD_NAME = 'fileName';

export const SIGN_UNIQUE_FIELD_NAME = 'fileName';

export const TOTAL_ENTITY = 'fileUpload';

export const NOT_FILLED_FIELDS_MESSAGE = 'Необходимо заполнить все обязательные поля';
export const NO_FILES_MESSAGE = 'Пожалуйста добавьте файлы для загрузки';
export const NO_HEADER_ORGANIZATION = 'Для этой организации в справочнике филиалов не '
    + 'заведена головная организация, загрузка файлов недоступна';
export const FILES_IS_EMPTY_MESSAGE = 'Добавленные файлы являются пустыми';

export const DefaultRegion: DocumentTypeResponse = {
    description: 'Прочий документ',
    id: '6',
    lookupCode: 'OTHER',
    lookupType: 'DOCUMENT_TYPE',
    meaning: 'Прочий документ',
};

export const DocumentsActions = {
    SET_DOCUMENT_TYPES: 'SET_DOCUMENT_TYPES',
    SET_DOCUMENT_TYPES_ON_UPLOAD: 'SET_DOCUMENT_TYPES_ON_UPLOAD',
    SET_DOCUMENTS_UPLOAD_MONITORING: 'SET_DOCUMENTS_UPLOAD_MONITORING',
    SET_DOCUMENTS_PAGINATION: 'SET_DOCUMENTS_PAGINATION',
    SET_DOCUMENT_SIGNING_METHOD: 'SET_SIGNING_METHOD',
    SET_DOCUMENTS_FOR_UPLOAD_ATTACH: 'SET_DOCUMENTS_FOR_UPLOAD_ATTACH',
    SET_DOCUMENT_PROPERTY: 'SET_DOCUMENT_PROPERTY',
};

export const BranchNamesActions = {
    SET_BRANCH_NAMES: 'SET_BRANCH_NAMES',
};

export const DocumentsForSignActions = {
    SET_DOCUMENT_LIST: 'SET_DOCUMENT_LIST',
};

export const SignatureStatus = {
    FOUND: 'Документ найден',
    NOT_FOUND: 'Документ не найден',
};

export const FileUploadActions = {
    SET_FILE_ACTIVITY_STATE: 'SET_FILE_ACTIVITY_STATE',
    RESET_ALL_ACTIVITY_STATE: 'RESET_ALL_ACTIVITY_STATE',
    RESET_FILE_ACTIVITY_STATE: 'RESET_FILE_ACTIVITY_STATE',
};

export const FileListActions = {
    SET_FILE_LIST: 'SET_FILE_LIST',
    RESET_FILE_LIST: 'RESET_FILE_LIST',
    DTO_FILE_LIST: 'DTO_FILE_LIST',
};

export const SignListActions = {
    SET_SIGN_LIST: 'SET_SIGN_LIST',
    RESET_SIGN_LIST: 'RESET_SIGN_LIST',
};
export const SingExtensions = {
    SET_SIGN_EXTENSIONS: 'SET_SIGN_EXTENSIONS',
    RESET_SIGN_EXTENSIONS: 'RESET_SIGN_EXTENSIONS',
};
export const SignatureUploadActions = {
    SET_SIGN_ACTIVITY_STATE: 'SET_SIGN_ACTIVITY_STATE',
    RESET_SIGN_ACTIVITY_STATE: 'RESET_SIGN_ACTIVITY_STATE',
};
