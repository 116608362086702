import {FormInstance} from 'antd/lib/form';
import {MutableRefObject} from 'react';

import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {StateSetter} from 'shared/types/generics';

import {
    getFilesToSign, processDocuments, processSignatures, validateCertificate,
} from '../lib';
import {
    CertificateExtended, CryptoProSignatureType, DocumentToSignInDatabase, FileWithId,
} from '../model/types';

interface BaseSigningParams {
    entityName: string;
    selectedCertificate?: CertificateExtended;
    selectedSignatureType: CryptoProSignatureType;
    isWithResign: boolean;
    modalRef: MutableRefObject<ModalOpenerComponentRef | null>;
    selectedFileExtension?: string;
}

interface FileSigningParams extends BaseSigningParams {
    files?: FileWithId[];
    fileFieldNames?: string[];
    fileList?: File[];
    form?: FormInstance;
    documentsToSignInDB?: DocumentToSignInDatabase[];
    value?: FileWithId[];
    setIsSigning: StateSetter<boolean>;
    onChange?: (files: File[], newValue: any) => void;
}

interface DocumentSigningParams extends BaseSigningParams {
    documentIdsToSign: string[];
    onStart?: () => void;
    onFinish?: () => void;
}

/**
 * Хук используется для обработки подписания файлов или документов
 * с использованием выбранного сертификата и алгоритма подписи при помощи плагина CryptoPro.
 * В зависимости от переданных параметров (файлы или документы) хук вызывает соответствующие функции для подписи,
 * такие как `processDocuments` или `processSignatures`, также выполняя валидацию сертификата
 * и организует выполнение процесса подписания с уведомлениями о ходе его выполнения и возникающих ошибках.
 *
 * @param params Параметры, которые могут включать файлы или документы для подписания,
 * а также информацию о сертификате и алгоритме подписи.
 *
 * @returns Объект с функцией `handleFinish`,
 * которая запускает процесс подписания в зависимости от переданных параметров.
 */
export const useCryptoProSigning = (
    params: FileSigningParams | DocumentSigningParams,
) => {
    const handleFinish = () => {
        const {
            selectedCertificate,
            selectedSignatureType,
            selectedFileExtension,
            isWithResign,
            entityName,
            modalRef,
        } = params;
        const validatedCertificate = validateCertificate(selectedCertificate);
        if (!validatedCertificate || !selectedCertificate) return;

        const {thumbprint, algorithm} = validatedCertificate;

        if ('files' in params || 'fileList' in params) {
            const {
                files,
                form,
                fileFieldNames,
                onChange,
                documentsToSignInDB,
                value,
                setIsSigning,
                fileList,
            } = params;

            const {filesToSign, overlappingFileNamesInDB} = getFilesToSign({
                form,
                fileFieldNames,
                inputFileValue: files,
                isWithResign,
                fileList,
            });

            modalRef.current?.closeModal();

            processSignatures({
                filesToSign,
                entityName,
                documentsToSignInDB,
                certificateThumbprint: thumbprint,
                certificateCadescomAlgorithm: algorithm,
                isWithResign,
                overlappingFileNamesInDB,
                value,
                setIsSigning,
                selectedCertificate,
                selectedSignatureType,
                selectedFileExtension,
                onChange,
                modalRef,
            });
        } else if ('documentIdsToSign' in params) {
            const {
                documentIdsToSign,
                onStart,
                onFinish,
            } = params;

            processDocuments({
                documentIdsToSign,
                entityName,
                certificateThumbprint: thumbprint,
                certificateCadescomAlgorithm: algorithm,
                selectedCertificate,
                selectedSignatureType,
                selectedFileExtension,
                modalRef,
                onStart,
                onFinish,
            });
        }
    };

    return {
        handleFinish,
    };
};
