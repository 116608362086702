import FileDownload from 'js-file-download';
import qs from 'qs';

import {UrlConfig} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {
    convertToFormData,
    performRequest,
    RequestPromise,
} from 'shared/utils';

import {FieldMeta, RequestType} from '../metadata';
import {
    DataRequestTableParams,
    DataResponseDirectory,
    DataResponseTable,
    Entity,
    LookupValue,
    TableEntityBodyParameters,
    userIdUrl,
} from './data-types';
import {convertParamsForRequest, getFilenameFromResponse} from './utils/data.utils';

export const fetchForCheckConfirm = (referenceUrl: string, params: Record<string, any>) => performRequest({
    url: `${referenceUrl}`,
    method: RequestType.GET,
    params,
});
export const fetchFormData = (
    entityName: string,
    id: string,
    entityComplexName?: string,
    params?: any,
    urlConfig?: UrlConfig,
) => {
    if (entityComplexName) {
        return performRequest({
            url: `${entityComplexName}/${id}`,
            method: RequestType.GET,
            params,
            urlConfig,
        });
    }

    return performRequest({
        url: `${entityName}/${id}`,
        method: RequestType.GET,
        urlConfig,
    });
};

export const fetchFormPageData = (
    entityName: string,
    entityComplexName?: string,
    params: DataRequestTableParams = {},
    body?: TableEntityBodyParameters,
    userId?: number,
    urlConfig?: UrlConfig,
): RequestPromise<DataResponseTable> => {
    if (entityComplexName) {
        return performRequest({
            url: `${entityComplexName}`,
            method: RequestType.GET,
            data: body,
            params,
            headers: {userId},
            urlConfig,
        });
    }

    return performRequest({
        url: `${entityName}/${EntityType.FORM.toLowerCase()}`,
        method: RequestType.GET,
        data: body,
        params,
        urlConfig,
    });
};

export const fetchReferenceData = <T = Entity[]>(
    referenceUrl?: string,
    referenceParams?: Record<string, any>,
    urlParam?: string,
): RequestPromise<T> => performRequest({
        url: urlParam ? `${referenceUrl}/${urlParam}` : `${referenceUrl}`,
        method: RequestType.GET,
        params: referenceParams && convertParamsForRequest(referenceParams),
    });

export const fetchFormDataFields = (
    referenceUrl?: string,
    id?: string | number,
    urlParam?: string,
    referenceParams?: Record<string, any>,
): RequestPromise<FieldMeta[]> => performRequest({
    url: `${referenceUrl}/${urlParam || id}`,
    method: RequestType.GET,
    params: referenceParams && convertParamsForRequest(referenceParams),
});
export const fetchPermissionDisplayFileComponent = (
    referenceUrl?: string,
    id?: string | number,
):RequestPromise<boolean> => performRequest({
    url: `${referenceUrl}/${id}`,
    method: RequestType.GET,
});

export const fetchTableData = (
    entityType: EntityType,
    entityName: string,
    params: DataRequestTableParams = {},
    entityComplexName?: string,
    userId?: number,
    prefix?: string,
    urlConfig?: UrlConfig,
): RequestPromise<DataResponseTable> => {
    const headers = entityComplexName === userIdUrl.news
    || entityName === userIdUrl.news
    || entityName === userIdUrl.settingsUser
    || entityName === userIdUrl.rolesAndOrganizations
    || entityComplexName === userIdUrl.settingsUser ? {userId} : undefined;
    const url = prefix || entityComplexName || entityName;

    const entityReportData = {
        DOC_ID: -1,
        page: 0,
        size: 5,
    };

    return performRequest({
        url: `${url}`,
        method: entityName === 'tax.accounting.income.report' ? RequestType.POST : RequestType.GET,
        params,
        headers,
        paramsSerializer: entityName === 'tax.accounting.income.report'
            ? undefined
            : () => qs.stringify(params, {arrayFormat: 'repeat'}),
        data: entityName === 'tax.accounting.income.report' ? entityReportData : undefined,
        urlConfig,
    });
};

export const fetchDirectoryData = (
    entityName: string,
): RequestPromise<DataResponseDirectory> => performRequest({
    url: `${entityName}`,
    method: RequestType.GET,
});

export const postDirectoryData = (
    referenceUrl: string,
    requestType: RequestType,
    data: Record<string, any>,
) => performRequest({
    url: referenceUrl,
    method: requestType,
    data: convertToFormData(data),
});

export const fetchSimpleData = (entityName: string) => performRequest({
    url: `${entityName}`,
    method: RequestType.GET,
});

export const actionRequestWithJson = (
    referenceUrl: string,
    requestType: RequestType,
    data: Record<string, any>,
    userId?: number,
) => performRequest({
    url: `${referenceUrl}`,
    method: requestType,
    data,
    headers: {userId},
});

export type TOptionsActionRequest = {
    isJsonRequest?: boolean;
    shouldUsedFileIndexName?: boolean;
    normalizedShouldUsedFileIndexName?: boolean;
    useRawData?: boolean;
};
export const updateNameDocument = (
    referenceUrl: string,
    requestType: RequestType,
    data: any,
) => performRequest({
    url: `${referenceUrl}`,
    method: requestType,
    params: data,
});
export const actionRequest = (
    referenceUrl: string,
    requestType: RequestType,
    data: Record<string, any>,
    {
        isJsonRequest,
        shouldUsedFileIndexName,
        normalizedShouldUsedFileIndexName,
        useRawData,
    }: TOptionsActionRequest,
    userId: number | string | undefined,
    urlConfig?: UrlConfig,
) => {
    if (isJsonRequest) {
        return performRequest({
            url: `${referenceUrl}`,
            method: requestType,
            data,
            urlConfig,
        });
    }
    if (requestType === RequestType.GET) {
        return performRequest({
            url: `${referenceUrl}`,
            method: requestType,
            params: data,
            urlConfig,
        });
    }
    return performRequest({
        url: `${referenceUrl}`,
        method: requestType,
        data: useRawData
            ? data
            : convertToFormData(data, {shouldUsedFileIndexName, normalizedShouldUsedFileIndexName}),
        headers: {userId},
        urlConfig,
    });
};

export const uploadFileAdditional = (
    url: string,
    file: FormData,
    params?: any,
) => performRequest({
    method: RequestType.POST,
    url,
    headers: {'Content-Type': 'multipart/form-data'},
    params,
    data: file,
});

export const postUploadFileAction = (
    url: string,
    data?: FormData,
    params?: any,
) => performRequest({
    url,
    method: RequestType.POST,
    data,
    params: convertParamsForRequest(params),
});

export const fetchReferenceValue = (
    referenceUrl: string,
    id: string | number,
): RequestPromise<LookupValue> => performRequest({
    url: `${referenceUrl}/${id}`,
    method: RequestType.GET,
});

export const uploadFile = (url: string, onUploadProgress: (progressEvent: any) => void) => performRequest({
    url,
    method: RequestType.POST,
    onUploadProgress,
});

export const downloadFile = async (url: string) => {
    const fileResponse = await performRequest({
        method: RequestType.GET,
        url,
        responseType: 'blob',
    });
    FileDownload(fileResponse.data, getFilenameFromResponse(fileResponse));
    return fileResponse;
};
