import get from 'lodash/get';
import React, {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Entity} from 'modules/data';
import {actionSaveRows} from 'modules/data/data-actions';
import {selectFilterEntityData, selectTableEntityData} from 'modules/data/data-selectors';
import {selectMetadata} from 'modules/metadata';
import {setMetadata} from 'modules/metadata/metadata-actions';
import {selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {RequestType, TableEntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {TableMode, TableModeContext} from '../../context';
import {ActionButton} from '../action-button';
import {disabledFields} from '../button-action-edit-field/button-action-edit-fields';
import {TableActionProps} from '../table-action-types';

export const ButtonActionSaveRows: React.FunctionComponent<TableActionProps> = ({
    meta,
    wrapper,
    entityName,
    ...props
}: TableActionProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {setTableMode} = useContext(TableModeContext);
    const editRows = useAppSelector(selectTableEntityData(entityName))?.editRows;
    const {fields} = useAppSelector(selectTableEntityMetadata(entityName));
    const {data: filterData} = useAppSelector(selectFilterEntityData(entityName));
    const fieldsMeta: TableEntityMeta = useAppSelector(selectMetadata(entityName, EntityType.TABLE));

    const {
        referenceUrl,
        requestType,
        additionalInfoKeys,
        shouldPickAdditionalInfoKeysFromFilter: shouldPickFromFilter,
        actionIcon,
        actionTitle,
        checkForDisabledMetaFields,
    } = meta;

    const actionsForRows = async () => {
        if (additionalInfoKeys) {
            const newDate: Entity[] = [];
            editRows?.forEach(row => {
                const valueRow: Record<string, any> = {};
                const dataToPickFrom = {
                    ...row,
                    ...(shouldPickFromFilter ? filterData : {}),
                };
                additionalInfoKeys
                    ?.filter((key: string) => Object.keys(dataToPickFrom).includes(key))
                    ?.forEach((key: string) => {
                        valueRow[key] = typeof dataToPickFrom[key] === 'object'
                            ? get(dataToPickFrom[key], 'value')
                            : dataToPickFrom[key];
                    });
                newDate.push(valueRow);
            });
            await dispatch(
                actionSaveRows(
                    entityName,
                    referenceUrl || entityName,
                    requestType || RequestType.POST,
                    newDate,
                ),
            );
        }
    };

    const handleClick = () => {
        (async () => {
            setIsLoading(true);
            if (checkForDisabledMetaFields) {
                if (fields?.some(field => field.isDisabled)) return;
                const newMetaData = disabledFields(fieldsMeta, true);
                await actionsForRows();
                dispatch(setMetadata({entityName, entityType: EntityType.TABLE, metadata: newMetaData}));
                setTableMode(TableMode.VIEW);
                return;
            }
            await actionsForRows();
            setTableMode(TableMode.VIEW);
            setIsLoading(false);
        })();
    };
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={actionIcon}
            disabled={!editRows?.length || isLoading}
        >
            {actionTitle}
        </Component>
    );
};
