import {EntityType} from 'shared/constants/entities';
import {createReducer} from 'store/config/creators';
import {Action} from 'store/config/types';

import {DataActions} from './data-constants';
import {
    DataResetPayload,
    DataSetFormModePayload,
    DataSetInitialDataPayload,
    DataSetIsEditablePayload,
    DataSetPayload,
    DataState,
    RemoveDocumentScansPayload,
    FileInfo,
    PropertyData,
    ResetFormDataPayload,
    SetDocumentScansDataPayload,
    SetFilterLoadingPayload,
    SetFormInstancePayload,
    SetUsedAdditionalOptionIdPayload,
    SetUsedAdditionalOptionsPayload,
    TableEntityData,
    UpdateFormDataPayload,
    SetSelectedSubsection,
} from './data-types';

const initialState: DataState = {};

export const dataReducer = createReducer(initialState, {
    [DataActions.SET_DATA]: (
        state: DataState,
        {payload}: Action<DataSetPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            loading: !!payload.loading,
            isError: payload.isError,
            [payload.entityType]: {
                ...(state[payload.entityName]?.[payload.entityType] || {}),
                ...(payload.data || {}),
                entityId: payload.entityId,
            },
        },
    }),
    [DataActions.RESET_DATA]: (
        state: DataState,
        {payload}: Action<DataResetPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            loading: !!payload.loading,
            isEditable: undefined,
            [payload.entityType]: undefined,
        },
    }),
    [DataActions.RESET_INITIAL_DATA]: (
        state: DataState,
        {payload}: Action<DataResetPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            loading: !!payload.loading,
            [payload.entityType]: {
                initialRows: undefined,
            },
        },
    }),

    [DataActions.RESET_ALL_DATA_WITH_EXCEPTION]: ( // reset всего, кроме заданного entityName
        state: DataState,
        {payload}: Action<Exclude<DataResetPayload, 'entityType'>>,
    ) => {
        const currentEntityNameData = state[payload.entityName];
        if (!currentEntityNameData) return state;
        return {
            [payload.entityName]: {
                ...currentEntityNameData,
                loading: !!payload.loading,
            },
        };
    },
    [DataActions.SET_FORM_MODE]: (
        state: DataState,
        {payload}: Action<DataSetFormModePayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            [payload.entityType]: {
                ...(state[payload.entityName]?.[payload.entityType] || {}),
                formMode: payload.formMode,
            },
        },
    }),
    [DataActions.SET_IS_EDITABLE]: (
        state: DataState,
        {payload}: Action<DataSetIsEditablePayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            [payload.entityType]: {
                ...(state[payload.entityName]?.[payload.entityType] || {}),
                isEditable: payload.isEditable,
            },
        },
    }),
    [DataActions.UPDATE_FORM_DATA]: (state: DataState, {payload}: Action<UpdateFormDataPayload>) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            [EntityType.FORM]: {
                ...(state[payload.entityName][EntityType.FORM] || {}),
                data: {...(state[payload.entityName][EntityType.FORM]?.data || {}), ...payload.data},
            },
        },
    }),
    [DataActions.RESET_FORM_DATA]: (state: DataState, {payload}: Action<ResetFormDataPayload>) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            [EntityType.FORM]: {},
        },
    }),
    [DataActions.SET_FORM_INSTANCE]: (state: DataState, {payload}: Action<SetFormInstancePayload>) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            [EntityType.FORM]: {
                ...(state[payload.entityName]?.[EntityType.FORM] || {}),
                formInstance: payload.form,
            },
        },
    }),
    [DataActions.SET_INITIAL_FORM_DATA]: (state: DataState, {payload}: Action<DataSetInitialDataPayload>) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            [EntityType.FORM]: {
                ...(state[payload.entityName][EntityType.FORM] || {}),
                data: state[payload.entityName][EntityType.FORM].initialData,
                isEditable: payload.isEditable,
            },
        },
    }),
    [DataActions.SET_FORM_USED_ADDITIONAL_OPTION_ID]: (
        state: DataState, {payload}: Action<SetUsedAdditionalOptionIdPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            [payload.entityType || EntityType.FORM_FIELDS_DATA]: payload.entityType ? {
                ...(state[payload.entityName]?.[payload.entityType] || {}),
                formUsedAdditionalOptionId: payload.optionId,
            } : {
                ...state[payload.entityName]?.[EntityType.FORM_FIELDS_DATA],
                [payload.entityNameChild]: {
                    formUsedAdditionalOptionId: payload.optionId,
                },
            },
        },
    }),
    [DataActions.SET_FORM_USED_ADDITIONAL_OPTIONS]: (
        state: DataState, {payload}: Action<SetUsedAdditionalOptionsPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            loading: !!payload.loading,
            FORM_FIELDS_DATA: {
                ...(state[payload.entityName].FORM_FIELDS_DATA || {}),
                options: {
                    ...(state[payload.entityName].FORM_FIELDS_DATA?.options || {}),
                    [payload.entityType]: payload.data,
                },
            },
        },
    }),
    [DataActions.RESET_FORM_USED_ADDITIONAL_OPTIONS]: (
        state: DataState, {payload}: Action<SetUsedAdditionalOptionIdPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            FORM_FIELDS_DATA: {
                ...(initialState[payload.entityName]?.FORM_FIELDS_DATA || {}),
            },
        },
    }),
    [DataActions.SET_FILTER_LOADING_STATE]: (
        state: DataState, {payload}: Action<SetFilterLoadingPayload>,
    ) => ({
        ...state,
        [payload.entityName]: {
            ...(state[payload.entityName] || {}),
            [EntityType.FILTER]: {
                ...(state[payload.entityName]?.[EntityType.FILTER] || {}),
                loading: payload.loading,
            },
        },
    }),
    [DataActions.SET_SELECTED_SUBSECTION]: (
        state: DataState,
        {payload}: Action<SetSelectedSubsection>,
    ) => ({
        ...state,
        selectedSubsection: payload,
    }),
    [DataActions.SET_NOTIFICATION_DURATION]: (
        state: DataState,
        {payload}: Action<PropertyData>,
    ) => ({...state, ...payload}),
    [DataActions.SET_DOCUMENT_SCANS_DATA]: (
        state: DataState,
        {payload}: Action<SetDocumentScansDataPayload>,
    ) => {
        const {
            entityName, entityType, docId, scans,
        } = payload;
        if (entityType) {
            const {rows: tableRows = []} = state[entityName]?.[entityType] as TableEntityData ?? {};

            const updatedTableRows = tableRows?.map(tableRow => {
                if (tableRow.id === docId) {
                    return {
                        ...tableRow,
                        scanDocumentAttachments: scans,
                    };
                }
                return tableRow;
            });

            if (!updatedTableRows) return {...state};

            return {
                ...state,
                [entityName]: {
                    ...(state[entityName] ?? {}),
                    [entityType]: {
                        ...(state[entityName]?.[entityType] ?? {}),
                        rows: updatedTableRows,
                    },
                },
            };
        }

        return {...state};
    },
    [DataActions.REMOVE_DOCUMENT_SCANS]: (
        state: DataState,
        {payload}: Action<RemoveDocumentScansPayload>,
    ) => {
        const {
            entityName, entityType, docId, attachmentsIds,
        } = payload;
        if (entityType) {
            const {rows: tableRows = []} = state[entityName]?.[entityType] as TableEntityData ?? {};

            const updatedTableRows = tableRows?.map(tableRow => {
                if (tableRow.id === docId) {
                    const scanDocumentAttachments = (tableRow.scanDocumentAttachments ?? []) as FileInfo[];

                    const scanDocumentAttachmentsFiltered = scanDocumentAttachments
                        .filter(attachment => !attachmentsIds.includes(`${attachment.attachmentId}`));

                    return {
                        ...tableRow,
                        scanDocumentAttachments: scanDocumentAttachmentsFiltered,
                    };
                }

                return tableRow;
            });

            if (!updatedTableRows) return {...state};

            return {
                ...state,
                [entityName]: {
                    ...(state[entityName] ?? {}),
                    [entityType]: {
                        ...(state[entityName]?.[entityType] ?? {}),
                        rows: updatedTableRows,
                    },
                },
            };
        }

        return {...state};
    },
});
