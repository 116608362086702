import {createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {ais3SliceName} from '../ais-slice-constants';

const selectAisSliceState = (state: AppState) => state[ais3SliceName];

export const selectNotificationScenariosSettingsTableData = createSelector(
    selectAisSliceState,
    ({aisNotificationScenariosSettings}) => aisNotificationScenariosSettings.notificationScenariosTable,
);

export const selectNotificationScenariosSettingsServices = createSelector(
    selectAisSliceState,
    ({aisNotificationScenariosSettings}) => aisNotificationScenariosSettings.notificationScenariosServices,
);
