import {createAsyncThunk} from '@reduxjs/toolkit';

import {
    fetchAisSignatories,
    FetchAisSignatoriesArgs,
    putAisSignatories,
    PutAisSignatoriesArgs,
} from 'store/slices/directories-company-slice/directories-company-slice-api';
import {directoriesCompanySliceName} from 'store/slices/directories-company-slice/directories-company-slice-constants';
import {AisSignatoryDto} from 'store/slices/directories-company-slice/directories-company-slice-types';

export const loadAisSignatoriesByOrgId = createAsyncThunk<
    AisSignatoryDto[], FetchAisSignatoriesArgs
    >(
        `${directoriesCompanySliceName}/loadAisSignatoriesByOrgId`,
        async (arg, {rejectWithValue}) => {
            try {
                const response = await fetchAisSignatories(arg);
                if (response.status !== 200) {
                    return rejectWithValue('При загрузке данных произошла ошибка');
                }

                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const uploadAisSignatoriesByOrgId = createAsyncThunk<
    string, PutAisSignatoriesArgs
    >(
        `${directoriesCompanySliceName}/uploadAisSignatoriesByOrgId`,
        async (arg, {rejectWithValue}) => {
            try {
                const response = await putAisSignatories(arg);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
