import {Card, Form as AntForm, Modal} from 'antd';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {FormMode} from 'components/form';
import {AlertErrorMessage} from 'components/form/alert-error-message/alert-error-message';
import {Buttons} from 'components/form/buttons';
import {Fields} from 'components/form/fields/fields';
import {useFormElementsAvailability} from 'components/form/hooks/check-form-elements-availability.hook';
import {dispatchFormWatcherEvent} from 'components/form/hooks/use-form-watcher';
import {Spinner} from 'components/spinner';
import {resetLoadedData} from 'modules/data';
import {fetchPermissionDisplayFileComponent} from 'modules/data/data-api';
import {normalizeFormDataForRequest} from 'modules/data/utils/data.utils';
import {loadMetadata as loadMetadataAction} from 'modules/metadata';
import {setFormDraftMetadata} from 'modules/metadata/metadata-actions';
import {selectFormEntityMetadata} from 'modules/metadata/metadata-selectors';
import {getActionByType} from 'modules/metadata/metadata-utils';
import {EntityType} from 'shared/constants/entities';
import {DEFAULT_FORM_MODAL_WIDTH} from 'shared/constants/layout';
import {URL_REQUESTS} from 'shared/constants/urls';
import {showMessageFromResponse} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface, selectModalData} from 'store/slices/modals-slice';
import {selectRequestData, selectSubmitButtonActionType} from 'store/slices/request-slice/request-selectors';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';
import {createRequestStatus} from 'store/slices/request-slice/request-slice-api';
import {
    loadRequestData, loadRequestStatusesCommentData,
    loadRequestStatusesData,
} from 'store/slices/request-slice/request-slice-thunks';
import {RequestStatus} from 'store/slices/request-slice/request-slice-type';

import {REQUESTS_DISPLAY_FILES_URL} from '../constants/requests.constants';
import {REQUEST_FIELD_FILES, RequestFormFormKey} from '../request-form/request-form.constants';
import {convertRequestFormData} from '../utils/request-api-utils';

import './request-form-modal.less';

export const RequestFormModal: React.FunctionComponent<ModalComponentInterface> = ({
    onClose,
}: ModalComponentInterface) => {
    const dispatch = useAppDispatch();
    const [form] = AntForm.useForm();

    const mode = FormMode.CREATE;

    const [errorFormMessage, setErrorFormMessage] = useState<string | null>(null);
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const [hasPermissionDisplayFileComponent, setPermissionDisplayFileComponent] = useState(false);

    const entityName = useAppSelector(state => selectModalData(state)?.entityName) || '';
    const metadata = useAppSelector(selectFormEntityMetadata(entityName));
    const modalParameters = useAppSelector(state => selectModalData(state))?.modalParameters;
    const requestData = useAppSelector(selectRequestData);
    const submitButtonActionType = useAppSelector(selectSubmitButtonActionType);

    const {resetRequestStatusesData, resetSubmitButtonActionType} = requestSliceActions;

    const entityClassName = entityName?.split('.').join('-');
    const {currentActionsVisibleState} = useFormElementsAvailability(mode, entityName);
    const newStatus = modalParameters?.status;
    const isFileComponent = modalParameters?.isFileComponent;
    const fieldList = isFileComponent && hasPermissionDisplayFileComponent
        ? [...metadata?.fields, REQUEST_FIELD_FILES]
        : metadata?.fields;
    const requestCommentId: string = modalParameters?.requestCommentId;
    const requestFormKey: string = modalParameters?.requestFormKey;
    const requestTypeId = requestData?.requests?.topicRequestType?.id;
    const requestType = requestData?.requests?.requestType || requestData?.requests?.topicRequestType?.typeCode;
    const isRequestAdditionalPlacementType = requestType === RequestFormFormKey.REQUEST_ADDITIONAL_PLACEMENT;
    const prolongationDate: Date = form?.getFieldValue('prolongationDate');
    const numberRequest: string = form?.getFieldValue('numberRequest');
    const convertedRequestFormData = convertRequestFormData(
        requestData,
        requestCommentId,
        prolongationDate,
        isRequestAdditionalPlacementType,
        numberRequest,
    );

    const formClassNames = cn(
        'form-body',
        {[entityClassName]: entityName},
    );

    useEffect(() => {
        if (!metadata) {
            dispatch(loadMetadataAction(entityName, EntityType.FORM));
        }
    }, []);

    useEffect(() => {
        form.setFieldsValue(
            convertedRequestFormData?.data,
        );
    }, [convertedRequestFormData]);

    useEffect(() => {
        if (requestData?.topicReportInfo && requestData?.topicReportInfo?.reportRowLinks) {
            const {reportRowLinks} = requestData.topicReportInfo;
            form.setFieldsValue({
                content: reportRowLinks.map(
                    reportRow => reportRow.links.map(links => links.link),
                ).flat().join(', '),
            });
        }

        const checkPermissionDisplayFileComponent = async () => {
            const hasPermission = await fetchPermissionDisplayFileComponent(
                REQUESTS_DISPLAY_FILES_URL,
                requestTypeId,
            );
            setPermissionDisplayFileComponent(hasPermission?.data);
        };
        checkPermissionDisplayFileComponent().catch(console.error);
    }, []);

    const handleFinish = async () => {
        try {
            const requestId = requestData?.requests?.id;

            if (!requestCommentId || !requestId) {
                return;
            }

            const saveAction = !!submitButtonActionType && getActionByType(submitButtonActionType)(metadata.actions);

            const newData = normalizeFormDataForRequest(form.getFieldsValue(), fieldList);

            setSubmitDisabled(true);
            if (saveAction) {
                await createRequestStatus()?.changeStatus(
                    newStatus,
                    requestCommentId,
                    saveAction?.referenceUrl || URL_REQUESTS,
                    newData,
                )?.then(res => {
                    if (res.status === 200) {
                        if (newStatus === RequestStatus.COMMENT_REQUEST
                                || newStatus === RequestStatus.REQUEST_RESPONSE) {
                            dispatch(loadRequestStatusesCommentData({commentId: requestCommentId, requestFormKey}));
                            dispatch(loadRequestData({id: String(requestId), url: URL_REQUESTS}));
                            return;
                        }
                        if (entityName) {
                            dispatch(resetLoadedData(entityName, EntityType.TABLE));
                        }

                        dispatch(loadRequestData({id: String(requestId), url: URL_REQUESTS}));
                        dispatch(resetRequestStatusesData());
                        // todo: загрузка после ресета не должна быть ручной, за этоим должен следить useEffect
                        dispatch(loadRequestStatusesData({commentId: requestCommentId, requestFormKey}));
                    }
                });
            }
            dispatch(setFormDraftMetadata({entityName, isDraft: false}));
            dispatch(resetSubmitButtonActionType());
            onClose();
        } catch (e) {
            console.error(e.error);
            setErrorFormMessage(e.response?.data);
            showMessageFromResponse({response: e.response, isError: true});
        } finally {
            setSubmitDisabled(false);
        }
    };

    const handleFinishFailed = () => {
        setErrorFormMessage('Форма не прошла проверку');
    };

    return (
        <Modal
            title={metadata?.title}
            className="request-form-modal"
            width={DEFAULT_FORM_MODAL_WIDTH}
            onCancel={onClose}
            maskClosable={false}
            footer={null}
            visible
        >
            <Card
                className={formClassNames}
            >
                <AntForm
                    layout="vertical"
                    className="form"
                    form={form}
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                    onValuesChange={(changed: any, values: any) => {
                        dispatchFormWatcherEvent({values, targetForm: form});
                    }}
                >
                    {
                        /* todo: условный рендер компонентов с передачей form через пропсы ради дизейбла кнопки делать
                            нельзя, это припятствует получению значений через form.getFieldValues в handleFinish.
                            Обернуть в Spin.
                        */
                    }
                    {isSubmitDisabled
                        ? <Spinner />
                        : (
                            <>
                                <AlertErrorMessage
                                    message={errorFormMessage}
                                    closeErrorMessage={() => setErrorFormMessage(null)}
                                />
                                <div className={formClassNames}>
                                    <div className="form-fields">
                                        <Fields
                                            formData={convertedRequestFormData}
                                            parentEntityName={entityName}
                                            entityName={entityName}
                                            list={fieldList}
                                            formMode={mode}
                                            form={form}
                                        />
                                    </div>
                                    <Buttons
                                        onClose={onClose}
                                        actions={metadata?.actions}
                                        form={form}
                                        setFormErrorMessage={setErrorFormMessage}
                                        currentVisibleState={currentActionsVisibleState}
                                        formMode={mode}
                                    />
                                </div>
                            </>
                        )}
                </AntForm>
            </Card>
        </Modal>
    );
};
