import React from 'react';

import {ReactComponent as DownloadIcon} from 'shared/assets/download.svg';
import {downloadFileById} from 'shared/utils/file';

import 'components/ais/reclaiming-information-card/fields/signature-item/signature-item.less';

interface SignatureItemProps {
    signatureName: string | undefined;
    attachmentId: string | undefined;
}

export const SignatureItem = ({
    signatureName, attachmentId,
}: SignatureItemProps) => (
    <div
        className="signature-item"
    >
        <div className="signature-item-info">
            {signatureName ?? '-'}
            {!!signatureName && (
                <DownloadIcon
                    onClick={() => attachmentId && downloadFileById(signatureName, attachmentId)}
                />
            )}
        </div>
    </div>
);
