import {Button as AntButton, Select} from 'antd';
import cn from 'classnames';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {TableActionProps} from 'components/table/action';

import './button-action-download-report.less';
// eslint-disable-next-line import/order
import {CaretDownOutlined} from '@ant-design/icons';
import {getPopupContainer} from 'shared/utils';

export const ButtonActionDownloadReport: React.FunctionComponent<TableActionProps> = ({
    meta,
    ...props
}: TableActionProps) => (
    <div className="report-action">
        <AntButton
            className={cn('table-action')}
            {...props}
        >
            {meta.actionIcon && ((
                <div className="action-icon">
                    <DynamicIcon
                        type={meta?.actionIcon}
                        style={{fontSize: 16}}
                    />
                </div>
            ))}
            {meta?.actionTitle}
        </AntButton>
        <Select
            defaultValue="DOCX"
            suffixIcon={() => (<CaretDownOutlined style={{color: '#fff'}} />)}
            options={[
                {value: 'PDF', label: 'PDF'},
                {value: 'Excel(постранично)', label: 'Excel(постранично)'},
                {value: 'Excel', label: 'Excel'},
                {value: 'CSV', label: 'CSV'},
                {value: 'DOCX', label: 'DOCX'},
            ]}
            getPopupContainer={getPopupContainer()}
        />
    </div>
);
