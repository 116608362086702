import {RcFile} from 'antd/lib/upload';
import {useState} from 'react';

import {signatureUpload} from 'components/table/columns/file/file-column-utils';
import {showMessage} from 'shared/utils/notifications';

import 'components/ais/reclaiming-information-card/fields/signature-item/signature-item.less';

interface useUploadSignatureProps {
  attachmentId: string | undefined;
  reloadData?: () => void;
}

/**
 * Хук `useUploadSignature` предоставляет функциональность для загрузки подписи и управления состоянием загрузки.
 *
 * @param attachmentId - Идентификатор родительского документа. Если отсутствует, загрузка невозможна.
 * @param reloadData - Необязательная функция для обновления данных после успешной загрузки.
 *
 * @returns Кортеж, содержащий:
 * - `isUploading` - Логическое значение, указывающее, выполняется ли загрузка файла в данный момент.
 * - `handleUploadFile` - Функция, обрабатывающая загрузку файла. Принимает файл в качестве аргумента.
 *
 * ### Описание:
 * - Если `attachmentId` не указан, будет отображено сообщение об ошибке.
 * - При успешной загрузке вызывается `reloadData` (если она передана).
 * - При ошибке загрузки выводится уведомление с описанием проблемы.
 */

export const useUploadSignature = ({attachmentId, reloadData}: useUploadSignatureProps):
[boolean, (file: RcFile) => boolean] => {
    const [isUploading, setIsUploading] = useState(false);

    const handleUploadFile = (file: RcFile) => {
        if (!attachmentId) {
            showMessage({
                message: 'Ошибка при загрузке подписи',
                description: 'Идентификатор родительского документа отсутствует',
                isError: true,
            });
            return false;
        }

        setIsUploading(true);
        signatureUpload(file, attachmentId)
            .then(() => reloadData?.())
            .catch(error => {
                showMessage({
                    message: 'Не удалось загрузить подпись',
                    description: `Текст ошибки «${error?.response?.data ?? 'Обратитесь к администратору'}»`,
                    isError: true,
                });
            })
            .finally(() => {
                setIsUploading(false);
            });

        return false;
    };

    return [isUploading, handleUploadFile];
};
