import moment from 'moment';
import {useMemo} from 'react';

import {DATE_WITH_TIME_DOTS_SECONDS_12} from 'shared/constants/date-format';

/**
 * Используется для обработки и форматирования дат действия сертификата.
 *
 * @param certificate Объект, содержащий данные о сроках действия сертификата.
 * @param certificate.validFrom Дата начала действия сертификата в виде строки. Допускается отсутствие.
 * @param certificate.validTo Дата окончания действия сертификата в виде строки. Допускается отсутствие.
 *
 * @returns Объект с информацией о форматированных датах и статусе валидности:
 * - `validFromFormatted` — отформатированная строка даты начала действия сертификата.
 * Если отсутствует, возвращает `'--'`.
 * - `validToFormatted` — отформатированная строка даты окончания действия сертификата.
 * Если отсутствует, возвращает `'--'`.
 * - `isValid` — булево значение, указывающее, действует ли сертификат в данный момент времени.
 * Если одна из дат отсутствует, возвращается `undefined`.
 */
export const useCertificateValidityDate = (certificate: { validFrom?: string; validTo?: string } | undefined) => {
    const validFromDate = useMemo(() => certificate?.validFrom && new Date(certificate.validFrom), [certificate]);
    const validToDate = useMemo(() => certificate?.validTo && new Date(certificate.validTo), [certificate]);

    const validFromFormatted = useMemo(
        () => (validFromDate ? moment(validFromDate).format(DATE_WITH_TIME_DOTS_SECONDS_12) : '--'),
        [validFromDate],
    );

    const validToFormatted = useMemo(
        () => (validToDate ? moment(validToDate).format(DATE_WITH_TIME_DOTS_SECONDS_12) : '--'),
        [validToDate],
    );

    const isValid = useMemo(() => {
        if (!validFromDate || !validToDate) return undefined;
        const now = new Date();
        return now >= validFromDate && now <= validToDate;
    }, [validFromDate, validToDate]);

    return {validFromFormatted, validToFormatted, isValid};
};
