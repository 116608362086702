import {RequestType} from 'modules/metadata';
import {RequestTableResponseWithPagination} from 'shared/types/requests';
import {RpcDeleteResponse} from 'shared/types/rpc';
import {performRequest} from 'shared/utils';

import {AIS3_SCENARIO_SETTINGS_API_PREFIX} from './ais-notification-scenarios-settings-constants';
import {AisNotificationScenariosSettingsServices, AisNotificationScenariosSettingsTableDto} from './ais-notification-scenarios-settings-types';

export const fetchAisNotificationScenariosSettingsTable = (
    params: FetchAisNotificationScenariosSettingsParams,
) => performRequest<RequestTableResponseWithPagination<AisNotificationScenariosSettingsTableDto>>({
    url: AIS3_SCENARIO_SETTINGS_API_PREFIX,
    method: RequestType.GET,
    params,
});

export const fetchAisNotificationScenariosServices = () => (
    performRequest<AisNotificationScenariosSettingsServices[]>({
        url: `${AIS3_SCENARIO_SETTINGS_API_PREFIX}/get-services`,
        method: RequestType.GET,
    }));

export const deleteAisNotificationScenariosSettingsTableRowsRequest = async (
    {
        ids,
    }: DeleteAisNotificationScenariosSettingsTableRowsParams,
) => performRequest<RpcDeleteResponse>({
    url: `${AIS3_SCENARIO_SETTINGS_API_PREFIX}/rpc/delete-list`,
    method: RequestType.DELETE,
    data: {ids},
});

export const postAisNotificationScenariosSetting = async (
    data: PostAisNotificationScenariosSettingData,
) => performRequest<string>({
    url: AIS3_SCENARIO_SETTINGS_API_PREFIX,
    method: RequestType.POST,
    data,
});

export const putAisNotificationScenariosSetting = async (
    data: PutAisNotificationScenariosSettingData,
) => performRequest<string>({
    url: AIS3_SCENARIO_SETTINGS_API_PREFIX,
    method: RequestType.PUT,
    data,
});

export interface FetchAisNotificationScenariosSettingsParams {
  length?: number;
  paginationCurrent?: number;
  paginationPageSize?: number;
  search?: string | null;
  sorterField?: string;
  sorterOrder?: 'ascend' | 'descend';
  start?: number;
  taxPeriodId?: number;
  organizationId?: number;
}

export interface DeleteAisNotificationScenariosSettingsTableRowsParams {
    ids: number[];
}

export type PostAisNotificationScenariosSettingData = Partial<Pick<AisNotificationScenariosSettingsTableDto,
'interactionScenarioId'
| 'notificationName'
| 'responseCodeId'
| 'recipientGroupsIds'
| 'recipientUsersIds'
| 'notificationMessage'
>>

export type PutAisNotificationScenariosSettingData = {id: number}
& Partial<Pick<AisNotificationScenariosSettingsTableDto,
'interactionScenarioId'
| 'notificationName'
| 'responseCodeId'
| 'recipientGroupsIds'
| 'recipientUsersIds'
| 'notificationMessage'
>>
