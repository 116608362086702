import {FileExtensions} from 'shared/constants/file-extensions';
import {performRequest} from 'shared/utils';

import {CryptoProSignedDoc} from '../model/types';
import {CryptoProFileInfo, SendSignedFileArgs} from './types';

/**
 * Получение хэша файла
 */
export const fetchFileHash = async (params: {
    entityName: string | undefined;
    docId: string;
    algorithmCode: number;
}): Promise<string> => {
    const response = await performRequest({
        url: `documents/${params?.entityName}/get-content-hash-cryptopro`,
        method: 'GET',
        params: {
            algorithmCode: params.algorithmCode,
            docId: params.docId,
        },
    });
    return response.data;
};

/**
 * Получение данных о файле
 */
export const fetchFileInfo = async (entityName: string | undefined, docId: string): Promise<CryptoProFileInfo> => {
    const response = await performRequest<CryptoProFileInfo>({
        url: `documents/${entityName}/get-content-param-cryptopro/${docId}`,
    });
    return response.data;
};

/**
 * Отправка подписанного файла на сохранение
 */
export const sendSignedFile = async ({
    signatureFile,
    fileName,
    docId,
    publishAllowed,
    entityName,
    selectedFileExtension = FileExtensions.SIG,
}: SendSignedFileArgs): Promise<void> => {
    const fileFormData = new FormData();

    fileFormData.set('signContent', signatureFile);
    fileFormData.set('fileName', `${fileName}${selectedFileExtension}`);
    fileFormData.set('docId', `${docId}`);
    fileFormData.set('publishAllowed', `${publishAllowed}`);

    await performRequest({
        url: `documents/${entityName}/save-sign-cryptopro`,
        method: 'POST',
        data: fileFormData,
    });
};

/**
 * Получение подписанных документов
 */
export const fetchSignedDocs = async (documentIds: string[]): Promise<CryptoProSignedDoc[]> => {
    const data = await performRequest<CryptoProSignedDoc[]>({
        url: 'documents/rpc/search-signed-docs',
        method: 'POST',
        data: {ids: documentIds},
    });
    return data.data;
};

/**
 * Получение незашифрованного сообщения по id документа
 */
export const fetchUnencryptedMessage = async (params: {
    entityName: string | undefined;
    docId: string | undefined;
}): Promise<string> => {
    const {entityName, docId} = params;
    const data = await performRequest<string>({
        url: `documents/${entityName}/get-content-data-cryptopro/${docId}`,
        method: 'GET',
    });
    return data.data;
};
