// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YUCoUAtOcCW6_DkCnfeeJ {\n  cursor: pointer;\n  color: #1E72D7;\n}\n.YUCoUAtOcCW6_DkCnfeeJ:hover {\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://src/components/table-report/components/dd-modal/dd-modal.module.less"],"names":[],"mappings":"AAGE;EACE,eAAA;EACA,cAAA;AAFJ;AAII;EACE,0BAAA;AAFN","sourcesContent":["@import 'src/themes/default/default';\n\n.dd-modal {\n  &__item {\n    cursor: pointer;\n    color: @link-complement-light;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n@hack: true; @import \"/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dd-modal__item": "YUCoUAtOcCW6_DkCnfeeJ"
};
export default ___CSS_LOADER_EXPORT___;
