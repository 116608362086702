// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3VoYy8GsaRIDuNs7fwZWT_ {\n  border: 1px solid #CBD1E0;\n  padding: 16px;\n  border-radius: 3px;\n  font-size: 12px;\n  background-color: #F8FAFD;\n}\n.UlbjFkP-tIO-wWocVFZcp {\n  margin-bottom: 10px;\n  display: flex;\n  gap: 5px;\n  align-items: center;\n  color: #ED1C24;\n  font-weight: 500;\n}\n.UlbjFkP-tIO-wWocVFZcp svg {\n  margin-bottom: 1px;\n  font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://src/features/sign-with-crypto-pro/ui/signature-modal-content/signature-modal-content.module.less"],"names":[],"mappings":"AAGE;EACE,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;AAFJ;AAII;EACE,mBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AAFN;AAJI;EASI,kBAAA;EACA,eAAA;AAFR","sourcesContent":["@import 'src/themes/default/default';\n\n.crypto-pro-signature-modal-content {\n  &__overlapping {\n    border: 1px solid @line-faint-light;\n    padding: 16px;\n    border-radius: 3px;\n    font-size: 12px;\n    background-color: @gray-100;\n\n    &__warning {\n      margin-bottom: 10px;\n      display: flex;\n      gap: 5px;\n      align-items: center;\n      color: @critic-dark;\n      font-weight: 500;\n\n      svg {\n        margin-bottom: 1px;\n        font-size: 14px;\n      }\n    }\n  }\n}\n@hack: true; @import \"/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crypto-pro-signature-modal-content__overlapping": "_3VoYy8GsaRIDuNs7fwZWT_",
	"crypto-pro-signature-modal-content__overlapping__warning": "UlbjFkP-tIO-wWocVFZcp"
};
export default ___CSS_LOADER_EXPORT___;
