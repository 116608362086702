import {FileExcelOutlined} from '@ant-design/icons';
import {Checkbox, Form} from 'antd';
import {FormInstance} from 'antd/es/form';
import Input from 'antd/es/input';
import {useForm} from 'antd/lib/form/Form';
import React, {useEffect, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {UIBlocker} from 'components/ui-blocker';
import {loadAcceptableExtensions} from 'modules/documents/documents-actions';
import {selectDocumentExtensions} from 'modules/documents/documents-selectors';
import {StateSetter} from 'shared/types/generics';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import {useCryptoProInit} from '../../hooks';
import {getOverlappingFiles} from '../../lib';
import {
    CertificateExtended, CryptoProSignatureType, DocumentToSignInDatabase, CryptoProSignedDoc,
} from '../../model/types';
import {CryptoProCertificateInfo} from '../certificate-info/certificate-info';
import s from './signature-modal-content.module.less';

interface CryptoProModalProps {
    selectedCertificate: CertificateExtended | undefined;
    selectedSignatureType: CryptoProSignatureType;
    setSelectedCertificate: StateSetter<CertificateExtended | undefined>;
    setSelectedSignatureType: StateSetter<CryptoProSignatureType>;
    isWithPluginInitializationError: boolean;
    setIsWithPluginInitializationError: StateSetter<boolean>;
    handleFinish: () => void;
    isWithResign: boolean;
    setIsWithResign: StateSetter<boolean>;
    fileFieldNames?: string[];
    currentValue?: File[];
    mainForm?: FormInstance;
    documentsToSignInDB?: DocumentToSignInDatabase[];
    documentIds?: string[];
    setSignedDocs?: StateSetter<CryptoProSignedDoc[]>;
    signedDocs?: CryptoProSignedDoc[];
    selectedFileExtension?: string;
    setSelectedFileExtension: StateSetter<string | undefined>;
}

export const CryptoProSignatureModalContent = React.forwardRef<FormInstance, CryptoProModalProps>(({
    selectedCertificate,
    selectedSignatureType,
    setSelectedCertificate,
    setSelectedSignatureType,
    isWithPluginInitializationError,
    setIsWithPluginInitializationError,
    handleFinish,
    isWithResign,
    setIsWithResign,
    fileFieldNames,
    currentValue,
    mainForm,
    documentsToSignInDB,
    documentIds,
    setSignedDocs,
    signedDocs,
    selectedFileExtension,
    setSelectedFileExtension,
}: CryptoProModalProps, ref) => {
    const fetchedFileExtensions = useAppSelector(selectDocumentExtensions)?.accept;
    const signFileExtensions = fetchedFileExtensions ? fetchedFileExtensions.split(',') : [];
    const signFileExtensionsEntries = signFileExtensions.map(e => ({value: e, label: e}));
    const [isFileExtensionListLoading, setIsFileExtensionListLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const [form] = useForm();

    const {cryptoProData} = useCryptoProInit({
        documentIds,
        setSignedDocs,
        setIsWithPluginInitializationError,
    });

    useEffect(() => {
        const fetchFileExtensions = async () => {
            setIsFileExtensionListLoading(true);
            await dispatch(loadAcceptableExtensions());

            if (signFileExtensionsEntries.length > 0) {
                const defaultExtension = signFileExtensionsEntries[0].value;

                setSelectedFileExtension(defaultExtension);
                form.setFieldsValue({
                    signatureType: selectedSignatureType,
                    fileExtension: defaultExtension,
                });
            }

            setIsFileExtensionListLoading(false);
        };

        fetchFileExtensions();
    }, [signFileExtensionsEntries.length]);

    const {overlappingFiles, overlappingFileNamesInDB} = getOverlappingFiles({
        currentValue,
        fileFieldNames,
        form: mainForm,
        documentsToSignInDB,
    });

    const signedDocsOverlapping = signedDocs?.filter(doc => documentIds?.includes(`${doc.docId}`)) || [];

    return (
        <div className={s['crypto-pro-signature-modal-content']}>
            <UIBlocker content={isWithPluginInitializationError ? (
                <div className="d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="fw-500">Произошла ошибка инициализации плагина ЭЦП</div>
                    <div
                        className="mt-1"
                        style={{fontSize: 12}}
                    >
                        Связь с &quot;Криптопро ЭЦП Browser plug-in&quot; не установлена
                        или не найдена информация о криптопровайдере
                    </div>
                </div>
            ) : undefined}
            >
                <Form
                    ref={ref}
                    form={form}
                    layout="vertical"
                    className="form-flex"
                    onFinish={handleFinish}
                >
                    {!!(overlappingFiles.length || overlappingFileNamesInDB.length || signedDocsOverlapping.length) && (
                        <>
                            <div className={s['crypto-pro-signature-modal-content__overlapping']}>
                                <div className={s['crypto-pro-signature-modal-content__overlapping__warning']}>
                                    <FileExcelOutlined />
                                    Некоторые из выбранных документов уже имеют подпись
                                </div>
                                {overlappingFiles.map((file, index) => (
                                    <div key={file.name}>{index + 1}. {file.name}</div>
                                ))}
                                {overlappingFileNamesInDB.map((fileName, index) => (
                                    <div key={fileName}>{overlappingFiles.length + index + 1}. {fileName}</div>
                                ))}
                                {signedDocsOverlapping.map((doc, index) => (
                                    <div key={doc.docId}>{index + 1}. {doc.docName}</div>
                                ))}
                            </div>
                            <Checkbox
                                style={{marginTop: 2}}
                                checked={isWithResign}
                                onChange={e => setIsWithResign(e.target.checked)}
                            >
                                Переподписать документы
                            </Checkbox>
                        </>
                    )}

                    <Form.Item label="Криптопровайдер">
                        <Input
                            value="Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider"
                            disabled
                        />
                    </Form.Item>

                    <Form.Item label="Версия криптопровайдера">
                        <Input
                            value={cryptoProData?.systemInfo?.cspVersion}
                            disabled
                            placeholder="Версия неизвестна"
                        />
                    </Form.Item>

                    <Form.Item label="Версия плагина">
                        <Input
                            value={cryptoProData?.systemInfo?.cadesVersion}
                            disabled
                            placeholder="Версия неизвестна"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Тип подписи"
                        required
                        name="signatureType"
                        rules={[{required: true, message: 'Пожалуйста, выберите тип подписи'}]}
                    >
                        <CustomSelect
                            entries={[
                                {label: 'Отсоединенная', value: 'detached'},
                                {label: 'Присоединенная', value: 'attached'},
                            ]}
                            value={selectedSignatureType}
                            settings={{
                                placeholder: 'Выберите тип подписи',
                                showSearch: true,
                                formInstance: form,
                            }}
                            onChange={value => setSelectedSignatureType(value)}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Расширение файла"
                        required
                        name="fileExtension"
                        rules={[{required: true, message: 'Пожалуйста, выберите расширение файла'}]}
                    >
                        <CustomSelect
                            entries={signFileExtensionsEntries}
                            value={selectedFileExtension}
                            settings={{
                                placeholder: 'Выберите расширение файла',
                                showSearch: true,
                                formInstance: form,
                                isLoading: isFileExtensionListLoading,
                                // useFirstOptionAfterEntriesChanged: true,
                            }}
                            onChange={value => setSelectedFileExtension(value)}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Сертификат"
                        required
                        name="certificate"
                        rules={[{required: true, message: 'Пожалуйста, выберите сертификат'}]}
                    >
                        <CustomSelect
                            settings={{
                                placeholder: 'Выберите сертификат',
                                showSearch: true,
                            }}
                            onChange={value => {
                                setSelectedCertificate(cryptoProData?.certificatesExtended
                                    .find(cert => cert.thumbprint === value));
                            }}
                            entries={cryptoProData?.certificatesExtended.map(cert => ({
                                label: (() => {
                                    const {certificate} = cert;
                                    if (certificate.name) return `${certificate.name} (${certificate.issuerName})`;
                                    return certificate.issuerName;
                                })(),
                                value: cert.thumbprint,
                            }))}
                        />
                    </Form.Item>

                    {selectedCertificate && (
                        <div className="mt-2">
                            <CryptoProCertificateInfo certificate={selectedCertificate} />
                        </div>
                    )}
                </Form>
            </UIBlocker>
        </div>
    );
});
