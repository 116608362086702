import React, {FunctionComponent} from 'react';
import {useDispatch} from 'react-redux';

import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';
import {
    DEFAULT_DELETE_ELEMENT_CONFIRM_TEXT,
} from 'components/table/action/button-action-delete-tab/constants/button-delete-tab-constants';
import {selectEntityData} from 'modules/data';
import {createActionHandlerForDelete} from 'modules/data/data-actions';
import {FormEntityData} from 'modules/data/data-types';
import {RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {SimpleActionButton} from '../../components';

interface ButtonDeleteElementProps {
    actionTitle: string;
    entityName: string;
    referenceUrl: string;
    requestType: RequestType;
    linkField: string;
    onClose?: () => void;
    actionIcon?: string;
    useUrl?: boolean;
    itemListKey?: string;
}

export const ButtonDeleteElement: FunctionComponent<ButtonDeleteElementProps> = ({
    actionTitle,
    actionIcon,
    entityName,
    referenceUrl,
    requestType,
    linkField,
    onClose,
    useUrl,
    itemListKey,
    ...props
}: ButtonDeleteElementProps) => {
    const dispatch = useDispatch();
    const dataSelector = selectEntityData(entityName, EntityType.FORM);
    const formData = useAppSelector(state => dataSelector(state)) as FormEntityData;

    const ids = (() => {
        const id = formData?.data?.[linkField];
        return [id] ?? [];
    })();

    const handleDelete = async () => {
        await dispatch(createActionHandlerForDelete(
            {
                entityName,
                referenceUrl,
                requestType,
                itemList: {ids},
                itemListKey,
                entityTypes: [EntityType.TABLE],
                useUrl,
            },
        ));
        onClose?.();
    };

    return (
        <ActionConfirmModal
            cancelText="Отмена"
            approveText="Удалить"
            modalTitle="Удаление"
            confirmModalContent={DEFAULT_DELETE_ELEMENT_CONFIRM_TEXT}
            confirmAction={handleDelete}
        >
            {
                handleClick => (
                    <SimpleActionButton
                        type="default"
                        className="delete-action-btn"
                        onClick={handleClick}
                        title={actionTitle || 'Удалить'}
                        style={{width: '100%'}}
                        icon={actionIcon as TIconType}
                        {...props}
                    />
                )
            }
        </ActionConfirmModal>
    );
};
