import {Popover, Tooltip} from 'antd';
import cn from 'classnames';
import React, {Dispatch, SetStateAction} from 'react';

import {
    MonitoringResult,
    ProgramSetStage,
    stageStatus,
} from 'modules/documents/documents-upload-monitoring-types';
import {getPopupContainer} from 'shared/utils';

import {getStageStatus} from '../utils/document-upload-monitoring-utils';
import {DocumentsUploadLogs} from './documents-upload-log/documents-upload-log-container';
import {DocumentsUploadStatusIcon} from './documents-upload-status-icon/documents-upload-status-icon';

import './documents-upload-stage.less';

export interface DocumentsUploadStageProps {
    stage: ProgramSetStage;
    stageMonitoringResults?: MonitoringResult[];
    index: string;
    popoverIsVisible: boolean;
    setPopoverVisible: Dispatch<SetStateAction<string>>;
}

export const DocumentsUploadStage = ({
    stage,
    stageMonitoringResults,
    popoverIsVisible,
    index,
    setPopoverVisible,
}: DocumentsUploadStageProps) => {
    const {status, logs} = getStageStatus(stage, stageMonitoringResults);
    const handlePopoverOpen = () => {
        if ([stageStatus.reject, stageStatus.success].includes(status)) {
            setPopoverVisible(v => (v === index ? '' : index));
        }
    };

    return (
        <div className="documents-upload-stage">
            <Popover
                getPopupContainer={getPopupContainer()}
                visible={popoverIsVisible}
                content={(
                    <DocumentsUploadLogs
                        logs={logs}
                        programSetPrograms={stage.programSetPrograms}
                    />
                )}
            >
                <Tooltip title={stage.name}>
                    <span
                        onClick={handlePopoverOpen}
                        className={cn('stage-status-icon', status)}
                    >
                        <DocumentsUploadStatusIcon status={status} />
                    </span>
                </Tooltip>
            </Popover>
        </div>
    );
};
