import {
    DatePicker,
    Form as AntForm,
    Input,
    Space,
} from 'antd';
import {DatePickerProps} from 'antd/es/date-picker';
import {FormInstance} from 'antd/es/form';
import Radio, {RadioChangeEvent} from 'antd/lib/radio';
import moment, {Moment} from 'moment';
import React from 'react';

import {selectTableEntityData} from 'modules/data/data-selectors';
import {PropertyCode} from 'modules/data/data-types';
import {getPopupContainer} from 'shared/utils';
import {checkDisabledActions} from 'shared/utils/request';
import {useAppSelector} from 'store/config/hooks';

import './checkbox-date-group.less';

export interface BoolFieldProps {
    entityName: string;
    fieldKey: string;
    referenceUrl: string;
    propertyCodeList: string[];
    format?: string;
    form?: FormInstance;
    onChange?: (value?: string) => void;
    parentEntityName?: string;
    dependentsFieldKey?: string;
}

export const CheckboxDateGroup: React.FunctionComponent<BoolFieldProps> = ({
    format,
    propertyCodeList = [],
    parentEntityName,
    referenceUrl,
    onChange,
    form,
    dependentsFieldKey,
}: BoolFieldProps) => {
    const rows = useAppSelector(selectTableEntityData(parentEntityName || ''))?.rows;
    const [disabledPublication, setDisabledPublication] = React.useState(false);
    const [disabledPublicationOnDate, setDisabledPublicationOnDate] = React.useState(false);
    const [resultValue, setResultValue] = React.useState<Moment | null>(null);
    const [value, setValue] = React.useState(1);
    const sectionCodeContext: string = String(rows?.slice(0, 1).shift()?.sectionCode);
    format = format ?? 'DD.MM.YYYY HH:mm:ss';

    const handleChange = (e: RadioChangeEvent) => {
        if (e.target.value === 1) {
            if (onChange) onChange(moment(new Date()).format());
            if (form && dependentsFieldKey) form.setFieldsValue({[dependentsFieldKey]: false});
            setResultValue(null);
        } else if (form && dependentsFieldKey) form.setFieldsValue({[dependentsFieldKey]: true});
        setValue(e.target.value);
    };

    const handleChangeDatePicker: DatePickerProps['onChange'] = date => {
        if (onChange) onChange(moment(date).format());
        setResultValue(date);
    };

    React.useMemo(() => {
        (async () => {
            const dataAll = await Promise.all(
                propertyCodeList?.map(async (propertyCode: string) => {
                    const {data} = await checkDisabledActions(referenceUrl, propertyCode, {sectionCodeContext});
                    return {data, propertyCode};
                }),
            );
            dataAll.forEach(data => {
                if (PropertyCode.PUBLIC === data.propertyCode) setDisabledPublication(data.data);
                if (PropertyCode.DELAYED_PUBLICATION === data.propertyCode) setDisabledPublicationOnDate(data.data);
            });
        })();
    }, [sectionCodeContext]);

    React.useMemo(() => {
        if ((disabledPublicationOnDate && disabledPublication) || (!disabledPublicationOnDate && disabledPublication)) {
            if (onChange) onChange(moment(new Date()).format());
            if (form && dependentsFieldKey) form.setFieldsValue({[dependentsFieldKey]: false});
            setValue(1);
        }
        if (disabledPublicationOnDate && !disabledPublication) {
            if (form && dependentsFieldKey) form.setFieldsValue({[dependentsFieldKey]: true});
            setValue(2);
        }
    }, [disabledPublication, disabledPublicationOnDate]);

    return (
        <Radio.Group
            onChange={handleChange}
            value={value}
            defaultValue={1}
            className="checkbox-date-group"
        >
            <Space direction="vertical">
                <Radio
                    value={1}
                    disabled={!disabledPublication}
                >
                    Незамедлительно
                </Radio>
                <Radio
                    disabled={!disabledPublicationOnDate}
                    value={2}
                >
                    На дату
                    {value === 2
                        ? (
                            <AntForm.Item
                                name="datePicker"
                                rules={[{required: true, message: 'Дата должна быть заполнена'}]}
                            >
                                <DatePicker
                                    format={format}
                                    showTime={format?.includes(' ')}
                                    onChange={handleChangeDatePicker}
                                    value={resultValue}
                                    className="date-picker"
                                    getPopupContainer={getPopupContainer()}
                                />
                            </AntForm.Item>
                        ) : <Input disabled />}
                </Radio>
            </Space>
        </Radio.Group>
    );
};
