import MenuItem from 'antd/es/menu/MenuItem';
import React from 'react';

import {CustomLink as InnerCustomLink} from 'components/@common/widgets/custom-link';
import {TableActionProps} from 'components/table/action/table-action-types';
import {selectEntityData} from 'modules/data';
import {selectTableEntityData} from 'modules/data/data-selectors';
import {TableEntityData} from 'modules/data/data-types';
import {EntityType} from 'shared/constants/entities';
import {LinkDto} from 'shared/types/links';
import {useAppSelector} from 'store/config/hooks';

interface DrillDownActionLinkProps extends TableActionProps {}

export const DrillDownActionLink = ({
    entityName,
    meta,
    wrapper,
    ...props
}: DrillDownActionLinkProps) => {
    const {
        actionTitle,
        key: linkDtoKey,
    } = meta;

    const {selectedRowKeys = []} = useAppSelector(s => (
        selectEntityData(entityName, EntityType.TABLE)(s) || {}
    ) as TableEntityData);

    const rows = useAppSelector(selectTableEntityData(entityName || ''))?.rows;
    const selectedRow = rows?.find(r => r.id === selectedRowKeys[0]) ?? {};
    const isSubmenu = !!wrapper;
    const linkConfig = linkDtoKey ? (selectedRow[linkDtoKey] as LinkDto) : undefined;

    const customLinkButton = selectedRowKeys.length === 1 ? (
        <InnerCustomLink
            link={linkConfig}
            externalProperties={{
                title: actionTitle,
            }}
            isKebabItem
        />
    ) : actionTitle;

    return (
        isSubmenu ? (
            <MenuItem
                key={meta.actionTitle}
                {...props}
                disabled={selectedRowKeys.length !== 1}

            >
                {customLinkButton}
            </MenuItem>
        ) : (
            <div>
                {customLinkButton}
            </div>
        )
    );
};
