import {
    Button, Checkbox, Input, Space,
} from 'antd';
import {FormInstance} from 'antd/lib/form';
import React from 'react';

import {FormEntityData, DatePickerState, LookupValue} from 'modules/data/data-types';
import {FieldMeta, FieldType} from 'modules/metadata';
import {IReferencePath} from 'modules/metadata/metadata-types';
import {FileDto} from 'shared/types/files';

import {DynamicIcon} from '../dynamic-icon';
import {RichTextEditor} from '../rich-text';
import {SubsectionReferenceSwitcher} from '../subsection-switcher';
import {DateCell} from '../table/columns/date-cell/date-cell';
import {ButtonOpenModal} from './actions/button-open-modal';
import {FieldBlock} from './fields/field-block';
import {FieldsTree} from './fields/fields-tree';
import {FormList} from './fields/form-list';
import {GroupFields} from './fields/group-fields';
import {InlineFields} from './fields/inline-fields';
import {InlineFieldsDelete} from './fields/inline-fields-delete';
import {FormMode} from './form-types';
import {BoolField} from './inputs/bool-field';
import {CheckboxDateGroup} from './inputs/checkbox-date-group';
import {CheckboxGroup} from './inputs/checkbox-group/checkbox-group';
import {CustomSelectMode} from './inputs/custom-select';
import {DependentsField} from './inputs/dependents-field';
import {ExternalEmployeeBoolField} from './inputs/external-employee-bool-field/external-employee-bool-field';
import {FileField} from './inputs/file-field';
import {InputNumber} from './inputs/input-number';
import {InputNumberDefaultValue} from './inputs/input-number-default-value/input-number-default-value';
import {InputString, InputWithDefaultValueSetter} from './inputs/input-string';
import {InputTooltip} from './inputs/input-tooltip';
import {LargeStringInput} from './inputs/large-string-input';
import {Password} from './inputs/password/password';
import {QueryParamsBoundSelect} from './inputs/query-params-bound-select';
import {ReferenceSelect} from './inputs/reference-select';
import {StaticSelect} from './inputs/static-select';
import {StringSelect} from './inputs/string-select/string-select';
import {TimePickerLabelInside} from './inputs/time-picker';
import {TimePickerField} from './inputs/time-picker/time-picker-field';
import {UploadFileField} from './inputs/upload-file-field';
import {UploaderList} from './inputs/uploader-list';
import {SignAndFileListUploader} from './inputs/uploader-list/sign-and-file-list-uploader/sign-and-file-list-uploader';

import './field/field.less';

interface Field {
    rows?: number;
    value?: string;
    onChange?: (firstArg: string) => void;
    props?: any;
    form?: FormInstance;
    parentEntityName?: string;
    field?: FieldMeta;
    fieldKey?: string;
    buttonText?: string;
    shouldFastUpdate?: boolean;
    accept?: string;
    format?: string;
    referenceUrl?: string;
    /**
     * Параметры для поля REFERENCE
     */
    isFilterable?: boolean;
    multipleMode?: boolean;
    path?: IReferencePath;
    isDependsOnMainFilter?: string;
    referenceParamsDefault?: Record<string, any>;
    referenceParams?: Record<string, any>;
    /**
     * Параметры для поля REFERENCE внутри FIELDS_TREE
     */
    areProgramSetProgramsUnique?: boolean;
    /**
     * Параметр(ы) для поля STATIC_SELECT
     */
    options?: {
        label: string;
        value: string;
    }[];
    name?: string | number | (string | number)[] | undefined;
    dataKeyForDownload?: string;
    url?: string;
    signUrl?: string;
    fileList?: FileDto[];
    downloadAllText?: string;
    downloadAllTextForFile?: string;
    downloadAllUrl?: string;
    placeholder?: string;
    datePickerStates?: DatePickerState;
}

export const fieldDefaultWidthResolver = (fieldType: FieldType) => {
    switch (fieldType) {
    case FieldType.BOOLEAN:
        return 180;
    case FieldType.REFERENCE:
    case FieldType.STATIC_SELECT:
        return 450;
    case FieldType.TEXTAREA:
    case FieldType.RICH_TEXT:
    case FieldType.FILE:
    case FieldType.FIELD_BLOCK:
        return 920;
    case FieldType.DATE:
        return 130;
    case FieldType.COMBINED_FIELD_WITH_DELETE:
        return 920;
    default:
        return 450;
    }
};

export const fieldTypeResolver = (
    fieldMeta: FieldMeta,
    getDefaultValue: () => any,
    formMode?: FormMode,
    formData?: FormEntityData,
    form?: FormInstance,
    entityName?: string,
    parentEntityName?: string,
    handleDisabled?: () => void,
    isDisabledByWatcher?: boolean,
    datePickerStates?: DatePickerState,
    dependentFieldData?: LookupValue[],
    areProgramSetProgramsUnique?: boolean,
) => {
    function getDisabledValue() {
        if (fieldMeta.isDisabled || isDisabledByWatcher) {
            return true;
        }
        return formMode === FormMode.EDIT && fieldMeta?.isEditDisabled;
    }

    switch (fieldMeta.type) {
    case FieldType.DYNAMIC_STRING:
        return ({value, onChange, props}: Field) => {
            const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (onChange) {
                    onChange(e.target.value);
                }
            };
            return (
                <Input
                    value={value}
                    {...props}
                    onChange={onHandleChange}
                    autoComplete="off"
                />
            );
        };

    case FieldType.TEXTAREA:
        return (props: Record<string, any>) => (
            <Input.TextArea
                {...props}
                disabled={getDisabledValue()}
                autoSize={{minRows: 3}}
            />
        );

    case FieldType.NUMBER:
        return ({value, onChange, props}: Field) => {
            const handleNumberChange = (val: number | null) => {
                if (onChange && val !== null) onChange(String(val));
            };
            return (
                <InputNumber
                    value={value}
                    disabled={getDisabledValue()}
                    placeholder={fieldMeta.placeholder}
                    onChange={handleNumberChange}
                    stringMode
                    {...props}
                />
            );
        };

    case FieldType.NUMBER_DEFAULT_BY_CREATE:
        return (props: Record<string, any>) => (
            <InputNumberDefaultValue
                entityName={entityName}
                disabled={getDisabledValue()}
                placeholder={fieldMeta.placeholder}
                referenceUrl={fieldMeta.referenceUrl}
                key={fieldMeta.key}
                formMode={formMode}
                {...props}
            />
        );

    case FieldType.DATE:
        return (props: any) => (
            <DateCell
                {...props}
                defaultValue={getDefaultValue()}
                disabled={getDisabledValue()}
                format={fieldMeta.dateFormat}
                requestFormat={fieldMeta.requestDateFormat}
                datePickerStates={datePickerStates}
            />
        );

    case FieldType.TIME:
        return (props: any) => (
            <TimePickerField
                {...props}
                disabled={getDisabledValue()}
                format={fieldMeta.dateFormat}
            />
        );

    case FieldType.TIME_WITH_LABEL_INSIDE:
        return (props: any) => (
            <TimePickerLabelInside
                {...props}
                label={fieldMeta.label}
                disabled={getDisabledValue()}
                placeholder={fieldMeta.placeholder}
                format={fieldMeta.dateFormat}
            />
        );

    case FieldType.REFERENCE:
        return (props: any) => (
            <ReferenceSelect
                {...props}
                entityName={entityName}
                useContext={fieldMeta.useContext}
                disabled={getDisabledValue()}
                path={fieldMeta.path}
                stringStructure={fieldMeta.stringStructure}
                additionalOptions={fieldMeta.additionalOptions}
                isDependsOnMainFilter={fieldMeta.isDependsOnMainFilter}
                multipleMode={fieldMeta.multipleMode}
                isFilterable={fieldMeta.isFilterable}
                isClearable={fieldMeta.isClearable}
                referenceUrl={fieldMeta.referenceUrl}
                referenceParamsKeys={fieldMeta.referenceParamsKeys}
                formData={formData}
                fixedDropdown={fieldMeta?.fixedDropdown}
                form={form}
                parentEntityName={entityName}
                onNotCreateKey={fieldMeta?.onNotCreateKey}
                displayFieldKey={fieldMeta?.displayFieldKey}
                requestFieldKey={fieldMeta?.requestFieldKey}
                requestKey={fieldMeta?.requestKey}
                dependentInputKey={fieldMeta?.dependentInputKey}
                additionalInputOptions={fieldMeta?.additionalInputOptions}
                isSetFilter={fieldMeta?.isSetFilter}
                useFirstOptionAsDefaultValue={fieldMeta?.useFirstOptionAsDefaultValue}
                isFieldWithoutValue={fieldMeta?.isFieldWithoutValue}
                defaultValue={fieldMeta?.defaultValue}
                hideSearch={fieldMeta?.hideSearch}
                field={fieldMeta}
                defaultValueByRule={fieldMeta?.defaultValueByRule}
                formMode={formMode}
                areProgramSetProgramsUnique={areProgramSetProgramsUnique}
            />
        );
    case FieldType.QUERY_PARAMS_BOUND_SELECT:
        return (props: any) => (
            <QueryParamsBoundSelect
                {...props}
                name={fieldMeta.key}
                settings={{
                    valuePath: 'id',
                    labelPath: 'meaning',
                    isClearable: fieldMeta.isClearable,
                    showSearch: fieldMeta.isFilterable,
                    mode: fieldMeta.multipleMode ? CustomSelectMode.multiple : undefined,
                    isDisabled: getDisabledValue(),
                    url: fieldMeta.referenceUrl,
                    formInstance: form,
                    formFieldKey: fieldMeta.key,
                }}
                useContext={fieldMeta.useContext}
            />
        );
    case FieldType.REFERENCE_SWITCHER:
        return (props: any) => (
            <SubsectionReferenceSwitcher
                {...props}
                {...fieldMeta}
                entityName={entityName}
                disabled={getDisabledValue()}
                formData={formData}
                form={form}
                parentEntityName={entityName}
            />
        );

    case FieldType.CHECKBOX_GROUP:
        return (props: any) => (
            <CheckboxGroup
                {...props}
                entityName={entityName}
                referenceUrl={fieldMeta.referenceUrl}
                fieldMeta={fieldMeta}
            />
        );
    case FieldType.CHECKBOX_DATE_GROUP:
        return (props: any) => (
            <CheckboxDateGroup
                {...props}
                form={form}
                formData={formData}
                fieldKey={fieldMeta?.key}
                format={fieldMeta?.dateFormat}
                parentEntityName={fieldMeta?.parentEntityName}
                propertyCodeList={fieldMeta?.propertyCodeList}
                referenceUrl={fieldMeta?.referenceUrl}
                dependentsFieldKey={fieldMeta?.dependentsFieldKey}
            />
        );
    case FieldType.STATIC_SELECT:
        return (props: any) => (
            <StaticSelect
                multipleMode={fieldMeta.multipleMode}
                isFilterable={fieldMeta.isFilterable}
                isClearable={fieldMeta.isClearable}
                options={fieldMeta.additionalInputOptions}
                placeholder={fieldMeta?.placeholder}
                useFirstOptionAsDefaultValue={fieldMeta?.useFirstOptionAsDefaultValue}
                disabled={getDisabledValue()}
                defaultValue={fieldMeta?.defaultValue}
                form={form}
                generateOptionsRules={fieldMeta?.generateOptionsRules}
                {...props}
            />
        );

    case FieldType.STRING_SELECT:
        return (props: any) => (
            <StringSelect
                {...fieldMeta}
                disabled={getDisabledValue()}
                form={form}
                generateOptionsRules={fieldMeta?.generateOptionsRules}
                {...props}
            />
        );
    case FieldType.BOOLEAN:
        return (props: Record<string, any>) => (
            <BoolField
                disabled={getDisabledValue()}
                defaultValue={fieldMeta.defaultValue as string}
                label={fieldMeta.label}
                {...props}
            />
        );
    case FieldType.EXTERNAL_EMPLOYEE_BOOL:
        return (props: Record<string, any>) => (
            <ExternalEmployeeBoolField
                disabled={getDisabledValue()}
                dependentFieldData={dependentFieldData}
                label={fieldMeta.label}
                {...props}
            />
        );
    case FieldType.BOOLEAN_DELETE:
        return (props: Record<string, any>) => (
            <BoolField
                {...props}
            />
        );

    case FieldType.BOOLEAN_CLASSIC:
        return ({value, onChange, ...props}: any) => {
            const handleChange = () => {
                onChange?.(!value);
                if (handleDisabled) handleDisabled();
            };

            return (
                <Checkbox
                    {...props}
                    onChange={handleChange}
                    checked={value}
                >{fieldMeta.title}
                </Checkbox>
            );
        };

    case FieldType.FILE:
        return (props: any) => (
            <FileField
                {...props}
                formData={formData}
                fieldKey={fieldMeta.key}
                entityName={entityName}
                fieldType={fieldMeta.type}
                buttonText={fieldMeta.buttonText}
                shouldFastUpdate={fieldMeta.shouldFastUpdate}
                accept={fieldMeta.accept}
                parentEntityName={parentEntityName}
                additionalOptions={fieldMeta.additionalOptions}
                dependentsFieldKey={fieldMeta.dependentsFieldKey}
            />
        );

    case FieldType.UPLOAD_FILE:
        return (props: any) => (
            <UploadFileField
                {...props}
                fieldKey={fieldMeta.key}
                entityName={entityName}
                fieldMeta={fieldMeta}
                dependentsFieldKey={fieldMeta.dependentsFieldKey}
                buttonText={fieldMeta.buttonText}
            />
        );

    case FieldType.LIST:
        return (props: any) => (
            <FormList
                {...props}
                key={fieldMeta.key}
                field={fieldMeta}
                rows={4}
                form={form}
            />
        );

    case FieldType.GROUP:
        return (props: any) => (
            <GroupFields
                {...props}
                key={fieldMeta.key}
                fieldMeta={fieldMeta}
                rows={4}
                form={form}
            />
        );

    case FieldType.FILE_LIST:
        return ({value, ...props}: any) => (
            <UploaderList
                {...props}
                dataKeyForDownload={fieldMeta.dataKeyForDownload}
                url={fieldMeta.uploadUrl}
                buttonText={fieldMeta.buttonText}
                fileList={value as FileDto[]}
            />
        );
    case FieldType.FILE_LIST_WITH_SIGNS:
        return ({value, ...props}: any) => (
            <SignAndFileListUploader
                {...props}
                disabled={getDisabledValue()}
                dataKeyForDownload={fieldMeta.dataKeyForDownload}
                url={fieldMeta.uploadUrl}
                signUrl={fieldMeta.uploadSignUrl}
                buttonText={fieldMeta.buttonText}
                fileList={value as FileDto[]}
                downloadAllText={fieldMeta.downloadAllText}
                downloadAllTextForFile={fieldMeta.downloadAllTextForFile}
                downloadAllUrl={fieldMeta.downloadAllUrl}
            />
        );
    case FieldType.PASSWORD:
        return (props: Record<string, any>) => (
            <Password
                disabled={getDisabledValue()}
                {...props}
            />
        );

    case FieldType.ARRAY_STRING:
        return (props: any) => {
            const {value, onChange} = props;
            const [stringFirst, stringSecond] = value || [];
            const changeArray = (values: { stringFirst?: string; stringSecond?: string }) => {
                const result = Array.isArray(value) ? [...value] : [];

                if (values?.stringFirst) {
                    result[0] = values?.stringFirst;
                }

                if (values?.stringSecond) {
                    result[1] = values?.stringSecond;
                }

                onChange(result);
            };

            const handleStringFirstChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const {target} = event;
                const {value: newValue} = target;

                changeArray({stringFirst: newValue});
            };

            const handleStringSecondChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const {target} = event;
                const {value: newValue} = target;

                changeArray({stringSecond: newValue});
            };

            return (
                <Space>
                    <Input
                        value={stringFirst}
                        onChange={handleStringFirstChange}
                    />

                    <Input
                        value={stringSecond}
                        onChange={handleStringSecondChange}
                    />
                </Space>
            );
        };

    case FieldType.DEPENDENTS:
        return ({value, ...props}: any) => (
            <DependentsField
                {...props}
                value={value}
                fieldKey={fieldMeta.key}
                dependFieldKey={fieldMeta?.dependentsFieldKey as string}
                isDependsOnMainFilter={fieldMeta?.isDependsOnMainFilter}
                parentEntityName={parentEntityName}
                isDisplay={fieldMeta.isNotDisplay}
                disabled={getDisabledValue()}
                maskField={fieldMeta.mask}
            />
        );

    case FieldType.COMBINED_FIELD_WITH_DELETE:
        return (props: Record<string, any>) => (
            <InlineFieldsDelete
                name={fieldMeta.key}
                title={fieldMeta.title}
                fields={fieldMeta.children}
                formData={formData}
                form={form}
                {...props}
            />
        );

    case FieldType.COMBINED_FIELD:
        return (props: Record<string, any>) => (
            <InlineFields
                disabled={getDisabledValue()}
                fields={fieldMeta.children}
                formData={formData}
                form={form}
                {...props}
            />
        );
    case FieldType.FIELD_BLOCK:
        return (props: Record<string, any>) => (
            <FieldBlock
                disabled={getDisabledValue()}
                label={fieldMeta.label}
                fields={fieldMeta.children}
                formData={formData}
                form={form}
                {...props}
            />
        );
    case FieldType.RICH_TEXT:
        return (props: Record<string, any>) => (
            <RichTextEditor
                toolbarId={`${entityName || ''}-${fieldMeta.key}-${fieldMeta.order || ''}`.replaceAll('.', '-')}
                disabled={getDisabledValue()}
                {...props}
            />
        );
    case FieldType.FIELDS_TREE:
        return (props: any) => (
            <FieldsTree
                fieldMeta={fieldMeta}
                formData={formData}
                form={form}
                entityName={entityName}
                {...props}
            />
        );
    case FieldType.STRING:
        return ({value, ...props}: any) => (
            <InputString
                defaultValue={getDefaultValue()}
                disabled={getDisabledValue()}
                value={value}
                autoComplete="off"
                placeholder={fieldMeta?.placeholder}
                field={fieldMeta}
                propertyCodeValue={fieldMeta?.propertyCodeValue}
                {...props}
            />
        );
    case FieldType.OPEN_MODAL:
        return ({...props}: any) => (
            <ButtonOpenModal
                title={fieldMeta.label}
                modalName={fieldMeta.modalName}
                modalEntityName={fieldMeta.modalEntityName}
                {...props}
            />
        );
    case FieldType.FUNCTION_BUTTON:
        return ({...props}) => (
            <Button
                style={{width: fieldMeta.width}}
                type={fieldMeta.buttonType ?? 'primary'}
                disabled={getDisabledValue()}
                {...props}
            >
                {fieldMeta.buttonIcon && <DynamicIcon type={fieldMeta.buttonIcon} />}
                {fieldMeta.buttonText}
            </Button>
        );
    case FieldType.LARGE_STRING_INPUT: {
        return ({value, ...props}: any) => (
            <LargeStringInput
                disabled={getDisabledValue()}
                value={value}
                name={fieldMeta.key}
                {...fieldMeta}
                {...props}
            />
        );
    }
    case FieldType.INPUT_WITH_DEFAULT_VALUE_SETTER: {
        return ({value, ...props}: any) => (
            <InputWithDefaultValueSetter
                disabled={getDisabledValue()}
                value={value}
                name={fieldMeta.key}
                {...fieldMeta}
                {...props}
            />
        );
    }
    case FieldType.INPUT_TOOLTIP: {
        return ({value, ...props}: any) => (
            <InputTooltip
                disabled={getDisabledValue()}
                value={value}
                placeholder={fieldMeta?.placeholder}
                fieldStructure={fieldMeta?.fieldStructure || []}
                {...props}
            />
        );
    }
    default:
        return ({value, ...props}: any) => (
            <Input
                disabled={getDisabledValue()}
                value={value}
                autoComplete="off"
                placeholder={fieldMeta?.placeholder}
                {...props}
            />
        );
    }
};
