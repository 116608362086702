import {Card} from 'antd';
import cn from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';

import {CustomTabs} from 'components/custom-tabs';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectThunkStatus} from 'store/slices/loading-state-slice';
import {loadTableReportConfiguration} from 'store/slices/report-configuration-slice/report-configuration';
import {
    reportConfigurationActions,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    createTableReportConfiguration,
    removeTableReportConfiguration,
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {
    ReportConfigurationTabKey,
} from './report-configuration.constants';
import {ReportConfigurationContext} from './report-configuration.context';
import {FormValidationErrors} from './report-configuration.types';
import {ReportComparison} from './tabs/report-comparison';
import {ReportConfigurator} from './tabs/report-configurator';
import {ReportDdFilters} from './tabs/report-dd-filters';
import {ReportDdParameters} from './tabs/report-dd-parameters';
import {ReportDdReferences} from './tabs/report-dd-references';
import {ReportHeaderSettings} from './tabs/report-header-settings';
import {ReportKsSettings} from './tabs/report-ks-settings';
import {ReportPropertiesSettings} from './tabs/report-properties-settings';
import {ReportTableOfContents} from './tabs/report-table-of-contents';
import {ReportTableSettings} from './tabs/report-table-settings';

import './report-configuration.less';

interface ReportConfigurationProps {
    entityName: string;
    templateCode: string;
}

export const ReportConfiguration: React.FC<ReportConfigurationProps> = (
    {
        entityName,
        templateCode,
    }: ReportConfigurationProps,
) => {
    const dispatch = useAppDispatch();

    const {
        isPending: isLoadingReportConfiguration,
    } = useAppSelector(s => selectThunkStatus(s, loadTableReportConfiguration.typePrefix));
    const {
        isPending: isUpdatingReportConfiguration,
    } = useAppSelector(s => selectThunkStatus(s, updateTableReportConfiguration.typePrefix));
    const {
        isPending: isCreatingReportConfiguration,
    } = useAppSelector(s => selectThunkStatus(s, createTableReportConfiguration.typePrefix));
    const {
        isPending: isDeletingReportConfiguration,
    } = useAppSelector(s => selectThunkStatus(s, removeTableReportConfiguration.typePrefix));

    const [selectedTabKey, setSelectedTabKey] = useState<React.Key>(ReportConfigurationTabKey.REPORT_CONFIGURATOR);

    const [validationErrors, setValidationErrors] = useState<FormValidationErrors>({});

    const isCreatingNewTemplate = templateCode === '-1';

    const {resetReportConfigurationData} = reportConfigurationActions;

    useEffect(() => {
        if (templateCode && !isCreatingNewTemplate) {
            dispatch(loadTableReportConfiguration({templateCode}));
        }
    }, [templateCode]);

    useEffect(() => () => {
        dispatch(resetReportConfigurationData());
    }, []);

    const refetchData = useCallback(() => {
        dispatch(loadTableReportConfiguration({templateCode}));
    }, [templateCode]);

    return (
        <div className={cn('report-configuration')}>
            <ReportConfigurationContext.Provider
                value={{
                    selectedTabKey,
                    setSelectedTabKey,
                    templateCode,
                    entityName,
                    isCreatingNewTemplate,
                    validationErrors,
                    setValidationErrors,
                    refetchData,
                }}
            >
                <Card
                    title="Настройка отчётных форм"
                    className={cn('report-configuration__card')}
                >
                    <CustomTabs
                        hideMenu
                        selectedTabKey={selectedTabKey}
                        contentSpinner={{
                            isSpinning: isLoadingReportConfiguration
                                || isUpdatingReportConfiguration
                                || isCreatingReportConfiguration
                                || isDeletingReportConfiguration,
                            tip: isUpdatingReportConfiguration
                                ? 'Сохранение данных шаблона...' : 'Загрузка данных шаблона...',
                        }}
                        tabs={[{
                            key: ReportConfigurationTabKey.REPORT_CONFIGURATOR,
                            title: 'Конфигуратор отчета',
                            content: <ReportConfigurator />,
                        }, {
                            key: ReportConfigurationTabKey.REPORT_TABLE_OF_CONTENTS,
                            title: 'Настройка оглавления',
                            content: <ReportTableOfContents />,
                            isHeavy: true,
                            noRenderWhileOnAnotherTab: true,
                        }, {
                            key: ReportConfigurationTabKey.REPORT_PROPERTIES_SETTINGS,
                            title: 'Параметры отчета',
                            header: 'Настройка параметров',
                            content: <ReportPropertiesSettings />,
                            noRenderWhileOnAnotherTab: true,
                        }, {
                            key: ReportConfigurationTabKey.REPORT_HEADER_SETTINGS,
                            title: 'Настройка заголовка отчета',
                            content: <ReportHeaderSettings />,
                            noRenderWhileOnAnotherTab: true,
                        }, {
                            key: ReportConfigurationTabKey.REPORT_TABLE_SETTINGS,
                            title: 'Настройка табличной части',
                            content: <ReportTableSettings />,
                            isHeavy: true,
                        }, {
                            key: ReportConfigurationTabKey.REPORT_DD_REFERENCES,
                            title: 'Настройка правил связей документов (ДД)',
                            content: <ReportDdReferences />,
                            noRenderWhileOnAnotherTab: true,
                            isHeavy: true,
                        },
                        {
                            key: ReportConfigurationTabKey.REPORT_DD_FILTERS,
                            title: 'Настройка правил фильтрации данных (ДД)',
                            content: <ReportDdFilters />,
                            noRenderWhileOnAnotherTab: true,
                            isHeavy: true,
                        },
                        {
                            key: ReportConfigurationTabKey.REPORT_DD_PARAMETERS,
                            title: 'Настройка правил передачи значений (ДД)',
                            content: <ReportDdParameters />,
                            noRenderWhileOnAnotherTab: true,
                            isHeavy: true,
                        },
                        {
                            key: ReportConfigurationTabKey.REPORT_COMPARISON,
                            title: 'Настройка сравнения',
                            content: <ReportComparison />,
                            noRenderWhileOnAnotherTab: true,
                        },
                        {
                            key: ReportConfigurationTabKey.REPORT_KS_SETTINGS,
                            title: 'Настройка КС',
                            content: <ReportKsSettings />,
                            noRenderWhileOnAnotherTab: true,
                        }]}
                    />
                </Card>
            </ReportConfigurationContext.Provider>
        </div>
    );
};
