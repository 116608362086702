import {Popover} from 'antd';
import cn from 'classnames';
import React from 'react';

import {getPopupContainer} from 'shared/utils';

import {FormatData, toolbarFormats} from './toolbar.utils';

interface QuillToolbarProps {
    disabled?: boolean;
    toolbarHtmlId?: string;
}

/**
 * Кастомный toolbar для rich text
 * @param disabled
 * @param toolbarHtmlId если 2 и более компонента на странице, то нужно указать разные id,
 * иначе Quill не понимает что делать
 * @param isSelectedText флаг есть ли выделенный текст для индикации кнопки вставки ссылки
 */
export const QuillToolbar = ({disabled, toolbarHtmlId}: QuillToolbarProps) => {
    const handleLinkButtonClick = () => {
        const editModal = document.querySelector('div.ql-tooltip.ql-editing');
        const input: HTMLInputElement | null = document.querySelector('div.ql-tooltip.ql-editing input');
        if (editModal && input) {
            input.placeholder = 'Введите ссылку';
            input.value = '';
        }
    };
    const renderOptions = (formatData: FormatData) => {
        const {className, options, content} = formatData;
        return (
            <Popover
                placement="bottom"
                content={content}
                getPopupContainer={getPopupContainer()}
            >
                <select
                    className={className}
                >
                    {
                        options?.map((value: string) => (
                            <option value={value}>
                                {value}
                            </option>
                        ))
                    }
                </select>
            </Popover>
        );
    };
    const renderSingle = (formatData: FormatData) => {
        const {
            className, value,
        } = formatData;
        return (
            <>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
                <button
                    className={className}
                    value={value}
                />
            </>
        );
    };
    return (
        <div
            className={cn('editor-toolbar ql-toolbar ql-snow', disabled && 'hidden')}
            id={toolbarHtmlId}
        >
            <span className="ql-formats">
                {
                    toolbarFormats?.map((classes: FormatData[]) => (
                        classes?.map((formatData: FormatData) => (
                            formatData?.options
                                ? renderOptions(formatData)
                                : renderSingle(formatData)
                        ))
                    ))
                }
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
                <button
                    className="ql-link"
                    onClick={() => handleLinkButtonClick()}
                />
            </span>
        </div>
    );
};
