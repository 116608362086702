// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._13jBu5Cr82TGu3yKvRj8Sn {\n  margin-right: 0.25rem;\n}\n._6NUbrVPRl6shOUWDFH5us {\n  color: #B7C1D5;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  gap: 0 5px;\n}\n._6NUbrVPRl6shOUWDFH5us:hover {\n  color: #959DAC;\n}\n", "",{"version":3,"sources":["webpack://src/features/sign-with-crypto-pro/ui/sign-with-crypto-pro-modal/sign-with-crypto-pro-modal.module.less"],"names":[],"mappings":"AAGE;EACE,qBAAA;AAFJ;AAKE;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,UAAA;AAHJ;AAKI;EACE,cAAA;AAHN","sourcesContent":["@import 'src/themes/default/default';\n\n.sign {\n  &-loading-icon {\n    margin-right: 0.25rem;\n  }\n\n  &-component {\n    color: @gray-500;\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    gap: 0 5px;\n\n    &:hover {\n      color: @faint-dark;\n    }\n  }\n}\n\n@hack: true; @import \"/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sign-loading-icon": "_13jBu5Cr82TGu3yKvRj8Sn",
	"sign-component": "_6NUbrVPRl6shOUWDFH5us"
};
export default ___CSS_LOADER_EXPORT___;
