import {Form as AntForm, Modal} from 'antd';
import {SizeType} from 'antd/lib/config-provider/SizeContext';
import {FormLayout} from 'antd/lib/form/Form';
import {omitBy} from 'lodash';
import React, {useContext} from 'react';

import {Buttons} from 'components/form/buttons';
import {Fields} from 'components/form/fields/fields';
import {TableModeContext} from 'components/table/context';
import {Entity, EntityValue} from 'modules/data';
import {
    createActionHandlerForTableChange,
    initializeMetadataAndData,
} from 'modules/data/data-actions';
import {selectFilterEntityData} from 'modules/data/data-selectors';
import {selectMetadata} from 'modules/metadata';
import {setMetadata} from 'modules/metadata/metadata-actions';
import {selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {EntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {isEmptyObject} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ModalComponentInterface} from 'store/slices/modals-slice';

import {disabledFields} from '../../button-action-edit-field/button-action-edit-fields';
import {useFilterInitialization} from './hooks';
import {generateActionsForFilter} from './integrated-filter-utils';

interface IOwnProps extends Partial<ModalComponentInterface> {
  entityName: string;
  url?: string;
  parentEntityType?: EntityType;
  entityType?: EntityType.FILTER | EntityType.CONTEXT_FILTER;
  layout?: FormLayout;
  defaultData?: EntityValue | any;
  defaultMetadata?: EntityMeta;
  isIntegratedFilter?: boolean;
  spacingValue?: SizeType | number;
  onClick?: () => void;
  checkForDisabledMetaFields?: boolean;
  initializedFilterContext?: React.MutableRefObject<boolean>;
  isDisablePagination?: boolean;
}

export const IntegratedFilter: React.FunctionComponent<IOwnProps> = ({
    entityName,
    entityType = EntityType.FILTER,
    parentEntityType,
    url,
    defaultMetadata,
    defaultData,
    layout = 'inline',
    isIntegratedFilter = true,
    onClose,
    spacingValue,
    isDisablePagination,
    ...props
}: IOwnProps) => {
    const [form] = AntForm.useForm();
    const {onClick} = props;
    const dispatch = useAppDispatch();
    const tableFieldsMeta = useAppSelector(selectTableEntityMetadata(entityName));
    const filterFieldsMeta = useAppSelector(selectMetadata(entityName, entityType));

    const filterSlice = useAppSelector(selectFilterEntityData(entityName));
    const {resetTableMode} = useContext(TableModeContext);

    const newMetaData = disabledFields(tableFieldsMeta, true);

    React.useEffect(() => {
        (async () => {
            if (defaultMetadata) {
                await dispatch(initializeMetadataAndData(entityType)(entityName, defaultMetadata, defaultData));
            }
            const dataToSet = filterSlice?.data || defaultData;
            if (dataToSet) form.setFieldsValue(omitBy(dataToSet, v => isEmptyObject(v)));
        })();
    }, [filterSlice?.data]);

    useFilterInitialization({
        entityName, fields: filterFieldsMeta?.fields, filter: filterSlice,
    });

    const handleValuesChanges = (values: Entity) => {
        Object.entries(values).forEach(value => {
            if (!value[1]) {
                form.setFieldsValue({[value[0]]: null});
            }
            form.setFieldsValue(value);
        });
    };

    // Обновление данных при изменении фильтров
    const onHandleFinish = () => {
        dispatch(createActionHandlerForTableChange(entityType)({
            entityName,
            newEntityTypeData: form.getFieldsValue(),
            url,
            parentEntityType,
            prefix: filterFieldsMeta?.prefix,
            disabledPagination: filterFieldsMeta?.disabledPagination || isDisablePagination,
            skipIfFilterDataIsLoading: true,
        })).then(() => {
            if (filterFieldsMeta?.checkForDisabledMetaFields) {
                dispatch(setMetadata({
                    entityName,
                    entityType: EntityType.TABLE,
                    metadata: newMetaData,
                }));
            }
            resetTableMode();
        });
        if (!isIntegratedFilter) {
            onClose?.();
        }
    };

    const defaultFilterActionsMeta = generateActionsForFilter(filterFieldsMeta?.name);

    return isIntegratedFilter ? (
        <AntForm
            {...props}
            colon={false}
            layout={layout}
            form={form}
            onValuesChange={onHandleFinish}
            onClick={onClick}
        >
            <Fields
                entityName={entityName}
                list={filterFieldsMeta?.fields}
                spacingValue={spacingValue}
            />
        </AntForm>
    ) : (
        <Modal
            title={filterFieldsMeta?.title}
            footer={null}
            visible
            width="100vw"
            onCancel={onClose}
        >
            <AntForm
                layout="vertical"
                form={form}
                onValuesChange={handleValuesChanges}
                onFinish={onHandleFinish}
            >
                <Fields
                    entityName={entityName}
                    list={filterFieldsMeta?.fields}
                />
                <Buttons
                    actions={defaultFilterActionsMeta}
                    onClose={onClose}
                />
            </AntForm>
        </Modal>
    );
};
