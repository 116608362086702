import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {AisSliceState} from '../ais-slice-types';
import {loadAisCommentsRequestRowsTable, loadAisCommentsRequestTable} from './ais-comments-request-thunks';

export const createAisCommentsRequestExtraReducer = (builder: ActionReducerMapBuilder<AisSliceState>) => {
    builder.addCase(loadAisCommentsRequestTable.fulfilled, (state, {payload}) => {
        state.aisCommentsRequest.aisCommentsRequestTable = payload.dataTable;
    });
    builder.addCase(loadAisCommentsRequestRowsTable.fulfilled, (state, {payload}) => {
        state.aisCommentsRequest.aisInspectorCommentInfo = payload;
    });
};
