import {Button} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {DirectoriesCompanyFormTabProps} from 'components/directories-company-form/directories-company-form.types';
import {DynamicIcon} from 'components/dynamic-icon';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {useAppHistory} from 'shared/hooks/use-app-history';
import {StateSetter} from 'shared/types/generics';

import './directories-company-form-header.less';

interface DirectoriesCompanyFormHeaderProps extends Pick<
    DirectoriesCompanyFormTabProps, 'form' | 'setDirty'
>{
    isDirty: boolean;
    isEditing: boolean;
    setEditing: StateSetter<boolean>;
}

export const DirectoriesCompanyFormHeader = ({
    form,
    isDirty,
    setDirty,
    isEditing,
    setEditing,
}: DirectoriesCompanyFormHeaderProps) => {
    const history = useAppHistory();
    const [isCancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
    const [isGoBackModalOpen, setGoBackModalOpen] = useState<boolean>(false);

    const handleResetChanges = () => {
        form.resetFields();
        setDirty(false);
        setCancelModalOpen(false);
        setEditing(false);
    };

    const handleBack = () => {
        if (!isDirty) {
            history.go(-1);
            return;
        }

        if (!isGoBackModalOpen) {
            setGoBackModalOpen(true);
        } else {
            setGoBackModalOpen(false);
            history.go(-1);
        }
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(() => form.submit());
    };

    return (
        <div className="directories-company-form-header">
            <div className="directories-company-form-header-title">
                Параметры организации
            </div>
            <div className="directories-company-form-header-buttons">
                <ModalOpenerComponent
                    component={(
                        <Button
                            type="default"
                            onClick={handleBack}
                        >
                            <DynamicIcon type="ArrowBackOutlined" />
                            Вернуться назад
                        </Button>
                    )}
                    isOpen={isGoBackModalOpen}
                    handleSave={handleBack}
                    handleCancel={() => setGoBackModalOpen(false)}
                    modalProps={{
                        title: 'Предупреждение',
                        centered: true,
                        width: '680px',
                    }}
                    controlLabels={{
                        save: 'Да',
                        cancel: 'Нет',
                    }}
                >
                    <div className="text-center">
                        Вы действительно хотите покинуть форму?
                        <br />Несохраненные данные будут потеряны.
                    </div>
                </ModalOpenerComponent>
                {isEditing ? (
                    <>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={!isDirty}
                        >
                            <DynamicIcon type="SaveOutlined" />
                            Сохранить
                        </Button>
                        <ModalOpenerComponent
                            component={(
                                <Button
                                    className={cn({'button-critic': isDirty})}
                                    type="default"
                                    onClick={() => {
                                        if (isDirty) {
                                            setCancelModalOpen(true);
                                        } else {
                                            setEditing(false);
                                        }
                                    }}
                                >
                                    <DynamicIcon type="CloseXOutlined" />
                                    {`Отменить${isDirty ? ' изменения' : ' редактирование'}`}
                                </Button>
                            )}
                            isOpen={isCancelModalOpen}
                            handleSave={handleResetChanges}
                            handleCancel={() => setCancelModalOpen(false)}
                            modalProps={{
                                title: 'Предупреждение',
                                centered: true,
                                width: '680px',
                            }}
                            controlLabels={{
                                save: 'Да',
                                cancel: 'Нет',
                            }}
                        >
                            <div className="text-center">
                                Вы действительно хотите отменить изменения?
                                <br />Внесенные данные будут потеряны.
                            </div>
                        </ModalOpenerComponent>
                    </>
                ) : (
                    <Button
                        type="primary"
                        onClick={() => setEditing(true)}
                    >
                        <DynamicIcon type="EditPenOutlined" />
                        Редактировать
                    </Button>
                )}
            </div>
        </div>
    );
};
