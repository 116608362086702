import {TIconType} from 'components/dynamic-icon/dynamic-icon';
import {EditableInput} from 'components/editable-table';
import {FormButtonWatcher} from 'components/form/buttons/use-form-button-watcher';
import {FormFieldWatcher} from 'components/form/hooks/use-form-watcher';
import {EntityType} from 'shared/constants/entities';

import {IBreadcrumbs} from '../breadcrumbs/breadcrumbs-types';
import {Entity, EntityValue} from '../data';
import {ERequestStatuses} from '../request-form/request-form.constants';
import {FieldTooltipMetadata} from './tooltip-metadata-types';

export interface MetadataState {
    [key: string]: MetadataEntityState;
}

export interface MetadataEntityState {
    [EntityType.FORM]: FormEntityMeta | IRequestFormEntityMeta;
    [EntityType.EDIT_FORM]: FormEntityMeta;
    [EntityType.FORM_TABLE]: FormTableEntityMeta;
    [EntityType.TABLE]: TableEntityMeta;
    [EntityType.EDITABLE_TABLE]: ModalEntityMeta;
    [EntityType.TABS]: DirectoryEntityMeta;
    [EntityType.TABS_WITH_REFERENCES]: EntityMeta;
    [EntityType.REFERENCE]: EntityMeta;
    [EntityType.NUMBER]: EntityMeta;
    [EntityType.FILTER]: FilterEntityMeta;
    [EntityType.CONTEXT_FILTER]: ContextFilterEntityMeta;
    [EntityType.SEARCH]: SearchEntityMeta;
    [EntityType.TABLE_STATE]: EntityMeta;
    [EntityType.REPORT]: EntityMeta;
    [EntityType.JASPER_REPORT]: JasperReportEntityMeta;
    [EntityType.LIST]: ListEntityMeta;
    [EntityType.LIST_GRID]: ListEntityMeta;
    [EntityType.MODAL]: ModalEntityMeta;
    [EntityType.SUBSECTION_TABLE]: SubsectionTableEntityMeta;
    [EntityType.TABS_WITH_FORM]: DirectoryEntityMeta;
    [EntityType.DASHBOARD]: EntityMeta;
    [EntityType.TABLE_REPORT]: EntityMeta;
    [EntityType.WIDGETS]: EntityMeta;
    [EntityType.FORM_FIELDS_DATA]: FormEntityMeta;
}

/**
 * Общая структура метаданных
 */
export interface EntityMeta {
    resetFormAfterSubmit?: boolean;
    title?: string;
    dynamicFormTitle?: DynamicTitle;
    titleStructure?: string;
    titleField?: string;
    name: string;
    parentEntityName?: string;
    /**
     * Сущности для таблиц
     * Используется для работы с пагинацией
     */
    isPageJumperEnabled?: boolean;
    isPageSizeChangerEnabled?: boolean;
    disabledPagination?: boolean;
    /**
     * Ключ поля, значение которого влияет на отображаемые поля в контексте списка.
     */
    dataKeyForFilterFields?: string;
    /**
     * Для форм определяет какое поле главное и будет использоваться как id.
     * Для таблиц используется как параметр для ссылки
     */
    linkField?: string;
    /**
     * Это поле используется на бэке для
     * разграничения данных для api
     */
    viewType?: ViewType;
    fields: FieldMeta[];
    svc?: SvcMeta;
    /**
     * Используется для конфигураци запросов на другой хост.
     */
    urlConfig?: UrlConfig;
    /**
     * Используется для форм, задает вкалдки формы.
     */
    tabs?: TabsMeta[];
    useContext?: boolean;
    useBreadcrumbs?: boolean;
    actions?: MetaActionInfo[];
    additionalOptions?: IAdditionalOptions;
    prefix?: string;
    filterParamKey?: string;
    duplicateButtons?: boolean;
    defaultFilters?: IDefaultFilters[];
    shouldLoadExtensions?: boolean;
    subsections?: SubsectionMenu[];
    discardDateAndUserInfo?: boolean;
    checkForDisabledMetaFields?: boolean;
    /**
     * поля используется в форме, для отправки запроса о прочтении открытого элемента
     * */
    isUpdateNotificationStatus?: boolean;
    tableEntityName?: string;
    /**
     * поле активации редактирования карточки, без открытия карточки создания
     * */
    useDifferentForms?: boolean;
    /**
     * размер модального окна
     * */
    width?: number;
    isDraft?: boolean;
}

export interface IDefaultFilters {
    key: string | any;
    value: string;
}

export interface UrlConfig {
    baseUrl?: string;
    prefix?: string;
    contextUrl?: string;
}

export type TVisibleMetaActionProp = 'default' | 'viewing' | 'creating' | 'editing';

/**
 * Общая структура данных для действий
 */
export interface MetaActionInfo {
    entityName?: string;
    linkedEntityName?: string;
    linkField?: string;
    visible?: TVisibleMetaActionProp;
    actionType: MetaActionType;
    actionTitle: string;
    actionCode: string;
    actionIcon?: TIconType | string;
    /**
     * Добавляется к entityName
     */
    childName?: string;
    order?: number;
    referenceUrl?: string;
    requestType?: RequestType;
    urlParamKey?: string;
    childrens?: MetaActionInfo[];
    /**
     * Имя модального окна, для действия
     */
    modalName?: string;
    /**
     * Сущность для модального окна.
     * Если нужно использовать имя сущности отличное от страницы.
     */
    modalEntityName?: string;
    /**
     * На основе этого параметра определяется
     * способ отображения фильтра (модальное окно или строка)
     */
    isIntegratedFilter?: boolean;

    /**
     * Параметр определяющий наличие
     * дополнительных ключей, которые
     * должны сохраняться для каждой записи
     */
    additionalInfoKeys?: string[];
    /** Использовать ли в качестве источника данных для additionalInfoKeys данные фильтра */
    shouldPickAdditionalInfoKeysFromFilter?: boolean;
    /**
     * Позволяет игнорировать настройки urlConfig
     */
    shouldIgnoreUrlConfig?: boolean;
    /** Список обязательных параметров, в отсутствии которых action блокируется (или любое иное действие) */
    requiredQueryParams?: string[];

    /**
     * Только для формы запросов
     */
    shouldDefaultRequestSave?: boolean;
    /**
     * Параметр нужен только для работы с конструктором метаданных,
     * что бы не создавать лишний вложенный список.
     * На бэк не должен передаваться.
     */
    shouldResetContext?: string;
    checkForDisabledMetaFields?: boolean;
    isSubmenu?: boolean;
    neededParamsForForm?: {
        from: string;
        to: string;
    }[];
    /**
     * Параметр нужен если мы хотим передавать заголовок application/json
     * вместо multipart/form-data
     */
    parentEntityType?: string;
    parentEntityName?: string;
    isJsonRequest?: boolean;
    selectedReferenceUrl?: string;
    mainReferenceUrl?: string;
    isResetFormValue?: boolean;
    buttonType?: ButtonType;
    tabKey?: string;
    title?: string;
    modalWidth?: string;
    /**
     * Для подстановки id в запросе непосредственно в url (/somePath/{id})
     */
    useUrl?: boolean;
    /**
     * Название ключа для массива id; по умолчанию - ids
     */
    itemListKey?: string;
    watchers?: FormButtonWatcher[];
    /**
     * По key достаем LinkDto из выбранной строки
     */
    key?: string;
}

export enum ButtonType {
    'default' = 'default',
    'primary' = 'primary',
    'text' = 'text',
    'link' = 'link',
}

export interface DirectoryEntityMeta extends EntityMeta {
    titleField: string;
}

interface Subsection {
    name: string;
    referenceUrl: string;
    parentId: string;
}

export interface Breadcrumb {
    name: string;
    referenceUrl: string;
    subsection?: Subsection[];
    path?: string;
}

export interface TableEntityMeta extends EntityMeta {
    isFilterable?: boolean;
    isSearchable?: boolean;
    isSelectable?: boolean;
    disabledPagination?: boolean;
    isPageJumperEnabled?: boolean;
    isPageSizeChangerEnabled?: boolean;
    isEditTable?: boolean;
    breadcrumbs?: IBreadcrumbs[];
    dependentLinkField?: string;
    isEditable?: boolean;
    prefix?: string;
    modalName?: string;
    parentEntityName?: string;
    checkForDisabledMetaFields?: boolean;
    allowEditTableInterface?: boolean;
    subsections?: SubsectionMenu[];
    dashboardEntityName?: string;
}

export interface ModalEntityMeta extends EntityMeta {
    cols: ColsMeta;
}

export interface JasperReportEntityMeta extends EntityMeta {
    referenceUrl?: string;
}

export interface FormEntityMeta extends EntityMeta {
    parentEntityName?: string;
    reportComparision?: { // todo: моковая структура для вкладки настроек сравнения
        listReferenceKey: string;
        attributeFieldKey: string;
        keyFieldKey: string;
        addKeyButtonKey: string;
        addAttributeButtonKey: string;
    };
}

export interface ListEntityMeta extends EntityMeta {
}

export interface IInformationFieldMeta extends FieldMeta {
    /**
     * Делает поле редактируемым при просмотре.
     * Нужно для работы с запросами, так как по умолчанию редактировать поля нельзя.
     */
    editableViewField?: boolean;
    /**
     * Делает поле не активным при создании
     */
    isDisabled?: boolean;
}

export interface IRequestFormEntityMeta extends FormEntityMeta {
    isEditable?: boolean;
    isViewing?: boolean;
    preFillingOptions?: {
        /**
         * На основе этого имени выбираются данные для дочернего формы из родительской
         */
        parentEntityName?: string;
        shouldUpdateParentFormAfterSaving?: string;
        fieldNameForParamsRequest?: string;
        /**
         * Список полей для пред заполнения данных дочерней формы,
         * если данных нет, то идет обращение к api пред заполнения
         */
        matchingFields?: {
            from: string; // Название поля родительского элемента
            to: string; // Новое названия поля для дочерней формы
        }[];
        /**
         * Будет пред заполнение через sendReplay
         */
        shouldRemoteData?: boolean;
    };
    // TODO удалить если не используется
    statusesActions?: ERequestStatuses[];
    fields: IInformationFieldMeta[];
    /**
     * Нужно для отображения ответа при просмотре запроса
     */
    additionalForm?: IRequestFormEntityMeta;
    /**
     * Ключ, значение которого будет использоваться
     * в качестве заголовка
     */
    dataKeyForTitle?: string;
    /**
     * Ключ, значение которого будет использоваться
     * в качестве оценки
     */
    dataKeyForGrade?: string;
    dataKeyForGradeId?: string;
    dataKeyForFilterFields?: string;
}

export interface FormTableEntityMeta extends TableEntityMeta, FormEntityMeta {
}

export interface SubsectionTableEntityMeta extends TableEntityMeta, FormEntityMeta {
}

export interface FilterEntityMeta extends EntityMeta {
    filterByKeyField?: string;
}

export interface ContextFilterEntityMeta extends FilterEntityMeta {
}

export interface SearchEntityMeta extends EntityMeta {
}

export type fieldStructureType = {
    prefixForField?: string;
    fieldKey: string;
    iconName: string;
    hideLabel?: boolean;
    referenceUrl?: string;
    iconColor?: string;
    iconSize?: number;
    attachName?: string;
};

export interface IReferencePath {
    label?: string;
    value?: string;
    reserveLabel?: string;
    lookupCode?: string;
}

export interface IListFieldMeta {
    /**
     * Ключ поля, значение которого влияет на отображаемые поля в контексте списка.
     */
    dataKeyForFilter?: string;
    /**
     * Поля по умолчанию, которое отображается всегда.
     */
    defaultItems?: FieldMeta[];
    /**
     * Объект в котором хранится список ключей и значений,
     * названия ключей строятся на основе значения поля соответствующего dataKeyForFilter.
     * А значение это массив полей, которые должны отображаться.
     */
    customItems?: { [valueFromFieldForFilter: string]: FieldMeta[] };
}

export interface IGroupFieldMeta {
    key: string;
    label: string;
    items?: FieldMeta[];
}

enum Align {
    left = 'left',
    right = 'right',
    center = 'center'
}

export interface ColsMeta {
    title: string;
    dataIndex: string;
    key: string;
    align?: Align;
    inputType?: EditableInput;
    width?: number;
    editable?: boolean;
    alwaysEditable?: boolean;
    inputProps?: {
        labelPath: string;
        valuePath: string;
    };
    ellipsis?: boolean;
    getFieldState?: boolean;
}

/**
 * Общая структура данных для полей
 */
interface IAdditionalField {
    paramKey?: string;
    isAdditionalFieldsFromRequest?: boolean;
    referenceUrl?: string;
    additionalFields?: FieldMeta[];
    isChangingFormStructure?: boolean;
}

export interface IAdditionalOptions {
    optionsField: IAdditionalField;
    discardEntityCreatedDate?: boolean;
    paramKey?: string;
}

export interface FieldMeta extends IListFieldMeta, IGroupFieldMeta {
    actions?: FieldAction[];
    isSortable?: boolean;
    key: string;
    label: string;
    hideLabel?: boolean;
    order?: number; // not implemented on back meta
    hint?: string;
    index?: number;
    isHidden?: boolean;
    isRequired?: boolean;
    required?: boolean; // for upload modals metadata
    isClearable?: boolean;
    isDisabled?: boolean;
    isEditDisabled?: boolean;
    isNotDisplay?: boolean;
    type: FieldType;
    defaultValue?: EntityValue;
    referenceUrl?: string;
    stringStructure?: string;
    editable?: boolean;
    additionalOptions?: IAdditionalOptions;
    dependentKeys?: string[];
    modalName?: string;
    modalEntityName?: string;
    breadcrumbsEntityName?: string;
    parentEntityName?: string;
    propertyCode?: string;
    propertyCodeValue?: string;
    propertyCodeList?: string[];
    customColor?: string;
    isChecked?: boolean;
    editModeWidth?: number;
    checkForDisabledMetaFields?: boolean;
    watchers?: FormFieldWatcher[];

    /**
     * Используется для COMBINED_STRING
     * В качестве ключа используется ключ поля,
     * для значения которого нужна подпись
     */
    labels?: Record<string, string | undefined>;
    fieldStructure?: fieldStructureType[];
    booleanValues?: string[];
    dateFormat?: string;
    requestDateFormat?: string; // Формат даты для запроса
    color?: string;
    jasperReportName?: string;
    /**
     * Параметр для поля REFERENCE
     */
    isFilterable?: boolean;
    multipleMode?: boolean;
    displayAfterActions?: boolean; // Параметр для формы запросов
    referenceParamsDefault?: Record<string, any>; // Вспомогательные значения для referenceParamsKeys
    referenceParamsKeys?: ReferenceParamKey[];
    path?: IReferencePath; // Определяет по каким ключам брать значения из объекта для select
    referenceValueKey?: string; // Ключ, который используется вместо id
    onNotCreateKey?: boolean;
    onNotCreateId?: boolean;
    displayFieldKey?: string;
    dependentInputKey?: string;
    requestFieldKey?: string; // Определяет по какому ключу отправлять значение (REFERENCE)
    requestKey?: string; // Определяет какое название ключа отправлять при запросе (REFERENCE)
    additionalInputOptions?: IAdditionalInputOptions[];
    generateOptionsRules?: GenerateOptionsRulesProps;
    isSetFilter?: boolean;
    useContext?: boolean; // Добавлять ли в запрос на получение опций organizationId и periodId
    useFirstOptionAsDefaultValue?: boolean; // Использовать ли первое значение из dropdown как дефолтное
    isFieldWithoutValue?: boolean;
    defaultValueByRule?: string;
    hideSearch?: boolean;
    /**
     * Параметры для UPLOAD_LIST
     */
    uploadUrl?: string; // url для загрузки фала
    dataKeyForDownload?: string; // ключ, значение которого будет в качестве параметра запроса
    uploadSignUrl?: string;
    /**
     * Параметры для FILE_LIST_WITH_SIGNS
     */
    downloadAllText?: string;
    downloadAllTextForFile?: string;
    downloadAllUrl?: string;
    /**
     * Параметр(ы) для поля STATIC_SELECT
     */
    options?: {
        label: string;
        value: string;
    }[];

    isDependsOnMainFilter?: string;
    /**
     * Параметры для FILE
     */
    buttonText?: string;
    shouldFastUpdate?: boolean;
    accept?: string;
    /**
     * Параметры валидации и поля Input.PASSWORD
     */
    validationMask?: string;
    validationErrorMessage?: string;
    requiredMatchPasswordField?: string;
    validationRules?: ValidationRules[];
    /**
     * Доп. параметры валидации
     */
    validationRegExpKey?: string;
    isNeedToRedirect?: string;
    /**
     * Параметр для добавления ссылки к полям в таблице по условию
     */
    redirectOnCondition?: RedirectOnCondition;
    /**
     * Параметр(ы) для поля DEPENDENTS
     */
    dependentsFieldKey?: string;
    mask?: string;
    children?: FieldMeta[];
    title?: string;
    dataIndex?: string;
    align?: Align;
    inputType?: EditableInput;
    width?: number | string;
    alwaysEditable?: boolean;
    inputProps?: {
        labelPath: string;
        valuePath: string;
    };
    ellipsis?: boolean;
    getFieldState?: boolean;
    /**
     * Параметры условного ренденига поля
     */
    nonRenderable?: boolean;
    renderOnCondition?: RenderProperties; // рендер по флагу от сервера
    placeholder?: string;
    isNotAvailableWhen?: FieldAvailabilityCondition[];
    tooltip?: FieldTooltipMetadata;
    fixedDropdown?: boolean;
    buttonType?: ButtonType;
    buttonIcon?: string;
    buttonAction?: string;
    additionalClassNames?: string[];
    isDraft?: boolean;
    reverseDependencyTo?: string;
}
export enum FieldActionType {
    DELETE = 'delete',
    UPLOAD = 'upload',
    DOWNLOAD = 'download'
}

export interface FieldAction {
    name?: FieldActionType;
    type?: string;
}

export interface ComparisionFormFieldMeta extends FieldMeta {
    renderPosition?: 'up';
}

export interface TabsMeta {
    title: string;
    key: string;
    /**
     * Key филдов входящих в Tabs
     */
    fieldKeys: string[];
}

export interface SvcMeta {
    widgetsTitle?: string;
    widgetsLayout?: Array<SvcLayout>[];
    dictionariesTitle?: string;
    dictionariesLayout?: Array<SvcLayout>[];
}

export interface SvcLayout {
    title?: string;
    buttons?: Array<SvcButtons>;
    statisticsFieldKey?: string;
    url?: string;
    icon?: string;
}

export interface SvcButtons {
    position: string;
    link: string;
    preset: string;
}

export interface GenerateOptionsRulesProps {
    from?: number; //  нижняя граница для генерации значений
    to?: number; // верхняя граница для генерации значений
    dynamicFrom?: string; // имя ключа, откуда нужно подтянуть нижнюю границу
    dynamicTo?: string; // имя ключа, откуда нужно подтянуть верхнюю границу
    step?: number; // шаг итерации от from до to
    labelStructure?: string; // структура подписи, по умолчанию - значение текущей итерации от from до to
    valueStructure?: string; // структура значения, по умолчанию - значение текущей итерации от from до to
}

export interface IAdditionalInputOptions {
    isFiltrationEnabled?: boolean;
    key: string;
    label: string;
    value?: string | boolean;
    onNotCreateKey?: boolean;
    displayFieldKey?: string;
    dependentInputKey?: string;
}

export interface ReferenceParamKey { // Ключи значения которых будут в качестве параметра для lookup
    constant?: string;
    from: string;
    to?: string;
}

export interface FieldAvailabilityCondition {
    fieldName: string;
    fieldValues: string[];
}

export interface IAdditionalFields extends FieldMeta {
    key: string;
    isRequired: boolean;
    id: number | string;
    displayOrder: number;
    defaultValue: string | number | boolean;
}

export enum FieldType {
    // Common types
    DATE = 'DATE',
    TIME = 'TIME',
    STRING = 'STRING',
    TEXT = 'TEXT',
    VALUESET = 'VALUESET',
    MULTIVALUESET = 'MULTIVALUESET',
    PASSWORD = 'PASSWORD',
    NUMBER = 'NUMBER',
    ICON = 'ICON',
    FILE = 'FILE',
    MULTIPART_FILE = 'MULTIPART_FILE',
    LIST_MULTIPART_FILE = 'LIST_MULTIPART_FILE',
    FILE_LIST = 'FILE_LIST',
    FILE_LIST_WITH_SIGNS = 'FILE_LIST_WITH_SIGNS',
    SIGNATURE = 'SIGNATURE',
    ATTORNEY = 'ATTORNEY',
    COMBINED_STRING = 'COMBINED_STRING',
    BOOLEAN = 'BOOLEAN',
    BOOLEAN_CLASSIC = 'BOOLEAN_CLASSIC',
    COUNTER = 'COUNTER',
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
    ICON_DOWNLOAD = 'ICON_DOWNLOAD',
    EDITABLE_FIELD = 'EDITABLE_FIELD',
    BREADCRUMB_ITEM = 'BREADCRUMB_ITEM',
    ACTION_DELETE = 'ACTION_DELETE',
    OPEN_MODAL_ACTION = 'OPEN_MODAL_ACTION',
    REPORT_LINK = 'REPORT_LINK',
    REQUEST_LINK = 'REQUEST_LINK',
    DATE_PASSED_INDICATOR = 'DATE_PASSED_INDICATOR',
    NUMBER_SQUARE_PRIMARY = 'NUMBER_SQUARE_PRIMARY',
    NUMBER_SQUARE_DEFAULT = 'NUMBER_SQUARE_DEFAULT',
    RICH_TEXT_RENDERER = 'RICH_TEXT_RENDERER',
    TABLE_ROW_INDEX = 'TABLE_ROW_INDEX',
    REFERENCE_SWITCHER = 'REFERENCE_SWITCHER',
    QUERY_PARAMS_BOUND_SELECT = 'QUERY_PARAMS_BOUND_SELECT',
    STATUS = 'STATUS',
    REPORT_WATCHER = 'REPORT_WATCHER',

    // Form types
    REFERENCE = 'REFERENCE',
    LARGE_STRING_INPUT = 'LARGE_STRING_INPUT',
    INPUT_WITH_DEFAULT_VALUE_SETTER = 'INPUT_WITH_DEFAULT_VALUE_SETTER',
    TEXTAREA = 'TEXTAREA',
    CHECKBOX = 'CHECKBOX',
    CHECKBOX_DATE_GROUP = 'CHECKBOX_DATE_GROUP',
    STATIC_SELECT = 'STATIC_SELECT',
    STRING_SELECT = 'STRING_SELECT',
    LIST = 'LIST',
    GROUP = 'GROUP',
    ARRAY_STRING = 'ARRAY_STRING',
    DYNAMIC_STRING = 'DYNAMIC_STRING',
    CHECKBOX_GROUP = 'CHECKBOX_GROUP',
    DEPENDENTS = 'DEPENDENTS',
    COMBINED_FIELD = 'COMBINED_FIELD',
    COMBINED_FIELD_WITH_DELETE = 'COMBINED_FIELD_WITH_DELETE',
    NUMBER_DEFAULT_BY_CREATE = 'NUMBER_DEFAULT_BY_CREATE',
    FIELD_BLOCK = 'FIELD_BLOCK',
    UPLOAD_FILE = 'UPLOAD_FILE',
    FUNCTION_BUTTON = 'FUNCTION_BUTTON',
    ICON_LINK = 'ICON_LINK',
    BOOLEAN_DELETE = 'BOOLEAN_DELETE',
    BOOLEAN_STRING = 'BOOLEAN_STRING',
    ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
    OPEN_EDIT_MODAL = 'OPEN_EDIT_MODAL',
    RICH_TEXT = 'RICH_TEXT',
    TIME_WITH_LABEL_INSIDE = 'TIME_WITH_LABEL_INSIDE',
    OPEN_MODAL = 'OPEN_MODAL',
    FIELDS_TREE = 'FIELDS_TREE',
    FIELDS_TREE_CHILD = 'FIELDS_TREE_CHILD',
    CUSTOM_SELECT = 'CUSTOM_SELECT',
    TABLE_DATA_SWITCHER = 'TABLE_DATA_SWITCHER',
    DISPLAY_ADDITIONAL_TABLE = 'DISPLAY_ADDITIONAL_TABLE',
    INPUT_TOOLTIP = 'INPUT_TOOLTIP',

    // Table types
    FILE_DOWNLOAD_LINK = 'FILE_DOWNLOAD_LINK',
    SIGNATURE_DOWNLOAD_LINK = 'SIGNATURE_DOWNLOAD_LINK',
    COMBINED_STRING_DATE = 'COMBINED_STRING_DATE',
    SIGNATURE_UPLOAD_CELL = 'SIGNATURE_UPLOAD_CELL',
    FILE_UPLOAD_CELL = 'FILE_UPLOAD_CELL',
    UPLOAD_SCAN_FILES = 'UPLOAD_SCAN_FILES',
    // Dashboard types
    BAR_STATISTICS = 'BAR_STATISTICS',
    CIRCLE_STATISTICS = 'CIRCLE_STATISTICS',
    HALF_CIRCLE_STATISTICS = 'HALF_CIRCLE_STATISTICS',
    PROGRESS_LIST_STATISTICS = 'PROGRESS_LIST_STATISTICS',
    PROGRESS_LIST_STATISTICS_SECONDARY = 'PROGRESS_LIST_STATISTICS_SECONDARY',

    // administration.report.configuration types
    OPEN_REPORT_CONFIGURATION_FORM = 'OPEN_REPORT_CONFIGURATION_FORM',
    OPEN_REPORT_CONFIGURATION_HISTORY_MODAL = 'OPEN_REPORT_CONFIGURATION_HISTORY_MODAL',

    // ais3.monitor.services
    AIS3_MS_REQUEST_CONTENT = 'AIS3_MS_REQUEST_CONTENT',
    AIS3_MS_RESPONSE_CONTENT = 'AIS3_MS_RESPONSE_CONTENT',
    OPEN_DOCUMENT_CODES_MODAL = 'OPEN_DOCUMENT_CODES_MODAL',

    // tableReportFilter
    MULTI_VALUE_SET = 'MULTIVALUESET',

    // administration.accounts.fns
    EXTERNAL_EMPLOYEE_BOOL = 'EXTERNAL_EMPLOYEE_BOOL',

    // flex fields types administration.dff
    OPEN_FLEX_FIELDS_SETTINGS_PAGE = 'OPEN_FLEX_FIELDS_SETTINGS_PAGE',
    EXTERNAL_SYSTEMS_MODAL = 'EXTERNAL_SYSTEMS_MODAL',
}

export enum MetaActionType {
    // Common types
    SUBMENU = 'SUBMENU',
    SUBMENU_OPTION = 'SUBMENU_OPTION',
    OPEN_MODAL = 'OPEN_MODAL',
    FILTER = 'FILTER',
    SEARCH = 'SEARCH',
    SEND_REQUEST = 'SEND_REQUEST',
    // Table types
    ACTION_FOR_TABLE = 'ACTION_FOR_TABLE',
    ACTION_FOR_TABLE_ROWS = 'ACTION_FOR_TABLE_ROWS',
    DELETE_TABLE_ROWS = 'DELETE_TABLE_ROWS',
    DOWNLOAD_TABLE_ROWS = 'DOWNLOAD_TABLE_ROWS',
    BUTTON_ACTION_SAVE_TABLE_ROWS = 'BUTTON_ACTION_SAVE_TABLE_ROWS',
    DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
    BUTTON_SAVE_EDITABLE_MODAL = 'BUTTON_SAVE_EDITABLE_MODAL',
    GENERATE_TC_DEMAND_RESPONSE = 'GENERATE_TC_DEMAND_RESPONSE',
    BUTTON_SAVE_FORM_PERSONAL_ACCOUNT='BUTTON_SAVE_FORM_PERSONAL_ACCOUNT',
    /**
     * Кнопка для отправки запроса с selectedRows и userName
     */
    SEND_REQUEST_WITH_USER='SEND_REQUEST_WITH_USER',
    // Form types
    /**
     * Кнопка сохранения.
     * Вызывает событие submit. Обработчиков на ней нет.
     */
    BUTTON_SAVE_FORM = 'BUTTON_SAVE_FORM',
    BUTTON_SAVE_OR_EDIT_FORM = 'BUTTON_SAVE_OR_EDIT_FORM',
    BUTTON_SEND_FILTER = 'BUTTON_SEND_FILTER',
    BUTTON_DOWNLOAD_FILE = 'BUTTON_DOWNLOAD_FILE',
    BUTTON_DOWNLOAD_DOCUMENT_FILE = 'BUTTON_DOWNLOAD_DOCUMENT_FILE',
    BUTTON_SET_EDIT_MODE = 'BUTTON_SET_EDIT_MODE',
    LAUNCH_PROGRAM = 'LAUNCH_PROGRAM',
    /**
     * Кнопка для изменения статуса
     */
    BUTTON_CHANGE_REQUEST_STATUS = 'BUTTON_CHANGE_REQUEST_STATUS',
    OPEN_REQUESTS_CHILD_MODAL = 'OPEN_REQUESTS_CHILD_MODAL',
    BUTTON_OPEN_MODAL_WITH_QUERY_PARAMS = 'BUTTON_OPEN_MODAL_WITH_QUERY_PARAMS',
    BUTTON_CONFIRM = 'BUTTON_CONFIRM',
    /**
     * Скачать все
     */
    BUTTON_DOWNLOAD_ALL = 'BUTTON_DOWNLOAD_ALL',
    CREATE_LIST_CP = 'CREATE_LIST_CP',
    SEND_REMINDER = 'SEND_REMINDER',
    UNLOAD_CP = 'UNLOAD_CP',
    UPLOAD_FROM_EXCEL = 'UPLOAD_FROM_EXCEL',
    UPLOAD_FROM_XML = 'UPLOAD_FROM_XML',
    DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE',
    DOWNLOAD_ALL_EXCEL = 'DOWNLOAD_ALL_EXCEL',
    EDIT_FIELD = 'EDIT_FIELD',
    BUTTON_SAVE_ROWS = 'BUTTON_SAVE_ROWS',
    DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL',
    DOWNLOAD_XML = 'DOWNLOAD_XML',
    CHECKBOX_CONFIRM = 'CHECKBOX_CONFIRM',
    BUTTON_COPY_RULES_FROM_LAST_PERIOD = 'BUTTON_COPY_RULES_FROM_LAST_PERIOD',
    BUTTON_DOWNLOAD_SELECTED_ROWS = 'BUTTON_DOWNLOAD_SELECTED_ROWS',
    BUTTON_UPDATE = 'BUTTON_UPDATE',
    BUTTON_BACK = 'BUTTON_BACK',
    UPLOAD_SCAN_FILES = 'UPLOAD_SCAN_FILES',
    /**
     * сбрасывает данные в карточке до тех что пришли первоначально
     * */
    BUTTON_SET_INITIAL_DATA = 'BUTTON_SET_INITIAL_DATA',

    BUTTON_EDIT_FORM = 'BUTTON_EDIT_FORM',
    REMOVE_FILTER = 'REMOVE_FILTER',
    BUTTON_CONTROL_COLLAPSE = 'BUTTON_CONTROL_COLLAPSE',
    /**
     * Действия для работы с документами
     * */
    UPDATE_DOCUMENTS_STATUS = 'UPDATE_DOCUMENTS_STATUS',
    PUBLISH_LIST = 'PUBLISH_LIST',
    BUTTON_OPEN_LINK = 'BUTTON_OPEN_LINK',
    BUTTON_SET_CORR_NUMBER = 'BUTTON_SET_CORR_NUMBER',
    DOWNLOAD_ZIP_TABLE_ROWS = 'DOWNLOAD_ZIP_TABLE_ROWS',
    CALCULATE_CONTROL_RATIO = 'CALCULATE_CONTROL_RATIO',
    OPEN_DOCUMENT_XML_MODAL = 'OPEN_DOCUMENT_XML_MODAL',
    OPEN_SYNTHESIS_DOCUMENT_MODAL = 'OPEN_SYNTHESIS_DOCUMENT_MODAL',
    OPEN_CRYPTO_PRO_SIGNING_MODAL = 'OPEN_CRYPTO_PRO_SIGNING_MODAL',
    SEND_SELECTED_DOCS_IDS = 'SEND_SELECTED_DOCS_IDS',
    /**
     * удаляет запись реестра через карточку
     * */
    BUTTON_DELETE_ELEMENT = 'BUTTON_DELETE_ELEMENT',
    BUTTON_SWITCH_TABS = 'BUTTON_SWITCH_TABS',
    BUTTON_DELETE_TAB = 'BUTTON_DELETE_TAB',
    BUTTON_HISTORY_BACK = 'BUTTON_HISTORY_BACK',
    BUTTON_REDIRECT = 'BUTTON_REDIRECT',
    BUTTON_CLEAR_FORM = 'BUTTON_CLEAR_FORM',
    /**
     * кнопки для работы с отчетами
     * */
    BUTTON_DOWNLOAD_REPORT = 'BUTTON_DOWNLOAD_REPORT',
    BUTTON_OPEN_SIMPLE_MODAL = 'BUTTON_OPEN_SIMPLE_MODAL',
    /**
     * кнопки для работы с запросами
     * */
    BUTTON_SEND_FOR_APPROVAL_REQUEST = 'BUTTON_SEND_FOR_APPROVAL_REQUEST',
    BUTTON_SEND_FOR_APPROVAL_REQUEST_DRAFT = 'BUTTON_SEND_FOR_APPROVAL_REQUEST_DRAFT',
    BUTTON_SEND_COMMENT_ON_THE_REQUEST = 'BUTTON_SEND_COMMENT_ON_THE_REQUEST',
    BUTTON_MARK_ALL_AS_READ = 'BUTTON_MARK_ALL_AS_READ',
    CREATE_RESPONSE_DRAFT = 'CREATE_RESPONSE_DRAFT',
    CREATE_PROLONGATION_REQUEST_DRAFT = 'CREATE_PROLONGATION_REQUEST_DRAFT',
    BUTTON_SAVE_FORM_DRAFT = 'BUTTON_SAVE_FORM_DRAFT',
    BUTTON_MARK_AS_READ = 'BUTTON_MARK_AS_READ',
    BUTTON_SEND_TO_AGREEMENT = 'BUTTON_SEND_TO_AGREEMENT',

    OPEN_FORM = 'OPEN_FORM',
    BUTTON_OPEN_SIMPLE_FILTER = 'BUTTON_OPEN_SIMPLE_FILTER',
    SIMPLE_CHECKBOX = 'SIMPLE_CHECKBOX',
    /**
     * кнопки для работы с СВК
     * */
    BUTTON_UPDATE_CP = 'BUTTON_UPDATE_CP',
    BUTTON_SAVE_EDITABLE_TABLE = 'BUTTON_SAVE_EDITABLE_TABLE',
    CLEAR_TABLE_ROWS = 'CLEAR_TABLE_ROWS',

    // кастомные кнопки формы пользователей и ролей (administration.accounts.fns)
    BUTTON_BLOCK_USER = 'BUTTON_BLOCK_USER',
    // Кнопка запуска программы в отвязке от меты
    LAUNCH_PROGRAM_BUTTON = 'LAUNCH_PROGRAM_BUTTON',

    /**
     * универсальные ссылки
     * */
    CUSTOM_LINK = 'CUSTOM_LINK',

    /**
     * Универсальная ссылка, формирующаяся по строке таблицы
     * */
    CUSTOM_LINK_TABLE_ROW = 'CUSTOM_LINK_TABLE_ROW',

    /**
     * Отправляет запрос на формирования агрегата
     * Используется в разделах Бухгалтерского учета
     * */
    FORMATION_AGGREGATE = 'FORMATION_AGGREGATE',
}

export enum RequestType {
    GET = 'GET',
    HEAD = 'HEAD',
    PUT = 'PUT',
    PATCH = 'PATCH',
    POST = 'POST',
    DELETE = 'DELETE',
}

export enum ViewType {
    FORM = 'form',
    TABLE = 'table',
    TABS = 'tabs',
    FILTER = 'filter',
    FORM_TABLE = 'form-table',
    REPORT = 'report',
    JASPER_REPORT = 'jasper-report',
}

export type MetadataSetAllPayload = {
    metadata: EntityMeta[];
}

export type MetadataSetPayload = {
    entityType: EntityType;
    entityName: string;
    metadata: EntityMeta;
}

export type FormDraftMetadataPayload= {
    entityName: string;
    isDraft: boolean;
}

export type MetadataResetPayload = {
    entityName: string;
    entityType: string;
    loading: boolean;
}
export type MetadataSetLoadingPayload = {
    entityName: string;
}

export type RequestMetadataSetPayload = {
    entityType: EntityType;
    entityName: string;
    requestType: string;
    metadata: IRequestFormEntityMeta;
}

export interface RequestsMetadataSetPayload {
    entityName: string;
    metadata: EntityMeta;
}

export type ReferenceResolver = (entity: Entity) => Entity & {
    value: number | string;
    label: string;
};

export interface RenderProperties {
    authorized?: boolean;
}

export interface SubsectionMenu {
    key: string;
    title: string;
    referenceUrl?: string;
    order?: number;
}

export interface DynamicTitle {
    edit?: string;
    create?: string;
}

export interface ValidationRules {
    mask?: string;
    maxNumericalValue?: number;
    minNumericalValue?: number;
    maxValueLength?: number;
    minValueLength?: number;
    regExpKey?: string;
    regExp?: string;
    errorMessage?: string;
}

export interface RedirectOnCondition {
    // ключ для формирования ссылки, по умолчанию id
    to?: string;
    // ключ проверяемого поля
    target: string;
    // проверяемое значение
    value: string;
}
