import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import {AlertErrorMessage} from 'components/form/alert-error-message/alert-error-message';
import {Spinner} from 'components/spinner';
import {AuthPage} from 'pages/auth-page/auth-page';
import {useProfileLoader} from 'pages/login-page/use-profile-loader/use-profile-loader';
import {URL_LOGIN} from 'shared/constants/urls';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectAuthToken} from 'store/slices/auth-slice';
import {checkAuthStatus} from 'store/slices/auth-slice/auth-slice-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

export const KerberosLoginPage = () => {
    const dispatch = useAppDispatch();

    const isAuthenticationCheckLoading = useAppSelector(s => selectIsThunkPending(s, checkAuthStatus.typePrefix));
    const token = useAppSelector(selectAuthToken);

    const {
        profile,
        profileLoaderStatusJsx,
        isKerberos,
    } = useProfileLoader();

    const isAuthorized = profile && !!token;

    useEffect(() => {
        if (!profile) {
            return;
        }

        if (isKerberos) {
            dispatch(checkAuthStatus())
                .unwrap();
        }
    }, [profile]);

    return (
        <AuthPage
            className="d-flex flex-direction-column justify-content-center align-items-center"
        >
            <>
                {(() => {
                    if (isAuthorized) {
                        return <Redirect to="/" />;
                    }

                    if (!profile) {
                        return profileLoaderStatusJsx;
                    }

                    if (!isKerberos) {
                        return <Redirect to={URL_LOGIN} />;
                    }

                    if (isAuthenticationCheckLoading) {
                        return <Spinner tip="Аутентификация пользователя..." />;
                    }

                    return (
                        <AlertErrorMessage
                            message="Ошибка аутентификации"
                            description="Произошла непредвиденная ошибка, обратитесь к администратору"
                            type="error"
                        />
                    );
                })()}
            </>
        </AuthPage>
    );
};
