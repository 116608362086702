// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RhJvIRcRFfz8HFHp-iba1 {\n  margin: 20px 0;\n  z-index: 2;\n}\n", "",{"version":3,"sources":["webpack://src/components/form/alert-error-message/alert-error-message.module.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,UAAA;AACF","sourcesContent":[".form-error-message{\n  margin: 20px 0;\n  z-index: 2;\n}\n@hack: true; @import \"/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-error-message": "RhJvIRcRFfz8HFHp-iba1"
};
export default ___CSS_LOADER_EXPORT___;
