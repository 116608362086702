import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {DocumentsSigningMethod} from 'modules/documents/documents-types';
import {isEmptyArray} from 'shared/utils';
import {areRequiredQueryParamsPresent} from 'shared/utils/metadata';
import {useQueryParams} from 'shared/utils/query-params';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {openModal} from 'store/slices/modals-slice';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

interface TableOpenModalActionProps extends TableActionProps {
}

export const TableOpenModalAction: React.FunctionComponent<TableOpenModalActionProps> = ({
    meta,
    wrapper,
    entityName,
    ...props
}: TableOpenModalActionProps) => {
    const documentsSigningMethod = useAppSelector(selectDocumentsSigningMethod);

    const {
        modalName,
        actionCode,
        requiredQueryParams,
    } = meta;

    const [isDisabled, setDisabled] = useState<boolean>(false);

    const location = useLocation();
    const {getQueryParam} = useQueryParams();

    useEffect(() => {
        if (!isEmptyArray(requiredQueryParams)) {
            const arePresent = areRequiredQueryParamsPresent({
                requiredQueryParams,
                getQueryParam,
            });
            setDisabled(!arePresent);
        }
    }, [location.search]);

    const dispatch = useAppDispatch();
    const onClick = () => {
        if (modalName) {
            dispatch(openModal({
                modalName,
                entityName: meta?.modalEntityName || entityName,
                parentEntityName: entityName,
            }));
        }
    };

    if (actionCode === 'UPLOAD_SGN' && documentsSigningMethod === DocumentsSigningMethod.CRYPTO_PRO_PLUGIN) return null;
    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={onClick}
            actionIcon={meta.actionIcon}
            disabled={isDisabled}
        >
            {meta.actionTitle}
        </Component>
    );
};
