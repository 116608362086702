import {createAsyncThunk} from '@reduxjs/toolkit';

import {RequestTableResponseWithPagination} from 'shared/types/requests';
import {RpcDeleteResponse} from 'shared/types/rpc';

import {
    DeleteAisNotificationScenariosSettingsTableRowsParams,
    deleteAisNotificationScenariosSettingsTableRowsRequest,
    fetchAisNotificationScenariosServices,
    FetchAisNotificationScenariosSettingsParams,
    fetchAisNotificationScenariosSettingsTable,
    postAisNotificationScenariosSetting,
    PostAisNotificationScenariosSettingData,
    putAisNotificationScenariosSetting,
    PutAisNotificationScenariosSettingData,
} from './ais-notification-scenarios-settings-api';
import {AIS3_SCENARIO_SETTINGS_API_PREFIX} from './ais-notification-scenarios-settings-constants';
import {AisNotificationScenariosSettingsServices, AisNotificationScenariosSettingsTableDto} from './ais-notification-scenarios-settings-types';

// ==== Thunks to work with Ais Notification Scenarios Settings Table ====
export const loadAisNotificationScenariosSettingsTable = createAsyncThunk<
    RequestTableResponseWithPagination<AisNotificationScenariosSettingsTableDto>,
    FetchAisNotificationScenariosSettingsParams>(
        `${AIS3_SCENARIO_SETTINGS_API_PREFIX}/aisNotificationScenariosSettingsTable`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await fetchAisNotificationScenariosSettingsTable(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const deleteAisNotificationScenariosSettingsTableRows = createAsyncThunk<
    RpcDeleteResponse,
    DeleteAisNotificationScenariosSettingsTableRowsParams>(
        `${AIS3_SCENARIO_SETTINGS_API_PREFIX}/deleteAisNotificationScenariosSettingsTableRows`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await deleteAisNotificationScenariosSettingsTableRowsRequest(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

// ==== Thunks to work with Ais Notification Scenarios Settings Form ====
export const createAisNotificationScenariosSetting = createAsyncThunk<
    string,
    PostAisNotificationScenariosSettingData>(
        `${AIS3_SCENARIO_SETTINGS_API_PREFIX}/createAisNotificationScenariosSetting`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await postAisNotificationScenariosSetting(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const updateAisNotificationScenariosSetting = createAsyncThunk<
    string,
    PutAisNotificationScenariosSettingData>(
        `${AIS3_SCENARIO_SETTINGS_API_PREFIX}/updateAisNotificationScenariosSetting`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await putAisNotificationScenariosSetting(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const loadAisNotificationScenariosServices = createAsyncThunk<
    AisNotificationScenariosSettingsServices[]>(
        `${AIS3_SCENARIO_SETTINGS_API_PREFIX}/aisNotificationScenariosSettingsServices`,
        async (_, {rejectWithValue}) => {
            try {
                const response = await fetchAisNotificationScenariosServices();
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
