import {DeleteFilled} from '@ant-design/icons';
import {Button, Spin} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import React, {FC, useEffect, useState} from 'react';

import {Search} from 'components/@common/inputs/search';
import {ActionBar} from 'components/@common/widgets/action-bar';
import {CustomCard} from 'components/@common/widgets/custom-card';
import {CustomTable, customTableUtils} from 'components/@common/widgets/custom-table';
import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';
import {selectContextRawData} from 'modules/context/context-selector';
import {ReactComponent as ReloadOutlined} from 'shared/assets/reload.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    AisNotificationScenariosSettingsTableDto,
    deleteAisNotificationScenariosSettingsTableRows,
    loadAisNotificationScenariosServices,
    loadAisNotificationScenariosSettingsTable,
    selectNotificationScenariosSettingsServices,
    selectNotificationScenariosSettingsTableData,
} from 'store/slices/ais-slice/ais-notification-scenarios-settings';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {COMMENTS_REQUEST_DEFAULT_PAGE_SIZE, TABLE_PAGINATION_CONFIG} from './constants/notification-scenarios-settings.constants';
import {NotificationScenariosSettingsModalOpener} from './modals';
import s from './notification-scenarios-settings.module.less';

interface AisNotificationScenariosSettingsPageProps {}

export const AisNotificationScenariosSettingsPage: FC<AisNotificationScenariosSettingsPageProps> = () => {
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [search, setSearch] = useState<string | null>(null);
    const [isContextLoaded, setIsContextLoaded] = useState(false);
    const {organizationId, taxPeriodId} = useAppSelector(selectContextRawData) || {};

    useEffect(() => {
        if (organizationId && taxPeriodId) {
            setIsContextLoaded(true);
        }
    }, [organizationId, taxPeriodId]);

    const fetchTableData = (
        searchValue?: string | null,
        pagination?: {
          paginationPageSize?: number;
          paginationCurrent?: number;
      },
    ) => {
        if (isContextLoaded) {
            dispatch(loadAisNotificationScenariosSettingsTable({
                paginationPageSize: COMMENTS_REQUEST_DEFAULT_PAGE_SIZE,
                taxPeriodId,
                organizationId,
                search: searchValue,
                ...pagination,
            }));
        }
    };

    useEffect(() => {
        if (isContextLoaded) {
            fetchTableData();
            dispatch(loadAisNotificationScenariosServices());
        }
    }, [isContextLoaded, organizationId, taxPeriodId]);

    const isLoading = useAppSelector(
        st => selectIsThunkPending(st, loadAisNotificationScenariosSettingsTable.typePrefix),
    );

    const notificationScenariosSettingsTable = useAppSelector(selectNotificationScenariosSettingsTableData);
    const notificationScenariosServices = useAppSelector(
        selectNotificationScenariosSettingsServices,
    ) ?? [];

    const handleDelete = async () => {
        await dispatch(deleteAisNotificationScenariosSettingsTableRows({
            ids: selectedRowKeys as number[],
        }));

        setSelectedRowKeys([]);
        fetchTableData();
    };

    const handlePaginationChange = (page: number, pageSize?: number | undefined) => {
        fetchTableData(search, {
            paginationCurrent: page,
            paginationPageSize: pageSize,
        });
    };

    return (
        <CustomCard
            title='Настройка уведомлений по сценариям обмена с АИС "Налог-3"'
            className={s['notification-settings']}
        >
            <ActionBar
                className={s['notification-settings__buttons-bar']}
            >
                <NotificationScenariosSettingsModalOpener
                    fetchTableData={fetchTableData}
                    notificationScenariosServices={notificationScenariosServices}
                />

                <ActionConfirmModal
                    cancelText="Отмена"
                    approveText="Удалить"
                    modalTitle="Удаление уведомлений"
                    confirmModalContent="Вы уверены, что хотите удалить выбранные уведомления?"
                    confirmAction={handleDelete}
                >
                    {
                        handleClick => (
                            <Button
                                type="primary"
                                className={s['notification-settings__delete-btn']}
                                onClick={handleClick}
                                icon={<DeleteFilled />}
                                disabled={
                                    (notificationScenariosSettingsTable
                                      && notificationScenariosSettingsTable.length <= 0)
                                  || selectedRowKeys.length <= 0
                                }
                            >
                                Удалить выбранное
                            </Button>
                        )
                    }
                </ActionConfirmModal>

                <Button
                    type="default"
                    disabled={isLoading}
                    icon={<ReloadOutlined />}
                    onClick={() => dispatch(loadAisNotificationScenariosSettingsTable({
                        paginationPageSize: COMMENTS_REQUEST_DEFAULT_PAGE_SIZE,
                        taxPeriodId,
                        organizationId,
                    }))}
                >
                    Обновить
                </Button>
                <Search
                    setSearch={setSearch}
                    fetchCallback={fetchTableData}
                />
            </ActionBar>

            <Spin
                spinning={isLoading || !isContextLoaded}
                tip="Загрузка..."
            >
                <CustomTable
                    rowKey="id"
                    pagination={{
                        ...TABLE_PAGINATION_CONFIG,
                        onChange: handlePaginationChange,
                    }}
                    dataSource={notificationScenariosSettingsTable}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (newSelectedRowKeys: React.Key[]) => {
                            setSelectedRowKeys(newSelectedRowKeys as number[]);
                        },
                    }}
                    onColumn={col => ({
                        ...customTableUtils.setHeaderStyles({
                            whiteSpace: 'pre-wrap',
                            textAlign: 'start',
                        }),
                        ...col,
                    })}
                    columns={[
                        {
                            title: '№',
                            dataIndex: 'notificationNumber',
                            key: 'notificationNumber',
                        },
                        {
                            title: 'Наименование уведомления',
                            dataIndex: 'notificationName',
                            key: 'notificationName',
                            width: '228px',
                            render: (_, row) => (
                                <NotificationScenariosSettingsModalOpener
                                    fetchTableData={fetchTableData}
                                    notificationScenariosServices={notificationScenariosServices}
                                    isEditing
                                    initialNotificationsValues={row}

                                />
                            ),
                        },
                        {
                            title: 'Сервис',
                            dataIndex: 'interactionScenarioName',
                            key: 'interactionScenarioName',
                        },
                        {
                            title: 'Код ответа',
                            width: '120px',
                            dataIndex: 'responseCode',
                            key: 'responseCode',
                        },
                        {
                            title: 'Группы пользователей, являющиеся получателями уведомления',
                            width: '228px',
                            dataIndex: 'recipientGroupsString',
                            key: 'recipientGroupsString',
                        },
                        {
                            title: 'Пользователи, являющиеся получателями уведомления ',
                            width: '228px',
                            dataIndex: 'recipientUsersString',
                            key: 'recipientUsersString',
                        },
                        {
                            title: 'Текст уведомления',
                            dataIndex: 'notificationMessage',
                            key: 'notificationMessage',
                            render: (_, row) => (
                                <p
                                    className={s['table__message-column']}
                                >
                                    {row.notificationMessage}
                                </p>
                            ),
                        },
                    ] as ColumnsType<AisNotificationScenariosSettingsTableDto>}
                />
            </Spin>
        </CustomCard>
    );
};
