import {useEffect, useState} from 'react';

import {StateSetter} from 'shared/types/generics';
import {showMessage} from 'shared/utils';

import {fetchSignedDocs} from '../api';
import {getCryptoProData} from '../lib';
import {CryptoProData, CryptoProSignedDoc} from '../model/types';

interface UseCryptoProInitParams {
    documentIds?: string[];
    setSignedDocs?: StateSetter<CryptoProSignedDoc[]>;
    setIsWithPluginInitializationError: StateSetter<boolean>;
    handleCryptoProError?: (error: any) => void;
    onLoadCryptoProData?: (data: CryptoProData) => void;
}

/**
 * Хук используется для инициализации данных CryptoPro и загрузки информации об уже подписанных документах.
 * Хук автоматически запускает запросы при монтировании компонента
 * и выполняет все необходимые действия для инициализации плагина.
 *
 * @param documentIds (опционально) Массив идентификаторов документов, для которых нужно загрузить подписанные версии.
 * @param setSignedDocs (опционально) Функция для обновления состояния с подписанными документами.
 * @param setIsWithPluginInitializationError Функция для установки состояния ошибки инициализации плагина.
 * @param handleCryptoProError (опционально) Функция для обработки ошибок,
 * связанных с инициализацией плагина или загрузкой документов.
 * @param onLoadCryptoProData (опционально) Функция, которая вызывается при успешной загрузке данных CryptoPro.
 *
 * @returns Объект, содержащий данные CryptoPro, полученные после инициализации.
 */
export const useCryptoProInit = ({
    documentIds,
    setSignedDocs,
    setIsWithPluginInitializationError,
    handleCryptoProError,
    onLoadCryptoProData,
}: UseCryptoProInitParams) => {
    const [cryptoProData, setCryptoProData] = useState<CryptoProData>();

    useEffect(() => {
        const loadCryptoProData = async () => {
            try {
                const data = await getCryptoProData();
                setCryptoProData(data);
                if (onLoadCryptoProData) {
                    onLoadCryptoProData(data);
                }
            } catch (error) {
                setIsWithPluginInitializationError(true);
                if (handleCryptoProError) {
                    handleCryptoProError(error);
                } else {
                    console.error('Ошибка инициализации CryptoPro: ', error);
                }
            }
        };

        const loadSignedDocs = async () => {
            if (documentIds && setSignedDocs) {
                try {
                    const signedDocsResponse = await fetchSignedDocs(documentIds);
                    setSignedDocs(signedDocsResponse);
                } catch (error) {
                    showMessage({message: 'Ошибка загрузки подписанных документов', isError: true});
                    if (handleCryptoProError) {
                        handleCryptoProError(error);
                    } else {
                        console.error('Ошибка загрузки подписанных документов: ', error);
                    }
                }
            }
        };

        loadCryptoProData();
        loadSignedDocs();
    }, []);

    return {
        cryptoProData,
    };
};
