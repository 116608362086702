import {Divider, Form} from 'antd';
import {FormListFieldData} from 'antd/es/form/FormList';
import {FormInstance} from 'antd/lib/form';
import {isMatch} from 'lodash';
import React, {useEffect} from 'react';

import {ProgramArgsType, ProgramSetProgramsType} from 'components/form/fields/fields-tree/fields-tree-types';
import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';

import {FieldsTreeGenerator} from '../fields-tree-generator';

import '../fields-tree.less';

interface FieldsTreeChildProps {
    fieldMeta: FieldMeta;
    entityName: string;
    parentField: FormListFieldData;
    formData?: FormEntityData;
    form?: FormInstance;
}

export const FieldTreeChild: React.FunctionComponent<FieldsTreeChildProps> = ({
    fieldMeta,
    form,
    formData,
    parentField,
    entityName,
}: FieldsTreeChildProps) => {
    const {programSetPrograms} = form?.getFieldsValue() || {};

    useEffect(() => {
        if (!parentField) return;
        const programSet = programSetPrograms[parentField.name];
        const programSetForUpdate = JSON.parse(JSON.stringify(programSetPrograms));
        let programArgs: ProgramArgsType[] | undefined = [];

        if (programSet && Array.isArray(formData?.data?.programSetPrograms)
            && formData?.data?.programSetPrograms[parentField.name]) {
            programArgs = (
                formData?.data?.programSetPrograms[parentField.name] as unknown as ProgramSetProgramsType
            )?.programArgs;
        }

        const defaultProgramSet: ProgramArgsType[] = [
            {
                code: '',
                defaultType: undefined,
            },
        ];
        if (programSetPrograms && programSet && Array.isArray(programArgs)) {
            if (!fieldMeta.children) return;
            if (!programSet || !parentField) return;

            // setting default programs for empty programs
            if (programArgs.length === 0) {
                if (!isMatch(programSet.programSetArgs, defaultProgramSet)) {
                    programSetForUpdate[parentField.name].programSetArgs = defaultProgramSet;
                    form?.setFieldsValue({programSetPrograms: programSetForUpdate});
                }
                return;
            }

            const argKeys = fieldMeta.children.map(child => child.key);
            const newArgs: ProgramArgsType[] = [];
            const newValues = form?.getFieldsValue();
            programArgs.forEach((arg: any) => {
                const newArg = defaultProgramSet[0];
                argKeys.forEach((key: string) => {
                    newArg[key] = arg[key] || '';
                });
                newArgs.push(JSON.parse(JSON.stringify(newArg)));
            });

            if (newValues.programSetPrograms && newValues.programSetPrograms[parentField.name]) {
                if (Array.isArray(newArgs) && Array.isArray(programSet?.programSetArgs)
                    && newArgs.length === programSet?.programSetArgs.length
                    && !programSet?.programSetArgs.some((arg: ProgramArgsType, index: number) => (
                        arg.code !== newArgs[index].code
                    ))) return;
                if (isMatch(newArgs, programSetPrograms[parentField.name]?.programSetArgs)) return;

                programSetForUpdate[parentField.name].programSetArgs = newArgs;

                // filter empty programs
                newValues.programSetPrograms = programSetForUpdate.filter(
                    (
                        programSetData: ProgramSetProgramsType,
                    ) => programSetData.program && 'id' in programSetData.program,
                );

                if (!isMatch(
                    programSetForUpdate[parentField.name]?.programSetArgs,
                    form?.getFieldsValue().programSetPrograms[parentField.name]?.programSetArgs,
                )) {
                    form?.setFieldsValue(newValues);
                }
            }
        }
    }, [programSetPrograms]);

    return (
        <div className="fields-tree">
            <Form.List
                {...parentField}
                name={[parentField.name, fieldMeta.key]}
            >
                {(fields: FormListFieldData[]) => (
                    <div>
                        <div className="fields-tree__header">{fieldMeta.label}</div>
                        <div className="fields-tree__wrapper">
                            {((!fields.length || fields.length === 1) ? [{fieldKey: 0, key: 0, name: 0}] : fields)
                                ?.map((childField: FormListFieldData, index) => (
                                    <div key={childField.key}>
                                        <Form.Item
                                            className="row"
                                            {...childField}
                                            name={[childField.name, fieldMeta.key]}
                                        >
                                            <FieldsTreeGenerator
                                                fieldData={childField}
                                                meta={fieldMeta}
                                                form={form}
                                                formData={formData}
                                                entityName={entityName}
                                            />
                                        </Form.Item>
                                        {index < fields.length - 1 && <Divider />}
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
            </Form.List>
        </div>
    );
};
