import {PushWithBreadcrumbsFunction} from 'components/table-report/hooks/use-breadcrumbs-controller';
import {performDrilldownPush} from 'components/table-report/utils';
import {StateSetter} from 'shared/types/generics';
import {TableReportColumn, TableReportDrillDown} from 'store/slices/table-report-slice/table-report-slice-types';

export interface UseColumnDrilldownArgs {
    drillDowns?: TableReportDrillDown[];
    value: any;
    column?: TableReportColumn;
    rowIndex: number;
    tableFontSize: string;
    pushWithBreadcrumbs: PushWithBreadcrumbsFunction;
    setDDModalEntries: StateSetter<TableReportDrillDown[] | undefined>;
    setIsDDModalVisible: StateSetter<boolean>;
}

export const useColumnDrilldown = ({
    drillDowns,
    column,
    rowIndex,
    pushWithBreadcrumbs,
    setDDModalEntries,
    setIsDDModalVisible,
}: UseColumnDrilldownArgs) => {
    const ddEntries = drillDowns?.filter(dd => (
        dd.columnName === column?.dataIndex && dd.rowIndex === rowIndex
    ));

    const isWithDrilldown = ddEntries?.length;

    const drilldownLinkClassName = (() => {
        if (ddEntries?.length) { return 'table-report__column drilldown-link'; }
        return '';
    })();

    const drilldownTooltipTitle = (() => {
        if (ddEntries?.length === 1) {
            const [ddEntry] = ddEntries;
            return ddEntry.templateName;
        }
        return 'Обратите внимание: показатель расшифровывается несколькими документами';
    })();

    const handleDrilldownClick = () => {
        if (ddEntries?.length === 1) {
            const [ddEntry] = ddEntries;

            performDrilldownPush({
                ddEntry,
                pushWithBreadcrumbs,
            });
        } else if ((ddEntries?.length ?? 0) > 1) {
            setDDModalEntries(ddEntries);
            setIsDDModalVisible(true);
        }
    };

    return {
        isWithDrilldown,
        handleDrilldownClick,
        drilldownLinkClassName,
        drilldownTooltipTitle,
    };
};
