import React, {ReactText} from 'react';
import {useDispatch} from 'react-redux';

import {selectEntityData} from 'modules/data';
import {createActionHandlerForDelete} from 'modules/data/data-actions';
import {TableEntityData} from 'modules/data/data-types';
import {RequestType, selectMetadata} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {ActionButton} from '../action-button';
import {ActionConfirmModal} from '../action-confirm-modal/action-confirm-modal';
import {
    DEFAULT_DELETE_ELEMENT_CONFIRM_TEXT,
} from '../button-action-delete-tab/constants/button-delete-tab-constants';
import {TableActionProps} from '../table-action-types';

interface ButtonActionForDeleteRowsOwnProps extends TableActionProps {
}

const ButtonActionForDeleteRowsComponent: React.FunctionComponent<ButtonActionForDeleteRowsOwnProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: ButtonActionForDeleteRowsOwnProps) => {
    const referenceUrl = meta?.referenceUrl || entityName;
    const requestType = meta?.requestType || RequestType.POST;
    const {urlConfig} = useAppSelector(selectMetadata(entityName, EntityType.TABLE)) || {};

    const {selectedRowKeys = []} = useAppSelector(selectEntityData(entityName, EntityType.TABLE)) as TableEntityData
        ?? {};

    const dispatch = useDispatch();
    const actionsForRows = (ids: ReactText[]) => dispatch(
        createActionHandlerForDelete({
            entityName,
            referenceUrl,
            requestType,
            itemList: meta?.itemListKey ? {[meta.itemListKey]: ids} : {ids},
            urlConfig: meta?.shouldIgnoreUrlConfig ? undefined : urlConfig,
        }),
    );
    const handleDelete = () => {
        actionsForRows(selectedRowKeys);
    };
    const Component = wrapper || ActionButton;

    return (
        <ActionConfirmModal
            cancelText="Отмена"
            approveText="Удалить"
            modalTitle="Удаление"
            confirmModalContent={DEFAULT_DELETE_ELEMENT_CONFIRM_TEXT}
            confirmAction={handleDelete}
        >
            {handleClick => (
                <Component
                    {...props}
                    onClick={handleClick}
                    actionIcon={meta.actionIcon}
                    disabled={!selectedRowKeys.length}
                    className="table-action critic-action"
                >
                    {meta.actionTitle}
                </Component>
            )}

        </ActionConfirmModal>
    );
};

export const ButtonActionForDeleteRows = ButtonActionForDeleteRowsComponent;
