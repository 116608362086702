import axios from 'axios';
import FileDownload from 'js-file-download';
import {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {getFilenameFromResponse} from 'modules/data/utils/data.utils';
import {LinkMethod} from 'shared/types/links';
import {performRequest} from 'shared/utils';

import {PAGE_MODES, PDF_FALLBACK_TOKEN_KEY} from '../pdf-link-fallback-page-constants';

/**
 *
 * @returns {object} The hook's state and actions.
 * @property {string} mode - The current mode of the page, such as HTTP error or non-PDF file.
 * @property {string} responseText - Text describing the result of the page's actions, including error messages.
 * @property {string | undefined} redirectTo - The URL to redirect to.
 * @property {string | undefined} token - The token for authorization retrieved from session storage.
 * @property {function} handleDownload - Initiates the file download process.
 *
 * @example
 * // In a React component:
 * const { mode, responseText, redirectTo, token, handleDownload } = usePDFLinkFallbackPage();
 */

export const usePDFLinkFallbackPage = () => {
    const location = useLocation();
    const redirectTo = location?.search?.replace('?redirectTo=', '');
    const token = sessionStorage.getItem(PDF_FALLBACK_TOKEN_KEY);
    const [responseText, setResponseText] = useState('');
    const [mode, setMode] = useState('');
    const initialTitle = useRef(window.document.title);

    const setHTTPErrorState = ({status}: {status: number}) => {
        setMode(PAGE_MODES.HTTP_ERROR);
        window.document.title = 'Ошибка';
        setResponseText(`Ошибка доступа к скан-образу. Код ошибки: ${status}. Обратитесь в техподдержку.`);
    };

    const handleDownload = async () => {
        try {
            const requestResponse = await performRequest({
                url: redirectTo?.replace('/report/ea/download', '/report/ea/upload-file'),
                method: LinkMethod.GET,
                headers: ({
                    Authorization: token,
                }),
                responseType: 'blob',
            }, true);
            const {data} = requestResponse;
            const filename = getFilenameFromResponse(requestResponse);
            FileDownload(data, filename);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (redirectTo && token) {
            (async () => {
                try {
                    const requestResponse = await axios(redirectTo, {
                        method: LinkMethod.GET,
                        headers: ({
                            Authorization: token,
                        }),
                    });
                    const {status} = requestResponse;

                    if (status !== 207) {
                        setHTTPErrorState({status});
                        return;
                    }

                    setMode(PAGE_MODES.NOT_PDF);
                    window.document.title = 'Формат файла отличен от PDF';
                    setResponseText('Файл имеет формат отличный от pdf.');
                } catch ({response: requestResponse}) {
                    if (!requestResponse) {
                        return;
                    }
                    setHTTPErrorState({status: requestResponse?.status});
                }
            })();
        }
        return () => {
            window.document.title = initialTitle.current;
        };
    }, [redirectTo, token]);

    return {
        mode, responseText, redirectTo, token, handleDownload,
    };
};
