import {showMessage} from 'shared/utils';

import {CertificateExtended} from '../model/types';

/**
 * Проверяет, определены ли отпечаток и алгоритм сертификата,
 * и возвращает их в случае успешной валидации.
 * Если данные не определены, отображается сообщение об ошибке и функция возвращает `null`.
 *
 * @param certificate Объект сертификата, который необходимо валидировать.
 *
 * @returns Объект с `thumbprint` (отпечатком сертификата) и `algorithm` (алгоритмом) в случае успешной валидации.
 * Возвращает `null`, если валидация не удалась.
 *
 * Если `certificate` не определён,
 * или отсутствуют `thumbprint` или `algorithm`,
 * функция отображает сообщение об ошибке
 * через `showMessage` и записывает соответствующее сообщение в консоль.
 */
export const validateCertificate = (certificate?: CertificateExtended) => {
    const thumbprint = certificate?.thumbprint;
    const algorithm = certificate?.algorithmCadescomConstant;

    if (!thumbprint || algorithm === undefined) {
        showMessage({message: 'Сертификат или алгоритм не определены', isError: true});
        console.error('Сертификат или алгоритм не определены');
        return null;
    }

    return {thumbprint, algorithm};
};
