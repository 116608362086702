import {Key, TablePaginationConfig, TableRowSelection} from 'antd/lib/table/interface';
import React from 'react';

import {performActionForTableState, setData} from 'modules/data/data-actions';
import {DefaultTableState, Entity, TableEntityData} from 'modules/data/data-types';
import {TableEntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch} from 'store/config/hooks';

import {convertObjectToTableStateFlat} from '../form-table/form-table.utils';

interface UseTableFeaturesProps {
    entityName: string;
    url: string;
    data: TableEntityData;
    metadata: TableEntityMeta;
}

export const useTableFeatures = ({
    entityName, url, data, metadata,
}: UseTableFeaturesProps) => {
    const dispatch = useAppDispatch();
    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: any,
    ) => {
        dispatch(performActionForTableState({
            entityName,
            newEntityTypeData: convertObjectToTableStateFlat(
                {pagination, sorter} as DefaultTableState,
            ) as Record<string, any>,
            prefix: metadata?.prefix ? metadata.prefix : url,
            urlConfig: metadata?.urlConfig,
        }));
    };

    const rowSelection: TableRowSelection<Record<string, Entity[]>> = {
        selectedRowKeys: data?.selectedRowKeys,
        onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: Entity[],
        ) => dispatch(setData({
            entityName,
            entityType: EntityType.TABLE,
            data: {
                selectedRowKeys,
                selectedRows,
            },
        })),
    };

    return {handleTableChange, rowSelection};
};
