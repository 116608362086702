import {FormInstance} from 'antd/es/form';
import {flatten} from 'lodash';

import {DocumentToSignInDatabase, FileWithId} from '../model/types';

interface GetOverlappingFilesArgs {
    fileFieldNames?: string[];
    currentValue?: FileWithId[] | File[];
    form?: FormInstance;
    documentsToSignInDB?: DocumentToSignInDatabase[];
    fileList?: File[];
}

/**
 * Определяет пересекающиеся файлы между текущими значениями, файлами в форме и документами в базе данных.
 *
 * @param currentValue Массив объектов `FileWithId` или `File`, представляющих текущие значения файлов.
 *
 * @param fileFieldNames Массив строк, представляющий названия полей формы, содержащих файлы.
 *
 * @param form Экземпляр формы (`FormInstance`) из библиотеки `antd`.
 * Используется для получения значений из полей формы.
 *
 * @param documentsToSignInDB Массив объектов `DocumentToSignInDatabase`,
 * представляющий документы, уже находящиеся в базе данных. Каждый объект включает поле `documentName`.
 *
 * @param fileList Массив объектов `File`,
 * представляющих текущий список файлов, которые необходимо проверить на пересечения.
 *
 * @returns Объект с двумя свойствами:
 * - `overlappingFiles` — массив объектов `File`, представляющих файлы,
 * которые пересекаются с текущими значениями (`currentValue`).
 * - `overlappingFileNamesInDB` — массив строк, представляющий имена файлов,
 * которые пересекаются с документами в базе данных (`documentsToSignInDB`).
 */
export const getOverlappingFiles = ({
    currentValue,
    fileFieldNames,
    form,
    documentsToSignInDB,
    fileList,
}: GetOverlappingFilesArgs) => {
    const filesToSign: File[] | undefined = (() => {
        const filesFromForm = fileFieldNames
            ?.map(fieldName => form?.getFieldValue(fieldName)).filter(v => !!v) ?? [];

        if (!fileList) return filesFromForm;
        return [...filesFromForm, ...fileList];
    })();

    const filesToSignFlat = flatten(filesToSign);
    const fileNamesInDBToSign = (documentsToSignInDB ?? []).map(doc => doc.documentName);

    const currentFileNames = (currentValue ?? []).map(file => file.name);

    const overlappingFiles = filesToSignFlat?.filter(file => currentFileNames.includes(`${file.name}.sig`));
    const overlappingFileNamesInDB = fileNamesInDBToSign
        ?.filter(fileName => currentFileNames.includes(`${fileName}.sig`));

    return {
        overlappingFiles,
        overlappingFileNamesInDB,
    };
};
