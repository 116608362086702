import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {AisSliceState} from '../ais-slice-types';
import {loadAisNotificationScenariosServices, loadAisNotificationScenariosSettingsTable} from './ais-notification-scenarios-settings-thunks';

export const createAisNotificationScenariosSettingsExtraReducer = (builder: ActionReducerMapBuilder<AisSliceState>) => {
    builder.addCase(loadAisNotificationScenariosSettingsTable.fulfilled, (state, {payload}) => {
        state.aisNotificationScenariosSettings.notificationScenariosTable = payload.dataTable;
    });
    builder.addCase(loadAisNotificationScenariosServices.fulfilled, (state, {payload}) => {
        state.aisNotificationScenariosSettings.notificationScenariosServices = payload;
    });
};
