import React, {ReactNode, useEffect} from 'react';

import {AlertErrorMessage} from 'components/form/alert-error-message/alert-error-message';
import {Spinner} from 'components/spinner';
import {useAppSelector, useAppDispatch} from 'store/config/hooks';
import {getAuthProfile, selectAuthProfile} from 'store/slices/auth-slice';
import {AuthProfile} from 'store/slices/auth-slice/auth-slice-types';
import {
    selectIsThunkPending,
    selectThunkError,
    ThunkRejectionError,
} from 'store/slices/loading-state-slice';

export interface UseProfileLoader {
    profile?: AuthProfile | null | undefined;
    profileError?: ThunkRejectionError | undefined;
    isProfileLoading?: boolean;
    profileLoaderStatusJsx?: ReactNode;
    isKerberos?: boolean;
}

export const useProfileLoader = (): UseProfileLoader => {
    const dispatch = useAppDispatch();

    const profile = useAppSelector(selectAuthProfile);
    const profileError = useAppSelector(s => selectThunkError(s, getAuthProfile.typePrefix));
    const isProfileLoading = useAppSelector(s => selectIsThunkPending(s, getAuthProfile.typePrefix));
    const isKerberos = profile === AuthProfile.KERBEROS;

    useEffect(() => {
        dispatch(getAuthProfile());
    }, []);

    const profileLoaderStatusJsx = (() => {
        if (isProfileLoading) {
            return <Spinner tip="Загрузка профиля..." />;
        }

        if (!profile || profileError) {
            return (
                <div
                    className="login-page__alert-error"
                >
                    <AlertErrorMessage
                        message="Ошибка получения профиля"
                        description="Не удалось загрузить профиль пользователя.
                                             Обратитесь к администратору"
                        type="error"
                    />
                </div>
            );
        }

        return null;
    })();

    return {
        profile,
        profileError,
        isProfileLoading,
        profileLoaderStatusJsx,
        isKerberos,
    };
};
