import {Button, Form} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React, {
    useEffect,
    useRef,
    useState,
    FC,
    useContext,
} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {SimpleActionButton} from 'components/form/components';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {setFieldsValueUntouched} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import './actions-settings-modal.less';

const ACTIONS_SETTINGS_PREFIX = 'actions' as const;

interface ModalReportActionsSettingsProps {
    templateCode?: string;
    isDisabled: boolean;
}

export const ModalReportActionsSettings: FC<ModalReportActionsSettingsProps> = ({
    templateCode,
    isDisabled,
}: ModalReportActionsSettingsProps) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();
    const modalOpenerRef = useRef<ModalOpenerComponentRef>(null);

    const {
        refetchData,
    } = useContext(ReportConfigurationContext);

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );

    const [initialValues, setInitialValues] = useState<{[ACTIONS_SETTINGS_PREFIX]:(string | undefined)[] | undefined}>({
        [ACTIONS_SETTINGS_PREFIX]: reportConfigurationData?.[ACTIONS_SETTINGS_PREFIX],
    });

    useEffect(() => {
        const actions = reportConfigurationData?.[ACTIONS_SETTINGS_PREFIX];
        if (actions) {
            setInitialValues({
                actions,
            });
        }
    }, [reportConfigurationData]);

    useEffect(() => {
        if (initialValues) setFieldsValueUntouched(form, initialValues);
    }, [initialValues]);

    const handleFinish = (values: {[ACTIONS_SETTINGS_PREFIX]: string[]}) => {
        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                templateCode,
                data: {
                    actionsEnabled: values?.[ACTIONS_SETTINGS_PREFIX].length > 0,
                    [ACTIONS_SETTINGS_PREFIX]: values?.[ACTIONS_SETTINGS_PREFIX],
                },
            })).then(() => {
                refetchData();
            });
        }
        modalOpenerRef.current?.setIsModalOpen(false);
    };

    const validateUniqueAction = (value: string) => {
        const {actions} = form.getFieldsValue();

        if (!actions) {
            return true;
        }

        const duplicates = actions?.filter((item: any) => item?.action === value);
        const isUnique = duplicates.length <= 1;
        return isUnique;
    };

    return (
        <ModalOpenerComponent
            shouldConfirm={() => form.isFieldsTouched()}
            ref={modalOpenerRef}
            handleCancel={setIsModalOpen => {
                setIsModalOpen(false);
                setFieldsValueUntouched(form, initialValues);
            }}
            handleSave={() => {
                form.submit();
            }}
            component={(
                <SimpleActionButton
                    type="primary"
                    title="Настройка действий"
                    disabled={isDisabled}
                />
            )}
            modalProps={{
                title: 'Настройка действий',
                width: '100%',
                style: {maxWidth: '1000px'},
                forceRender: false,
                destroyOnClose: true,
                centered: true,
            }}
        >
            <div className={cn('actions-settings-modal')}>
                <Form
                    onFinish={handleFinish}
                    form={form}
                    layout="vertical"
                    className="form-flex"
                >
                    <Form.List name="actions">
                        {(fields, {add, remove}) => (
                            <>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        onClick={() => add()}
                                        style={{
                                            marginBottom: 20,
                                        }}
                                    >
                                        <DynamicIcon type="PlusCircleOutlined" />
                                        Добавить
                                    </Button>
                                </Form.Item>
                                {fields.map(field => (
                                    <div
                                        key={field.key}
                                        className="form-fields form-flex-row"
                                    >
                                        <Form.Item
                                            label="Действие"
                                            name={[field.name, 'action']}
                                            required
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        const isUniqueAction = validateUniqueAction(value);

                                                        if (!isUniqueAction) {
                                                            return Promise.reject(
                                                                new Error('Это действие уже было выбрано'),
                                                            );
                                                        }

                                                        if (value) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(new Error('Необходимо указать действие'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <CustomSelect
                                                settings={{
                                                    url: 'lookupValue/TABLE_REPORT_ACTIONS',
                                                    valuePath: 'lookupCode',
                                                    labelPath: 'meaning',
                                                    placeholder: 'Выберите действие',
                                                    useFirstOptionAfterEntriesChanged: true,
                                                    noUseFirstOptionWhenValueIsSet: true,
                                                    isClearable: true,
                                                    showSearch: true,
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Функция"
                                            name={[field.name, 'function']}
                                            required
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        if (value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Необходимо указать функцию'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <CustomSelect
                                                settings={{
                                                    url: 'lookupValue/FUNCTIONS',
                                                    valuePath: 'lookupCode',
                                                    labelPath: 'meaning',
                                                    placeholder: 'Выберите функцию',
                                                    useFirstOptionAfterEntriesChanged: true,
                                                    noUseFirstOptionWhenValueIsSet: true,
                                                    isClearable: true,
                                                    showSearch: true,
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Программа"
                                            name={[field.name, 'program']}

                                        >
                                            <CustomSelect
                                                settings={{
                                                    url: 'lookupValue/MONITOR_PROGRAM',
                                                    valuePath: 'lookupCode',
                                                    labelPath: 'meaning',
                                                    placeholder: 'Выберите программу',
                                                    useFirstOptionAfterEntriesChanged: true,
                                                    noUseFirstOptionWhenValueIsSet: true,
                                                    isClearable: true,
                                                    showSearch: true,
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Активно"
                                            name={[field.name, 'active']}
                                            className="isActive-select"
                                        >
                                            <CustomSelect
                                                entries={[{value: true, label: 'Да'}, {value: false, label: 'Нет'}]}
                                                settings={{
                                                    isClearable: true,
                                                    showSearch: true,
                                                }}
                                            />
                                        </Form.Item>
                                        <Button
                                            type="primary"
                                            className="button-critic delete-row-button"
                                            onClick={() => remove(field.name)}
                                        >
                                            <DynamicIcon type="TrashXOutlined" />
                                        </Button>
                                    </div>
                                ))}
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
        </ModalOpenerComponent>
    );
};
