import React from 'react';
import {Redirect} from 'react-router-dom';

import {Spinner} from 'components/spinner';
import {useProfileLoader} from 'pages/login-page/use-profile-loader/use-profile-loader';
import {URL_LOGIN, URL_LOGIN_KERBEROS} from 'shared/constants/urls';
import {useAppSelector} from 'store/config/hooks';
import {selectAuthToken} from 'store/slices/auth-slice';

/**
 * Точка входа в приложение - здесь происходит маршрутизация
 * по разным страницам логина, в зависимости от профиля
 */
export const AuthRouter: React.FC = () => {
    const {
        profile,
        isKerberos,
    } = useProfileLoader();

    const token = useAppSelector(selectAuthToken);

    const isAuthorized = profile && !!token;

    return ((() => {
        if (isAuthorized) {
            return <Redirect to="/" />;
        }

        if (!profile) {
            return (
                <Spinner
                    classNames={{containerClassName: 'full-screen-h'}}
                    tip="Загрузка профиля..."
                />
            );
        }

        if (isKerberos) {
            return <Redirect to={URL_LOGIN_KERBEROS} />;
        }

        return <Redirect to={URL_LOGIN} />;
    })());
};
