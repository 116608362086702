import {AxiosResponse} from 'axios';
import {get} from 'lodash';
import compact from 'lodash/compact';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import moment from 'moment';
import {ReactText} from 'react';

import {isArrayNull} from 'components/form/inputs/uploader-list/uploader-list.utils';
import {DATE_FOR_REQUEST} from 'shared/constants/date-format';
import {
    getMapOfValues, isArray, isBlob, isObject,
} from 'shared/utils';
import {DEFAULT_REQUEST_CONFIG} from 'shared/utils/request';

import {FieldMeta, FieldType, RequestType} from '../../metadata';
import {BOOLEAN_MATCH_TABLE} from '../data-constants';
import {Entity, EntityValue, IDeleteFormValue} from '../data-types';

export const normalizeDataForProgramStages = (data: Entity) => {
    const {programSetPrograms} = data ?? {};
    const normalizedData = {
        ...data,
        programSetPrograms: (programSetPrograms as any[]).map(entry => {
            const {program, programSetArgs} = entry;
            const programNormalized = {
                id: program.id,
                name: program.name,
                description: program.description,
            };
            const programSetArgsNormalized = programSetArgs.map((p: any) => ({
                id: p?.id,
                code: p?.code,
                defaultType: {
                    id: p?.defaultType?.id,
                },
            })).filter(
                (p: any) => p?.defaultType.id !== undefined,
            );
            return {program: programNormalized, programSetArgs: programSetArgsNormalized, id: entry.id};
        }),
    };
    return normalizedData;
};

export const getMatchesBoolValue = (value: string | number | boolean) => BOOLEAN_MATCH_TABLE[String(value)];

const getFirstValueFromObject = (object: Record<string, any>, names: string[]) => {
    const existingName = names.find(name => object[name]) || '';

    return object[existingName];
};

export const fieldValue = (value: Entity, fieldNames: string[]) => (Array
    .isArray(value)
    ? value.map(item => item && getFirstValueFromObject(item, fieldNames))
    : value && getFirstValueFromObject(value, fieldNames));

const createKeyForData = (key: string) => {
    const shouldChangeKey = !/Id$/.test(key);
    return shouldChangeKey
        ? `${key}Id`
        : key;
};

export const concatObjectFromArray = (listData: Array<Record<string, any>>) => (
    listData
        .reduce((acc: Record<string, any>, currentData: Record<string, any>) => ({...acc, ...currentData}), {})
);

const createRecordWithValueForFieldReference = (
    key: string,
    oldValue: any,
) => {
    if (oldValue?.requestFieldKey) {
        const newValue = oldValue?.[oldValue?.requestFieldKey];
        return [key, newValue];
    }
    if (oldValue?.value) {
        return [key, oldValue.value];
    }
    if (typeof oldValue === 'string') {
        return [key, oldValue];
    }
    if (oldValue?.lookupCode) {
        return [key, oldValue.lookupCode];
    }
    return [key, undefined];
};

const createRecordWithIdForFieldReference = (
    oldKey: string,
    oldValue: any,
    isJsonRequest?: boolean,
    metaField?: FieldMeta,
    onNotCreateId?: boolean,
) => {
    const notCreateKey = (isArray(oldValue) && oldValue.length ? oldValue[0]?.onNotCreateKey
        : oldValue?.onNotCreateKey) || metaField?.onNotCreateKey;
    const newKey = metaField?.requestKey ?? (notCreateKey ? oldKey : createKeyForData(oldKey));
    let newValue;
    if (oldValue?.requestFieldKey) {
        newValue = oldValue?.[oldValue?.requestFieldKey];
    } else if (oldValue?.displayFieldKey) {
        newValue = oldValue?.[oldValue?.displayFieldKey];
    } else if (isArray(oldValue)) {
        newValue = oldValue.length
            ? oldValue.map((item: Record<string, any>) => (item?.id || item))
            : oldValue;
    } else if (oldValue?.id && oldValue?.id !== -1) {
        newValue = isJsonRequest && !onNotCreateId ? {id: oldValue?.id} : oldValue?.id ?? oldValue;
    } else if (oldValue?.lookupCode && oldValue?.value) {
        return [oldValue?.lookupCode, oldValue?.value];
    } else if (isNumber(oldValue) || isString(oldValue)) {
        newValue = oldValue;
    } else {
        return [newKey, undefined];
    }

    return [newKey, newValue];
};
/**
 * Избавляется от объектов в данных,
 * и вместо них подставляет их id,
 * но с изменённым ключом
 */
export const convertParamsForRequest = (data: Record<string, any>) => Object.keys(data)
    // повышает приоритет id объекта
    .sort((a: string, b: string) => {
        if (typeof data[a] === typeof data[b]) return 0;
        if (typeof data[a] === 'object') return 1;
        return -1;
    })
    .reduce((acc: Record<string, any>, currentKey: string) => {
        let value = data[currentKey];
        let key = currentKey;
        const additionalParamKey: string = data[currentKey]?.dependentInputKey;
        const additionalParamValue: string = additionalParamKey && 'N';
        const isReferenceFieldWithArray = isArray(value) && isObject(value[0]) && !isBlob(value[0]);
        const isReferenceFieldWithObject = isObject(value) && !isArray(value) && !isBlob(value);
        const isReferenceField = isReferenceFieldWithArray || isReferenceFieldWithObject;
        if (isReferenceField) {
            [key, value] = createRecordWithIdForFieldReference(key, data[key]);
        }
        return {...acc, [key]: value, [additionalParamKey]: additionalParamValue};
    }, {});

type ObjectToConvert<T> = {[index: string]: T};
export const convertMapToArray = <T=any>(data: ObjectToConvert<T> | undefined) => {
    try {
        if (!data) return data;
        return Object.keys(data)
            .map(key => {
                const innerData = get(data, key);
                return {
                    keyName: key, // зарезервированный параметр, в котором значением является ключ
                    ...innerData,
                };
            }) as Array<{keyName: string} & T>;
    } catch {
        return data;
    }
};

export const convertArrayToMap = <T=any>(data: T) => {
    if (isArray(data)) {
        return data?.reduce((obj, item) => {
            const itemToConvert = {...item};
            const key = isObject(itemToConvert.keyName) ? get(itemToConvert.keyName, 'value') : itemToConvert.keyName;
            delete itemToConvert.keyName;
            obj[key] = itemToConvert;
            return obj;
        }, {}) as {[index: string]: T};
    }
    return data;
};

/**
 * Преобразование данных формы к общему виду
 * для запроса на создание или изменение
 * @param listData
 * @param fieldsMeta
 * @param linkField
 * @param formListKeys // ключи подформ FORM_LIST главной формы TABS, у них своя нормализация на основе пожеланий бэка
 * @param isJsonRequest
 */
// TODO весь метод - костыль для сохранения данных с ReportConfigurationForm
export const normalizeFormDataForConfigurationForm = (
    listData: Array<Record<string, any> | undefined> | Record<string, any>,
    fieldsMeta: FieldMeta[],
    linkField?: string,
    formListKeys?: string[],
    isJsonRequest?: boolean,
) => {
    const data = Array.isArray(listData)
        ? concatObjectFromArray(compact(listData))
        : listData;
    const metaFieldKeyMap: Record<string, FieldMeta> = getMapOfValues(fieldsMeta, 'key');
    // Оставляем только поля, которые есть в метаданных
    const fieldKeys: string[] = Object.keys(metaFieldKeyMap);
    const keyForId = linkField || 'id';
    const addRequestKeys = formListKeys ?? [];
    const valueKeysForRequest = [keyForId, ...fieldKeys, ...addRequestKeys];
    const falseValues = ['null', 'undefined', ''];
    const isEmptyValue = (value: any) => falseValues.includes(String(value));

    return valueKeysForRequest
        .reduce((acc: Record<string, any>, currentKey: string) => {
            const metaField: FieldMeta = metaFieldKeyMap[currentKey];
            let value = data[currentKey];
            if (formListKeys?.includes(currentKey) && isArray(value)) {
                const updatedValue = value.map(a => {
                    const keyNameProp = get(a, 'keyName');
                    let keyNamePropVal;
                    if (isArray(keyNameProp)) {
                        [keyNamePropVal] = keyNameProp;
                    }
                    const pulledValue = isObject(keyNamePropVal) ? get(keyNamePropVal, 'value') : a.keyName;
                    return ({
                        ...a,
                        keyName: pulledValue,
                    });
                });
                value = convertArrayToMap(updatedValue);
            }
            let key = currentKey;
            let additionalFields = {};

            if (typeof value === 'string') {
                value = value.trim();
            }

            if (metaField?.type === FieldType.REFERENCE
                || metaField?.type === FieldType.CUSTOM_SELECT
                || key === 'keyName') {
                [key, value] = createRecordWithValueForFieldReference(key, data[key]);
            }

            // Для корректного сохранения файла на бэке
            if (metaField?.type === FieldType.FILE) {
                const fileKey = key;
                const dependentsFieldKey = metaField?.dependentsFieldKey || '';
                // Нужно, так как путь отправляется всегда,
                // а файл при редактировании не приходит.
                const neededAdditionalField = !!data[dependentsFieldKey];

                if (neededAdditionalField) {
                    additionalFields = {
                        [dependentsFieldKey]: data[dependentsFieldKey],
                    };
                }
                key = fileKey;
                value = data[fileKey];
            }

            if (metaField?.type === FieldType.FILE_LIST) {
                value = data[key]?.map((file: Record<string, any>) => (file?.file), []);
            }

            if (metaField?.type === FieldType.FILE_LIST_WITH_SIGNS) {
                const signsKey = 'signs';

                const signs: Blob[] = [];
                const files: Blob[] = [];

                data[key]?.forEach((file: Record<string, any>) => {
                    files.push(file?.file);
                    signs.push(file?.sign || null);
                }, []);

                additionalFields = {
                    [signsKey]: signs,
                };

                value = files;
            }
            if (metaField?.type === FieldType.COMBINED_FIELD_WITH_DELETE) {
                const keys = Array.isArray(value) && Object.keys(value[0]);
                const combineValue = Array.isArray(value) && value.map(object => {
                    const newObject: Record<string, any> = {};
                    Object.entries(object).forEach(([key_, value_]) => {
                        if (keys && keys.includes(key_)) {
                            newObject[key_.toString()] = value_;
                        }
                    });
                    return newObject;
                });
                value = JSON.stringify(combineValue);
            }

            if (metaField?.type === FieldType.COMBINED_FIELD || metaField?.type === FieldType.FIELD_BLOCK) {
                const fieldsValue = {} as Record<string, any>;
                metaField.children?.forEach(el => {
                    if (el?.type === FieldType.REFERENCE) {
                        [key, value] = createRecordWithIdForFieldReference(el.key, data[el.key], isJsonRequest);
                        fieldsValue[key] = value;
                    } else {
                        fieldsValue[el.key] = data[el.key];
                    }
                });

                return {
                    ...acc,
                    ...additionalFields,
                    ...fieldsValue,
                };
            }

            if (isEmptyValue(value)) return acc;

            return {
                ...acc,
                ...additionalFields,
                [key]: value,
            };
        }, {});
};

/**
 * Преобразование данных формы к общему виду
 * для запроса на создание или изменение
 * @param listData
 * @param fieldsMeta
 * @param linkField
 * @param isJsonRequest
 */
// TODO normalizeFormDataForRequest не поддерживает работу с FieldType.LIST
export const normalizeFormDataForRequest = (
    listData: Array<Record<string, any> | undefined> | Record<string, any>,
    fieldsMeta: FieldMeta[],
    linkField?: string,
    isJsonRequest?: boolean,
) => {
    let shouldUsedFileIndexName: boolean | undefined;
    const data = Array.isArray(listData)
        ? concatObjectFromArray(compact(listData))
        : listData;
    const metaFieldKeyMap: Record<string, FieldMeta> = getMapOfValues(fieldsMeta, 'key');
    // Оставляем только поля, которые есть в метаданных
    const fieldKeys: string[] = Object.keys(metaFieldKeyMap);
    // Информационные поля для бэка
    const whoFieldsKeys = ['createdById', 'createdDate', 'updatedById', 'updatedDate'];
    // todo: баг по 7909, исправить в 8587
    // const contextKeys = ['organizationId', 'taxPeriodId', 'entityName', 'taxTypeId', 'userId'];
    const isWhoFieldsKey = (key: string) => whoFieldsKeys.includes(key);
    const keyForId = linkField || 'id';
    const valueKeysForRequest = [keyForId, ...fieldKeys, ...whoFieldsKeys];
    const falseValues = ['null', 'undefined', ''];
    const isEmptyValue = (value: any) => falseValues.includes(String(value));
    return valueKeysForRequest
        .reduce((acc: Record<string, any>, currentKey: string) => {
            const metaField: FieldMeta = metaFieldKeyMap[currentKey];
            let value = data[currentKey];
            let key = currentKey;
            let additionalFields = {};

            if (typeof value === 'string') {
                value = value.trim();
            }

            // Заглушка для справочника Правила обезличивания назначения платежа
            if (metaField?.type === FieldType.DEPENDENTS) {
                data.anywaySaveAccount = 'N';
            }

            if (isWhoFieldsKey(key) && !value) return acc;

            if (metaField?.type === FieldType.REFERENCE) {
                [key, value] = createRecordWithIdForFieldReference(
                    key,
                    data[key],
                    isJsonRequest,
                    metaField,
                    metaField?.onNotCreateId,
                );
            }

            // Для корректного сохранения файла на бэке
            if (metaField?.type === FieldType.FILE) {
                const fileKey = key;
                const dependentsFieldKey = metaField?.dependentsFieldKey || '';
                // Нужно, так как путь отправляется всегда,
                // а файл при редактировании не приходит.
                const neededAdditionalField = !!data[dependentsFieldKey];

                if (neededAdditionalField) {
                    additionalFields = {
                        [dependentsFieldKey]: data[dependentsFieldKey],
                    };
                }
                key = fileKey;
                value = data[fileKey];
            }

            if (metaField?.type === FieldType.FILE_LIST) {
                shouldUsedFileIndexName = true;
                value = data[key]?.map((file: Record<string, any>) => (file?.file), []);
            }

            if (metaField?.type === FieldType.FILE_LIST_WITH_SIGNS) {
                const signs: (Blob | null)[] = [];
                const files: (Blob | null)[] = [];
                const attachmentList: number[] = [];
                data[key]?.forEach((file: Record<string, any>) => {
                    if (file?.removedFile) {
                        files.push(null);
                        signs.push(null);
                        attachmentList.push(file.attachmentId || file?.id);
                        return;
                    }
                    if (file?.signRemovedFile) {
                        files.push(new Blob([], {type: 'text/plain'}));
                        signs.push(null);
                        attachmentList.push(file?.attachmentId || file?.id);
                        return;
                    }
                    if (file?.attachmentId || file?.id) {
                        files.push(new Blob([], {type: 'text/plain'}));
                        signs.push(file?.sign || new Blob([], {type: 'text/plain'}));
                        attachmentList.push(file?.attachmentId || file?.id);
                        return;
                    }
                    files.push(file?.file);
                    signs.push(file?.sign || new Blob([], {type: 'text/plain'}));
                }, []);
                if (signs?.length && !isArrayNull(signs)) {
                    additionalFields = {
                        signs,
                    };
                }
                if (attachmentList?.length) {
                    additionalFields = {
                        ...additionalFields,
                        attachIds: attachmentList,
                    };
                }
                return {
                    ...acc,
                    ...additionalFields,
                    shouldUsedFileIndexName: true,
                    [key]: !isArrayNull(files) ? files : undefined,
                };
            }
            if (metaField?.type === FieldType.COMBINED_FIELD_WITH_DELETE) {
                const keys: Array<string> = [];
                metaField?.children?.forEach(child => keys.push(child.key));
                const combineValue = Array.isArray(value) && value.map(object => {
                    const newObject: Record<string, any> = {};
                    Object.entries(object).forEach(([key_, value_]) => {
                        if (keys && keys.includes(key_)) {
                            newObject[key_.toString()] = value_;
                        }
                    });
                    return newObject;
                });
                value = isJsonRequest ? combineValue : JSON.stringify(combineValue);
            }

            if (metaField?.type === FieldType.COMBINED_FIELD || metaField?.type === FieldType.FIELD_BLOCK) {
                const fieldsValue = {} as Record<string, any>;
                metaField.children?.forEach(el => {
                    if (el?.type === FieldType.REFERENCE) {
                        [key, value] = createRecordWithIdForFieldReference(el.key, data[el.key],
                            isJsonRequest, metaField);
                        fieldsValue[key] = value;
                    } else {
                        fieldsValue[el.key] = data[el.key];
                    }
                });
                return {
                    ...acc,
                    ...additionalFields,
                    ...fieldsValue,
                };
            }

            if (metaField?.type === FieldType.DATE) {
                if (metaField.requestDateFormat && value) {
                    value = moment(value, metaField.requestDateFormat).isValid()
                        ? moment(value, metaField.requestDateFormat).format(metaField.requestDateFormat)
                        : moment(value).format(metaField.requestDateFormat);
                }
            }

            if (isEmptyValue(value)) return acc;

            return {
                ...acc,
                ...additionalFields,
                shouldUsedFileIndexName: shouldUsedFileIndexName || undefined,
                [key]: value,
            };
        }, {});
};

/**
 * Добавляет информацию о создании и изменении
 * @param data
 * @param userId
 * @param linkField
 * @param discardDateAndUserInfo
 * @param requestDateFormat
 * @param additionalData
 */
export const addAdditionalInformationForData = (
    data: Record<string, any>,
    userId: string | number,
    linkField?: string,
    discardDateAndUserInfo?: boolean,
    additionalData?: Entity,
    requestDateFormat?: string,
) => {
    const keyForId = linkField || 'id';
    const id = data[keyForId] || -1;
    const isCreate = id === -1;
    const isUpdate = !isCreate;
    const defaultWhoFieldDate = moment().format(requestDateFormat || DATE_FOR_REQUEST);
    const whoFields = discardDateAndUserInfo
        ? null
        : {
            createdById: data.createdById || userId,
            createdDate: data.createdDate || defaultWhoFieldDate,
            updatedById: isUpdate || data.updatedById
                ? userId
                : data.updatedById,
            updatedDate: isUpdate || data.updatedDate
                ? defaultWhoFieldDate
                : data.updatedDate,
        };
    return {
        ...data,
        ...whoFields,
        ...additionalData,
        [keyForId]: id,
    };
};

export const getDeleteInfoData = (
    listData: Array<Record<string, any> | undefined> | Record<string, any>,
    fieldsMeta: FieldMeta[],
    linkField: string,
): IDeleteFormValue => {
    const data = Array.isArray(listData)
        ? concatObjectFromArray(compact(listData))
        : listData;
    const keyForId = linkField || 'id';
    const metaFieldKeyMap: Record<string, FieldMeta> = getMapOfValues(fieldsMeta, 'key');
    const fieldKeys: string[] = Object.keys(metaFieldKeyMap);
    let result: IDeleteFormValue = {
        isDelete: false,
    };

    fieldKeys.forEach((key: string) => {
        const metaField: FieldMeta = metaFieldKeyMap[key];
        const ids: ReactText[] = [data[keyForId]];
        if (metaField?.type === FieldType.BOOLEAN_DELETE) {
            result = {
                isDelete: data[key] === 'Y',
                referenceUrl: metaField?.referenceUrl,
                ids: {ids},
            };
        }
    });
    return result;
};

export const createUrlAndMethod = (
    url: string,
    method: RequestType = RequestType.POST,
    id: EntityValue,
    urlParamKey?: EntityValue,
) => {
    if (id !== -1 && method === RequestType.POST) {
        return [`${url}/${urlParamKey || id}`, RequestType.PUT];
    }
    if (id === -1 && method === RequestType.PUT) {
        return [`${url}`, RequestType.PUT];
    }
    if (id === -1 && method === RequestType.GET) {
        return [`${url}`, RequestType.GET];
    }
    return [urlParamKey ? `${url}/${urlParamKey}` : url, method];
};

export const generateLink = (
    referenceUrl: string,
) => `${DEFAULT_REQUEST_CONFIG.baseURL}/${referenceUrl}`;

export const getFilenameFromResponse = (xhr: AxiosResponse) => {
    const disposition = xhr.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
    }
    return 'null';
};
