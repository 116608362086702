import {Modal} from 'antd';
import React from 'react';

import {CustomTable, customTableUtils} from 'components/@common/widgets/custom-table';
import {PushWithBreadcrumbsFunction} from 'components/table-report/hooks/use-breadcrumbs-controller';
import {performDrilldownPush} from 'components/table-report/utils';
import {TableReportDrillDown} from 'store/slices/table-report-slice/table-report-slice-types';

import s from './dd-modal.module.less';

interface DrillDownModalProps{
    entries?: TableReportDrillDown[];
    visible: boolean;
    setVisible: (visibility: boolean) => void;
    pushWithBreadcrumbs: PushWithBreadcrumbsFunction;
}

export const DrillDownModal = ({
    entries,
    visible,
    setVisible,
    pushWithBreadcrumbs,
}: DrillDownModalProps) => (
    <Modal
        className={s['dd-modal']}
        title="Для перехода на нужный документ нажмите на название из списка"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={660}
    >
        <CustomTable
            rowKey="docId"
            pagination={false}
            columns={[{
                title: 'Тип документа',
                dataIndex: 'docTypeName',
                key: 'docTypeName',
                render: (_, row) => (
                    <span
                        key={`${row?.drillId}${row?.rowIndex}`}
                        className={s['dd-modal__item']}
                        onClick={() => {
                            performDrilldownPush({
                                ddEntry: row,
                                pushWithBreadcrumbs,
                            });
                            setVisible(false);
                        }}
                    >
                        {row.docTypeName}
                    </span>
                ),
            },
            {
                title: 'Наименование типа документа',
                dataIndex: 'docTypeDescription',
                key: 'docTypeDescription',
                render: (_, row) => (row.docTypeDescription ?? row.templateCode),
            }]}
            dataSource={entries}
            onColumn={col => ({
                ...customTableUtils.setHeaderStyles({
                    whiteSpace: 'pre',
                    textAlign: 'start',
                }),
                ...col,
            })}
        />
    </Modal>
);
