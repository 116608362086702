import {Card} from 'antd';
import {Key, TablePaginationConfig} from 'antd/lib/table/interface';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import {initializeMetadataAndData, performActionForTableState} from 'modules/data/data-actions';
import {selectFilterEntityData, selectTableEntityData} from 'modules/data/data-selectors';
import {DefaultTableState, IComparison, TableEntityData} from 'modules/data/data-types';
import {loadMetadata as loadMetadataAction} from 'modules/metadata';
import {selectTableEntityMetadata} from 'modules/metadata/metadata-selectors';
import {TableEntityMeta} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {Spinner} from '../spinner';
import {TableAction} from '../table/action';
import {convertObjectToTableStateFlat} from '../table/form-table/form-table.utils';
import {Table} from '../table/table';
import {normalizeComparisonData} from './document-comparison-utils';

interface TableContainerProps {
    entityName: string;
}

export const DocumentComparison: React.FunctionComponent<TableContainerProps> = ({
    entityName,
}: TableContainerProps) => {
    const dispatch = useDispatch();
    const [newMetaData, setNewMetaData] = React.useState<TableEntityMeta>();
    const [newTableData, setNewTableData] = React.useState<TableEntityData>();
    const [sheet, setSheet] = useState<string[]>([]);
    const [addition, setAddition] = useState<string[]>([]);

    const tableData = useAppSelector(selectTableEntityData(entityName));
    const metaData = useAppSelector(selectTableEntityMetadata(entityName));
    const filter = useAppSelector(selectFilterEntityData(entityName));

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: any,
    ) => {
        dispatch(
            performActionForTableState({
                entityName,
                newEntityTypeData: convertObjectToTableStateFlat({
                    pagination,
                    sorter,
                } as DefaultTableState) as Record<string, any>,
            }),
        );
    };

    React.useEffect(() => {
        const shouldLoadMetadata = !metaData;
        const shouldFilterData = !filter?.data;
        const shouldLoadData = tableData?.ids && !tableData?.rows;
        const shouldLoadFilteredData = shouldLoadData && metaData?.isFilterable && filter?.data;
        const shouldCreateMeta = tableData?.rows && tableData?.ids;
        const tableDataOptions = {
            useContext: !!metaData?.useContext,
            useSearch: !!metaData?.isSearchable,
            disabledPagination: metaData?.disabledPagination,
        };

        if (shouldLoadMetadata) {
            dispatch(loadMetadataAction(entityName, EntityType.TABLE));
        } else if (shouldFilterData) {
            dispatch(initializeMetadataAndData(EntityType.FILTER)(entityName));
        } else if (shouldLoadFilteredData) {
            dispatch(
                performActionForTableState({
                    entityName,
                    newEntityTypeData: {
                        ...tableDataOptions,
                        docIds: tableData?.ids,
                    },
                    url: entityName,
                }),
            );
        } else if (shouldCreateMeta) {
            normalizeComparisonData(
                tableData.rows as unknown as IComparison[],
                metaData,
                setNewMetaData,
                setNewTableData,
                setSheet,
                setAddition,
            );
        }
    }, [entityName, metaData, filter, tableData?.rows]);

    return (
        <Card
            title={metaData?.title}
            className="table-container"
        >
            <div className="table-container">
                {sheet?.length > 0 && (
                    <p>Листы: {sheet.join(', ')}</p>
                )}
            </div>
            <div className="table-container">
                {addition?.length > 0 && (
                    <p>Приложения: {addition.join(', ')}</p>
                )}
            </div>
            {
                newMetaData && newTableData
                    ? (
                        <>
                            <div className="table-container__actions">
                                {metaData?.actions?.map(action => (
                                    <TableAction
                                        meta={action}
                                        key={action.actionTitle}
                                        entityName={entityName}
                                    />
                                ))}
                            </div>

                            <Table
                                metadata={newMetaData}
                                tableData={newTableData}
                                url={entityName}
                                onChange={handleTableChange}
                                loading={false}
                                entityName={entityName}
                            />
                        </>

                    )
                    : <Spinner />
            }
        </Card>
    );
};
