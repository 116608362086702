import {ReportRowLinks} from 'components/table-report/table-report-types';
import {FieldMeta, MetaActionType} from 'modules/metadata';
import {FileDto} from 'shared/types/files';
import {LinkDto} from 'shared/types/links';

export interface RequestSliceState {
    requestData: RequestData | undefined;
    requestStatuses: RequestStatusesInfo | undefined;
    requestFormFields: FieldMeta[] | undefined;
    submitButtonActionType: MetaActionType | undefined;
}
interface AddresseeEntity {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    departmentName?: string;
    displayName?: string;
    middleName?: string;
}

interface CreateByData {
    id: number;
    email: string;
    enabled: boolean;
    name: string;
    position: string;
}

export interface RequestTypesInfo {
    id: string;
    lookupType: string;
    lookupCode: string;
    meaning: string;
    attribute1: string;
    attribute2: string;
    attribute3: string;
    attribute4: string;
    attribute5: string;
}

interface TopicRequestTypeData {
    id: number;
    description: string;
    type?: RequestTypesInfo;
    typeCode?: string;
}

interface CategoryData {
    id: number;
    categoryCode: string;
    categoryName: string;
    createdBy: CreateByData;
    topicRequestTypes: TopicRequestTypeData;
}

interface TopicStatusData {
    id: number;
    lookupType: string;
    lookupCode: string;
    meaning: string;
    description: string;
}
export interface FlexFieldList{
    attributeColumnName: string;
    segmentSeqNum: number;
    segmentColumnName: string;
    segmentUserName: string;
    segmentValue: string;
    global: boolean;
    displayed: boolean;
}

export interface RequestInfo {
    id: string;
    addressees: AddresseeEntity[];
    attachmentList: FileDto[];
    category: CategoryData;
    topicRequestType: TopicRequestTypeData;
    content: string;
    fromUser: string;
    subject: string;
    toUser: string;
    idRequestComment: string;
    requestReplies: any;
    updateDate?: Date;
    topicStatus: TopicStatusData;
    flexFieldList : any[];
    taxTypeId: number;
    requestNum: number;
    numberRequest: string;
    requestType: string;
    requestDate:Date;
    status: TopicStatusData;
    rating?: number;
    ddUrlDto?: LinkDto[];
}

export interface RequestReply {
    idRequestComment: number;
    createDate: string;
    author: string;
    addressees: string;
    content: string;
    attachments: FileDto[];
}

interface AuthorEntity{
    id: number | string;
    name: string;
    email: string;
    creationDate: Date;
    commentType: string;
    attachments: FileDto[];
    addresses: string;
    content: string;
}
interface CommentEntity {
    id: number | string;
    content: string;
    author: AuthorEntity;
    attachments: FileDto[];
    commentAttachments: RequestReply[];
}

export interface RequestResponse {
    id: string | number;
    idRequestComment: string;
    comment: CommentEntity;
    documentDate: Date;
    commentType: string;
    numberRequest: string;
    attachments: FileDto[];
    status: TopicStatusData;
    rating?: number;
    partialExecution?: string;
}
export interface ProlongationRequest {
    id: string | number;
    idRequestComment: string;
    addressees: string;
    author: string;
    createDate: Date;
    content: string;
    prolongationDate:Date;
    prolongationDateResponse: Date;
    attachments: FileDto[];
}

export interface ProlongationResponse extends ProlongationRequest {
    prolongationDateResponse: Date;
}
interface RequestRejection extends RequestResponse{
    reasonsProlongationId: string;
}

export interface TopicReportInfo {
    templateCode: string;
    docId: string;
    reportSectionName: string;
    reportParameters: string;
    reportRowLinks: ReportRowLinks[];
}

export interface RequestData {
    requestReplies: RequestReply[];
    requests: RequestInfo;
    responseReplies: any;
    responses: any;
    requestResponse: RequestResponse;
    prolongationRequest: ProlongationRequest;
    prolongationResponse: ProlongationResponse;
    requestRejection: RequestRejection ;
    enabledLink: boolean;
    topicReportInfo?: TopicReportInfo;
}

interface RequestStatusesDate{
    statuses: RequestStatus[];
}
export interface RequestStatusesInfo {
    [key: string]: RequestStatusesDate;
}

export enum RequestStatus {
    // Common status
    /**
     * ЗАКРЫТЬ ЗАПРОС
     */
    CLOSE_REQUEST = 'CLOSE_REQUEST',
    /**
     * КОММЕНТАРИЙ
     */
    COMMENT_REQUEST = 'COMMENT_REQUEST',
    SEND_MESSAGE = 'SEND_MESSAGE',
    /**
     * КНОПКА УПРАВЛЕНИЯ ЧАТОМ
     */
    BUTTON_CONTROL_COLLAPSE = 'BUTTON_CONTROL_COLLAPSE',
    /**
     *  ВЗЯТЬ В РАБОТУ
     */
    TAKE_TO_WORK_REQUEST = 'TAKE_TO_WORK_REQUEST',
    TAKE_TO_WORK_REQUEST_NOT_DATE = 'TAKE_TO_WORK_REQUEST_NOT_DATE',
    /**
     * НАЗНАЧИТЬ ОТВЕТСТВЕННОГО
     */
    ASSIGN_RESPONSIBLE = 'ASSIGN_RESPONSIBLE',
    /**
     * ОТВЕТ НА ЗАПРОС
     */
    REQUEST_RESPONSE = 'REQUEST_RESPONSE',
    /**
     * ЗАПРОСИТЬ ПРОДЛЕНИЕ
     */
    REQUEST_PROLONGATION = 'REQUEST_PROLONGATION',
    /**
     * СОЗДАТЬ ОТВЕТ
     */
    GENERATE_RESPONSE = 'GENERATE_RESPONSE',
    /**
     * ОТВЕТ НА ЗАПРОС
     */
    REPLY_REQUEST = 'REPLY_REQUEST',
    /**
     * ОТПРАВИТЬ ЗАПРОС НА УТВЕРЖДЕНИЕ
     */
    SEND_FOR_APPROVAL_REQUEST = 'SEND_FOR_APPROVAL_REQUEST',
    /**
     *  ОТПРАВИТЬ ОТВЕТ НА ЗАПРОС
     */
    REQUEST_RESPONSE_SEND = 'REQUEST_RESPONSE_SEND',
    /**
     * Запрос о продлении
     */
    PROLONGATION_REQUEST = 'PROLONGATION_REQUEST',
    /**
     * Ответ на запрос о продлении
     */
    PROLONGATION_RESPONSE = 'PROLONGATION_RESPONSE',
    /**
     * Сформировать отказ
     */
    GENERATE_REJECTION_RESPONSE = 'GENERATE_REJECTION_RESPONSE',
    /**
     * EDIT_RESPONSE
     */
    EDIT_RESPONSE = 'EDIT_RESPONSE',
    /**
     * Удалить
     */
    DELETE_REQUEST = 'DELETE_REQUEST',
    /**
     * APPROVE
     */
    APPROVE_REQUEST = 'APPROVE_REQUEST',
    /**
     * REJECT
     */
    REJECT = 'REJECT',
    REJECT_REQUEST = 'REJECT_REQUEST',
    GENERATE_ADD_PLACEMENT_DOC_RESPONSE = 'GENERATE_ADD_PLACEMENT_DOC_RESPONSE',
    /**
     * RATE
     */
    RATE_REQUEST='RATE_REQUEST',
    FINALLY_CLOSED='FINALLY_CLOSED',

    /**
     * D365
     */
    REQUEST_FROM_D365 = 'REQUEST_FROM_D365',
}
