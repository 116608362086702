import {DocumentToSignInDatabase} from '../model/types';

interface IsComponentClickPreventedArgs {
    files?: File[];
    documentsToSignInDB?: DocumentToSignInDatabase[];
    fileList?: File[];
}

/**
 * Возвращает логическое значение, которое указывает нужно ли предотвращать нажатие на компонент,
 * исходя из наличия файлов, документов в базе данных или списка файлов.
 *
 * @param files Массив объектов `File`, представляющих текущие файлы для обработки (опционально).
 *
 * @param documentsToSignInDB Массив объектов `DocumentToSignInDatabase`, представляющих документы,
 * доступные в базе данных для подписания (опционально).
 *
 * @param fileList Массив объектов `File`, представляющих список загруженных файлов (опционально).
 *
 * @returns Возвращает булево значение:
 * - `true`, если все входные массивы пустые или отсутствуют, то есть нажатие нужно предотвратить.
 * - `false`, если хотя бы один из массивов содержит элементы.
 */
export const isComponentClickPrevented = ({
    files,
    documentsToSignInDB,
    fileList,
}: IsComponentClickPreventedArgs) => !files?.length && !documentsToSignInDB?.length && !fileList?.length;
