import {createAction} from 'store/config/creators';
import {AppState, Dispatch} from 'store/config/types';

import {selectEntityDataFormInstance, selectFormEntityData} from '../data/data-selectors';
import {FormEntityMeta} from '../metadata/metadata-types';
import {WarningActions} from './warning-constants';
import {SetWarningVisibilityPayload} from './warning-types';
import {isFormDirty} from './warning-utils';

export const setWarningVisibility = createAction<SetWarningVisibilityPayload>(WarningActions.SET_WARNING_VISIBILITY);

export const resolveModalDirtiness = (entityName: string, metadata: FormEntityMeta, onClose: () => void) => (
    dispatch: Dispatch, getState: () => AppState,
) => {
    const initialData = selectFormEntityData(entityName)(getState())?.data;
    const form = selectEntityDataFormInstance(entityName)(getState());

    if (form && 'getFieldsValue' in form && isFormDirty(entityName, form.getFieldsValue(), initialData, metadata)) {
        dispatch(setWarningVisibility({entityName, warningVisible: true}));
    } else {
        dispatch(setWarningVisibility({entityName, warningVisible: false}));
        onClose();
    }
};
