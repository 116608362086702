import {Card, Form as AntForm} from 'antd';
import {Key, TablePaginationConfig, TableRowSelection} from 'antd/lib/table/interface';
import classNames from 'classnames';
import React from 'react';
import {useDispatch} from 'react-redux';

import {ConfirmModal} from 'components/confirm-modal/confirm-modal';
import {Buttons} from 'components/form/buttons';
import {Spinner} from 'components/spinner';
import {
    selectEntityData, Entity, loadFormTableData,
} from 'modules/data';
import {performActionForFormTable, performActionForTableState, setData} from 'modules/data/data-actions';
import {
    DefaultTableState,
    DefaultTableStateFlat,
    FilterEntityData,
    TableEntityData,
} from 'modules/data/data-types';
import {loadDocumentTypes as loadDocumentTypesAction} from 'modules/documents';
import {
    selectMetadata,
    loadMetadata as loadMetadataAction,
    MetaActionType,
    RequestType,
} from 'modules/metadata';
import {TableEntityMeta} from 'modules/metadata/metadata-types';
import {getActionByType} from 'modules/metadata/metadata-utils';
import {loadRegions as loadRegionsAction} from 'modules/regions';
import {PageTemplateProps} from 'pages';
import {EntityType} from 'shared/constants/entities';
import {ConfirmContent} from 'shared/constants/form';
import {useAppSelector} from 'store/config/hooks';

import {Table} from '../table';
import {FormsFormTableContext} from './form-table-context';
import './form-table.less';
import {convertObjectToTableStateFlat, normalizeFormTableData} from './form-table.utils';

interface FormTableComponentOwnProps
    extends Pick<PageTemplateProps, 'entityName' | 'url' | 'hideTitle'> {
}

export interface FormTableComponentProps extends FormTableComponentOwnProps {
    entityName: string;
    entityType?: EntityType;
}

export const FormTable: React.FunctionComponent<FormTableComponentProps> = ({
    entityName,
    entityType,
    url,
    hideTitle,
}: FormTableComponentProps) => {
    const dispatch = useDispatch();
    const metadata = useAppSelector(selectMetadata(entityName, EntityType.FORM_TABLE)) as TableEntityMeta;
    const data = useAppSelector(selectEntityData(entityName, EntityType.FORM_TABLE)) as TableEntityData;
    const filter = useAppSelector(selectEntityData(entityName, EntityType.FILTER)) as FilterEntityData;
    const entityClassName = entityName?.split('.').join('-');
    const [isConfirmVisible, setConfirmVisible] = React.useState(false);
    const getConfirmAction = getActionByType(MetaActionType.BUTTON_SAVE_FORM_PERSONAL_ACCOUNT)(metadata?.actions);
    const actionsClassNames = classNames(
        {
            'table-container__actions': true,
            [String(entityClassName)]: entityName,
        },
    );
    const fetchData = () => {
        const shouldLoadFilteredData = metadata?.isFilterable && filter?.data;
        const shouldLoadAllData = !metadata?.isFilterable;
        const tableDataOptions = {
            useContext: !!metadata?.useContext,
            useSearch: !!metadata?.isSearchable,
            disabledPagination: metadata?.disabledPagination,
            filterParamKey: metadata?.filterParamKey,
        };

        if (shouldLoadFilteredData) {
            dispatch(loadFormTableData(entityName, {...tableDataOptions, paramsToBeConverted: filter?.data}, url));
        } else if (shouldLoadAllData) {
            dispatch(loadFormTableData(entityName, {...tableDataOptions}, url));
        }
    };

    React.useEffect(() => {
        const shouldLoadMetadata = !metadata;
        const isEmptyData = !data?.rows;

        if (shouldLoadMetadata) {
            dispatch(loadMetadataAction(entityName, EntityType.FORM_TABLE));
        }
        if (!shouldLoadMetadata && isEmptyData) {
            fetchData();
        }
    }, [data, entityName, metadata, filter]);

    React.useEffect(() => {
        if (metadata && metadata?.useContext) {
            dispatch(loadRegionsAction());
            dispatch(loadDocumentTypesAction());
        }
    }, [metadata?.useContext]);

    const setSelectedRows = (
        selectedRowKeys: React.Key[],
        selectedRows: Entity[],
    ) => dispatch(setData({
        entityName,
        entityType: EntityType.FORM_TABLE,
        data: {

            selectedRowKeys,
            selectedRows,
        },
    }));
    const actionForFormTable = (
        referenceUrl: string,
        requestType: RequestType,
        newData: Record<string, any>,
    ) => dispatch(performActionForFormTable(
        entityName, referenceUrl, requestType, newData,
    ));

    const changeTableState = (
        newData: DefaultTableStateFlat,
    ) => dispatch(performActionForTableState({
        entityName,
        newEntityTypeData: newData as Record<string, any>,
        url,
    }));

    const rowSelection: TableRowSelection<Record<string, Entity[]>> = {
        selectedRowKeys: data?.selectedRowKeys,
        onChange: setSelectedRows,
    };

    const isLoadedTable = metadata?.fields && data?.rows;
    const cardTitle = !hideTitle && metadata?.title;
    const isFormTable = true;
    const [form] = AntForm.useForm();

    const handleClose = () => {
        form.resetFields();
    };

    const handleOpenConfirmModal = () => {
        if (form.isFieldsTouched()) {
            setConfirmVisible(true);
        }
    };
    const handleFinish = (values: any) => {
        const saveAction = getActionByType(MetaActionType.BUTTON_SAVE_FORM)(metadata.actions)
            || getActionByType(MetaActionType.BUTTON_SAVE_FORM_PERSONAL_ACCOUNT)(metadata.actions);

        if (!saveAction) throw new Error('В метаданных не описано действие для сохранения');

        const isEmptyData = (value: any) => !value || !Object.keys(value).length;

        const {requestType, referenceUrl, additionalInfoKeys} = saveAction || {};

        const additionalValues: Record<string, any> = {};
        additionalInfoKeys?.forEach((key: string) => {
            additionalValues[key] = String(data.rows?.find(value => value[key])?.[key]);
        });

        if (requestType && referenceUrl && metadata.name && !isEmptyData(values)) {
            actionForFormTable(
                referenceUrl,
                requestType,
                normalizeFormTableData(values, additionalValues, metadata.linkField),
            );
        }
    };

    const handleFinishFailed = () => {
        console.error('Форма не прошла проверку');
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: any,
    ) => {
        if (changeTableState) {
            changeTableState(
                convertObjectToTableStateFlat({pagination, sorter} as DefaultTableState),
            );
        }
    };
    const handleFinishConfirm = () => {
        setConfirmVisible(false);
        handleFinish(form.getFieldsValue());
    };

    const handleCancelConfirm = () => {
        setConfirmVisible(false);
        form.resetFields();
    };

    const confirmModal = (
        <ConfirmModal
            title="Подтверждение"
            visible={isConfirmVisible}
            content={(getConfirmAction && ConfirmContent?.[getConfirmAction?.actionType])}
            onCancel={handleCancelConfirm}
            cancelText="Нет"
            onConfirm={handleFinishConfirm}
            approveText="Да"
            useWrapperDivElementAsContainer
        />
    );

    return (
        <Card
            title={cardTitle}
        >
            {confirmModal}
            <FormsFormTableContext.Provider
                value={{
                    form,
                }}
            >
                <AntForm
                    layout="vertical"
                    form={form}
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                >
                    <div className={actionsClassNames}>
                        <Buttons
                            actions={metadata?.actions}
                            onClose={handleClose}
                            form={form}
                            handleOpenConfirmModal={handleOpenConfirmModal}
                        />
                    </div>
                    {isLoadedTable ? (
                        <Table
                            metadata={metadata as TableEntityMeta}
                            isFormTable={isFormTable}
                            tableData={data}
                            url={url}
                            rowSelection={rowSelection}
                            onChange={handleTableChange}
                            entityName={entityName}
                            entityType={entityType}
                        />
                    ) : <Spinner />}
                </AntForm>
            </FormsFormTableContext.Provider>
        </Card>
    );
};
