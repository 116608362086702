import {AuthProfile} from 'store/slices/auth-slice/auth-slice-types';

export const getButtonNameByProfile = (profile: AuthProfile) => {
    const defaultPrefix = 'Войти через';

    switch (profile) {
    case AuthProfile.SAML: return `${defaultPrefix} ${profile.toUpperCase()}`;
    case AuthProfile.KERBEROS: return 'Вход (сотрудники компании)';
    default: return `${defaultPrefix} ${profile}`;
    }
};

interface CreateRequiredValidatorArgs {
    onResolve: () => void;
    onReject: () => void;
}
export const createRequiredValidator = ({onResolve, onReject}: CreateRequiredValidatorArgs) => (() => ({
    validator(_: any, value: string) {
        if (!value) {
            onReject();
            return Promise.reject();
        }
        onResolve();
        return Promise.resolve();
    },
}));
