import {getSystemInfo} from 'crypto-pro';

import {getUserCertificatesExtended} from './get-user-certificates-extended';

/**
 * Асинхронная функция используется для получения
 * информации о CSP и плагине `crypto-pro`
 * и расширенной информации о пользовательских сертификатах с использованием `crypto-pro`.
 *
 * @returns Объект, содержащий следующие данные:
 * - `certificatesExtended` — массив с расширенной информацией о пользовательских сертификатах,
 * полученной с помощью функции `getUserCertificatesExtended`.
 * - `systemInfo` — информация о CSP и плагине `crypto-pro`
 * полученная с помощью функции `getSystemInfo` из библиотеки `crypto-pro`.
 *
 * Функция выполняет следующие шаги:
 * 1. Асинхронно вызывает `getUserCertificatesExtended`
 * для получения расширенной информации о сертификатах пользователя.
 * 2. Асинхронно вызывает `getSystemInfo` для получения системной информации.
 * 3. Возвращает объект с объединённой информацией.
 */
export const getCryptoProData = async () => {
    const certificatesExtended = await getUserCertificatesExtended();
    const systemInfo = await getSystemInfo();

    return {
        certificatesExtended,
        systemInfo,
    };
};
