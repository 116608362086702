import React, {ReactText} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {selectEntityData} from 'modules/data';
import {createActionGetFile, createActionHandlerDownloadFile} from 'modules/data/data-actions';
import {TableEntityData} from 'modules/data/data-types';
import {MetaActionType, RequestType, selectMetadata} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';

const ButtonActionForDownloadFileRowsComponent: React.FunctionComponent<TableActionProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: TableActionProps) => {
    const referenceUrl = meta?.referenceUrl || entityName;
    const {requestType} = meta;
    const {urlConfig} = useAppSelector(selectMetadata(entityName, EntityType.TABLE)) || {};
    const tableData = useSelector((state: AppState) => (
        selectEntityData(entityName, EntityType.TABLE)(state) || {}) as TableEntityData);
    const {selectedRowKeys = []} = tableData || {};
    const dispatch = useDispatch();
    const actionsForRows = (ids: ReactText[]) => {
        if (meta.actionType === MetaActionType.DOWNLOAD_TABLE_ROWS) {
            const newUrl = `${referenceUrl}?${selectedRowKeys.map(id => `ids=${id}`).join('&')}`;
            if (requestType && requestType === RequestType.GET) {
                dispatch(createActionGetFile({referenceUrl: newUrl, requestType}));
                return;
            }
            dispatch(
                createActionHandlerDownloadFile(
                    entityName,
                    referenceUrl,
                    RequestType.POST,
                    {ids},
                    meta?.shouldIgnoreUrlConfig ? undefined : urlConfig,
                ),
            );
        }
        /* else if (meta.actionType === MetaActionType.DOWNLOAD_MULTIPLE_TABLE_ROWS) {
            if (requestType && requestType === RequestType.GET) {
                selectedRows.forEach(row => {
                    if (row?.attachmentIds && Array.isArray(row?.attachmentIds)) {
                        row?.attachmentIds.forEach(id => {
                            dispatch(createActionGetFile({
                                referenceUrl: `${referenceUrl}/${id}`,
                                requestType,
                                urlConfig: meta?.shouldIgnoreUrlConfig ? undefined : urlConfig,
                            }));
                        });
                    }
                });
            }
        } */
    };
    const handleClick = () => {
        actionsForRows(selectedRowKeys);
    };
    const Component = wrapper || ActionButton;

    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
            disabled={!selectedRowKeys.length}
        >
            {meta.actionTitle}
        </Component>
    );
};

export const ButtonActionForDownloadFileRows = ButtonActionForDownloadFileRowsComponent;
