import {Button, Input} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import {FormListFieldData} from 'antd/es/form/FormList';
import React from 'react';

import {DirectoriesCompanyFormCard} from 'components/directories-company-form/directories-company-form-card/directories-company-form-card';
import {DynamicIcon} from 'components/dynamic-icon';
import {CustomSelect} from 'components/form/inputs/custom-select';

import './ais-signatories-item.less';

interface AisSignatoriesItemProps {
    field: FormListFieldData;
    onRemove: (index: number | number[]) => void;
    isDisabled: boolean;
}

export const AisSignatoriesItem = ({field, onRemove, isDisabled}: AisSignatoriesItemProps) => (
    <DirectoriesCompanyFormCard
        key={field.fieldKey}
        className="ais-signatories-item"
        title="Подписант"
    >
        <FormItem
            name={[field.name, 'sign']}
            label="Признак"
            rules={[{required: true}]}
        >
            <CustomSelect
                settings={{
                    placeholder: 'Выберите признак',
                    url: 'ais/valueLists/AIS_SIGNATORY_STATUS',
                    valuePath: 'lookupCode',
                    labelPath: 'description',
                    isDisabled,
                }}
            />
        </FormItem>
        <FormItem
            name={[field.name, 'lastName']}
            label="Фамилия"
            rules={[
                {required: true},
                {
                    pattern: /^[а-яА-ЯёЁVI]+[- ]?[а-яА-ЯёЁVI]+$/,
                    message: 'Данное поле должно содержать только буквы, одно тире или пробел',
                },
                {
                    pattern: /^.{1,60}$/,
                    message: 'Количество знаков в данном поле должно быть не более 60',
                },
            ]}
        >
            <Input
                placeholder="Введите фамилию"
                disabled={isDisabled}
            />
        </FormItem>
        <FormItem
            name={[field.name, 'firstName']}
            label="Имя"
            rules={[
                {required: true},
                {
                    pattern: /^[а-яА-ЯёЁVI]+[- ]?[а-яА-ЯёЁVI]+$/,
                    message: 'Данное поле должно содержать только буквы, одно тире или пробел',
                },
                {
                    pattern: /^.{1,60}$/,
                    message: 'Количество знаков в данном поле должно быть не более 60',
                },
            ]}
        >
            <Input
                placeholder="Введите имя"
                disabled={isDisabled}
            />
        </FormItem>
        <FormItem
            name={[field.name, 'middleName']}
            label="Отчество"
            rules={[
                {required: true},
                {
                    pattern: /^[а-яА-ЯёЁVI]+[- ]?[а-яА-ЯёЁVI]+$/,
                    message: 'Данное поле должно содержать только буквы, одно тире или пробел',
                },
                {
                    pattern: /^.{1,60}$/,
                    message: 'Количество знаков в данном поле должно быть не более 60',
                },
            ]}
        >
            <Input
                placeholder="Введите отчество"
                disabled={isDisabled}
            />
        </FormItem>
        <FormItem
            name={[field.name, 'jobTitle']}
            label="Должность"
            rules={[
                {required: true},
                {
                    pattern: /^.{1,255}$/,
                    message: 'Количество знаков в данном поле должно быть не более 255',
                },
            ]}
        >
            <Input
                placeholder="Введите должность"
                disabled={isDisabled}
            />
        </FormItem>
        <FormItem
            name={[field.name, 'docName']}
            label="Наименование и реквизиты документа, подтверждающего полномочия представителя организации"
            rules={[
                {
                    pattern: /^.{1,120}$/,
                    message: 'Количество знаков в данном поле должно быть не более 120',
                },
            ]}
        >
            <Input
                placeholder="Введите наименование"
                disabled={isDisabled}
            />
        </FormItem>

        <div className="ais-signatories-item__actions">
            <Button
                className="button-critic"
                onClick={() => onRemove(field.name)}
                disabled={isDisabled}
            >
                <DynamicIcon type="TrashXOutlined" />
            </Button>
        </div>
    </DirectoriesCompanyFormCard>
);
