import {CryptoProSignedDoc} from '../model/types';

/**
 * Возвращает список идентификаторов документов,
 * которые нужно подписать, в зависимости от параметра переотправки `isWithResign`.
 *
 * @param isWithResign Логическое значение,
 * указывающее, нужно ли подписывать все документы, независимо от их текущего статуса.
 * - Если `true`, возвращаются все переданные идентификаторы документов (`documentIds`).
 * - Если `false`, из списка исключаются уже подписанные документы (`signedDocs`).
 *
 * @param documentIds Массив строковых идентификаторов документов, которые необходимо обработать.
 *
 * @param signedDocs Массив объектов типа `CryptoProSignedDoc`, представляющий уже подписанные документы.
 * Каждый объект содержит:
 * - `docId` — идентификатор подписанного документа.
 *
 * @returns Массив строковых идентификаторов документов:
 * - Если `isWithResign = true`, возвращается исходный массив `documentIds`.
 * - Если `isWithResign = false`, возвращается массив, исключающий документы, присутствующие в `signedDocs`.
 */
export const getDocumentsIdsToSign = (
    isWithResign: boolean,
    documentIds: string[],
    signedDocs: CryptoProSignedDoc[],
) => (isWithResign
    ? documentIds
    : documentIds.filter(id => !signedDocs.some(doc => doc.docId === id)));
