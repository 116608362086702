import {Select} from 'antd';
import {FormInstance} from 'antd/es/form';
import {SelectProps} from 'antd/lib/select';
import {PropertyPath, groupBy, set} from 'lodash';
import React, {
    CSSProperties, ReactElement,
    useEffect, useImperativeHandle, useMemo, useRef,
} from 'react';

import {FormItemProps} from 'shared/types';
import {getPopupContainer, setFieldsValueUntouched} from 'shared/utils';

import {TagRenderer} from '../reference-select/tag-renderer';
import {CustomSelectSpinner} from './components/custom-select-spinner';
import {SelectSuffixIcon} from './custom-select-suffix';
import {createFieldFormatterForSelect} from './custom-select-utils';
import {useCustomSelectConfig} from './hooks/use-custom-select-config';
import {useCustomSelectReferenceOptionsFetcher} from './hooks/use-custom-select-reference-options-fetcher';

import './custom-select.less';

export interface CustomSelectEntry<L, V> {/*  */
    label: L;
    value: V;
}

export enum CustomSelectMode {
    multiple = 'multiple',
}

interface CustomSelectSettings {
    formInstance?: FormInstance;
    formFieldKey?: React.Key;
    formFieldPathName?: PropertyPath;

    isDisabled?: boolean;
    isLoading?: boolean;
    isClearable?: boolean;

    showNoValueWhenNoOptions?: boolean;

    referenceUrlQueryParams?: { [index: string]: string };

    url?: string;
    placeholder?: string;
    labelPath?: string;
    valuePath?: string;

    useFirstOptionAfterReceivedFromFetcher?: boolean;
    useFirstOptionAfterEntriesChanged?: boolean;
    noUseFirstOptionWhenValueIsSet?: boolean;
    setFirstOptionUntouched?: boolean; /* Если true, то форма НЕ станет touched (form.isFieldsTouched())
        после автоматической установки (useFirstOptionAfterReceivedFromFetcher) первого значения в селекте.
        Для работы нужно передать formInstance и formFieldKey.
        Если используется в Form.List, то нужно дополнительно передать formFieldPathName.
    */

    depsToCallCustomTrigger?: any[];

    showSearch?: boolean;

    isReferenceNonStorable?: boolean;

    triggerOnValueSet?: boolean;
    allowTriggerWhenNoValueFound?: boolean; /* Разрешить триггеру срабатывать, если объект по ключу-значению в
    списке options (entries) не найден.
    Используется, если нужно передать остальные параметры (entries и т.д.), но value не определён. */

    showNullValueAsUndefined?: boolean;
    showEmptyStringAsUndefined?: boolean;
    setNullInsteadOfUndefinedOnChange?: boolean;

    mode?: CustomSelectMode;
    size?: SelectProps<any>['size'];
}

export enum CustomSelectValueTriggerEvent {
    valueWasSet = 'valueWasSet',
    valueHasBeenChangedManually = 'valueHasBeenChangedManually',
    firstOptionWasSet = 'firstOptionWasSet',
    receivedDataFromFetcher = 'receivedDataFromFetcher',
    customTrigger = 'customTrigger',
}

interface OptionsFilterCallbackArgs<L, V> {
    entry: CustomSelectEntry<L, V>;
    entriesToRender: CustomSelectEntry<L, V>[];
}

export interface CustomSelectProps<L, V> extends FormItemProps {
    entries?: CustomSelectEntry<L, V>[];
    additionalEntries?: CustomSelectEntry<L, V>[];
    settings?: CustomSelectSettings;
    style?: CSSProperties;
    dropdownClassName?: string;
    handleTriggerEvent?: (
        value?: any,
        event?: CustomSelectValueTriggerEvent,
        entries?: CustomSelectEntry<L, V>[]
    ) => void;
    optionsFilterCallback?: (args: OptionsFilterCallbackArgs<L, V>) => boolean;
    optionsLabelFormatter?: (label: L, value: V) => any;
    rawDataInStoreFormatter?: (data: any) => any;
    fixedDropdown?: boolean;
}

export interface CustomSelectComponentRef {
    entriesFromFetcher?: { label: any; value: any}[];
}

export const CustomSelectComponent = <L, V>({
    entries = [],
    additionalEntries,
    value: formFieldValue,
    onChange: onSelectValueChange,
    settings = {},
    style,
    handleTriggerEvent,
    optionsFilterCallback,
    rawDataInStoreFormatter,
    optionsLabelFormatter,
    dropdownClassName,
    fixedDropdown = true,

}: CustomSelectProps<L, V>, ref: React.Ref<CustomSelectComponentRef>) => {
    const {
        formFieldKey,
        formInstance,
        formFieldPathName,
        isDisabled,
        isLoading,
        isClearable,
        url,
        referenceUrlQueryParams,
        placeholder,
        labelPath,
        valuePath,
        useFirstOptionAfterReceivedFromFetcher,
        useFirstOptionAfterEntriesChanged,
        noUseFirstOptionWhenValueIsSet,
        showNoValueWhenNoOptions,
        depsToCallCustomTrigger = [],
        showSearch,
        isReferenceNonStorable,
        triggerOnValueSet,
        setFirstOptionUntouched,
        mode,
        allowTriggerWhenNoValueFound,
        showNullValueAsUndefined,
        setNullInsteadOfUndefinedOnChange,
        showEmptyStringAsUndefined,
        size,
    } = settings;

    if (url && (!valuePath || !labelPath)) throw new Error('Не указан labelPath или valuePath.');

    const valueWasSetRef = useRef<boolean>(false);

    const referenceUrl = (() => {
        const querySuffix = new URLSearchParams(referenceUrlQueryParams).toString();
        return querySuffix ? `${url}?${querySuffix}` : url;
    })();

    const {isFetchingEntries, entriesFromFetcher} = useCustomSelectReferenceOptionsFetcher({
        referenceUrl,
        labelPath,
        referenceUrlQueryParams,
        isReferenceNonStorable,
        additionalEntries,
        rawDataInStoreFormatter,
    });

    const entriesToRender = useMemo(() => {
        if (entriesFromFetcher) return entriesFromFetcher;
        return entries;
    }, [entries, entriesFromFetcher]);
    const pathToEntriesArrayMapping = groupBy(entriesToRender.map(entry => entry.value), valuePath);

    const formatFieldValueForSelect = createFieldFormatterForSelect(valuePath);
    const selectedCurrentFieldValueFromForm = formatFieldValueForSelect(formFieldValue);

    const {
        filterEntry,
        filteredEntries,
        dropdownOpen,
        setDropdownOpen,
        setDropdownSelectedIcon,
        multipleModeDropdownWithSearch,
    } = useCustomSelectConfig({
        mode, showSearch, entriesToRender, optionsFilterCallback, formatFieldValueForSelect, optionsLabelFormatter,
    });

    const {entriesFilteredJSX} = filteredEntries;

    const setFirstValueInEntriesAsCurrentFieldValue = (entriesToPickDataFrom?: CustomSelectEntry<L, V>[]) => {
        if (!entriesToPickDataFrom) return;

        if (formInstance && formFieldKey) {
            if (entriesToPickDataFrom?.length) {
                const [{value: firstEntryValue}] = entriesToPickDataFrom;
                const firstValueFormatted = formatFieldValueForSelect(firstEntryValue);
                if (setFirstOptionUntouched) {
                    if (formFieldPathName) {
                        const fieldList = formInstance.getFieldValue(formFieldKey);
                        set(fieldList, formFieldPathName, firstValueFormatted);
                        if (true as any) return;
                        setFieldsValueUntouched(formInstance, {[formFieldKey]: fieldList});
                    } else {
                        setFieldsValueUntouched(formInstance, {[formFieldKey]: firstValueFormatted});
                    }
                } else formInstance.setFieldsValue({[formFieldKey]: firstValueFormatted});
                handleTriggerEvent?.(firstEntryValue, CustomSelectValueTriggerEvent.firstOptionWasSet);
            } else {
                if (setFirstOptionUntouched) {
                    setFieldsValueUntouched(formInstance, {[formFieldKey]: undefined});
                } else { formInstance.setFieldsValue({[formFieldKey]: undefined}); }
                handleTriggerEvent?.(undefined, CustomSelectValueTriggerEvent.firstOptionWasSet);
            }
        } else if (entriesToPickDataFrom.length) {
            const [{value: firstEntryValue}] = entriesToPickDataFrom;
            const firstValueFormatted = formatFieldValueForSelect(firstEntryValue);
            onSelectValueChange?.(firstValueFormatted, [{touched: false}]);
            handleTriggerEvent?.(firstEntryValue, CustomSelectValueTriggerEvent.firstOptionWasSet);
        }
    };

    const useFirstOption = useFirstOptionAfterReceivedFromFetcher;

    const callTrigger = (event = CustomSelectValueTriggerEvent.customTrigger) => {
        const currentValue = (() => {
            if (pathToEntriesArrayMapping?.[formFieldValue]?.length) {
                const [value] = pathToEntriesArrayMapping[formFieldValue];
                if (!allowTriggerWhenNoValueFound) {
                    handleTriggerEvent?.(value, event, entriesFromFetcher);
                }
                return value;
            }
            return formFieldValue;
        })();

        if (allowTriggerWhenNoValueFound) {
            handleTriggerEvent?.(currentValue, event, entriesFromFetcher);
        }
    };

    useEffect(() => {
        if (depsToCallCustomTrigger.length) callTrigger();
    }, [...depsToCallCustomTrigger]);

    useEffect(() => {
        if (entriesFromFetcher?.length) {
            callTrigger(CustomSelectValueTriggerEvent.receivedDataFromFetcher);
            if (useFirstOption) {
                if (noUseFirstOptionWhenValueIsSet && formFieldValue) return;
                setFirstValueInEntriesAsCurrentFieldValue(entriesFromFetcher);
            }
        }
    }, [entriesFromFetcher]);

    useEffect(() => {
        if (noUseFirstOptionWhenValueIsSet && formFieldValue) return;
        if (useFirstOptionAfterEntriesChanged) {
            setFirstValueInEntriesAsCurrentFieldValue(entries);
        }
    }, [entries]);

    useImperativeHandle(ref, () => ({
        entriesFromFetcher,
    }));

    return (
        <>
            <Select
                size={size}
                allowClear={isClearable}
                className="custom-select select-field"
                dropdownClassName={dropdownClassName}
                getPopupContainer={getPopupContainer(fixedDropdown)}
                value={(() => {
                    if (isLoading || isFetchingEntries) return <CustomSelectSpinner />;
                    if (showEmptyStringAsUndefined && selectedCurrentFieldValueFromForm === '') return undefined;
                    if (showNullValueAsUndefined && selectedCurrentFieldValueFromForm === null) return undefined;
                    if (showNoValueWhenNoOptions && !entriesToRender.length) return undefined;
                    // trigger value setting event with the object from the reference, not the value itself
                    if (triggerOnValueSet && !valueWasSetRef.current && selectedCurrentFieldValueFromForm) {
                        const refValues = pathToEntriesArrayMapping[selectedCurrentFieldValueFromForm];
                        if (refValues?.length) {
                            const [value] = refValues;
                            handleTriggerEvent?.(value, CustomSelectValueTriggerEvent.valueWasSet);
                            valueWasSetRef.current = true;
                        }
                    }
                    if (mode === CustomSelectMode.multiple) {
                        return formFieldValue;
                    }
                    return selectedCurrentFieldValueFromForm;
                })()} // ant не умеет хранить тут объекты, нужен уникальный примитив
                onChange={(newValueFormatted, option) => {
                    const valueForTrigger = (() => { // в formatted - сырой id
                        // в valueForTrigger - полный объект для этого id
                        if (setNullInsteadOfUndefinedOnChange && newValueFormatted === undefined) {
                            return null;
                        }
                        if (!newValueFormatted || newValueFormatted?.length === 0) {
                            return newValueFormatted?.length === 0 ? undefined : newValueFormatted;
                        }
                        if (Array.isArray(newValueFormatted) && newValueFormatted.length > 1) {
                            return newValueFormatted?.map((val: any) => {
                                const [valueConverted] = pathToEntriesArrayMapping[val];
                                return valueConverted;
                            });
                        }
                        const [value] = pathToEntriesArrayMapping[newValueFormatted];
                        return value;
                    })();

                    const setNewValue = (newValue: any) => {
                        if (onSelectValueChange) onSelectValueChange(newValue, option);
                        else if (formInstance && formFieldKey) {
                            formInstance.setFieldsValue({[formFieldKey]: newValue});
                        }
                    };

                    if (setNullInsteadOfUndefinedOnChange && newValueFormatted === undefined) setNewValue(null);
                    else setNewValue(newValueFormatted);

                    handleTriggerEvent?.(valueForTrigger, CustomSelectValueTriggerEvent.valueHasBeenChangedManually);
                }}
                placeholder={placeholder}
                open={dropdownOpen}
                style={{...style}}
                disabled={isFetchingEntries || isDisabled}
                onDropdownVisibleChange={v => {
                    setDropdownOpen(v);
                }}
                mode={mode}
                tagRender={TagRenderer}
                showSearch={showSearch}
                filterOption={filterEntry}
                menuItemSelectedIcon={mode === CustomSelectMode.multiple && setDropdownSelectedIcon}
                dropdownRender={mode === CustomSelectMode.multiple
                && showSearch ? multipleModeDropdownWithSearch : undefined}
                suffixIcon={(
                    <div onClick={() => {
                        setDropdownOpen(p => !p);
                    }}
                    >
                        <SelectSuffixIcon
                            showSearch={showSearch}
                            isDropdownOpen={dropdownOpen}
                            isSelectDisabled={isDisabled}
                            isClearable={isClearable}
                        />
                    </div>
                )}
            >
                {entriesFilteredJSX}
            </Select>
        </>
    );
};

export const CustomSelect = React.forwardRef(CustomSelectComponent) as
    <L=string, V=any>(p: CustomSelectProps<L, V> & {ref?: React.Ref<CustomSelectComponentRef>}) => ReactElement;

export const GenericCustomSelect = React.forwardRef(CustomSelectComponent) as
    <L, V>(p: CustomSelectProps<L, V> & {ref?: React.Ref<CustomSelectComponentRef>}) => ReactElement;
