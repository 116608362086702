import {Alert} from 'antd';
import {AlertProps} from 'antd/lib/alert';
import React from 'react';

import s from './alert-error-message.module.less';

interface AlertErrorMessageProps {
    message: string | null;
    description?: string;
    type?: AlertProps['type'];
    closable?: boolean;
    closeErrorMessage?: () => void;
}

export const AlertErrorMessage: React.FC<AlertErrorMessageProps> = ({
    message,
    description,
    type = 'info',
    closable = true,
    closeErrorMessage,
}: AlertErrorMessageProps) => {
    if (!message) return null;
    return (
        <Alert
            className={s['form-error-message']}
            message={message}
            description={description}
            type={type}
            showIcon
            closable={closable}
            onClose={closeErrorMessage}
        />
    );
};
