import {RequestType} from 'modules/metadata';
import {performRequest} from 'shared/utils';
import {isProduction} from 'shared/utils/is-production';
import {AuthRoutes} from 'store/slices/auth-slice/auth-slice-routes';
import {AuthResponse} from 'store/slices/auth-slice/auth-slice-types';

export interface AuthRequestArgs {
    username: string;
    password: string;
    twoFactorAuthCode?: string;
}
export const authRequest = ({
    username,
    password,
    twoFactorAuthCode,
}: AuthRequestArgs): Promise<AuthResponse> => {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    if (twoFactorAuthCode) {
        params.append('code', twoFactorAuthCode);
    }

    const baseUrl = isProduction()
        ? window.location.origin
        : process.env.REACT_APP_BASE_URL;
    const url = `${baseUrl}/nalmon/login`;

    return (
        fetch(url, {
            method: RequestType.POST,
            body: params,
            headers: {
                'x-react-req-id': '1', // react requests mark for backend
            },
        }).then(r => r.json())
    );
};

export interface ChangePasswordRequestArgs {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}
export const changePasswordRequest = ({
    currentPassword,
    newPassword,
    confirmNewPassword,
}: ChangePasswordRequestArgs) => {
    const params = new URLSearchParams();
    params.append('currentPassword', currentPassword);
    params.append('newPassword', newPassword);
    params.append('confirmNewPassword', confirmNewPassword);

    return (
        performRequest({
            url: AuthRoutes.CHANGE_PASSWORD,
            method: RequestType.POST,
            data: params,
            headers: {
                'Access-Control-Allow-Origin': window.location.origin,
                'Access-Control-Allow-Credentials': true,
            },
            withCredentials: true,
        })
    );
};

export const fetchUserInfo = () => (
    performRequest({
        url: AuthRoutes.USER_INFO,
        method: RequestType.GET,
    })
);

export const logoutRequest = () => (
    performRequest({
        url: AuthRoutes.LOGOUT,
        method: RequestType.POST,
    })
);

export const fetchTwoFAConfig = () => (
    performRequest({
        url: AuthRoutes.GET_TWO_FA_CONFIG,
        method: RequestType.GET,
    })
);

export const fetchMicroserviceToken = () => (
    performRequest({
        url: AuthRoutes.MICROSERVICE_TOKEN,
        method: RequestType.GET,
    })
);

export type FetchTwoFACodeArgs = Pick<AuthRequestArgs, 'username'>;
export const fetchTwoFACode = ({username}: FetchTwoFACodeArgs) => {
    const params = new URLSearchParams();
    params.append('username', username);

    return (
        performRequest({
            url: AuthRoutes.GET_TWO_FA_CODE,
            method: RequestType.POST,
            data: params,
        })
    );
};

export const fetchChangePasswordSettings = () => (
    performRequest({
        url: AuthRoutes.CHANGE_PASSWORD_SETTINGS,
        method: RequestType.GET,
    })
);

export const fetchAuthProfile = () => (
    performRequest({
        url: AuthRoutes.PROFILE,
        method: RequestType.GET,
    })
);

export const fetchAuthStatus = () => (
    performRequest({
        url: AuthRoutes.AUTHENTICATION,
        method: RequestType.GET,
    })
);
