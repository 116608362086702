// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m-ojoJnCmAAxEMxzDAwZ7 {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n._2Gdbxt-EIQca5sT6iA2uXH {\n  height: 100vh;\n}\n", "",{"version":3,"sources":["webpack://src/components/spinner/spinner.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAEA;EACE,aAAA;AAAF","sourcesContent":[".spinner-container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.full-screen-h {\n  height: 100vh;\n}\n\n@hack: true; @import \"/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner-container": "m-ojoJnCmAAxEMxzDAwZ7",
	"full-screen-h": "_2Gdbxt-EIQca5sT6iA2uXH"
};
export default ___CSS_LOADER_EXPORT___;
