export const URL_HOME = '/:entityName';

// --- auth ---
export const URL_AUTH_ROUTER = '/auth';
export const URL_LOGIN = '/login';
export const URL_CHANGE_PASSWORD = '/changePassword';
export const URL_GET_2FA_CODE = '/get2faCode';
export const URL_LOGIN_KERBEROS = '/sso/login';

export const URL_FORBIDDEN = '/forbidden';
export const URL_DOCS = '/docs';
export const URL_METADATA_CONSTRUCTOR = '/metadata-constructor';
export const URL_WIDGETS = '/widgets';
export const URL_COMPARISON = 'document.comparison';
export const URL_SVC_WIDGETS = '/analysis2.main'; // todo: temporary solution
export const URL_SVC_CP = '/analysis2.org.control.proc';
export const URL_SVC_CP_PERIOD = '/analysis2.org.control.proc.period';
export const URL_SVC_RISK = '/analysis2.org.risk';
export const URL_SVC_RISK_PERIOD = '/analysis2.org.risk.period';
export const URL_SVC_ORG_LEVEL_CONFIRMED = 'analysis2.org.level/isConfirmed';
export const URL_AIS3_MAIN = '/ais3.dashboard';

export const URL_REQUESTS = '/requests';
export const URL_REQUESTS_REQUIREMENT = '/requests-requirement';
export const URL_REQUESTS_CLOSE = 'requests-requirement/close';
export const URL_TAKE_TO_WORK_REQUESTS_NOT_DATE = 'requests-requirement/take-to-work-not-date';
export const URL_REQUESTS_APPROVE = '/requests-requirement/approve';
export const URL_REQUESTS_REJECT = '/requests-requirement/reject';
export const URL_REQUESTS_ACTION = 'requests-requirement/status-action';
export const URL_REQUESTS_DELETE = 'requests-requirement/delete';
export const URL_REQUESTS_STATUS_COMMENT_ACTION = 'requests-requirement/status-action-comment';
export const URL_REQUESTS_READ = 'requests/request-selected-read';
export const URL_REQUESTS_REQUIREMENT_PROLONGATION_MESSAGE_UPDATE = '/requests-requirement/message/update';
export const URL_REQUESTS_REQUIREMENT_PROLONGATION_UPDATE = '/requests-requirement/update';
export const URL_REQUESTS_SEND = '/requests-requirement/send';
export const URL_REQUESTS_CREATE_APPROVE = '/requests-requirement/replay-for-approval';
export const URL_REQUESTS_RUN_REQUEST_ACTION = '/requests/action/run';
export const URL_REQUESTS_GET_REQUEST_ACTION_STATUS = '/requests/action/status';
export const URL_REQUESTS_REQUIREMENT_RATING = 'requests-requirement/sendRating';
export const URL_REQUEST_RATING = 'request/sendRating';

export const URL_REQUESTS_SIGNATURE = '/signature';
export const URL_REQUESTS_ATTACH = '/attach';
export const REPORT_TYPE_NOT_FOUND = '/report-type-not-found';

export const URL_FUNCTION_ENABLED = 'administration.functions/isFunctionEnabled';
export const URL_ADMINISTRATION_SETTINGS_PROGRAMS = 'administration.settings.programs';

// --- AIS3 ---
export const URL_AIS3_FILE_SHARING = '/ais3.file.exchange';
export const URL_AIS3_DOCUMENT_CODES = 'ais3.document.codes';
export const URL_AIS3_REQUIREMENT_RESPONSE = 'ais3.response.requirement';
export const URL_AIS3_DOCUMENT_INVENTORY = 'ais3.document.inventory';
