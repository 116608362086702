// https://docs.cryptopro.ru/cades/plugin/plugin-methods
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411 = 100; // Алгоритм ГОСТ Р 34.11-94.
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256 = 101; // Алгоритм ГОСТ Р 34.11-2012.
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512 = 102; // Алгоритм ГОСТ Р 34.11-2012.
export const CADESCOM_HASH_ALGORITHM_CP_SHA1 = 0;

export const OID_TO_HASH_ALG_MAPPING = {
    '1.2.643.2.2.19': CADESCOM_HASH_ALGORITHM_CP_GOST_3411,
    '1.2.643.7.1.1.1.1': CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
    '1.2.643.7.1.1.1.2': CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,
    '1.2.840.113549.1.1.1': CADESCOM_HASH_ALGORITHM_CP_SHA1,
} as {[index: string]: number};

export const SIGNING_MESSAGE_KEY = 'signing';
export const DEFAULT_SIGNED_FILE_NAME = 'sgn';
