import {Menu} from 'antd';
import moment from 'moment';
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import {customTableUtils} from 'components/@common/widgets/custom-table';
import {useCustomTable, UseCustomTableArgs} from 'components/@common/widgets/custom-table/custom-table';
import {ColumnSortData} from 'components/@common/widgets/custom-table/table-column-menu';
import {KebabMenu} from 'components/@common/widgets/kebab-menu';
import {IconsMap} from 'components/dynamic-icon';
import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {Spinner} from 'components/spinner';
import {selectContextRawData} from 'modules/context/context-selector';
import {ViewPudButton} from 'pages/ais/documents-register-page/components/view-pud-button/view-pud-button';
import {DATE_DMY} from 'shared/constants/date-format';
import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {usePagination} from 'shared/hooks/use-pagination';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {StateSetter} from 'shared/types/generics';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions, selectAisSliceMeta} from 'store/slices/ais-slice/ais-slice';
import {loadAisDocumentsRegister} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisDocRegisterFieldTypes, AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {AIS_REGISTER_ROLE_SECTION} from './ais-documents-register-constants';
import {DocumentsRegisterParametersFilterForm, DocumentsRegisterRequestQuery} from './ais-documents-register-types';
import {AisRequestModal} from './modals/ais-request-modal';
import {DocumentCardModal} from './modals/document-card-modal';
import {PlacementRequestModal} from './modals/placement-request-modal';
import {PUDDeleteConfirmationModal} from './modals/pud-delete-confirmation-modal';
import {PUDModal} from './modals/pud-modal';
import {RequireRequestModal} from './modals/require-request-modal';
import {TaxSliceModal} from './modals/tax-slice-modal';

const {
    setHeaderStyles,
    createIndexChild,
} = customTableUtils;

interface AisDocumentsRegisterTableProps {
    documentsParametersFilter?: DocumentsRegisterParametersFilterForm;
    selectedRowKeys: React.Key[];
    setSelectedRowKeys: StateSetter<React.Key[]>;
    documentsRequestQuery?: DocumentsRegisterRequestQuery;
    setDocumentsRequestQuery: Dispatch<SetStateAction<DocumentsRegisterRequestQuery | undefined>>;
}
export interface AisDocumentsRegisterTableRef {
    setSortData: StateSetter<ColumnSortData>;
}

export const AisDocumentsRegisterTable = React.forwardRef<
AisDocumentsRegisterTableRef, AisDocumentsRegisterTableProps>((
    {
        documentsParametersFilter,
        selectedRowKeys,
        setSelectedRowKeys,
        documentsRequestQuery,
        setDocumentsRequestQuery,
    }: AisDocumentsRegisterTableProps,
    ref,
) => {
    const dispatch = useAppDispatch();

    const [sortData, setSortData] = useState<ColumnSortData>({
        sort: {},
    });

    const {organizationId} = useAppSelector(selectContextRawData) ?? {};

    const {selectAll: selectAllDocuments} = aisSelectors.documentsSelectors;

    const isLoadingRegisterTableData = useAppSelector(s => selectIsThunkPending(
        s, loadAisDocumentsRegister.typePrefix,
    ));

    const documents = useAppSelector(selectAllDocuments);

    const {
        quantityLines: defaultPageSize,
        useParameters,
    } = useAppSelector(aisSelectors.selectAisRegisterDocumentsSettings) ?? {};
    const {columns: rawColumns} = useAppSelector(aisSelectors.selectAisRegisterDocumentsFields) ?? {};

    const shouldLoadWithoutFilters = useParameters === false;

    const requestPlacementModalRef = useRef<ModalOpenerComponentRef>(null);
    const requireRequestsModalRef = useRef<ModalOpenerComponentRef>(null);
    const aisModalRef = useRef<ModalOpenerComponentRef>(null);
    const taxSliceModalRef = useRef<ModalOpenerComponentRef>(null);
    const PUDModalRef = useRef<ModalOpenerComponentRef>(null);
    const PUDDeleteModalRef = useRef<ModalOpenerComponentRef>(null);

    const {hasUserRoleFunction, isLoadingUserFunctions} = useUserRoleFunctions({
        sections: [AIS_REGISTER_ROLE_SECTION],
    });

    const pageSizeValue = defaultPageSize ?? 20;
    // @ts-ignore
    const pageSizeOptions = [...new Set(['5', '10', '20', '50', '100', String(defaultPageSize)])]
        .sort((a, b) => Number(a) - Number(b));

    const {
        current, pageSize, handleChange, setCurrentManually, shouldTriggerPaginationFetch,
    } = usePagination({
        pageDefault: 1,
        pageSizeDefault: pageSizeValue,
    });

    const {documentsTotalCount} = useAppSelector(selectAisSliceMeta);

    useImperativeHandle(ref, () => ({
        setSortData,
    }));

    const columns: UseCustomTableArgs['columns'] = [
        {
            title: '',
            render: (value: any, record: any) => (
                <div className="d-flex align-items-center gap-1-25">
                    <KebabMenu
                        button={{
                            type: 'primary',
                            size: 'small',
                        }}
                        placeholder={[
                            UserRoleFunction.VIEW_REQUESTS,
                            UserRoleFunction.VIEW_REQUIREMENTS,
                            UserRoleFunction.UPLOAD_PUD,
                            UserRoleFunction.DELETE_PUD,
                            UserRoleFunction.DOC_JOURNAL_TAXES,
                            UserRoleFunction.VIEW_REQ_4_SCENARIO,
                        ].every(f => !hasUserRoleFunction(f)) ? <>Нет доступных действий</> : undefined}
                    >
                        <Menu>
                            {hasUserRoleFunction(UserRoleFunction.VIEW_REQUESTS) && (
                                <Menu.Item onClick={() => {
                                    requestPlacementModalRef.current?.showModal();
                                }}
                                >Просмотреть запросы на доразмещение
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.VIEW_REQUIREMENTS) && (
                                <Menu.Item onClick={() => {
                                    requireRequestsModalRef.current?.showModal();
                                }}
                                >Просмотреть требования
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.UPLOAD_PUD)
                            && !record.contentAvailability
                            && (
                                <Menu.Item
                                    onClick={() => { PUDModalRef.current?.showModal(); }}
                                >Разместить ПУД
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.DELETE_PUD)
                            && record.contentAvailability && (
                                <Menu.Item onClick={() => {
                                    PUDDeleteModalRef.current?.showModal();
                                }}
                                >Удалить ПУД
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.DOC_JOURNAL_TAXES) && (
                                <Menu.Item onClick={() => {
                                    taxSliceModalRef.current?.showModal();
                                }}
                                >Просмотреть документ в разрезе налогов
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.VIEW_REQ_4_SCENARIO) && (
                                <Menu.Item onClick={() => {
                                    aisModalRef.current?.showModal();
                                }}
                                >Просмотреть запросы по 4 сценарию
                                </Menu.Item>
                            )}
                            <PlacementRequestModal
                                registerDocument={record}
                                ref={requestPlacementModalRef}
                            />
                            <RequireRequestModal
                                ref={requireRequestsModalRef}
                                registerDocument={record}
                            />
                            <AisRequestModal
                                ref={aisModalRef}
                                registerDocument={record}
                            />
                            <PUDModal
                                ref={PUDModalRef}
                                registerDocument={record}
                            />
                            <PUDDeleteConfirmationModal
                                ref={PUDDeleteModalRef}
                                registerDocument={record}
                            />
                            <TaxSliceModal
                                ref={taxSliceModalRef}
                                registerDocument={record}
                            />
                        </Menu>
                    </KebabMenu>

                    {hasUserRoleFunction(UserRoleFunction.VIEW_PUD) && (
                        <ViewPudButton
                            link={record?.fixed_dd_url_config}
                        />
                    )}
                </div>
            ),
        },
    ];

    rawColumns?.forEach(rc => {
        const render = (() => {
            if (rc.fieldKey === 'aisDocId') {
                return (v: string, registerDocumentRecord: AisRegisterDocumentDto | undefined) => (
                    <DocumentCardModal
                        initialRegisterDocument={registerDocumentRecord}
                        cellValue={v}
                    />
                );
            }

            switch (rc.type) {
            case AisDocRegisterFieldTypes.MONETARY: {
                return (v?: string) => (
                    <div className="text-center">
                        {Intl.NumberFormat('ru-RU').format(Number(v?.toString().replace(',', '.')))}
                    </div>
                );
            }
            case AisDocRegisterFieldTypes.DATE:
                return (v: string) => (
                    <div className="text-center">
                        {v && moment(v).format(DATE_DMY)}
                    </div>
                );
            default:
                return undefined;
            }
        })();

        columns.push({
            title: rc.label,
            dataIndex: rc.fieldKey,
            key: rc.fieldKey,
            render,
        });
    });

    const {
        customTableJSX,
        tableColumnSortDataAdaptedForRequest,
        tableColumnSortData: customTableSortData,
    } = useCustomTable({
        tableColumnSortData: sortData,
        setTableColumnSortData: setSortData,
        pagination: {
            position: ['bottomLeft'],
            showQuickJumper: {goButton: <IconsMap.ArrowRightIcon className="pagination_jumper" />},
            showSizeChanger: true,
            locale: {items_per_page: 'на странице', jump_to: ''},
            current,
            pageSizeOptions,
            total: (() => {
                if (!isLoadingUserFunctions && !hasUserRoleFunction(UserRoleFunction.VIEW)) return undefined;
                return documentsTotalCount;
            })(),
            pageSize,
            onChange: handleChange,
        },
        rowKey: 'id',
        rowSelection: {
            selectedRowKeys,
            onChange: selectedTableRowKeys => setSelectedRowKeys(selectedTableRowKeys),
        },
        // ---
        className: 'ais-documents-register-table mt-5',
        locale: {
            emptyText: (
                <div style={{marginTop: 30}}>
                    {(() => {
                        if (!hasUserRoleFunction(UserRoleFunction.VIEW) && isLoadingUserFunctions) {
                            return <div><Spinner tip="Загрузка..." /></div>;
                        }
                        if (!hasUserRoleFunction(UserRoleFunction.VIEW) && !isLoadingUserFunctions) {
                            return 'Недостаточно прав для просмотра данных';
                        }
                        if ((documentsParametersFilter || shouldLoadWithoutFilters) && !isLoadingRegisterTableData) {
                            return 'Ничего не найдено';
                        }
                        return 'Для формирования данных необходимо задать параметры';
                    })()}
                </div>
            ),
        },
        dataSource: (() => {
            if (isLoadingUserFunctions) return [];
            if (!isLoadingUserFunctions && !hasUserRoleFunction(UserRoleFunction.VIEW)) return [];
            if (!documentsParametersFilter && !shouldLoadWithoutFilters) return [];
            return documents;
        })(),
        onColumn: (col, i) => {
            const SKIP_INDEX = 1;
            const ordinal = rawColumns?.find(rc => rc?.fieldKey === col.dataIndex)?.columnOrdinal ?? i;

            return {
                withCustomFilters: i >= SKIP_INDEX,
                ...setHeaderStyles({
                    whiteSpace: 'pre',
                    textAlign: 'center',
                }),
                ...createIndexChild(col.dataIndex?.toString(), `${ordinal}`, ordinal < SKIP_INDEX, {
                    render: col.render ?? (cellValue => <div style={{textAlign: 'center'}}>{cellValue}</div>),
                }),
                ...col,
            };
        },
        bordered: true,
        columns,
    });

    useEffect(() => {
        setDocumentsRequestQuery({
            ...documentsParametersFilter,
            paginationCurrent: current,
            paginationPageSize: pageSize,
        });
    }, [documentsParametersFilter, current, pageSize, organizationId]);

    useEffect(() => {
        if (shouldLoadWithoutFilters && organizationId) {
            const loadThunk = dispatch(loadAisDocumentsRegister({
                ...documentsRequestQuery,
                organizationId,
                paginationCurrent: 1,
            }));
            setCurrentManually(1);
            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [shouldLoadWithoutFilters]);

    useAfterEffect(() => {
        if (documentsParametersFilter && organizationId) {
            const loadThunk = dispatch(loadAisDocumentsRegister({
                ...documentsRequestQuery,
                ...documentsParametersFilter,
                organizationId,
                paginationCurrent: 1,
            }));
            setCurrentManually(1);

            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [documentsParametersFilter]);

    useAfterEffect(() => {
        if (organizationId
          && ((documentsParametersFilter && shouldTriggerPaginationFetch) || shouldLoadWithoutFilters)) {
            const loadThunk = dispatch(loadAisDocumentsRegister({...documentsRequestQuery, organizationId}));

            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [pageSize, current]);

    useAfterEffect(() => {
        if (documentsParametersFilter && !customTableSortData.noFetch && organizationId) {
            const loadThunk = dispatch(loadAisDocumentsRegister({...documentsRequestQuery, organizationId}));

            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [tableColumnSortDataAdaptedForRequest]);

    useEffect(() => () => { dispatch(aisSliceActions.updateAisSliceMeta({documentsTotalCount: undefined})); }, []);

    return <>{customTableJSX}</>;
});
