import {message} from 'antd';
import React from 'react';

interface NotifyArgs {
    key: string;
    documentCount?: number;
    successfulCount?: number;
    totalCount?: number;
    status?: 'loading' | 'success' | 'error';
    duration?: number;
}

/**
 * Отображает уведомления с помощью компонента `message` из библиотеки Ant Design.
 * Она используется для информирования пользователя о процессе формирования подписей для документов,
 * успешном завершении или возникновении ошибки.
 *
 * @param key Уникальный ключ для уведомления, который используется для идентификации и обновления уведомлений.
 *
 * @param documentCount Количество документов, для которых формируется подпись.
 * Обязателен для статуса `'loading'`.
 *
 * @param successfulCount Количество успешно подписанных документов.
 * Обязателен для статуса `'success'`.
 *
 * @param totalCount Общее количество документов, для которых была попытка подписания.
 * Обязателен для статуса `'success'`.
 *
 * @param status Текущий статус уведомления:
 * - `'loading'` — процесс формирования подписи;
 * - `'success'` — успешное завершение подписания;
 * - `'error'` — ошибка в процессе подписания.
 * Значение по умолчанию: `'loading'`.
 *
 * @param duration Длительность отображения уведомления (в секундах).
 * Значение по умолчанию: `2`. Для статуса `'loading'` уведомление отображается бесконечно (duration: 0).
 *
 * @throws Если для статусов `'loading'` или `'success'`
 * не предоставлены обязательные параметры, выбрасывается ошибка через `console.error`.
 */
export const notify = ({
    key,
    documentCount,
    successfulCount,
    totalCount,
    status = 'loading',
    duration = 2,
}: NotifyArgs): void => {
    let content: React.ReactNode;

    switch (status) {
    case 'loading':
        if (documentCount === undefined) {
            console.error('documentCount is required for loading status');
            return;
        }
        content = documentCount === 1
            ? 'Формирование подписи документа...'
            : (
                <span className="ml-1">
                    Формирование подписи документов...
                    <div style={{fontSize: 12}}>
                        Файлов для подписания: {documentCount}
                    </div>
                </span>
            );
        message.loading({
            content,
            key,
            // Если 0, то уведомление будет показываться до вызова success или error
            duration: 0,
        });
        break;

    case 'success':
        if (successfulCount === undefined || totalCount === undefined) {
            console.error('successfulCount and totalCount are required for success status');
            return;
        }
        content = successfulCount === 0
            ? 'Формирование подписи завершено с ошибкой'
            : (
                <span className="ml-1">
                    Формирование подписей завершено
                    <div style={{fontSize: 12}}>
                        Подписано: {successfulCount} из {totalCount}
                    </div>
                </span>
            );
        message.success({
            content,
            key,
            duration,
        });
        break;

    case 'error':
        content = 'Ошибка формирования подписи в одном из выбранных документов';
        message.error({
            content,
            key,
            duration,
        });
        break;

    default:
        console.error('Unknown status:', status);
    }
};
