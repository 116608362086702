import {
    LoadingOutlined, SafetyCertificateOutlined,
} from '@ant-design/icons';
import {FormInstance} from 'antd/lib/form';
import cn from 'classnames';
import React, {useImperativeHandle, useRef, useState} from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';

import {useCryptoProSigning} from '../../hooks';
import {getDocumentsIdsToSign} from '../../lib';
import {CertificateExtended, CryptoProSignatureType, CryptoProSignedDoc} from '../../model/types';
import {CryptoProSignatureModalContent} from '../signature-modal-content/signature-modal-content';
import s from './sign-with-crypto-pro-modal.module.less';

interface SignWithCryptoProIModalProps {
    documentIds: string[]; // todo: нужна типизация
    onFinish?: () => void;
    onStart?: () => void;
    entityName: string;
    className?: cn.Argument;
}

export const SignWithCryptoProIModal = React.forwardRef<any, SignWithCryptoProIModalProps>((
    {
        documentIds, onFinish, onStart, entityName, className,
    }: SignWithCryptoProIModalProps,
    ref,
) => {
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateExtended>();
    const [selectedSignatureType, setSelectedSignatureType] = useState<CryptoProSignatureType>('detached');
    const [selectedFileExtension, setSelectedFileExtension] = useState<string | undefined>();
    const [isWithPluginInitializationError, setIsWithPluginInitializationError] = useState(false);
    const [isSignButtonTemporarilyInactive, setIsSignButtonTemporarilyInactive] = useState(false);
    const [signedDocs, setSignedDocs] = useState<CryptoProSignedDoc[]>([]);
    const [isWithResign, setIsWithResign] = useState(true);

    const formRef = useRef<FormInstance>(null);
    const modalRef = useRef<ModalOpenerComponentRef | null>(null);

    const documentIdsToSign = getDocumentsIdsToSign(isWithResign, documentIds, signedDocs);

    const {
        handleFinish,
    } = useCryptoProSigning({
        entityName,
        onFinish,
        onStart,
        isWithResign,
        selectedCertificate,
        selectedSignatureType,
        selectedFileExtension,
        modalRef,
        documentIdsToSign,
    });

    const resetState = () => {
        setSelectedCertificate(undefined);
        setIsWithPluginInitializationError(false);
        setIsSignButtonTemporarilyInactive(false);
        setSelectedSignatureType('detached');
        setSelectedFileExtension(undefined);
        setSignedDocs([]);
        setIsWithResign(true);
    };

    useImperativeHandle(ref, () => modalRef.current);

    return (
        <>
            <ModalOpenerComponent
                ref={modalRef}
                componentWrapperClassNames={className}
                modalProps={{
                    centered: true,
                    forceRender: false,
                    destroyOnClose: true,
                    title: 'Настройка подписи',
                }}
                hideControls={{
                    save: isWithPluginInitializationError,
                }}
                disabledControls={{
                    save: !selectedCertificate || isSignButtonTemporarilyInactive || !documentIdsToSign.length,
                }}
                controlLabels={{
                    save: isSignButtonTemporarilyInactive ? (
                        <div>
                            <LoadingOutlined className={s['sign-loading-icon']} />
                            Подписать
                        </div>
                    ) : 'Подписать',
                    cancel: isWithPluginInitializationError ? 'Закрыть' : undefined,
                }}
                handleSave={() => {
                    setIsSignButtonTemporarilyInactive(true);
                    setTimeout(() => {
                        setIsSignButtonTemporarilyInactive(false);
                    }, 1000);
                    formRef.current?.submit();
                }}
                component={(
                    <div className={s['sign-component']}>
                        <SafetyCertificateOutlined />
                        <span>Подписать</span>
                    </div>
                )}
                afterModalClose={resetState}
            >
                <CryptoProSignatureModalContent
                    ref={formRef}
                    selectedCertificate={selectedCertificate}
                    setSelectedCertificate={setSelectedCertificate}
                    selectedSignatureType={selectedSignatureType}
                    setSelectedSignatureType={setSelectedSignatureType}
                    selectedFileExtension={selectedFileExtension}
                    setSelectedFileExtension={setSelectedFileExtension}
                    isWithPluginInitializationError={isWithPluginInitializationError}
                    setIsWithPluginInitializationError={setIsWithPluginInitializationError}
                    handleFinish={handleFinish}
                    documentIds={documentIds}
                    setSignedDocs={setSignedDocs}
                    signedDocs={signedDocs}
                    isWithResign={isWithResign}
                    setIsWithResign={setIsWithResign}
                />
            </ModalOpenerComponent>
        </>
    );
});
