import {Card} from 'antd';
import React, {SetStateAction, useState} from 'react';
import {useDispatch} from 'react-redux';

import {selectBreadcrumbsData} from 'modules/breadcrumbs/breadcrumbs-selector';
import {selectContextRawData} from 'modules/context/context-selector';
import {selectEntityData, loadFormData, Entity} from 'modules/data';
import {
    filterNonRenderableFieldsAndUpdateState as filterNonRenderableFieldsAndUpdateStateAction,
    initBlankForm as initBlankFormAction,
    performActionForForm,
    resetData,
    resetFormData,
    resetFormUsedAdditionalOptions,
} from 'modules/data/data-actions';
import {FilterEntityData, FormEntityData} from 'modules/data/data-types';
import {
    selectMetadata, loadMetadata as loadMetadataAction, RequestType,
} from 'modules/metadata';
import {resetMetadata} from 'modules/metadata/metadata-actions';
import {
    DynamicTitle, EntityMeta, FormEntityMeta, UrlConfig,
} from 'modules/metadata/metadata-types';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';
import {selectModalData} from 'store/slices/modals-slice';

import {FormMode} from '../form';
import {Form as FormComponent} from '../form/form';
import {Spinner} from '../spinner';

interface FormContainerProps {
    entityName: string;
    url?: string;
    hideTitle?: boolean;
    match?: {
        params: { id: string };
    };
    onClose: () => void;
    dirty?: boolean;
    setIsDirty?: (v: boolean) => void;
    mode?: FormMode;
    contextData?: Record<string, any>;
    additionalValuesForRequest?: Record<string, any>;
}

export const FormContainer: React.FunctionComponent<FormContainerProps> = ({
    entityName,
    url,
    match,
    ...props
}: FormContainerProps) => {
    const [fieldsFiltered, setFieldsFiltered] = useState<boolean>(false);
    const dispatch = useDispatch();
    const contextData = useAppSelector(selectContextRawData);
    const metadata = useAppSelector(selectMetadata(entityName, EntityType.FORM)) as FormEntityMeta;
    const filter = useAppSelector(selectEntityData(entityName, EntityType.FILTER)) as FilterEntityData;
    const formData = useAppSelector(selectEntityData(entityName, EntityType.FORM)) as FormEntityData;
    const breadcrumbs = useAppSelector(selectBreadcrumbsData(entityName));
    const parentEntityName = useAppSelector(state => selectModalData(state)?.parentEntityName) || '';

    const filterNonRenderableFieldsAndUpdateState = (
        meta: EntityMeta,
        name: string,
        entityType: EntityType,
        setFilteredFlag: React.Dispatch<SetStateAction<boolean>>,
    ) => dispatch(filterNonRenderableFieldsAndUpdateStateAction(meta, name, entityType, setFilteredFlag));

    React.useEffect(() => {
        if (match) {
            const {id} = match.params;
            dispatch(loadMetadataAction(entityName, EntityType.FORM));

            if (!formData) {
                let params = {};
                if (breadcrumbs) {
                    breadcrumbs.forEach(crumbItem => {
                        if (crumbItem?.children) {
                            crumbItem.children.forEach(childrenItem => {
                                params = {...params, [childrenItem.key]: childrenItem?.params};
                            });
                        }
                    });
                }
                dispatch(loadFormData(entityName, id, url, params, metadata?.urlConfig));
            }
        } else {
            dispatch(initBlankFormAction(entityName));
        }
        return () => {
            dispatch(resetFormData({entityName}));
            dispatch(resetFormUsedAdditionalOptions({entityName}));
            dispatch(resetData({entityName, entityType: EntityType.NUMBER}));
            dispatch(resetMetadata({loading: false, entityName, entityType: EntityType.FORM}));
        };
    }, []);

    const actionForForm = (
        name: string,
        referenceUrl: string,
        requestType: RequestType,
        newData: Record<string, any>,
        isJsonRequest?: boolean,
        urlParamKey?: string,
        additionalData?: Entity,
        urlConfig?: UrlConfig,
    ) => dispatch(performActionForForm()(
        name, referenceUrl, requestType, newData, isJsonRequest, urlParamKey, additionalData, urlConfig,
    ));

    React.useEffect(() => {
        if (metadata?.fields && !fieldsFiltered) {
            filterNonRenderableFieldsAndUpdateState(metadata, metadata.name, EntityType.FORM, setFieldsFiltered);
        }
    }, [metadata?.fields]);

    const editMode = !!match;
    const isLoaded = editMode ? !!metadata && !!formData && fieldsFiltered : !!metadata && fieldsFiltered;

    const dynamicTitleOptions: DynamicTitle = metadata?.dynamicFormTitle as DynamicTitle;

    const cardTitle = dynamicTitleOptions?.create;

    return isLoaded ? (
        <FormComponent
            cardTitle={cardTitle}
            parentEntityName={parentEntityName || entityName}
            tableFilterData={filter?.data}
            formData={formData}
            entityName={entityName}
            meta={metadata}
            actionForForm={actionForForm}
            contextData={contextData}
            {...props}
        />
    ) : (
        <Card style={{paddingTop: 20}}> <Spinner /></Card>
    );
};
