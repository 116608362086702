import {
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';
import {
    createAisOrganizationStructureReducer,
    createAisOrganizationStructureExtraReducer,
} from 'store/slices/ais-slice/organization-structure';

import {createAisCommentsRequestExtraReducer} from './ais-comments-request';
import {
    createAisDashboardsExtraReducer,
    createAisDashboardsReducer,
} from './ais-dashboards/ais-dashboards-reducer';
import {createAisNotificationScenariosSettingsExtraReducer} from './ais-notification-scenarios-settings';
import {ais3SliceName} from './ais-slice-constants';
import {
    loadAisDocumentsByTaxRegister,
    loadAisDocumentsInterfaceData,
    loadAisDocumentsInterfaceFields,
    loadAisDocumentsRegister,
    loadAisDocumentsRegisterFields,
    loadAisDocumentsRegisterSettings,
    loadAisIncludedDocumentsByTax,
    loadAisRequests,
    loadAisRequestsToPublish,
    loadAisRequirements,
    removeSinglePUDDocument,
    updateAisDocumentInRegister,
    uploadPUDFileToDocument,
} from './ais-slice-thunks';
import {
    AisSliceState, AisRegisterDocumentDto, AisRegisterDocumentByTaxDto, AisRequirementDto,
    AisRequestToPublishDto,
    AisRequestDto,
    AisInterfaceDocumentDto,
} from './ais-slice-types';
import {convertRawAisDocInterfaceFields, convertRawAisDocRegistersFields, getMappingIdsByDocumentId} from './ais-slice-utils';

const aisRegisterDocumentsEntityAdapter = createEntityAdapter<AisRegisterDocumentDto>({
    selectId: model => model.id,
});

const aisRegisterDocumentsByTaxEntityAdapter = createEntityAdapter<AisRegisterDocumentByTaxDto>({
    selectId: model => model.mappingId,
});

const aisRegisterIncludedDocumentsByTaxEntityAdapter = createEntityAdapter<AisRegisterDocumentByTaxDto>({
    selectId: model => model.mappingId,
});

const aisRequirementsEntityAdapter = createEntityAdapter<AisRequirementDto>({
    selectId: model => model.requirementNumber,
});

const aisRequestsToPublishAdapter = createEntityAdapter<AisRequestToPublishDto>({
    selectId: model => model.topicId,
});

const aisRequestsEntityAdapter = createEntityAdapter<AisRequestDto>({
    selectId: model => model.ticketId,
});

const aisInterfaceDocumentsEntityAdapter = createEntityAdapter<AisInterfaceDocumentDto>({
    selectId: model => model.id,
});

export const aisSlice = createSlice({
    initialState: {
        registerDocuments: aisRegisterDocumentsEntityAdapter.getInitialState(),
        registerDocumentsSettings: undefined,
        registerDocumentsFields: undefined,

        registerDocumentsByTax: aisRegisterDocumentsByTaxEntityAdapter.getInitialState(),
        includedDocumentsByTax: aisRegisterIncludedDocumentsByTaxEntityAdapter.getInitialState(),

        aisRequirements: aisRequirementsEntityAdapter.getInitialState(),
        aisRequestsToPublish: aisRequestsToPublishAdapter.getInitialState(),
        aisRequests: aisRequestsEntityAdapter.getInitialState(),

        interfaceDocumentsData: aisInterfaceDocumentsEntityAdapter.getInitialState(),
        interfaceDocumentsFields: undefined,

        meta: {
            documentsTotalCount: undefined,
            documentsByTaxTotalCount: undefined,
        },

        aisDashboards: {
            availabilityStatus: undefined,
            summaryTransactionsProtocol: undefined,
            register: undefined,
            s4s5RequestsStatistics: undefined,
            s3s6s12requestStatistic: undefined,
            tokenStatus: undefined,
            unavailabilityChart: undefined,
        },

        aisCommentsRequest: {
            aisCommentsRequestTable: undefined,
            aisInspectorCommentInfo: undefined,
        },

        organizationStructure: undefined,

        aisNotificationScenariosSettings: {
            notificationScenariosTable: undefined,
            notificationScenariosServices: undefined,
        },
    } as AisSliceState,
    name: ais3SliceName,
    reducers: {
        ...createAisDashboardsReducer(),
        ...createAisOrganizationStructureReducer(),
        updateAisSliceMeta(state, {payload}: PayloadAction<AisSliceState['meta']>) {
            state.meta = {
                ...state.meta,
                ...payload,
            };
        },
        resetRegisterDocuments(state) {
            aisRegisterDocumentsEntityAdapter.removeAll(state.registerDocuments);
            state.meta.documentsTotalCount = undefined;
        },
        resetRegisterDocumentsByTax(state) {
            aisRegisterDocumentsByTaxEntityAdapter.removeAll(state.registerDocumentsByTax);
            state.meta.documentsByTaxTotalCount = undefined;
        },
        resetInterfaceDocuments(state) {
            aisInterfaceDocumentsEntityAdapter.removeAll(state.interfaceDocumentsData);
            state.meta.documentsTotalCount = undefined;
        },
        resetRequirements(state) {
            aisRequirementsEntityAdapter.removeAll(state.aisRequirements);
        },
        resetRequestsToPublish(state) {
            aisRequestsToPublishAdapter.removeAll(state.aisRequestsToPublish);
        },
        resetRequests(state) {
            aisRequestsEntityAdapter.removeAll(state.aisRequests);
        },
        setRegisterDocuments(state, {
            payload,
        }: PayloadAction<AisRegisterDocumentDto[]>) {
            aisRegisterDocumentsEntityAdapter.setAll(state.registerDocuments, {
                ...payload,
            });
        },
        addRegisterDocument(state, {
            payload,
        }: PayloadAction<AisRegisterDocumentDto>) {
            aisRegisterDocumentsEntityAdapter.upsertOne(state.registerDocuments, {
                ...payload,
            });
        },
        updateRegisterDocument(state, {payload}: PayloadAction<AisRegisterDocumentDto['id']>) {
            aisRegisterDocumentsEntityAdapter.updateOne(state.registerDocuments, {changes: {}, id: payload});
        },
        removeRegisterDocument(state, {payload}: PayloadAction<AisRegisterDocumentDto['id']>) {
            aisRegisterDocumentsEntityAdapter.removeOne(state.registerDocuments, payload);
        },
        resetIncludedDocumentsByTax(state) {
            aisRegisterIncludedDocumentsByTaxEntityAdapter.removeAll(state.includedDocumentsByTax);
        },
    },
    extraReducers: builder => {
        createAisDashboardsExtraReducer(builder);
        createAisOrganizationStructureExtraReducer(builder);
        createAisNotificationScenariosSettingsExtraReducer(builder);
        createAisCommentsRequestExtraReducer(builder);
        // ==== Additional Placement Requests (Requests to publish) =======
        builder.addCase(loadAisRequestsToPublish.fulfilled, (state, {payload}) => {
            aisRequestsToPublishAdapter.setAll(state.aisRequestsToPublish, payload);
        });
        // ==== Requests =======
        builder.addCase(loadAisRequests.fulfilled, (state, {payload}) => {
            aisRequestsEntityAdapter.setAll(state.aisRequests, payload);
        });
        // ==== Requirements =======
        builder.addCase(loadAisRequirements.fulfilled, (state, {payload}) => {
            aisRequirementsEntityAdapter.setAll(state.aisRequirements, payload);
        });
        // ==== Documents by Tax ====
        builder.addCase(loadAisDocumentsByTaxRegister.fulfilled, (state, {payload}) => {
            aisRegisterDocumentsByTaxEntityAdapter.setAll(state.registerDocumentsByTax, payload.dataTable);
            state.meta.documentsByTaxTotalCount = payload.recordsTotal;
        });
        // ==== Documents ====
        builder.addCase(loadAisDocumentsRegister.fulfilled, (state, {payload}) => {
            aisRegisterDocumentsEntityAdapter.setAll(state.registerDocuments, payload.dataTable);
            state.meta.documentsTotalCount = payload.recordsTotal;
        });
        builder.addCase(updateAisDocumentInRegister.fulfilled, (state, action) => {
            const updatedData = action.payload;
            aisRegisterDocumentsEntityAdapter.updateOne(state.registerDocuments, {
                changes: updatedData,
                id: updatedData.id,
            });

            const mappingIdsToUpdate = getMappingIdsByDocumentId(state, action.meta.arg.id);
            aisRegisterDocumentsByTaxEntityAdapter
                .updateMany(state.registerDocumentsByTax, mappingIdsToUpdate.map(id => ({
                    changes: updatedData,
                    id,
                })));
        });
        builder.addCase(loadAisIncludedDocumentsByTax.fulfilled, (state, {payload}) => {
            aisRegisterIncludedDocumentsByTaxEntityAdapter.setAll(state.includedDocumentsByTax, payload);
        });
        builder.addCase(removeSinglePUDDocument.fulfilled, (state, action) => {
            aisRegisterDocumentsEntityAdapter.updateOne(state.registerDocuments, {
                changes: {
                    contentAvailability: 0,
                },
                id: action.meta.arg.id,
            });

            const mappingIdsToUpdate = getMappingIdsByDocumentId(state, action.meta.arg.id);
            aisRegisterDocumentsByTaxEntityAdapter
                .updateMany(state.registerDocumentsByTax, mappingIdsToUpdate.map(id => ({
                    changes: {contentAvailability: 0},
                    id,
                })));
        });
        builder.addCase(uploadPUDFileToDocument.fulfilled, (state, action) => {
            // обновить во всех документах ↴
            aisRegisterDocumentsEntityAdapter.updateOne(state.registerDocuments, {
                changes: {
                    contentAvailability: 1,
                },
                id: action.meta.arg.id,
            });

            // обновить в документах по налогам ↴
            const mappingIdsToUpdate = getMappingIdsByDocumentId(state, action.meta.arg.id);
            aisRegisterDocumentsByTaxEntityAdapter
                .updateMany(state.registerDocumentsByTax, mappingIdsToUpdate.map(id => ({
                    changes: {contentAvailability: 1},
                    id,
                })));
        });
        builder.addCase(loadAisDocumentsRegisterSettings.fulfilled, (state, action) => {
            const settingsAttributesMap = new Map(action.payload.map(a => [a.attribute, a.value]));

            state.registerDocumentsSettings = {
                useParameters: settingsAttributesMap.get('use_parameters') === 'Y',
                quantityLines: settingsAttributesMap.has('quantity_lines')
                    ? Number(settingsAttributesMap.get('quantity_lines'))
                    : undefined,
                contentAvailabilityCondition: settingsAttributesMap.get('content_availability_condition') === 'Y',
                useIdAutogeneration: settingsAttributesMap.get('ais_doc_id_autogeneration') === 'Y',
            };
        });
        builder.addCase(loadAisDocumentsRegisterFields.fulfilled, (state, action) => {
            state.registerDocumentsFields = convertRawAisDocRegistersFields(action.payload);
        });
        builder.addCase(loadAisDocumentsInterfaceFields.fulfilled, (state, action) => {
            state.interfaceDocumentsFields = convertRawAisDocInterfaceFields(action.payload);
        });
        builder.addCase(loadAisDocumentsInterfaceData.fulfilled, (state, {payload}) => {
            aisInterfaceDocumentsEntityAdapter.setAll(state.interfaceDocumentsData, payload.dataTable);
            state.meta.documentsTotalCount = payload.recordsTotal;
        });
    },
});

const selectAisSliceState = (state: AppState) => state[ais3SliceName];

export const selectAisSliceMeta = createSelector(selectAisSliceState, ({meta}) => meta);

const aisRegisterDocumentsEntityAdapterSelectors = aisRegisterDocumentsEntityAdapter.getSelectors(
    (state: AppState) => selectAisSliceState(state).registerDocuments,
);

const selectAisRegisterDocumentsSettings = createSelector(
    selectAisSliceState, ({registerDocumentsSettings}) => registerDocumentsSettings,
);

const selectAisRegisterDocumentsFields = createSelector(
    selectAisSliceState, ({registerDocumentsFields}) => registerDocumentsFields,
);

const aisRegisterDocumentsByTaxEntityAdapterSelectors = aisRegisterDocumentsByTaxEntityAdapter.getSelectors(
    (state: AppState) => selectAisSliceState(state).registerDocumentsByTax,
);

const aisRequirementsSelectors = aisRequirementsEntityAdapter.getSelectors(
    (state: AppState) => selectAisSliceState(state).aisRequirements,
);

const aisRequestsSelectors = aisRequestsEntityAdapter.getSelectors(
    (state: AppState) => selectAisSliceState(state).aisRequests,
);

const aisRequestsToPublishSelectors = aisRequestsToPublishAdapter.getSelectors(
    (state: AppState) => selectAisSliceState(state).aisRequestsToPublish,
);

const aisRegisterIncludedDocumentsByTaxEntityAdapterSelectors = aisRegisterIncludedDocumentsByTaxEntityAdapter
    .getSelectors(
        (state: AppState) => selectAisSliceState(state).includedDocumentsByTax,
    );

const selectAisInterfaceDocumentsFields = createSelector(
    selectAisSliceState, ({interfaceDocumentsFields}) => interfaceDocumentsFields,
);

const aisInterfaceDocumentsEntityAdapterSelectors = aisInterfaceDocumentsEntityAdapter.getSelectors(
    (state: AppState) => selectAisSliceState(state).interfaceDocumentsData,
);

export const aisSelectors = {
    selectAisSliceState,
    selectAisSliceMeta,
    selectAisRegisterDocumentsSettings,
    selectAisRegisterDocumentsFields,
    documentsSelectors: aisRegisterDocumentsEntityAdapterSelectors,
    documentsByTaxSelectors: aisRegisterDocumentsByTaxEntityAdapterSelectors,
    requirementsSelectors: aisRequirementsSelectors,
    requestsToPublishSelectors: aisRequestsToPublishSelectors,
    requestsSelectors: aisRequestsSelectors,
    includedDocumentsByTaxSelectors: aisRegisterIncludedDocumentsByTaxEntityAdapterSelectors,
    selectAisInterfaceDocumentsFields,
    documentsInterfaceSelectors: aisInterfaceDocumentsEntityAdapterSelectors,
};

export const {
    reducer: aisSliceReducer,
    actions: aisSliceActions,
} = aisSlice;
