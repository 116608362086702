import {PlusCircleOutlined} from '@ant-design/icons';
import {
    Button,
    Form,
    Input,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {useForm} from 'antd/lib/form/Form';
import React, {useEffect, useState} from 'react';

import {ButtonClearForm} from 'components/form/actions/button-clear-form/button-clear-form';
import {CustomSelect, CustomSelectMode} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {selectContextRawData} from 'modules/context/context-selector';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    AisNotificationScenariosSettingsAllowedCode,
    AisNotificationScenariosSettingsServices,
    AisNotificationScenariosSettingsTableDto,
    createAisNotificationScenariosSetting,
    updateAisNotificationScenariosSetting,
} from 'store/slices/ais-slice/ais-notification-scenarios-settings';

interface NotificationScenariosSettingsModalOpenerProps {
  fetchTableData: () => void;
  notificationScenariosServices: AisNotificationScenariosSettingsServices[];
  isEditing?: boolean;
  initialNotificationsValues?: AisNotificationScenariosSettingsTableDto;
}

const DEFAULT_NOTIFICATION_SETTINGS_VALUES: Partial<AisNotificationScenariosSettingsTableDto> = {
    notificationName: undefined,
    interactionScenarioId: undefined,
    responseCodeId: undefined,
    recipientGroupsIds: undefined,
    recipientUsersIds: undefined,
    notificationMessage: undefined,
};

export const NotificationScenariosSettingsModalOpener: React.FC<NotificationScenariosSettingsModalOpenerProps> = ({
    fetchTableData,
    notificationScenariosServices,
    isEditing = false,
    initialNotificationsValues,
}: NotificationScenariosSettingsModalOpenerProps) => {
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allowedResponseCodes, setAllowedResponseCodes] = useState<AisNotificationScenariosSettingsAllowedCode[]>(
        isEditing
            ? (
                notificationScenariosServices.find(
                    e => e.id === initialNotificationsValues?.interactionScenarioId,
                )?.allowedCodes ?? []
            )
            : [],
    );
    const [form] = useForm<Partial<AisNotificationScenariosSettingsTableDto>>();
    const {organizationId} = useAppSelector(selectContextRawData) || {};

    const handleCancel = () => {
        setIsModalOpen(prev => !prev);
        form.resetFields();
    };

    const handleSave = async () => {
        const values = {
            ...form.getFieldsValue(),
            organizationId,
        };
        form.validateFields().then(async () => {
            try {
                if (isEditing && initialNotificationsValues) {
                    await dispatch(updateAisNotificationScenariosSetting({
                        id: initialNotificationsValues.id,
                        ...values,
                    })).unwrap();
                } else {
                    await dispatch(createAisNotificationScenariosSetting(values)).unwrap();
                }

                fetchTableData();
                handleCancel();
            } catch (err) {
                const errorMessage = err?.response?.data
              || `Произошла ошибка при ${isEditing ? 'редактировании ' : 'сохранении'} данных`;
                showMessage({
                    message: errorMessage,
                    isError: true,
                });
            }
        });
    };

    const setAllowedResponseCodesList = (value: number | undefined) => {
        setAllowedResponseCodes(
            notificationScenariosServices
                .find(e => e.id === value)?.allowedCodes ?? [],
        );
    };

    useEffect(() => {
        if (initialNotificationsValues) {
            setAllowedResponseCodesList(initialNotificationsValues.interactionScenarioId);
        }
    }, [notificationScenariosServices]);

    return (
        <ModalOpenerComponent
            isOpen={isModalOpen}
            shouldConfirm={() => form.isFieldsTouched()}
            setIsOpen={setIsModalOpen}
            controlLabels={{
                save: 'Сохранить',
            }}
            handleCancel={handleCancel}
            handleSave={handleSave}
            tooltip={!isEditing ? {title: 'Редактировать уведомление'} : undefined}
            extraControls={(
                <ButtonClearForm
                    handleClearForm={() => form.setFieldsValue(DEFAULT_NOTIFICATION_SETTINGS_VALUES)}
                    actionTitle="Очистить форму"
                    confirmText="Вы действительно хотите очистить форму?"
                    approveText="Очистить"
                />
            )}
            component={(
                <>
                    {
                        isEditing
                            ? (
                                <Button
                                    type="link"
                                >
                                    {initialNotificationsValues?.notificationName ?? ''}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    icon={<PlusCircleOutlined />}
                                >
                                    Создать
                                </Button>
                            )
                    }
                </>
            )}
            modalProps={{
                title: isEditing ? 'Редактирование уведомления' : 'Создание уведомления',
                forceRender: true,
                destroyOnClose: false,
                centered: true,
                width: '680px',
            }}
        >
            <Form
                className="form-flex"
                layout="vertical"
                form={form}
                initialValues={initialNotificationsValues ?? DEFAULT_NOTIFICATION_SETTINGS_VALUES}
            >
                <Form.Item
                    label="Наименование уведомления"
                    name="notificationName"
                    className="ant-form-item"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Наименование уведомления\' должно быть заполнено',
                        },
                    ]}
                >
                    <Input
                        placeholder="Введите наименование"
                    />
                </Form.Item>

                <Form.Item
                    label="Сервис"
                    name="interactionScenarioId"
                    className="ant-form-item"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Сервис\' должно быть заполнено',
                        },
                    ]}
                >
                    <CustomSelect
                        entries={notificationScenariosServices.map(e => ({
                            label: e.serviceNameRus,
                            value: e.id,
                        }))}
                        onChange={value => {
                            setAllowedResponseCodesList(value);
                        }}
                        settings={{
                            placeholder: 'Выберите номер сервиса',
                            showSearch: true,
                            isClearable: true,
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Код ответа запроса"
                    name="responseCodeId"
                    className="ant-form-item"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Код ответа запроса\' должно быть заполнено',
                        },
                    ]}
                >
                    <CustomSelect
                        entries={allowedResponseCodes.map(e => ({
                            label: `${e.code} - ${e.text}`,
                            value: e.id,
                        }))}
                        settings={{
                            placeholder: 'Выберите код ответа запроса',
                            showSearch: true,
                            isClearable: true,
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Пользователи, являющиеся получателями уведомления"
                    name="recipientUsersIds"
                    className="ant-form-item"
                    rules={[
                        {
                            validator: (_, value) => {
                                if ((
                                    form.getFieldValue('recipientGroupsIds') === undefined
                                    || form.getFieldValue('recipientGroupsIds').length === 0
                                )
                                    && (
                                        value === undefined
                                    || value.length === 0
                                    )
                                ) {
                                    return Promise.reject(new Error(
                                        `Поле 'Пользователи, являющиеся получателями уведомления'
                                        должно быть заполнено`,
                                    ));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <CustomSelect
                        settings={{
                            url: '/valueLists/USERS',
                            labelPath: 'meaning',
                            valuePath: 'id',
                            placeholder: 'Выберите получателей уведомления',
                            mode: CustomSelectMode.multiple,
                            isClearable: true,
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Группы пользователей, являющиеся получателями уведомления"
                    name="recipientGroupsIds"
                    className="ant-form-item"
                    rules={[
                        {
                            validator: (_, value) => {
                                if ((
                                    form.getFieldValue('recipientUsersIds') === undefined
                                    || form.getFieldValue('recipientUsersIds').length === 0
                                )
                                    && (
                                        value === undefined
                                    || value.length === 0
                                    )
                                ) {
                                    return Promise.reject(new Error(
                                        `Поле 'Группы пользователей, являющиеся получателями уведомления'
                                        должно быть заполнено`,
                                    ));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <CustomSelect
                        settings={{
                            url: '/valueLists/ALL_GROUPS',
                            labelPath: 'meaning',
                            valuePath: 'id',
                            placeholder: 'Выберите группу получателей уведомления',
                            mode: CustomSelectMode.multiple,
                            isClearable: true,
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="notificationMessage"
                    label="Текст уведомления"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Текст уведомления\' должно быть заполнено',
                        },
                    ]}
                >
                    <TextArea
                        autoSize
                        placeholder="Введите текст уведомления"
                    />
                </Form.Item>
            </Form>
        </ModalOpenerComponent>
    );
};
