import {EntityState} from '@reduxjs/toolkit';

import {DateTimeDetails} from 'shared/types/dates';
import {OrganizationStructureDto} from 'store/slices/ais-slice/organization-structure/organization-structure-types';

import {AisCommentsRequestResultTableDto, AisInspectorCommentDto} from './ais-comments-request';
import {FetchAisDashboardDataResponseDto} from './ais-dashboards/ais-dashboards-api';
import {AisNotificationScenariosSettingsServices, AisNotificationScenariosSettingsTableDto} from './ais-notification-scenarios-settings';

export type AisSliceState = {
    registerDocuments: EntityState<AisRegisterDocumentDto>;
    registerDocumentsSettings: AisDocRegisterSettings | undefined;
    registerDocumentsFields: AisDocRegisterFields | undefined;

    registerDocumentsByTax: EntityState<AisRegisterDocumentByTaxDto>;
    includedDocumentsByTax: EntityState<AisRegisterDocumentByTaxDto>;

    aisRequirements: EntityState<AisRequirementDto>;
    aisRequestsToPublish: EntityState<AisRequestToPublishDto>;
    aisRequests: EntityState<AisRequestDto>;

    interfaceDocumentsData: EntityState<AisInterfaceDocumentDto>;
    interfaceDocumentsFields: AisDocInterfaceFields | undefined;

    meta: {
        documentsTotalCount?: number;
        documentsByTaxTotalCount?: number;
    };

    aisDashboards: {
        availabilityStatus: any;
        summaryTransactionsProtocol: FetchAisDashboardDataResponseDto | undefined;
        register: FetchAisDashboardDataResponseDto | undefined;
        s4s5RequestsStatistics: FetchAisDashboardDataResponseDto | undefined;
        s3s6s12requestStatistic: FetchAisDashboardDataResponseDto | undefined;
        tokenStatus: any;
        unavailabilityChart: any;
    };

    aisCommentsRequest: {
      aisCommentsRequestTable: AisCommentsRequestResultTableDto[] | undefined;
      aisInspectorCommentInfo: AisInspectorCommentDto | undefined;
    };

    organizationStructure: OrganizationStructureDto | undefined;

    aisNotificationScenariosSettings: {
        notificationScenariosTable: AisNotificationScenariosSettingsTableDto[] | undefined;
        notificationScenariosServices: AisNotificationScenariosSettingsServices[] | undefined;
    };
}

export interface AisRequestToPublishDto {
    docJournalId: string | null;
    topicId: number;
    requestNum: string | null;
    requestDate: string | null;
    responseDeadline: string | null;
    responseDate: string | null;
    status: string | null;
    responseBy: string | null;
}

export interface AisRequestDto {
    ticketId: string;
    requestDate: string;
    requestBody: string;
    responseBody: string;
}

export interface AisRequirementDto {
    requirementNumber: string;
    basisRequirement: string;
    dateReceiptRequirement: string | null;
    responseTime: string | null;
    responseStatus: string | null;
    responseDate: string | null;
    responseBy: string | null;
    responseFTSStatus: string | null;
}

export interface AisRegisterDocumentDto {
    id: number;
    aisDocId: string;
    baseDocCode: string;
    baseDocDate: string;
    baseDocName: string;
    baseDocNumber: string;
    contentAvailability: number;
    cpartyCountryCode: string;
    cpartyForeignRegNum: string;
    cpartyInn: string;
    cpartyKpp: string;
    cpartyName: string;
    docCode: string;
    docDate: {
        year: number;
        month: string;
        dayOfYear: number;
        monthValue: number;
        dayOfMonth: number;
        chronology: {
            calendarType: string;
            id: string;
        };
        era: string;
        leapYear: boolean;
        dayOfWeek: string;
    };
    docName: string;
    docNumber: string;
    docSumGross: number;
    docTaxSum: number;
    orgId: number;
  }

export interface AisRegisterTax {
    kndCode: string;
    reportingYear: number;
    taxCode: string;
    corrNum: string;
    taxPeriod: string;
    mappingId: number;

    section: string;
    subsection: string;
}

export interface AisDocRegisterSettings {
    useParameters: boolean;
    quantityLines: number | undefined;
    contentAvailabilityCondition: boolean;
    useIdAutogeneration: boolean;
}

export interface AisDocRegisterRawField {
    id: string;
    columnOrdinal: number;
    userName: string;
    field: string;
    fieldType: string;
    display: boolean;
    isParam: boolean;
    paramRequired: boolean;
    paramType: string;
    paramLov: string;
}

export interface AisDocRegisterField {
    id: string;
    columnOrdinal: number;
    label: string;
    fieldKey: string;
    type: string;
}

export interface AisDocRegisterFilter extends AisDocRegisterField {
    isRequired: boolean;
    referenceUrl: string;
}

export interface AisDocRegisterFields {
    columns: AisDocRegisterField[] | undefined;
    filters: AisDocRegisterFilter[] | undefined;
}

export enum AisDocRegisterFieldTypes {
    DATE = 'date',
    REFERENCE = 'lov',
    MONETARY = 'number(0,00)',
}

export interface AisDocInterfaceField {
    id: string;
    columnOrdinal: number;
    label: string;
    fieldKey: string;
    type: string;
}

export interface AisDocInterfaceFilter extends AisDocRegisterField {
    isRequired: boolean;
    referenceUrl: string;
}

export interface AisDocInterfaceFields {
    columns: AisDocInterfaceField[] | undefined;
    filters: AisDocInterfaceFilter[] | undefined;
}

export enum AisDocInterfaceFieldTypes {
    DATE = 'date',
    REFERENCE = 'lov',
    MONETARY = 'numeric(19,2)',
}

export interface AisInterfaceDocumentDto {
    aisDocId: string;
    author: string;
    contentAvailability: number;
    counterpartyCountryCode: string;
    counterpartyForeignRegNumber: string;
    counterpartyInn: string;
    counterpartyKpp: string;
    counterpartyName: string;
    createdById: number;
    createdDate: DateTimeDetails;
    dateFrom: string;
    dateTo: string;
    docId: number;
    docJournalId: number;
    documentBaseCode: string;
    documentBaseDate: string;
    documentBaseName: string;
    documentBaseNumber: string;
    documentCatCode: string;
    documentCode: string;
    documentDate: string;
    documentName: string;
    documentNumber: string;
    documentSumGross: number;
    documentTaxSum: number;
    editor: string;
    id: number;
    lastModifiedById: number;
    lastModifiedDate: DateTimeDetails;
    loadId: number;
    orgId: number;
    statusCode: string;
  }

export enum AisArchiveSystemCode {
    'Showcase' = 'Showcase' // Витрина
}

export type AisRegisterDocumentByTaxDto = AisRegisterDocumentDto & AisRegisterTax;
