import {Form} from 'antd';
import {FormInstance} from 'antd/es/form';
import React from 'react';

import {
    dynamicFieldResolver,
} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/monitor-service-dynamic-fields.utils';
import {fieldDefaultWidthResolver} from 'components/form/field-type-resolver';
import {fieldValidationResolver, maskValidation} from 'components/form/utils/field-validation-resolver';
import {OPENTEXT_LOOKUP_CODE} from 'components/table/action/open-launch-program-modal';
import {AdministrationOpenTextFieldsService} from 'components/table/action/open-launch-program-modal/open-launch-program-modal.constants';
import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {FieldType} from 'modules/metadata';
import {ValidationRules} from 'modules/metadata/metadata-types';
import {ProgramArg} from 'store/api/monitor-services/monitor-services.types';
import {useAppSelector} from 'store/config/hooks';

interface AdministrationMonitorServiceDynamicFieldsProps {
    form: FormInstance;
    fields: ProgramArg[] | undefined;
    entityName: string;
    programName: string;
}

export const AdministrationMonitorServiceDynamicFields = ({
    form,
    fields,
    entityName,
    programName,
}: AdministrationMonitorServiceDynamicFieldsProps) => {
    const documentsSigningMethod = useAppSelector(selectDocumentsSigningMethod);

    if (!fields?.length) return null;

    const getValidationRulesForMonitorServiceField = (field: ProgramArg): ValidationRules[] | undefined => {
        if (programName === OPENTEXT_LOOKUP_CODE) {
            switch (field?.code) {
            case AdministrationOpenTextFieldsService.P_DATE_FROM:
                return [{mask: maskValidation.monitorServiceOpenTextDateFrom}];
            case AdministrationOpenTextFieldsService.P_DATE_TO:
                return [{mask: maskValidation.monitorServiceOpenTextDateTo}];
            case AdministrationOpenTextFieldsService.P_DOC_IDS:
                return [{mask: maskValidation.monitorServiceOpenTextDocIds}];
            default:
                return [];
            }
        }
        return undefined;
    };

    const getDefaultValue = (field: ProgramArg): string | undefined => {
        if (field?.valueType.lookupCode === FieldType.TEXT
        || field?.valueType.lookupCode === FieldType.STRING) {
            return field?.defaultValue ?? undefined;
        }

        return undefined;
    };

    return (
        <>
            {fields?.map(f => (
                f?.displayFlag
                    ? (
                        <Form.Item
                            key={f?.id || f?.code}
                            name={f?.code}
                            label={f?.name}
                            style={{
                                width: fieldDefaultWidthResolver(f?.valueType.lookupCode) ?? '100%',
                                display: !f?.displayFlag ? 'none' : 'block',
                            }}
                            rules={fieldValidationResolver({
                                validationRules: getValidationRulesForMonitorServiceField(f),
                                fieldMeta: {
                                    type: f?.valueType.lookupCode ?? FieldType.STRING,
                                    label: f?.name ?? '',
                                    isRequired: f?.requiredFlag,
                                    isDisabled: !f.enabledFlag,
                                },
                            })}
                            initialValue={getDefaultValue(f)}
                        >
                            {dynamicFieldResolver({
                                form,
                                documentsSigningMethod,
                                entityName,
                                type: f?.valueType.lookupCode,
                                isDisabled: !f.enabledFlag,
                                defaultValue: getDefaultValue(f),
                                referenceUrl: `lookupValue/${f.valuesetLookupType?.lookupType}`,
                                key: f.code,
                                useFirstOptionAsDefaultValue: false,
                            })}
                        </Form.Item>
                    ) : null
            ))}
        </>
    );
};
