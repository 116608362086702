import {FormInstance} from 'antd/es/form';

import {StateSetter} from 'shared/types/generics';

export enum DirectoriesCompanyTabs {
    common = 'common',
    aisSignatories = 'aisSignatories',
}

export type DirectoriesCompanyDto = {
    id: string;
    address: string;
    contactPerson: string;
    contactPhone: string;
    externalCode: string;
    inn: string;
    kpp: string;
    kpp2: string;
    name: string;
    ogrn: string;
    okpo: string;
    okved: string;
    organizationCode: string;
    organizationFnsName: string;
    parentOrgId: string;
    postAddress: string;
    signCertCode: string;
    startDate: string;
    endDate: string;
};

export interface DirectoriesCompanyFormTabProps {
    form: FormInstance;
    isEntityExists: boolean;
    isDisabled: boolean;
    setDirty: StateSetter<boolean>;
    resetFormStatus: () => void;
}
