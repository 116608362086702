import {TableColumnFilterExpressionDto} from 'components/@common/widgets/custom-table/table-column-filter/table-column-filter-types';
import {ReportConfigurationReportGroupDto} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {
    TableReportColumnSortForRequest,
    TableReportDrilldownParameter,
    TableReportQueryParameters,
    TableReportRequestParameters,
} from 'store/slices/table-report-slice/table-report-slice-types';

export enum DesignTemplates {
    DECLARATION = 'DECLARATION',
    REGISTERS = 'REGISTERS',
    TABLE_REPORT_DESIGN_TEMPLATE_WITH_PARAMETER = 'TABLE_REPORT_DESIGN_TEMPLATE_WITH_PARAMETER',
    DOCS_FILTERS = 'DOCS_FILTERS',
    POSTING_CARD = 'POSTING_CARD',
    REGISTER_WITH_ROW_HEADERS = 'REGISTER_WITH_ROW_HEADERS',
}

export interface TableReportPassedDrilldownParams {
    drillId?: string;
    drillParameters?: TableReportDrilldownParameter[];
}

export interface TableReportRequestTopicParams {
    TOPIC_REQUEST_ID?: string;
    TOPIC_REQUEST_PARAMS?: TableReportRequestParameters;
}

export interface TableReportLocationDefaults {
    locationPage?: number;
    locationSize?: number;
    locationFilters?: TableColumnFilterExpressionDto;
    locationSort?: TableReportColumnSortForRequest[];
    locationParameters?: TableReportQueryParameters;
    locationReportGroups?: {
        reportGroups: { [reportGroupName: string]: ReportConfigurationReportGroupDto } | undefined;
        groupAfterUserFilter: boolean | undefined;
    };
}

export interface TableReportProps {
    entityName?: string;
    docId: string | null;
    templateCode: string;
}

export interface ReportRowLinks {
    reportRowId: string;
    links: {
        columnName: string;
        link: string | number | object | boolean;
    }[];
}
