import {ButtonType} from 'antd/lib/button';
import React, {FunctionComponent} from 'react';

import {SimpleActionButton, SimpleActionButtonProps} from 'components/form/components';
import {useAppDispatch} from 'store/config/hooks';
import {openModal} from 'store/slices/modals-slice';

interface ButtonOpenModalProps extends Pick<SimpleActionButtonProps, 'title' | 'icon'> {
    modalName: string;
    modalEntityName?: string;
    buttonType?: ButtonType;
}

export const ButtonOpenModal: FunctionComponent<ButtonOpenModalProps> = ({
    modalEntityName,
    modalName,
    buttonType,
    ...props
}: ButtonOpenModalProps) => {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(openModal({modalName, entityName: modalEntityName}));
    };
    return (
        <SimpleActionButton
            type={buttonType}
            onClick={handleClick}
            {...props}
        />
    );
};
