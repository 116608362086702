import {Collapse, Timeline} from 'antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';

import {
    SignAndFileListUploader,
} from 'components/form/inputs/uploader-list/sign-and-file-list-uploader/sign-and-file-list-uploader';
import {RichTextRenderer} from 'components/rich-text';
import {ReactComponent as CaretDown} from 'shared/assets/caret-down.svg';
import {DATE_WITH_TIME_DOTS} from 'shared/constants/date-format';
import {FileDto} from 'shared/types/files';

import {ReplyMessage} from './reply-message/reply-message';
import {setValueForAllReplies} from './utils';

import './replies.less';

const {Panel} = Collapse;

export interface RequestReply {
    idRequestComment: number;
    createDate: string;
    author: string;
    addressees: string;
    content: string;
    attachments: FileDto[];
}

interface RepliesProps {
    entityName?: string;
    data?: RequestReply[];
    collapseIsOpen?: boolean;
}

export const Replies: React.FC<RepliesProps> = ({
    data = [],
    collapseIsOpen = false,
    entityName,
}: RepliesProps) => {
    const [replyInfo, setReplyInfo] = useState(setValueForAllReplies(data, collapseIsOpen));

    useEffect(() => {
        setReplyInfo(setValueForAllReplies(data, collapseIsOpen));
    }, [collapseIsOpen]);

    if (!data?.length) return null;
    const timelineItems = data.map((item: RequestReply, index: number) => {
        const isOpen = replyInfo[index];
        const handleChange = () => {
            setReplyInfo({...replyInfo, [index]: !isOpen});
        };
        const panelKey = index;
        return (
            <Timeline.Item
                key={item.idRequestComment}
                label={moment(item.createDate).format(DATE_WITH_TIME_DOTS)}
                color={!isOpen ? 'gray' : undefined}
            >
                <Collapse
                    ghost
                    activeKey={isOpen ? [panelKey] : undefined}
                    onChange={handleChange}
                    expandIcon={({isActive}) => (
                        <CaretDown style={
                            {transform: `rotate(${isActive ? 180 : 0}deg)`}
                        }
                        />
                    )}
                >
                    <Panel
                        header={(
                            <ReplyMessage
                                author={item.author}
                                addresses={item.addressees}
                            />
                        )}
                        key={panelKey}
                    >
                        <RichTextRenderer value={String(item.content)} />
                        {item.attachments && !!item.attachments?.length && (
                            <SignAndFileListUploader
                                disabled
                                requestCommentId={item.idRequestComment.toString()}
                                fileList={item.attachments as FileDto[]}
                                url={entityName}
                                signUrl={entityName}
                                dataKeyForDownload="id"
                                isDownloadDocumentList
                            />
                        )}
                    </Panel>
                </Collapse>
            </Timeline.Item>
        );
    });

    return (
        <Timeline
            className="message-replies"
            mode="left"
        >
            {timelineItems}
        </Timeline>
    );
};
