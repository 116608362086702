import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import {Spinner} from 'components/spinner';
import {AuthPage} from 'pages/auth-page/auth-page';
import {LoginForm} from 'pages/login-page/login-form';
import {useProfileLoader} from 'pages/login-page/use-profile-loader/use-profile-loader';
import {urlRegExp} from 'shared/regular-expressions';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    AuthRoutes,
    EXTERNAL_AUTH_PROFILES,
    getTwoFAConfig,
    selectAuthPasswordExpired,
    selectAuthToken,
} from 'store/slices/auth-slice';
import 'pages/login-page/login-page.less';
import {checkAuthStatus} from 'store/slices/auth-slice/auth-slice-thunks';
import {AuthProfile} from 'store/slices/auth-slice/auth-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

export const LoginPage: React.FC = () => {
    const dispatch = useAppDispatch();

    const token = useAppSelector(selectAuthToken);
    const passwordExpiredFlag = useAppSelector(selectAuthPasswordExpired);
    const isAuthenticationCheckLoading = useAppSelector(s => selectIsThunkPending(s, checkAuthStatus.typePrefix));

    const {
        profile,
        profileLoaderStatusJsx,
    } = useProfileLoader();

    const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

    const isAuthorized = profile && !!token;

    useEffect(() => {
        if (!profile) {
            return;
        }

        if (EXTERNAL_AUTH_PROFILES.includes(profile)) {
            dispatch(checkAuthStatus())
                .unwrap()
                .catch(response => {
                    if (urlRegExp.test(response)) {
                        setRedirectUrl(response);
                    }
                });
        }
        if (profile === AuthProfile.LDAP) {
            dispatch(getTwoFAConfig());
        }
    }, [profile]);

    return (
        <AuthPage
            className="d-flex flex-direction-column justify-content-center align-items-center"
        >
            {(() => {
                if (isAuthorized) {
                    return <Redirect to="/" />;
                }

                if (passwordExpiredFlag) {
                    return <Redirect to={AuthRoutes.CHANGE_PASSWORD} />;
                }

                return (
                    <>
                        {(() => {
                            if (!profile) {
                                return profileLoaderStatusJsx;
                            }

                            if (isAuthenticationCheckLoading) {
                                return <Spinner tip="Аутентификация пользователя..." />;
                            }

                            return (
                                <LoginForm
                                    profile={profile}
                                    redirectUrl={redirectUrl}
                                />
                            );
                        })()}
                    </>
                );
            })()}
        </AuthPage>
    );
};
