import {AxiosError, AxiosResponse} from 'axios';
import FileDownload from 'js-file-download';
import {Dispatch, SetStateAction} from 'react';

import {FileInfo} from 'modules/data';
import {LookupEntry} from 'shared/types/lookups';
import {
    convertToFormData, performRequest, RequestPromise, showMessageFromResponse,
} from 'shared/utils';
import {JsonFormValue} from 'shared/utils/convert-to-form-data';

import {DocumentsUploadConfig} from '../../components/documents/file-modal/hooks';
import {RequestType} from '../metadata';
import {
    DocumentTypeResponse,
    DocumentUploadFileResponse,
    DocumentUploadSignatureResponse,
    FileAttachInfoResponse,
    FileSizeFormat,
    DocumentTemplatesTypeResponse,
    DocumentsSigningMethod,
    DocumentFileInfo,
} from './documents-types';

export interface FetchDocumentsForUploadAttachArgs {
    sublistCode: string;
}
export const fetchDocumentsForUploadAttach = async (
    {sublistCode}: FetchDocumentsForUploadAttachArgs,
) => performRequest<LookupEntry[]>({
    url: 'valueLists/DOCUMENTS_FOR_UPLOAD_ATTACH',
    method: RequestType.GET,
    params: {
        sublistCode,
    },
});

export interface DownloadDocumentScanArgs {
    attachmentId: string;
    fileName?: string;
}

export const downloadDocumentScan = async (
    {attachmentId, fileName = 'scan'}: DownloadDocumentScanArgs,
) => {
    const fileResponse = await performRequest({
        url: `download-attached/${attachmentId}`,
        responseType: 'blob',
        method: RequestType.POST,
    });

    FileDownload(fileResponse.data, fileName);

    return fileResponse;
};

export interface DownloadScansArchiveByDocumentIdArgs {
    docId: string;
    fileName?: string;
}
export const downloadScansArchiveByDocumentId = async (
    {docId, fileName = 'archive.zip'}: DownloadScansArchiveByDocumentIdArgs,
) => {
    const fileResponse = await performRequest({
        url: `download-zip-attached/${docId}`,
        responseType: 'blob',
        method: RequestType.POST,
    });

    FileDownload(fileResponse.data, fileName);

    return fileResponse;
};

export interface FetchDocumentScansByIdArgs {
    docId: string;
}
export const fetchDocumentScansById = ({docId}: FetchDocumentScansByIdArgs) => performRequest<FileInfo[]>({
    url: `scan-attached/${docId}`,
});

export interface DeleteDocumentScanArgs {
    attachmentId: string;
}
export const deleteDocumentScan = ({
    attachmentId,
}: DeleteDocumentScanArgs) => performRequest<string>({
    url: `delete-attached/${attachmentId}`,
    headers: {
        'Content-Type': 'application/json',
    },
    method: RequestType.DELETE,
});

export interface DeleteDocumentScansByDocumentIdArgs {
    docId: string;
}
export const deleteDocumentScansByDocumentId = ({
    docId,
}: DeleteDocumentScansByDocumentIdArgs) => performRequest<string>({
    url: `delete-all-attached/${docId}`,
    method: RequestType.DELETE,
});
export interface UploadAttachedArgs {
    file: File;
    docId: string;
    handleProgress?: (progressEvent: ProgressEvent) => void;
}
export const uploadAttachedToDocument = ({
    docId,
    file,
    handleProgress,
}: UploadAttachedArgs) => performRequest((() => {
    const formData = new FormData();

    formData.append('file', file);

    return {
        url: `upload-attached/${docId}`,
        data: formData,
        method: RequestType.POST,
        onUploadProgress: progressEvent => {
            handleProgress?.(progressEvent);
        },
    };
})());

export const fetchDocumentTypesOnUpload = (): RequestPromise<DocumentTypeResponse[]> => performRequest({
    method: RequestType.GET,
    url: 'valueLists/DOCUMENT_TYPE_ON_UPLOAD',
});

export const fetchDocumentTypes = (): RequestPromise<DocumentTypeResponse[]> => performRequest({
    method: RequestType.GET,
    url: 'lookupValue/DOCUMENT_TYPE',
});

export type DocumentInfoParams = {
    attachId?: string | number;
    sectionCode?: string;
    sgnFileName?: string;
}

export const fetchDocumentInfo = (
    params: DocumentInfoParams,
): RequestPromise<DocumentTypeResponse[]> => performRequest({
    method: RequestType.GET,
    url: 'fileupload/getDocument',
    params,
});

export const fetchDocumentAttachInfo = (
    {attachId}: {attachId: string},
): RequestPromise<DocumentFileInfo> => performRequest({
    method: RequestType.GET,
    url: `upload-attached-other/${attachId}`,
});

export const fetchBranchesLinkingFlag = (): RequestPromise<string> => performRequest({
    method: RequestType.GET,
    url: 'property/LINKING_DOCUMENTS_TO_BRANCHES_WHEN_UPLOADED',
});

export interface ContextParamType {
    organizationId?: number;
    taxPeriodId?: number;
    taxTypeId?: number;
}

interface DocumentForSignType extends ContextParamType {
    entityName?: string;
}

export const fetchAcceptableExtensions = (): RequestPromise<string> => performRequest({
    method: RequestType.GET,
    url: '/property/SIGN_AVAIL_EXTENSIONS',
});
export const fetchDocumentsForSign = (
    contextParam: DocumentForSignType,
): RequestPromise<DocumentTypeResponse[]> => performRequest({
    method: RequestType.POST,
    url: '/fileupload/documentAttachments',
    data: contextParam,
});

export const fetchDefaultFileInfo = (
    sectionCode: string,
    subsectionCode: string,
    files: File[],
): RequestPromise<DocumentUploadFileResponse[]> => {
    const formData = new FormData();
    formData.append('sectionCode', sectionCode);
    formData.append('subsectionCode', subsectionCode);
    files.forEach((file, index) => formData.append(`files[${index}]`, file));

    return performRequest({
        method: RequestType.POST,
        url: 'fileupload/save',
        data: formData,
    });
};

interface DefaultSignInfoRequest extends ContextParamType {
    entityName?: string;
    files?: File[];
}

export const fetchDefaultSignInfo = (
    data: DefaultSignInfoRequest,
): RequestPromise<DocumentUploadSignatureResponse[]> => {
    const formData = convertToFormData(data as Record<string, JsonFormValue>);

    return performRequest({
        method: RequestType.POST,
        url: 'fileupload/sign-attach-info/list',
        data: formData,
    });
};

const createParserRequest = (url: string) => (
    id: string | number,
) => (
    performRequest({
        method: RequestType.POST,
        url: `${url}/${id}`,
    })
);

const runProgramParser = createParserRequest('/fileupload/runProgramParser');
const runXmlParser = createParserRequest('/fileupload/runXmlParser');

export const runXmlAndProgramParser = async (
    id: string | number,
    {
        onParsingStart,
        onParsingSuccess,
        onParsingException,
    }: ParserRequestOptions,
) => {
    onParsingStart();

    try {
        const parsingResults = await Promise.all([
            runXmlParser(id),
            runProgramParser(id),
        ]);

        const successMessage = parsingResults
            .map(result => result.data.message)
            .join('. ');

        onParsingSuccess(successMessage);
    } catch (error) {
        const exceptionMessage = error?.response.data.message;
        onParsingException(exceptionMessage);
    }
};

export interface ParserRequestOptions {
    onParsingStart: () => void;
    onParsingSuccess: (message?: string) => void;
    onParsingException: (message?: string) => void;
}

export interface UploadFilesRequestOptions extends ParserRequestOptions {
    onFileUploadProgress: (progressEvent: any) => void;
    onFileUploadException: (message?: string) => void;
    onFileUploadSuccess: (message?: string) => void;
}

export interface UploadFilesRequestParams {
    organizationId?: number;
    taxPeriodId?: number;
    userId?: number;
    docUploadTemplateCode?: string;
}

export interface UploadSignsRequestOptions {
    onFileUploadProgress: (progressEvent: any) => void;
    onFileUploadException: (message?: string) => void;
    onFileUploadSuccess: (message?: string) => void;
}

const createFileUploadRequest = (url: string) => (
    data: Record<string, any>,
    {
        onFileUploadProgress,
        onFileUploadException,
    }: UploadFilesRequestOptions,
    params?: UploadFilesRequestParams,
) => performRequest({
    method: RequestType.POST,
    url,
    onUploadProgress: onFileUploadProgress,
    data: convertToFormData(data),
    params,
}).catch(({response}: AxiosError) => {
    showMessageFromResponse({response, isError: true});
    onFileUploadException(response?.data?.message);
});

export const uploadDocumentFileRequest = createFileUploadRequest('fileupload');

const createSignUploadRequest = (url: string) => (
    data: Record<string, any>,
    {onFileUploadProgress, onFileUploadException, onFileUploadSuccess}: UploadSignsRequestOptions,
) => performRequest({
    method: RequestType.POST,
    url,
    onUploadProgress: onFileUploadProgress,
    data: convertToFormData(data),
})
    .then((response: AxiosResponse) => {
        const successMessage = response.data?.message;

        onFileUploadSuccess(successMessage);
    })
    .catch(({response}: AxiosError) => onFileUploadException(response?.data.message));

export const uploadDocumentSignatureRequest = createSignUploadRequest('fileupload/saveSgnFile');

export const fetchFileUploadProperties = async (
    setDocumentsUploadConfig: Dispatch<SetStateAction<DocumentsUploadConfig>>,
) => {
    await performRequest({
        method: RequestType.GET,
        url: 'property/FILE_SIZE_UOM',
    }).then(data => {
        if (Object.values(FileSizeFormat).includes(data?.data?.toUpperCase())) {
            setDocumentsUploadConfig(v => ({...v, fileSizeFormat: data.data}));
        } else {
            setDocumentsUploadConfig(v => ({...v, fileSizeFormat: null}));
        }
    }).catch(() => setDocumentsUploadConfig(v => ({...v, fileSizeFormat: null})));

    await performRequest({
        method: RequestType.GET,
        url: 'property/UNACCEPTED_FILE_EXTENSIONS_FOR_UPLOADS',
    }).then(
        res => res.data && typeof res.data === 'string' && setDocumentsUploadConfig(
            v => ({...v, unacceptedFileExtensions: res.data.split(',')}),
        ),
    ).catch(() => setDocumentsUploadConfig(
        v => ({...v, unacceptedFileExtensions: undefined}),
    ));

    await performRequest({
        method: RequestType.GET,
        url: 'property/NEED_START_DOC_DATE',
    }).then(res => res?.data && typeof res.data === 'string' && setDocumentsUploadConfig(
        v => ({...v, useUploadDate: res.data !== 'N'}),
    )).catch(() => setDocumentsUploadConfig(v => ({...v, useUploadDate: undefined})));
};
export const fetchBranchNames = (): RequestPromise<DocumentTypeResponse[]> => performRequest({
    method: RequestType.GET,
    url: 'valueLists/BRANCHES_COMPANIES',
});

export const fetchDocumentsSigningMethod = (): RequestPromise<DocumentsSigningMethod> => performRequest({
    method: RequestType.GET,
    url: 'property/DOCUMENT_SIGNING_METHOD',
});

export const fetchFileAttachInfo = (
    sectionCode: string, subsectionCode: string, fileNames: string[],
): RequestPromise<FileAttachInfoResponse[]> => performRequest({
    method: RequestType.POST,
    url: 'fileupload/attach-info',
    params: {sectionCode, subsectionCode},
    data: fileNames,
});

export const fetchTemplateTypesForFile = async (lookupType: string
  | undefined): Promise<DocumentTemplatesTypeResponse[]> => {
    try {
        const res = await performRequest({
            method: RequestType.GET,
            url: 'valueLists/DOCUMENT_TYPES_TEMPLATES',
            params: {sublistCode: lookupType},
        });
        return res.data;
    } catch (e) {
        console.error('Невозможно получить значения по умолчанию для загружаемых файлов', e);
    }
    return [];
};
