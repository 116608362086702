import React from 'react';

import {AIS_ROUTES} from 'components/ais/ais.constants';
import {AisCommentsRequestResultPage} from 'pages/ais/comments-request-result';
import {AisDocumentsByTaxRegister} from 'pages/ais/documents-by-tax-register-page';
import {AisDocumentsInterface} from 'pages/ais/documents-interface-page';
import {AisDocumentsRegister} from 'pages/ais/documents-register-page';
import {FileSharingCard} from 'pages/ais/file-sharing/file-sharing-card';
import {MonitorServicesPage} from 'pages/ais/monitor-services-page/monitor-services-page';
import {AisNotificationScenariosSettingsPage} from 'pages/ais/notification-scenarios-settings';
import {OrganizationStructurePage} from 'pages/ais/organization-structure/organization-structure-page';
import {ReclaimingInformation} from 'pages/ais/reclaiming-information/reclaiming-information';
import {FormAwareTableProps} from 'pages/table-page/query-params-aware-table/query-params-aware-table';

type AisPageResolverProps = Pick<FormAwareTableProps,
    'entityName'
    | 'entityType'
    | 'url'
    | 'hideTitle'
    | 'scroll'
    > & {
    entity: string | null;
    handleFormClose: () => void;
}

export const AisPageResolver = ({
    entity, entityName, entityType, url, handleFormClose, scroll, hideTitle,
}: AisPageResolverProps) => {
    switch (entityName) {
    case AIS_ROUTES.FILE_EXCHANGE_ENTITY_NAME:
        return (
            <FileSharingCard
                url={url}
                entityType={entityType}
                entityName={entityName}
                entity={entity}
                hideTitle={hideTitle}
                scroll={scroll}
            />
        );

    case AIS_ROUTES.RECLAIMING_INFORMATION:
        return (
            <ReclaimingInformation
                url={url}
                entityType={entityType}
                entityName={entityName}
                entity={entity}
                hideTitle={hideTitle}
                scroll={scroll}
            />
        );

    case AIS_ROUTES.MONITOR_SERVICES:
        return (
            <MonitorServicesPage
                url={url}
                entityType={entityType}
                entityName={entityName}
                hasEntity={!!entity}
                hideTitle={hideTitle}
                scroll={scroll}
                handleFormClose={handleFormClose}
            />
        );

    case AIS_ROUTES.NOTIFICATION_SCENARIOS_SETTINGS:
        return <AisNotificationScenariosSettingsPage />;

    case AIS_ROUTES.DOCUMENTS_REGISTER:
        return <AisDocumentsRegister />;

    case AIS_ROUTES.DOCUMENTS_INTERFACE:
        return <AisDocumentsInterface />;

    case AIS_ROUTES.DOCUMENTS_BY_TAX_REGISTER:
        return <AisDocumentsByTaxRegister />;

    case AIS_ROUTES.COMMENTS_REQUEST_RESULT:
        return <AisCommentsRequestResultPage />;

    case AIS_ROUTES.ORGANIZATION_STRUCTURE:
        return <OrganizationStructurePage />;

    default:
        return null;
    }
};

type IsAisPageArgs = Pick<FormAwareTableProps, 'entityName'>;

export const isAisPage = ({entityName}: IsAisPageArgs) => Object.values(AIS_ROUTES).includes(entityName);
