import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

export enum FormMode {
    EDIT = 'edit',
    CREATE = 'create',
    VIEW = 'view',
    REPRESENTATION = 'representation', // full disabled, only shows values
}

export interface ReportAdditionalValues {
  reportRowIds?: string[];
  docId?: string | null;
  templateCode?: string;
  reportSectionName?: string | null;
  reportParameters?: string;
  reportRowLinks?: string;
}
export interface RoleModelSection{
    functionName: UserRoleFunction;
    section: string;
}
