// утилиты, связанные с метаданными

import {MetaActionInfo} from 'modules/metadata';

interface AreRequiredQueryParamsPresentProps {
    requiredQueryParams: MetaActionInfo['requiredQueryParams'];
    getQueryParam: (name: string) => (string | null);
}
export const areRequiredQueryParamsPresent = ({
    requiredQueryParams,
    getQueryParam,
}: AreRequiredQueryParamsPresentProps): boolean => (
    !requiredQueryParams?.some((rp: string) => !getQueryParam(rp))
);
