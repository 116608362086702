import {createAttachedSignature, createDetachedSignature} from 'crypto-pro';

import {fetchUnencryptedMessage} from '../api';
import {CryptoProSignatureType} from '../model/types';

interface GetSignatureByTypeArgs {
    selectedSignatureType: CryptoProSignatureType;
    certificateThumbprint: string;
    certificateCadescomAlgorithm: number;
    docId?: string;
    fileHash: string;
    fileArrayBuffer?: ArrayBuffer;
    entityName?: string;
}

/**
 * Создаёт подпись (отсоединённую или присоединённую)
 * для переданного файла с использованием криптографического сертификата `crypto-pro`.
 *
 * @param selectedSignatureType Тип подписи:
 * - `'detached'` — отсоединённая подпись, создаётся на основе хэша файла.
 * - `'attached'` — присоединённая подпись, создаётся на основе необработанного файла или сообщения.
 *
 * @param certificateThumbprint Отпечаток сертификата, который используется для создания подписи.
 *
 * @param certificateCadescomAlgorithm Числовая константа,
 * представляющая криптографический алгоритм, связанный с сертификатом.
 *
 * @param docId Идентификатор документа в системе (опционально).
 * Используется для получения необработанного сообщения, если файл не предоставлен.
 *
 * @param fileHash Хэш файла, используется для создания отсоединённой подписи.
 *
 * @param fileArrayBuffer Объект `ArrayBuffer`, представляющий необработанные данные файла.
 * Используется для создания присоединённой подписи, если сообщение недоступно через API.
 *
 * @param entityName Имя сущности, связанной с документом, используется для получения необработанного сообщения из API.
 *
 * @returns Возвращает `Promise`, который разрешается в строку, представляющую электронную подпись.
 *
 * @throws Генерирует ошибку, если указан неизвестный тип подписи (`selectedSignatureType`).
 */
export const getSignatureByType = async ({
    selectedSignatureType,
    certificateThumbprint,
    certificateCadescomAlgorithm,
    docId,
    fileHash,
    fileArrayBuffer,
    entityName,
}: GetSignatureByTypeArgs): Promise<string> => {
    if (selectedSignatureType === 'detached') {
        const detachedSignature = await createDetachedSignature(
            certificateThumbprint,
            fileHash,
            {hashedAlgorithm: certificateCadescomAlgorithm},
        );
        return detachedSignature;
    }

    if (selectedSignatureType === 'attached') {
        const unencryptedMessage = fileArrayBuffer ?? await fetchUnencryptedMessage({entityName, docId});

        const attachedSignature = await createAttachedSignature(
            certificateThumbprint,
            unencryptedMessage,
        );

        return attachedSignature;
    }

    throw new Error(`Неизвестный тип подписи: ${selectedSignatureType}`);
};
