import React, {ReactText} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {selectContextRawData} from 'modules/context/context-selector';
import {selectEntityData} from 'modules/data';
import {createActionHandlerForDelete} from 'modules/data/data-actions';
import {TableEntityData} from 'modules/data/data-types';
import {RequestType} from 'modules/metadata';
import {EntityType} from 'shared/constants/entities';
import {
    AppControlProceduresResultApi, useGetAppStatisticsConfirmedQuery,
} from 'store/api/app-control-procedures-result/app-control-procedures-result.api';
import {useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';

import {EditableFormTableContext} from '../../../editable-table-modal/editable-form-table-data';
import {ActionButton} from '../action-button';
import {ActionConfirmModal} from '../action-confirm-modal/action-confirm-modal';
import {DEFAULT_CLEAR_ELEMENT_CONFIRM_TEXT} from '../button-action-delete-tab/constants/button-delete-tab-constants';
import {TableActionProps} from '../table-action-types';

interface ButtonActionForClearRowsOwnProps extends TableActionProps {
}

const ButtonActionForClearRowsComponent: React.FunctionComponent<ButtonActionForClearRowsOwnProps> = ({
    wrapper,
    meta,
    entityName,
    ...props
}: ButtonActionForClearRowsOwnProps) => {
    const referenceUrl = meta?.referenceUrl || entityName;
    const {initData} = React.useContext(EditableFormTableContext);
    const context = useAppSelector(selectContextRawData);

    const {data: isStatisticsConfirmed} = useGetAppStatisticsConfirmedQuery(
        {entityName, params: context}, {skip: !entityName},
    );

    const {selectedRowKeys = []} = useSelector((state: AppState) => (
        selectEntityData(entityName,
            EntityType.TABLE)(state) || {}
    ) as TableEntityData);
    const dispatch = useDispatch();
    const actionsForRows = (ids: ReactText[]) => dispatch(
        createActionHandlerForDelete(
            {
                entityName,
                referenceUrl,
                requestType: meta?.requestType || RequestType.POST,
                itemList: meta?.itemListKey ? {[meta.itemListKey]: ids} : {ids},
            },
        ),
    );
    const handleClear = async () => {
        actionsForRows(selectedRowKeys);
        await dispatch(AppControlProceduresResultApi.util.invalidateTags(['AppStatisticsConfirmed']));
    };
    const Component = wrapper || ActionButton;

    return (
        <ActionConfirmModal
            cancelText="Отмена"
            approveText="Очистить"
            modalTitle="Выполнить действие"
            confirmModalContent={DEFAULT_CLEAR_ELEMENT_CONFIRM_TEXT}
            confirmAction={handleClear}
        >
            {handleClick => (
                <Component
                    {...props}
                    onClick={handleClick}
                    actionIcon={meta.actionIcon}
                    disabled={!selectedRowKeys.length || (!initData?.length && isStatisticsConfirmed)}
                >
                    {meta.actionTitle}
                </Component>
            )}

        </ActionConfirmModal>
    );
};

export const ButtonActionForClearRows = ButtonActionForClearRowsComponent;
