import {isNil} from 'lodash';

import {BreadcrumbItem} from 'components/breadcrumbs/breadcrumb';
import {RequestType} from 'modules/metadata';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {HistoryLocationState} from 'shared/hooks/use-app-history/use-app-history-types';
import {useFileViewer} from 'shared/hooks/use-file-viewer';
import {LinkDto, LinkMethod, LinkType} from 'shared/types/links';
import {performRequest} from 'shared/utils';

type ActionType = (() => Promise<void>) | (() => void) | null;

interface UseCustomLinkProps {
    link: LinkDto | undefined;
    breadcrumbs?: BreadcrumbItem[];
    extraStateData?: Partial<HistoryLocationState>;
}

export const useCustomLink = ({link, extraStateData}: UseCustomLinkProps) => {
    const {
        urlType,
        url,
        method,
        bodyParameters,
        queryParameters,
        token,
        filterParameters,
        ignoreRequiredParameters,
    } = link ?? {};

    const {pushWithStateUpdate} = useAppHistory();
    const {loadFile} = useFileViewer({
        url: isNil(url) ? '/' : url,
    });

    const linkMeta = {
        ignoreRequiredParameters,
    };

    const queryParamsString = queryParameters ? `?${new URLSearchParams(queryParameters).toString()}` : '';
    const urlWithParams = `${url}${queryParamsString}`;

    function createState() {
        return {
            ...(filterParameters ? {[LocationStateKey.LINK_FILTER_PARAMETERS]: filterParameters} : {}),
            // Строка балы необходима для сохранения хлебных крошек в более ранней реализации
            // сейчас поле passBreadcrumbs не передается бэкендом на фронт
            // ...(link?.linkProperties?.passBreadcrumbs ? {[LocationStateKey.PASSED_BREADCRUMBS]: breadcrumbs} : {}),
            [LocationStateKey.LINK_META]: linkMeta,
            ...(extraStateData ?? {}),
        };
    }

    // todo - подумать над типизацией возвращаемого значения
    let action: ActionType = null;

    if (!url) return {action};

    if (urlType === LinkType.INTERNAL) {
        action = () => pushWithStateUpdate(urlWithParams, createState());
    } else if (urlType === LinkType.FILE || urlType === LinkType.FILE_WITH_TOKEN) {
        // token приходит сразу готовым, поэтому оба сценария одинаковые.
        const headers = [] as [string, string][];
        if (token) {
            headers.push([
                'Authorization', token,
            ]);
        }
        action = () => loadFile({headers});
    } else {
        action = () => performRequest({
            url,
            method: method === LinkMethod.GET ? RequestType.GET : RequestType.POST, // todo: расширить
            data: (() => {
                if (bodyParameters) {
                    const formData = new FormData();
                    Object.entries(bodyParameters).forEach(([key, value]) => {
                        formData.set(key, value);
                    });
                    return formData;
                }
                return undefined;
            })(),
            params: (() => {
                if (method === LinkMethod.GET && queryParameters) return queryParameters;
                return undefined;
            })(),
            headers: (() => {
                if (token) {
                    return {
                        Authorization: token,
                    };
                }
                return undefined;
            })(),
        }, true);
    }

    return {
        action,
    };
};
