import React, {FunctionComponent} from 'react';
import {useSelector} from 'react-redux';

import {TableActionProps} from 'components/table/action';
import {ActionButton} from 'components/table/action/action-button';
import {selectEntityData} from 'modules/data';
import {formationRequestAction} from 'modules/data/data-actions';
import {TableEntityData} from 'modules/data/data-types';
import {EntityType} from 'shared/constants/entities';
import {AppState} from 'store/config/types';

export const ButtonFormation: FunctionComponent<TableActionProps> = ({
    meta,
    entityName,
    wrapper,
    ...props
}: TableActionProps) => {
    const {selectedRowKeys = []} = useSelector(
        (state: AppState) => (
            selectEntityData(entityName, EntityType.TABLE)(state) || {}
        ) as TableEntityData,
    );
    const handleClick = () => {
        const sectionCodeContext = `app.${entityName}`;
        const referenceUrl = `${meta.referenceUrl}?sectionCodeContext=${sectionCodeContext}` ?? '';
        const formNameMessage = meta.actionTitle.replace('Сформировать агрегат ', '');

        formationRequestAction(
            selectedRowKeys,
            referenceUrl,
            `Формирование агрегата ${formNameMessage} запущено`,
            ' Непредвиденная ошибка, просьба обратиться в поддержку. ',
        );
    };

    const Component = wrapper || ActionButton;
    return (
        <Component
            {...props}
            onClick={handleClick}
            actionIcon={meta.actionIcon}
            disabled={!selectedRowKeys.length}
        >
            {meta.actionTitle}
        </Component>
    );
};
