import {
    CloseOutlined,
    LoadingOutlined, SafetyCertificateOutlined,
} from '@ant-design/icons';
import {Button} from 'antd';
import {FormInstance} from 'antd/lib/form';
import cn from 'classnames';
import React, {useImperativeHandle, useRef, useState} from 'react';

import {IconsMap} from 'components/dynamic-icon';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {FormItemProps} from 'shared/types';
import {showMessage} from 'shared/utils';

import {useCryptoProSigning} from '../../hooks';
import {
    getFilesToSign, isComponentClickPrevented,
} from '../../lib';
import {
    CertificateExtended, CryptoProSignatureType, DocumentToSignInDatabase, FileWithId,
} from '../../model/types';
import {CryptoProSignatureModalContent} from '../signature-modal-content/signature-modal-content';
import s from './sign-with-crypto-pro-input.module.less';

interface SignWithCryptoProInputProps extends FormItemProps {
    className?: cn.Argument;
    value?: FileWithId[];
    entityName: string;
    showSignComponent?: boolean;

    // (!) 1-й вариант работы: указать форму и поля, из которых забирать файлы.
    form?: FormInstance;
    fileFieldNames?: string[]; // названия полей с файлами, которые нужно подписать

    // (!) 2-й вариант работы: передать напрямую файлы, которые надо подписать.
    fileList?: File[];

    // (!) 3-й вариант работы: передать id документов/аттачей на бэке
    documentsToSignInDB?: DocumentToSignInDatabase[];

    // ---
    isSignaturesListHidden?: boolean; // скрывать список подписей из value
    showIconOnly?: boolean; // отображать одну иконку вместо кнопки
    buttonLabel?: string; // надпись на кнопке
}

export const SignWithCryptoProInput = React.forwardRef<any, SignWithCryptoProInputProps>((
    {
        className,
        form,
        value,
        onChange,
        entityName,
        fileFieldNames,
        documentsToSignInDB,
        showSignComponent = true,
        fileList,
        isSignaturesListHidden,
        buttonLabel,
        showIconOnly,
    }: SignWithCryptoProInputProps,
    ref,
) => {
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateExtended>();
    const [selectedSignatureType, setSelectedSignatureType] = useState<CryptoProSignatureType>('detached');
    const [selectedFileExtension, setSelectedFileExtension] = useState<string | undefined>();
    const [isWithPluginInitializationError, setIsWithPluginInitializationError] = useState(false);
    const [isSignButtonTemporarilyInactive, setIsSignButtonTemporarilyInactive] = useState(false);
    const [isWithResign, setIsWithResign] = useState(true);
    const [isSigning, setIsSigning] = useState(false);

    const formRef = useRef<FormInstance>(null);
    const modalRef = useRef<ModalOpenerComponentRef | null>(null);

    useImperativeHandle(ref, () => modalRef.current);

    const {
        handleFinish,
    } = useCryptoProSigning({
        form,
        value,
        onChange,
        entityName,
        fileFieldNames,
        documentsToSignInDB,
        fileList,
        modalRef,
        selectedCertificate,
        isWithResign,
        setIsSigning,
        selectedSignatureType,
        selectedFileExtension,
    });

    const resetState = () => {
        setSelectedCertificate(undefined);
        setIsWithPluginInitializationError(false);
        setIsSignButtonTemporarilyInactive(false);
        setIsWithResign(true);
        setSelectedFileExtension(undefined);
        setSelectedSignatureType('detached');
    };

    return (
        <div className={s['crypto-pro-signing-input']}>
            {!isSignaturesListHidden && (
                <div className={s['crypto-pro-signing-input__files']}>
                    {(() => {
                        if (isSigning) {
                            return (
                                <div className={s['crypto-pro-signing-input__files__signing']}>
                                    <LoadingOutlined />
                                    Формирование подписей...
                                </div>
                            );
                        }
                        if (!value?.length) {
                            return (
                                <div className={s['crypto-pro-signing-input__files__empty']}>
                                    <IconsMap.FileOutlined />
                                    Файлы подписи не прикреплены
                                </div>
                            );
                        }
                        return value?.map(signatureFile => (
                            <div
                                key={signatureFile?.uid ?? signatureFile.name}
                                className={s['crypto-pro-signing-input__files__file']}
                            >
                                <IconsMap.FileOutlined
                                    className={s['crypto-pro-signing-input__files__file__icon']}
                                />
                                <span className={s['crypto-pro-signing-input__files__file__name']}>
                                    {signatureFile.name}
                                </span>
                                <CloseOutlined
                                    className={s['crypto-pro-signing-input__files__file__icon-close']}
                                    onClick={() => {
                                        onChange?.(value?.filter(v => v.uid !== signatureFile.uid), undefined);
                                    }}
                                />
                            </div>
                        ));
                    })()}
                </div>
            )}

            <ModalOpenerComponent
                ref={modalRef}
                componentWrapperClassNames={className}
                modalProps={{
                    centered: true,
                    forceRender: false,
                    destroyOnClose: true,
                    title: 'Настройка подписи',
                    style: {marginLeft: -14},
                    width: 800,
                }}
                hideControls={{
                    save: isWithPluginInitializationError,
                }}
                disabledControls={{
                    save: !selectedCertificate || isSignButtonTemporarilyInactive || isSigning,
                }}
                controlLabels={{
                    save: (isSignButtonTemporarilyInactive || isSigning) ? (
                        <div>
                            <LoadingOutlined className="mr-1" />
                            Подписать
                        </div>
                    ) : 'Подписать',
                    cancel: isWithPluginInitializationError ? 'Закрыть' : undefined,
                }}
                handleSave={() => {
                    setIsSignButtonTemporarilyInactive(true);
                    setTimeout(() => {
                        setIsSignButtonTemporarilyInactive(false);
                    }, 1000);
                    formRef.current?.submit();
                }}
                onComponentClick={() => {
                    const {filesToSign} = getFilesToSign({
                        form,
                        fileFieldNames,
                        fileList,
                        isWithResign,
                        documentsToSignInDB,
                    });

                    if (isComponentClickPrevented({
                        files: filesToSign,
                        documentsToSignInDB,
                        fileList,
                    })) {
                        showMessage({
                            message: 'Не выбрано ни одного файла для подписания',
                            isError: true,
                        });
                    }
                }}
                preventComponentClick={() => {
                    const {filesToSign} = getFilesToSign({
                        form,
                        fileFieldNames,
                        fileList,
                        isWithResign,
                        documentsToSignInDB,
                    });
                    return isComponentClickPrevented({
                        files: filesToSign,
                        documentsToSignInDB,
                        fileList,
                    });
                }}
                component={showSignComponent ? (
                    showIconOnly
                        ? (isSigning && isSignaturesListHidden)
                            ? (
                                <LoadingOutlined className={cn(
                                    s['crypto-pro-signing-input__sign-icon'],
                                    s['crypto-pro-signing-input__sign-icon_loading'],
                                )}
                                />
                            )
                            : <SafetyCertificateOutlined className={s['crypto-pro-signing-input__sign-icon']} />
                        : (
                            <Button
                                type="primary"
                                style={{minWidth: 131}}
                                disabled={isSignButtonTemporarilyInactive || isSigning}
                            >
                                {(isSigning && isSignaturesListHidden)
                                    ? <LoadingOutlined className={s['crypto-pro-signing-input__sign-icon_loading']} />
                                    : <SafetyCertificateOutlined />}
                                {buttonLabel ?? 'Подписать'}
                            </Button>
                        )
                ) : undefined}
                afterModalClose={resetState}
            >
                <CryptoProSignatureModalContent
                    ref={formRef}
                    selectedCertificate={selectedCertificate}
                    selectedSignatureType={selectedSignatureType}
                    setSelectedCertificate={setSelectedCertificate}
                    setSelectedSignatureType={setSelectedSignatureType}
                    isWithPluginInitializationError={isWithPluginInitializationError}
                    setIsWithPluginInitializationError={setIsWithPluginInitializationError}
                    handleFinish={handleFinish}
                    currentValue={value}
                    fileFieldNames={fileFieldNames}
                    isWithResign={isWithResign}
                    mainForm={form}
                    setIsWithResign={setIsWithResign}
                    setSelectedFileExtension={setSelectedFileExtension}
                    documentsToSignInDB={documentsToSignInDB}
                />
            </ModalOpenerComponent>
        </div>
    );
});
